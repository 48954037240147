var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DragItDude',{ref:"buscador",style:([
    _vm.windowPcVersion
      ? { 'min-width': '335px', width: '335px' }
      : { 'min-width': '850px' },
    { top: '7px' },
    { left: '15px' },
  ]),attrs:{"id":"buscador"}},[_c('div',{staticClass:"modal-content"},[_c('div',{style:([
        _vm.windowPcVersion ? { height: '50px' } : { 'height:': '45px' },
        { 'background-color': '#031D6A' },
      ])},[_c('img',{staticClass:"img-fluid",staticStyle:{"right":"25px","top":"3px !important","position":"absolute"},attrs:{"width":[_vm.windowPcVersion ? '85px' : '185px'],"src":"/img/logoLeCleire.png","alt":""}}),_c('br')]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-select',{attrs:{"loading":_vm.searchLoading,"resetOnOptionsChange":true,"clearable":_vm.clearable,"options":_vm.options,"value":_vm.options.selected,"label":"ERP_DOC"},on:{"search":_vm.fetchOptions,"input":_vm.SenVal}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Ingresa un número de pedido válido")])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }