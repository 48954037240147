<template>
  <div id="app">
    <!-- EXPORTAR A excel -->
    <div
      v-if="loading"
      class="d-flex justify-content-center"
      style="padding-top: 25px"
    >
      <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Cargando...
    </div>
    <div class="row" v-show="!loading">
      <div class="col-md-12" v-if="vistasegmentada">
        <div class="custom-control custom-switch ml-3">
          <input
            @change="fnMostrarPedidosSegmentados()"
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
          />
          <label
            v-if="PedidosAlContado"
            class="custom-control-label"
            for="customSwitch1"
            >{{ PedidosAlContadoTitulo }}</label
          >
          <label
            v-if="!PedidosAlContado"
            class="custom-control-label"
            for="customSwitch1"
            >{{ PedidosAlCreditoTitulo }}</label
          >
        </div>
      </div>
      <div class="col-md-12">
        <download-excel
          class="pull-right btn-exportar-verde"
          style="cursor: pointer"
          :fields="columnasEXCL"
          :fetch="exportTableData"
          :type="dataExportType"
          :name="'Reporte resumen por sector.' + dataExportType"
          >Exportar xls</download-excel
        >
      </div>
    </div>
    <v-client-table
      ref="tablaPedidosSector"
      id="tablaPedidosSector"
      :data="rows"
      :columns="columns"
      :options="options"
      v-show="!loading"
    >
      <div class="progress" slot-scope="props" slot="sectorProgreso">
        <div
          class="progress-bar"
          :class="[
            'progress-bar',
            props.row.porcentaje <= 84.99 ? ' bg-red' : '',
            props.row.porcentaje >= 85 && props.row.porcentaje <= 89.99
              ? ' bg-yellow'
              : '',
            props.row.porcentaje >= 90 && props.row.porcentaje <= 94.99
              ? ' bg-orange'
              : '',
            props.row.porcentaje >= 95 ? ' bg-success' : ''
          ]"
          role="progressbar"
          :style="'width:' + props.row.porcentaje + '%'"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <small
          class="justify-content-center d-flex position-absolute progress-value"
          >{{ props.row.porcentaje }}%</small
        >
      </div>
    </v-client-table>
  </div>
</template>

<script>
import JsonExcel from "vue-json-excel";
export default {
  components: {
    downloadExcel: JsonExcel
  },
  props: [
    "anuladosbodega",
    "pedsconreenvio",
    "pedsnorecibidos",
    "pedidosRezagados",
    "anularPedido",
    "cargarPedido",
    "realizarEntrega",
    "realizarDevolucion"
  ],
  watch: {
    anuladosbodega: function(newVal, oldVal) {
      //
      this.refreshTotales("anuladosbodega");
      //
      console.log("anuladosbodega");
      //
      this.fnRecargarTablaSector();
    },
    pedsconreenvio: function(newVal, oldVal) {
      //
      this.refreshTotales("pedsconreenvio");
      //
      console.log("pedsconreenvio");
      //
      this.fnRecargarTablaSector();
    },
    pedsnorecibidos: function(newVal, oldVal) {
      //
      this.refreshTotales("pedsnorecibidos");
      //
      console.log("pedsnorecibidos");
      //
      this.fnRecargarTablaSector();
    },
    pedidosRezagados: function(newVal, oldVal) {
      //
      this.refreshTotales("pedidosRezagados");
      //
      console.log("pedidosRezagados");
      //
      this.fnRecargarTablaSector();
    },
    anularPedido: function(newVal, oldVal) {
      //
      this.refreshTotales("anularPedido");
      //
      console.log("pedido anulado: " + newVal);
      for (var i in this.rows) {
        if (this.rows[i].sector == newVal.DELIVERY_CLIENT_REGION) {
          console.log("pedido anulado, sector: " + this.rows[i]);
          this.rows[i].total_pedidos = newVal.total_pedidos;
          this.rows[i].pedidos_picking = newVal.pedidos_picking;
          this.rows[i].pedidos_no_app = newVal.pedidos_no_app;
          this.rows[i].pedidos_devueltos = newVal.pedidos_devueltos;
          this.rows[i].pedidos_entregados = newVal.pedidos_entregados;
          this.rows[i].pedidos_pendientes = newVal.pedidos_pendientes;
          this.rows[i].pedidos_anulados = newVal.pedidos_anulados;
          this.rows[i].pedidos_resagados = newVal.pedidos_resagados;
          this.rows[i].porcentaje = (
            ((this.rows[i].pedidos_entregados +
              this.rows[i].pedidos_devueltos +
              this.rows[i].pedidos_anulados) /
              (this.rows[i].total_pedidos -
                this.rows[i].pedidos_picking -
                this.rows[i].pedidos_no_app[i].pedidos_resagados)) *
            100
          ).toFixed(2);
          this.rows[i].ultima_entrega = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
    },
    cargarPedido: function(newVal, oldVal) {
      this.refreshTotales("cargarPedido");
      //this.segmentar(newVal);
    },
    realizarEntrega: function(newVal, oldVal) {
      //
      this.refreshTotales("realizarEntrega");
      //this.segmentar(newVal);
      for (var i in this.rows) {
        if (this.rows[i].sector == newVal.DELIVERY_CLIENT_REGION) {
          console.log(
            this.rows[i].sector + "===" + newVal.DELIVERY_CLIENT_REGION
          );
          this.rows[i].pedidos_entregados++;
          this.rows[i].pedidos_pendientes--;
          this.rows[i].porcentaje = (
            ((this.rows[i].pedidos_entregados +
              this.rows[i].pedidos_devueltos +
              this.rows[i].pedidos_anulados) /
              (this.rows[i].total_pedidos -
                this.rows[i].pedidos_picking -
                this.rows[i].pedidos_no_app[i].pedidos_resagados)) *
            100
          ).toFixed(2);
          this.rows[i].ultima_entrega = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
    },
    realizarDevolucion: function(newVal, oldVal) {
      //
      this.refreshTotales("realizarDevolucion");
      //this.segmentar(newVal);
      for (var i in this.rows) {
        if (this.rows[i].sector == newVal.DELIVERY_CLIENT_REGION) {
          this.rows[i].pedidos_devueltos++;
          this.rows[i].pedidos_pendientes--;
          this.rows[i].porcentaje = (
            ((this.rows[i].pedidos_entregados +
              this.rows[i].pedidos_devueltos +
              this.rows[i].pedidos_anulados) /
              (this.rows[i].total_pedidos -
                this.rows[i].pedidos_picking -
                this.rows[i].pedidos_no_app[i].pedidos_resagados)) *
            100
          ).toFixed(2);
          this.rows[i].ultima_entrega = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
    }
  },
  data() {
    return {
      vistasegmentada: false,
      PedidosAlContado: true,
      PedidosAlContadoTitulo: "Pedidos al contado",
      PedidosAlCreditoTitulo: "Pedidos al credito",
      loading: true,
      totales: [],
      columnasEXCL: {
        Sector: "sector",
        "Total pedidos": "total_pedidos",
        Entregados: "pedidos_entregados",
        Picking: "pedidos_picking",
        "Engregados sin app": "pedidos_no_app",
        Devolucion: "pedidos_devueltos",
        Pendientes: "pedidos_pendientes",
        Rezagados: "pedidos_resagados",
        Anulados: "pedidos_anulados",
        "Ultima entrega": "ultima_entrega_REPORTE",
        "Progreso (%)": "porcentaje"
      },
      //
      dataExportType: "xls",
      //
      codigoDevolucion: "",
      ventanaInformacionDeEntregaDevolucion: null,
      ventanaInformacionDeEntrega: null,
      // seccion de manifiestos anexos
      entregaERPDOCDevolucion: null,
      entregaERPDOCDetalle: null,
      //
      anexosDeManifiesto: [],
      //fin
      columns: [
        "sector",
        "RUTAS",
        "total_pedidos",
        "pedidos_entregados",
        "pedidos_pendientes",

        "pedidos_picking",
        "pedidos_no_app",
        "pedidos_devueltos",
        "pedidos_anulados",
        "pedidos_resagados",
        "PEDIDO_REENVIO",
        "PEDIDO_NO_REENVIO",
        "ANULADO_EN_BODEGA",

        "ultima_entrega",
        "sectorProgreso",
        "porcentaje"
      ],
      rows: [],
      options: {
        footerHeadings: true,
        rowClassCallback(row) {
          //return "d-flex";
        },
        // columnsClasses: {
        //   sector: "col-1",
        //   RUTAS: "col-1",
        //   total_pedidos: "col-1",
        //   pedidos_entregados: "col-1",
        //   pedidos_pendientes: "col-1",
        //   pedidos_resagados: "col-1",
        //   pedidos_picking: "col-1",
        //   pedidos_no_app: "col-1",
        //   pedidos_devueltos: "col-1",
        //   pedidos_anulados: "col-1",
        //   ultima_entrega: "col-2",
        //   sectorProgreso: "col-1",
        //   porcentaje: "col-1",
        // },
        hiddenColumns: ["porcentaje"],
        orderBy: {
          column: "porcentaje",
          ascending: false
        },
        customSorting: {
          porcentaje: function(ascending) {
            return function(a, b) {
              var lastA = parseFloat(a.porcentaje, 2);
              var lastB = parseFloat(b.porcentaje, 2);

              if (ascending) return lastA >= lastB ? 1 : -1;

              return lastA <= lastB ? 1 : -1;
            };
          },
          sectorProgreso: function(ascending) {
            return function(a, b) {
              var lastA = parseFloat(a.porcentaje, 2);
              var lastB = parseFloat(b.porcentaje, 2);

              if (ascending) return lastA >= lastB ? 1 : -1;

              return lastA <= lastB ? 1 : -1;
            };
          }
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar ",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Cargando...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["ultima_entrega"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["ultima_entrega", "sector", "RUTAS"],
        headings: {
          sector: "SECTOR",
          RUTAS: "Rutas",
          total_pedidos: "total de pedidos",
          pedidos_picking: "En planta",
          pedidos_no_app: "Entregados sin APP",
          pedidos_entregados: "Entregados",
          pedidos_devueltos: "Devoluciones",
          PEDIDO_REENVIO: "Pedidos reenvios",
          PEDIDO_NO_REENVIO: "Pedidos no recibidos",
          ANULADO_EN_BODEGA: "Anulados en asignación",
          pedidos_pendientes: "En ruta",
          pedidos_resagados: "Rezagados",
          pedidos_anulados: "Anulados",
          ultima_entrega: "ultima entrega",
          sectorProgreso: "PORCENTAJE",
          PORCENTAJE: "PORCENTAJE"
        },

        cellClasses: {
          PEDIDO_REENVIO: [
            {
              class: "pedido-reenvio",
              condition: row => row.PEDIDO_REENVIO >= 0
            }
          ],
          PEDIDO_NO_REENVIO: [
            {
              class: "pedido-no-recibido",
              condition: row => row.PEDIDO_NO_REENVIO >= 0
            }
          ],
          ANULADO_EN_BODEGA: [
            {
              class: "pedido-anulado-bodega",
              condition: row => row.ANULADO_EN_BODEGA >= 0
            }
          ]
        }
      }
    };
  },
  methods: {
    fnMostrarPedidosSegmentados() {
      this.loading = true;
      this.PedidosAlContado = !this.PedidosAlContado;
      this.fnRecargarPedidosPorContadoYsector();
      console.log(this.PedidosAlContado);
    },
    fnRecargarPedidosPorContadoYsector() {
      if (
        this.$route.path == "/alcontadoycredito" &&
        this.PedidosAlContado == true
      ) {
        this.$store
          .dispatch("Entregassectorcontado/find", {
            query: {}
          })
          .then(response => {
            this.rows = [];
            console.log("mostrando pedidos al contado");
            this.fnArmarTotalesContado();
            response.forEach(SECTOR => {
              //DELIVERY_DATE
              if (SECTOR.ultima_entrega !== null) {
                SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
                //
                SECTOR.ultima_entrega = moment(
                  SECTOR.ultima_entrega,
                  "DD-MM-YYYY HH:mm"
                );
              } else {
                SECTOR.ultima_entrega_REPORTE = "N/A";
                SECTOR.ultima_entrega = "N/A";
              }

              // calculo de datos para mostrar segun total de pedidos en porcentaje
              if (
                SECTOR.total_pedidos -
                  SECTOR.pedidos_picking -
                  SECTOR.pedidos_no_app -
                  SECTOR.pedidos_resagados -
                  SECTOR.pedidos_anulados ==
                0
              )
                SECTOR.porcentaje = 0;
              else {
                SECTOR.porcentaje = (
                  ((SECTOR.pedidos_entregados +
                    SECTOR.pedidos_devueltos +
                    SECTOR.pedidos_anulados) /
                    (SECTOR.total_pedidos -
                      SECTOR.pedidos_picking -
                      SECTOR.pedidos_no_app)) *
                  100
                ).toFixed(2);
              }

              this.rows.push(SECTOR);
            });
            this.loading = false;
          });
      }
      ////////////////////// FIN SECCION PEDIDOS AL CONTADO

      if (
        this.$route.path == "/alcontadoycredito" &&
        this.PedidosAlContado == false
      ) {
        this.$store
          .dispatch("Entregassectorcredito/find", {
            query: {}
          })
          .then(response => {
            this.rows = [];
            this.fnArmarTotalesCredito();
            console.log("mostrando pedidos al credito");
            response.forEach(SECTOR => {
              //DELIVERY_DATE
              if (SECTOR.ultima_entrega !== null) {
                SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
                //
                SECTOR.ultima_entrega = moment(
                  SECTOR.ultima_entrega,
                  "DD-MM-YYYY HH:mm"
                );
              } else {
                SECTOR.ultima_entrega_REPORTE = "N/A";
                SECTOR.ultima_entrega = "N/A";
              }

              // calculo de datos para mostrar segun total de pedidos en porcentaje
              if (
                SECTOR.total_pedidos -
                  SECTOR.pedidos_picking -
                  SECTOR.pedidos_no_app -
                  SECTOR.pedidos_resagados -
                  SECTOR.pedidos_anulados ==
                0
              )
                SECTOR.porcentaje = 0;
              else {
                SECTOR.porcentaje = (
                  ((SECTOR.pedidos_entregados +
                    SECTOR.pedidos_devueltos +
                    SECTOR.pedidos_anulados) /
                    (SECTOR.total_pedidos -
                      SECTOR.pedidos_picking -
                      SECTOR.pedidos_no_app)) *
                  100
                ).toFixed(2);
              }

              this.rows.push(SECTOR);
            });
            this.loading = false;
          });
      }
      ///////////////////////////////////////////////////////// fin seccion contados credito
    },
    fnRecargarTablaSector() {
      this.$store
        .dispatch("Entregassector/find", {
          query: {}
        })
        .then(response => {
          this.rows = [];
          response.forEach(SECTOR => {
            //DELIVERY_DATE
            if (SECTOR.ultima_entrega !== null) {
              SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
              //
              SECTOR.ultima_entrega = moment(
                SECTOR.ultima_entrega,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              SECTOR.ultima_entrega_REPORTE = "N/A";
              SECTOR.ultima_entrega = "N/A";
            }

            // calculo de datos para mostrar segun total de pedidos en porcentaje
            if (
              SECTOR.total_pedidos -
                SECTOR.pedidos_picking -
                SECTOR.pedidos_no_app -
                SECTOR.pedidos_resagados -
                SECTOR.pedidos_anulados ==
              0
            )
              SECTOR.porcentaje = 0;
            else {
              SECTOR.porcentaje = (
                ((SECTOR.pedidos_entregados +
                  SECTOR.pedidos_devueltos +
                  SECTOR.pedidos_anulados) /
                  (SECTOR.total_pedidos -
                    SECTOR.pedidos_picking -
                    SECTOR.pedidos_no_app)) *
                100
              ).toFixed(2);
            }

            this.rows.push(SECTOR);
          });
        });
    },
    exportTableData() {
      return this.$refs.tablaPedidosSector.allFilteredData; // Change "tablaPedidosSector" to your ref
    },
    refreshTotales(status) {
      console.log("refresh: " + status);
      if (
        this.$route.path == "/alcontadoycredito" &&
        this.PedidosAlContado == true
      ) {
        this.$store
          .dispatch("Armartotalesporsectorcontado/find", {
            query: {}
          })
          .then(response => {
            this.totales = response;

            document.querySelector(
              "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
            ).innerHTML = this.totales[0].total_pedidos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_entregados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_pendientes;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_resagados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_picking;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_no_app;

            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
            ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_devueltos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_anulados;
          });
      }
      if (
        this.$route.path == "/alcontadoycredito" &&
        this.PedidosAlContado == false
      ) {
        this.$store
          .dispatch("Armartotalesporsectorcredito/find", {
            query: {}
          })
          .then(response => {
            this.totales = response;

            document.querySelector(
              "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
            ).innerHTML = this.totales[0].total_pedidos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_entregados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_pendientes;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_resagados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_picking;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_no_app;

            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
            ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_devueltos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_anulados;
          });
      }
      if (this.$route.path != "/alcontadoycredito") {
        this.$store
          .dispatch("Armartotalesporsector/find", {
            query: {}
          })
          .then(response => {
            this.totales = response;

            document.querySelector(
              "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
            ).innerHTML = this.totales[0].total_pedidos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_entregados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_pendientes;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_resagados;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_picking;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_no_app;

            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
            ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
            document.querySelector(
              "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
            ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;

            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_devueltos;
            document.querySelector(
              "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
            ).innerHTML = this.totales[0].pedidos_anulados;
          });
      }
    },
    armarTotales() {
      var elems = document.querySelectorAll(
        "tfoot > tr > th > span.VueTables__sort-icon.pull-right.fa.fa-sort"
      );
      var index = 0,
        length = elems.length;
      for (; index < length; index++) {
        elems[index].style.display = "none";
      }
      document.querySelector(
        "tfoot > tr > #VueTables_th--sector > span.VueTables__heading"
      ).innerHTML = "Totales:";
      document.querySelector(
        "tfoot > tr > #VueTables_th--RUTAS > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--ultima_entrega > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--sectorProgreso > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector("tfoot > tr").style.backgroundColor = "#031D6A";
      document.querySelector("tfoot > tr").style.color = "#FFFFFF";
      //
      this.$store
        .dispatch("Armartotalesporsector/find", {
          query: {}
        })
        .then(response => {
          this.totales = response;
          document.querySelector(
            "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
          ).innerHTML = this.totales[0].total_pedidos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_entregados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_pendientes;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_resagados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_picking;

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_no_app;

          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.backgroundColor = "#bbdefb";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.color = "#000000";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.backgroundColor = "#fce4ec";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
          ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.backgroundColor = "#fbfcd1";
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_devueltos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_anulados;
          //
          this.loading = false;
        });
      //
    },
    fnArmarTotalesContado() {
      var elems = document.querySelectorAll(
        "tfoot > tr > th > span.VueTables__sort-icon.pull-right.fa.fa-sort"
      );
      var index = 0,
        length = elems.length;
      for (; index < length; index++) {
        elems[index].style.display = "none";
      }
      document.querySelector(
        "tfoot > tr > #VueTables_th--sector > span.VueTables__heading"
      ).innerHTML = "Totales:";
      document.querySelector(
        "tfoot > tr > #VueTables_th--RUTAS > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--ultima_entrega > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--sectorProgreso > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector("tfoot > tr").style.backgroundColor = "#031D6A";
      document.querySelector("tfoot > tr").style.color = "#FFFFFF";
      //
      this.$store
        .dispatch("Armartotalesporsectorcontado/find", {
          query: {}
        })
        .then(response => {
          this.totales = response;
          document.querySelector(
            "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
          ).innerHTML = this.totales[0].total_pedidos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_entregados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_pendientes;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_resagados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_picking;

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_no_app;

          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.backgroundColor = "#bbdefb";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.color = "#000000";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.backgroundColor = "#fce4ec";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
          ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.backgroundColor = "#fbfcd1";
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_devueltos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_anulados;
          //
          this.loading = false;
        });
      //
    },
    fnArmarTotalesCredito() {
      var elems = document.querySelectorAll(
        "tfoot > tr > th > span.VueTables__sort-icon.pull-right.fa.fa-sort"
      );
      var index = 0,
        length = elems.length;
      for (; index < length; index++) {
        elems[index].style.display = "none";
      }
      document.querySelector(
        "tfoot > tr > #VueTables_th--sector > span.VueTables__heading"
      ).innerHTML = "Totales:";
      document.querySelector(
        "tfoot > tr > #VueTables_th--RUTAS > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--ultima_entrega > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector(
        "tfoot > tr > #VueTables_th--sectorProgreso > span.VueTables__heading"
      ).innerHTML = "";
      document.querySelector("tfoot > tr").style.backgroundColor = "#031D6A";
      document.querySelector("tfoot > tr").style.color = "#FFFFFF";
      //
      this.$store
        .dispatch("Armartotalesporsectorcredito/find", {
          query: {}
        })
        .then(response => {
          this.totales = response;
          document.querySelector(
            "tfoot > tr > #VueTables_th--total_pedidos > span.VueTables__heading"
          ).innerHTML = this.totales[0].total_pedidos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_entregados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_entregados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_pendientes > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_pendientes;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_resagados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_resagados;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_picking > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_picking;

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_no_app > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_no_app;

          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.backgroundColor = "#bbdefb";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_REENVIO"
          ).style.color = "#000000";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO > span.VueTables__heading"
          ).innerHTML = this.totales[0].PEDIDOS_NO_REENVIO;
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.backgroundColor = "#fce4ec";
          document.querySelector(
            "tfoot > tr > #VueTables_th--PEDIDO_NO_REENVIO"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA > span.VueTables__heading"
          ).innerHTML = this.totales[0].ANULADOS_EN_BODEGA;
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.backgroundColor = "#fbfcd1";
          document.querySelector(
            "tfoot > tr > #VueTables_th--ANULADO_EN_BODEGA"
          ).style.color = "#000000";

          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_devueltos > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_devueltos;
          document.querySelector(
            "tfoot > tr > #VueTables_th--pedidos_anulados > span.VueTables__heading"
          ).innerHTML = this.totales[0].pedidos_anulados;
          //
          this.loading = false;
        });
      //
    }
  },
  mounted() {
    // activar scroll de la pagina por la excesiva cantidad de registros
    document.querySelector(
      "body > div:nth-child(1) > div.wrapper"
    ).style.overflow = "scroll";

    if (
      this.$route.path == "/alcontadoycredito" &&
      this.PedidosAlContado == true
    ) {
      this.vistasegmentada = true;
      this.$store
        .dispatch("Entregassectorcontado/find", {
          query: {}
        })
        .then(response => {
          response.forEach(SECTOR => {
            //DELIVERY_DATE
            if (SECTOR.ultima_entrega !== null) {
              SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
              //
              SECTOR.ultima_entrega = moment(
                SECTOR.ultima_entrega,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              SECTOR.ultima_entrega_REPORTE = "N/A";
              SECTOR.ultima_entrega = "N/A";
            }

            // calculo de datos para mostrar segun total de pedidos en porcentaje
            if (
              SECTOR.total_pedidos -
                SECTOR.pedidos_picking -
                SECTOR.pedidos_no_app -
                SECTOR.pedidos_resagados -
                SECTOR.pedidos_anulados ==
              0
            )
              SECTOR.porcentaje = 0;
            else {
              SECTOR.porcentaje = (
                ((SECTOR.pedidos_entregados +
                  SECTOR.pedidos_devueltos +
                  SECTOR.pedidos_anulados) /
                  (SECTOR.total_pedidos -
                    SECTOR.pedidos_picking -
                    SECTOR.pedidos_no_app)) *
                100
              ).toFixed(2);
            }

            this.rows.push(SECTOR);
          });

          //

          document.querySelector(
            "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
          ).style.display = "block";

          ///
          this.fnArmarTotalesContado();
        });
    }
    ////////////////////// FIN SECCION PEDIDOS AL CONTADO

    if (
      this.$route.path == "/alcontadoycredito" &&
      this.PedidosAlContado == false
    ) {
      this.vistasegmentada = true;
      this.$store
        .dispatch("Entregassectorcredito/find", {
          query: {}
        })
        .then(response => {
          response.forEach(SECTOR => {
            //DELIVERY_DATE
            if (SECTOR.ultima_entrega !== null) {
              SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
              //
              SECTOR.ultima_entrega = moment(
                SECTOR.ultima_entrega,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              SECTOR.ultima_entrega_REPORTE = "N/A";
              SECTOR.ultima_entrega = "N/A";
            }

            // calculo de datos para mostrar segun total de pedidos en porcentaje
            if (
              SECTOR.total_pedidos -
                SECTOR.pedidos_picking -
                SECTOR.pedidos_no_app -
                SECTOR.pedidos_resagados -
                SECTOR.pedidos_anulados ==
              0
            )
              SECTOR.porcentaje = 0;
            else {
              SECTOR.porcentaje = (
                ((SECTOR.pedidos_entregados +
                  SECTOR.pedidos_devueltos +
                  SECTOR.pedidos_anulados) /
                  (SECTOR.total_pedidos -
                    SECTOR.pedidos_picking -
                    SECTOR.pedidos_no_app)) *
                100
              ).toFixed(2);
            }

            this.rows.push(SECTOR);
          });

          //

          document.querySelector(
            "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
          ).style.display = "block";

          ///
          this.fnArmarTotalesCredito();
        });
    }
    ///////////////////////////////////////////////////////// fin seccion contados credito
    if (this.$route.path != "/alcontadoycredito") {
      this.vistasegmentada = false;
      this.$store
        .dispatch("Entregassector/find", {
          query: {}
        })
        .then(response => {
          response.forEach(SECTOR => {
            //DELIVERY_DATE
            if (SECTOR.ultima_entrega !== null) {
              SECTOR.ultima_entrega_REPORTE = SECTOR.ultima_entrega;
              //
              SECTOR.ultima_entrega = moment(
                SECTOR.ultima_entrega,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              SECTOR.ultima_entrega_REPORTE = "N/A";
              SECTOR.ultima_entrega = "N/A";
            }

            // calculo de datos para mostrar segun total de pedidos en porcentaje
            if (
              SECTOR.total_pedidos -
                SECTOR.pedidos_picking -
                SECTOR.pedidos_no_app -
                SECTOR.pedidos_resagados -
                SECTOR.pedidos_anulados ==
              0
            )
              SECTOR.porcentaje = 0;
            else {
              SECTOR.porcentaje = (
                ((SECTOR.pedidos_entregados +
                  SECTOR.pedidos_devueltos +
                  SECTOR.pedidos_anulados) /
                  (SECTOR.total_pedidos -
                    SECTOR.pedidos_picking -
                    SECTOR.pedidos_no_app)) *
                100
              ).toFixed(2);
            }

            this.rows.push(SECTOR);
          });

          //

          document.querySelector(
            "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
          ).style.display = "block";

          ///
          this.armarTotales();
        });
    }
    ////////////////////////////////////////fin sección RESUMEN DE PEDIDOS POR SECTOR
  }
};
</script>
<style>
.pedido-reenvio {
  background-color: #bbdefb !important;
}
.pedido-no-recibido {
  background-color: #fce4ec !important;
}
.pedido-anulado-bodega {
  background-color: #fbfcd1 !important;
}
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.table-responsive {
  overflow: hidden !important;
}
.progress {
  height: 13px !important;
}
.progress-value {
  width: 4.75%;
  color: white;
  font-weight: bold;
}
.bg-red {
  background-color: #eb2b2b !important;
}
.bg-orange {
  background-color: #fbb002 !important;
}
.bg-yellow {
  background-color: #ecfb02 !important;
}
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
#app > div.VueTables.VueTables--client > div:nth-child(1) > div {
  display: block !important;
}
.VueTables__limit-field {
  display: block !important;
}
.block {
  width: 160px;
  background-color: hotpink;
}
tfoot > tr > th > span.VueTables__sort-icon.pull-right.fa.fa-sort {
  display: none;
}
</style>
