<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12">
        <div class="select-campania pull-right">
          <label for="campania">Seleccionar campaña</label>
          <select
            class="form-control"
            @change="manifiestosPorCampania"
            name="campania"
          >
            <option
              v-for="(item, index) in campanias"
              :key="index"
              :value="item.campania"
            >
              Campaña {{ item.campania }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <v-client-table
      :data="rows"
      :columns="columns"
      :options="options"
      name="manifiestosTable"
    >
      <div slot="filter__anexos">
        <select class="form-control" @change="filtroAnexos">
          <option value>Todos</option>
          <option value="1">Con anexos</option>
          <option value="2">Sin anexos</option>
        </select>
      </div>
      <button
        slot-scope="props"
        class="btn btn-sm"
        slot="anexos"
        data-toggle="modal"
        data-target="#modalAnexosManifiestos"
        v-bind:class="
          props.row.cantidad_anexos == 0 ? 'btn-danger' : 'btn-success'
        "
        @click="
          fnAsignaranexoManifiestoRouteId(
            props.row.MANIFEST_ID,
            props.row.MANIFEST_DB_ID
          )
        "
      >
        Anexos
      </button>
      <a
        slot="pdf"
        slot-scope="props"
        class="fa fa-file-pdf"
        target="_blank"
        :href="
          VUE_APP_API_URL +
            'generar-manifiesto?compuesta=' +
            props.row.MANIFEST_ID +
            '&correlativa=' +
            props.row.MANIFEST_DB_ID
        "
        download="download"
      ></a>
    </v-client-table>

    <RampasManifiestosModalAnexos
      v-bind:anexoManifiestoRouteId="anexoManifiestoId"
      v-bind:MANIFEST_DB_ID="MANIFEST_DB_ID"
      @anexoAdjuntado="anexoAdjuntado($event)"
      @anexoEliminado="anexoEliminado($event)"
    ></RampasManifiestosModalAnexos>
  </div>
</template>

<script>
import { Event } from "vue-tables-2";
import { mapState, mapGetters, mapActions } from "vuex";
import RampasManifiestosModalAnexos from "@/pages/ModuloRampas/RampasManifiestosModalAnexos.vue";
export default {
  name: "vistaManifiestos",
  components: { RampasManifiestosModalAnexos },
  data() {
    return {
      // seccion de manifiestos anexos
      MANIFEST_DB_ID: "",
      anexoManifiestoId: "",
      anexosDeManifiesto: [],
      //fin
      campanias: [],
      campaniaSelected: "",
      columns: [
        "MANIFEST_DB_ID",
        "MANIFEST_ID",
        "PEDIDOS",
        "consejeras",
        "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
        "DELIVERY_COMPANY_NAME",
        "total_pedidos",
        "inicio_carga",
        "anexos",
        "pdf"
      ],
      rows: [],
      options: {
        templates: {
          PEDIDOS: function(h, row, index) {
            if (row.PEDIDOS == null) {
              return "Pedidos asignados";
            } else if (row.PEDIDOS.length <= 20) {
              return row.PEDIDOS;
            }

            let text = row.PEDIDOS.substring(0, 12) + " ";
            let last = text.lastIndexOf(" ");
            text = text.substring(0, last);
            return text + "...";
          },
          consejeras: function(h, row, index) {
            if (row.consejeras == null) {
              return "N/A";
            }
            let consejera = row.consejeras.toLowerCase();
            if (consejera.length <= 40) {
              return consejera;
            }

            let text = consejera.substring(0, 40) + " ";
            let last = text.lastIndexOf(" ");
            text = text.substring(0, last);
            return text + "...";
          }
        },
        orderBy: {
          column: "MANIFEST_DB_ID",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se han generado manifiestos...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "consejeras",
          "PEDIDOS",
          "MANIFEST_DB_ID",
          "MANIFEST_ID",
          "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
          "DELIVERY_COMPANY_NAME",
          "inicio_carga"
        ],
        headings: {
          consejeras: "Consejer@",
          PEDIDOS: "Pedidos",
          MANIFEST_DB_ID: "Manifiesto",
          MANIFEST_ID: "PLACA/RUTA/CAMPAÑA",
          DELIVERY_ASSIGNED_TO_DRIVER_NAME: "Nombre de piloto",
          DELIVERY_COMPANY_NAME: "Nombre de empresa",
          total_pedidos: "Cantidad de pedidos",
          inicio_carga: "Fecha de carga",
          pdf: "Imprimir PDF",
          anexos: "anexos"
        },
        customFilters: [
          {
            name: "filtrar-anexos",
            callback: function(row, query) {
              if (query == 1) {
                return row.cantidad_anexos > 0;
              } else if (query == 2) {
                return row.cantidad_anexos == 0;
              } else {
                return row;
              }
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("Serviciorampamanifiesto", {
      areServiciorampamanifiestoLoading: "isFindPending"
    }),
    ...mapGetters("Serviciorampamanifiesto", {
      findServiciorampamanifiestoInStore: "find"
    }),
    ...mapGetters("Campanias", {
      findCampaniasInStore: "find"
    }),
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    }
  },
  methods: {
    ...mapActions("Serviciorampamanifiesto", {
      findServiciorampamanifiesto: "find"
    }),
    ...mapActions("Campanias", {
      findCampanias: "find"
    }),
    fnAsignaranexoManifiestoRouteId(idManifiesto, MANIFEST_DB_ID) {
      this.anexoManifiestoId = idManifiesto;
      this.MANIFEST_DB_ID = MANIFEST_DB_ID;
    },
    manifiestosPorCampania(event) {
      this.rows = [];
      this.campaniaSelected = event.target.value;
      this.cargarManifiestos({ CAMPAING_ID: this.campaniaSelected });
    },
    cargarManifiestos(query) {
      this.findServiciorampamanifiesto({ query: query }).then(response => {
        console.log(response);
        response.forEach(manifiesto => {
          //

          //
          if (manifiesto.MANIFEST_DB_ID == null) {
            manifiesto.MANIFEST_DB_ID = "n/a";
          }
          if (manifiesto.MANIFEST_ID != null) {
            //manifiesto.MANIFEST_ID = manifiesto.MANIFEST_ID.replace(/--/g, "/");
            const newManifiesto = {
              consejeras: manifiesto.consejeras,
              PEDIDOS: manifiesto.PEDIDOS,
              MANIFEST_DB_ID: manifiesto.MANIFEST_DB_ID,
              MANIFEST_ID: manifiesto.MANIFEST_ID,
              DELIVERY_ASSIGNED_TO_DRIVER_NAME:
                manifiesto.DELIVERY_ASSIGNED_TO_DRIVER_NAME,
              DELIVERY_COMPANY_NAME: manifiesto.DELIVERY_COMPANY_NAME,
              total_pedidos: manifiesto.total_pedidos,

              inicio_carga: moment.utc(
                new Date(manifiesto.inicio_carga).toISOString()
              ),
              cantidad_anexos: manifiesto.cantidad_anexos
            };

            this.rows.push(newManifiesto);
          }
        });
      });
    },
    filtroAnexos(query) {
      let value = query.target.value;
      Event.$emit("vue-tables.manifiestosTable.filter::filtrar-anexos", value);
    },
    anexoAdjuntado(event) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].MANIFEST_ID === event) {
          this.rows[i].cantidad_anexos = this.rows[i].cantidad_anexos + 1;
        }
      }
    },
    anexoEliminado(event) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].MANIFEST_ID === event) {
          const cantidadAnexos = this.rows[i].cantidad_anexos - 1;
          this.rows[i].cantidad_anexos =
            cantidadAnexos < 0 ? 0 : cantidadAnexos;
        }
      }
    }
  },
  mounted() {
    const { Cargarpedido } = this.$FeathersVuex.api;
    Cargarpedido.on("patched", pedido => {
      console.log(pedido);
    });
  },
  created() {
    this.findCampanias({ query: {} }).then(response => {
      this.campanias = response;
    });
    // Find all Serviciorampamanifiesto. We'll use the getters to separate them.
    this.cargarManifiestos();
  }
};
</script>
<style>
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.table-responsive {
  overflow: hidden !important;
}
.select-campania {
  width: 300px;
  margin: 10px 0px;
}
</style>
