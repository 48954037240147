<template>
  <dragItDude
    id="vistaGraficaEntregas"
    :style="[windowPcVersion ? {'width':'595px'} : {'width':'1330px'},{'top':'7px'}, {'left':'15px'}]"
  >
    <div class="modal-content">
      <!-- animated bounce -->
      <!-- <div class="modal-header">
        <h1 style="margin-left: 25px;">
          Resumen de rutas {{ rutasPedidosEntregados }} /
          {{ rutasPedidosDevolucion }} /
          {{ rutasPedidosPendientes }}
        </h1>
        <img
          width="280px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />
      </div>-->
      <div :style="[windowPcVersion? {'height':'25px'} : {'height:':'45px'}]">
        <!-- switch y titulo encab ezado -->
        <div class="switchEncabezado">
          <!-- switch -->
          <div class="custom-control custom-switch">
            <input
              @click="toggleWindowVersion()"
              type="checkbox"
              class="custom-control-input"
              id="siwthGraficaSectorRuta"
            />
            <label class="custom-control-label" for="siwthGraficaSectorRuta"></label>
          </div>
          <!-- fin switch -->
        </div>
        <!-- fin -->
        <img
          :width="[windowPcVersion ? '85px':'185px']"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />
        <br />
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- SECTOR******************* -->

          <div class="col-md-6">
            <!-- grafica por sector -->
            <div class="form-group">
              <label for="selectSector">
                Resumen sector
                <span :class="[windowPcVersion ? '':'modal-encabezadoTv']">
                  <strong class="mr-2">({{ graficaPorSectorId }})</strong>
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#41B883"
                  >{{ rutasPedidosEntregados2 }}</span> /
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#7e57c2;"
                  >{{ rutasPedidosDevolucion2 }}</span> /
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#e46953;"
                  >{{ rutasPedidosPendientes2 }}</span>
                </span>
              </label>
              <select
                v-model="firstOption"
                class
                id="selectSector"
                @change="mostrarResumenSector(firstOption)"
              >
                <option selected>Seleccione un sector</option>
                <option
                  v-for="(item, index) in resumenporsectoryruta"
                  :key="index"
                  :value="index"
                >{{ item.sector }}</option>
              </select>
            </div>

            <reactive-bar-chart :chartData="chartData2"></reactive-bar-chart>
          </div>

          <!-- RUTA******************** -->
          <div class="col-md-6">
            <!-- grafica por ruta -->
            <div class="form-group">
              <label for="selectRuta">
                Resumen ruta
                <span :class="[windowPcVersion ? '':'modal-encabezadoTv']">
                  <strong class="mr-2">({{ graficaPorRutaId }})</strong>
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#41B883"
                  >{{ rutasPedidosEntregados }}</span> /
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#7e57c;"
                  >{{ rutasPedidosDevolucion }}</span> /
                  <span
                    class="resumenGraficaCantidad"
                    style="color:#e46953;"
                  >{{ rutasPedidosPendientes }}</span>
                </span>
              </label>
              <select
                v-model="secondOption"
                v-if="firstOption != null"
                class
                id="selectRuta"
                @change="mostrarResumenRuta($event)"
              >
                <option
                  v-for="(option, index) in resumenporsectoryruta[firstOption].rutas"
                  :key="index"
                  :value="option.ruta"
                >{{option.ruta}}</option>
              </select>
              <select v-if="firstOption == null" class>
                <option value selected disabled>Seleccione un sector</option>
              </select>
            </div>
            <reactive-bar-chart :chartData="chartData"></reactive-bar-chart>
          </div>

          <!-- refresh grafica -->
          <!-- <div class="col-md-12">
            <a href="#" class="pull-right">
              <i class="tim-icons icon-refresh-02"></i>
            </a>
          </div>-->
          <!-- FIN RUTA***************** -->
        </div>
      </div>
    </div>
  </dragItDude>
</template>

<script>
//fin
import ReactiveBarChart from "@/components/Charts/PieChart.js";
import DragItDude from "vue-drag-it-dude";
export default {
  props: ["realizarEntrega", "realizarDevolucion"],
  watch: {
    realizarEntrega: function (newVal, oldVal) {
      if (newVal.DELIVERY_CLIENT_REGION == this.graficaPorSectorId) {
        var self = this;
        setTimeout(function () {
          self.$store
            .dispatch("Engregasmapaprincipalsector/find", {
              query: {
                SECTOR_ID: self.graficaPorSectorId,
              },
            })
            .then((result) => {
              self.graficaResumenSector = [];
              self.rutasPedidosEntregados2 = 0;
              self.rutasPedidosDevolucion2 = 0;
              self.rutasPedidosPendientes2 = 0;
              self.rutasPedidosTotal2 = 0;
              self.chartData2 = null;
              self.fnOnLoad2(result);
              self.graficaResumenSector = result;
            });
        }, 500);
      }
      if (newVal.ROUTE_ID == this.graficaPorRutaId) {
        var self = this;
        setTimeout(function () {
          self.$store
            .dispatch("Engregasmapaprincipalruta/find", {
              query: {
                ROUTE_ID: self.graficaPorRutaId,
              },
            })
            .then((result) => {
              self.resumenEntregasGrafica = [];
              self.rutasPedidosEntregados = 0;
              self.rutasPedidosDevolucion = 0;
              self.rutasPedidosPendientes = 0;
              self.rutasPedidosTotal = 0;
              self.chartData = null;

              self.fnOnLoad(result);
              self.resumenEntregasGrafica = result;
            });
        }, 500);
      }
    },
    realizarDevolucion: function (newVal, oldVal) {
      if (newVal.DELIVERY_CLIENT_REGION == this.graficaPorSectorId) {
        var self = this;
        setTimeout(function () {
          self.$store
            .dispatch("Engregasmapaprincipalsector/find", {
              query: {
                SECTOR_ID: self.graficaPorSectorId,
              },
            })
            .then((result) => {
              self.graficaResumenSector = [];
              self.rutasPedidosEntregados2 = 0;
              self.rutasPedidosDevolucion2 = 0;
              self.rutasPedidosPendientes2 = 0;
              self.rutasPedidosTotal2 = 0;
              self.chartData2 = null;
              self.fnOnLoad2(result);
              self.graficaResumenSector = result;
            });
        }, 500);
      }
      if (newVal.ROUTE_ID == this.graficaPorRutaId) {
        var self = this;
        setTimeout(function () {
          self.$store
            .dispatch("Engregasmapaprincipalruta/find", {
              query: {
                ROUTE_ID: self.graficaPorRutaId,
              },
            })
            .then((result) => {
              self.resumenEntregasGrafica = [];
              self.rutasPedidosEntregados = 0;
              self.rutasPedidosDevolucion = 0;
              self.rutasPedidosPendientes = 0;
              self.rutasPedidosTotal = 0;
              self.chartData = null;

              self.fnOnLoad(result);
              self.resumenEntregasGrafica = result;
            });
        }, 500);
      }
    },
  },
  name: "DashboardMapaPrincipalGraficaSectorRuta",
  components: {
    ReactiveBarChart,
    DragItDude,
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  data() {
    return {
      //
      firstOption: null,
      secondOption: null,
      //
      resumenporsectoryruta: [],
      //
      graficaPorRutaId: "",
      graficaPorSectorId: "",
      //
      windowPcVersion: true,
      //
      resumenEntregasGrafica: [],
      rutasPedidosEntregados: 0,
      rutasPedidosDevolucion: 0,
      rutasPedidosPendientes: 0,
      rutasPedidosTotal: 0,
      chartData: null,
      //
      graficaResumenSector: [],
      rutasPedidosEntregados2: 0,
      rutasPedidosDevolucion2: 0,
      rutasPedidosPendientes2: 0,
      rutasPedidosTotal2: 0,
      chartData2: null,
    };
  },
  methods: {
    mostrarResumenSector(index) {
      this.graficaPorSectorId = this.resumenporsectoryruta[index].sector;
      var self = this;
      setTimeout(function () {
        self.$store
          .dispatch("Engregasmapaprincipalsector/find", {
            query: {
              SECTOR_ID: self.graficaPorSectorId,
            },
          })
          .then((result) => {
            self.graficaResumenSector = [];
            self.rutasPedidosEntregados2 = 0;
            self.rutasPedidosDevolucion2 = 0;
            self.rutasPedidosPendientes2 = 0;
            self.rutasPedidosTotal2 = 0;
            self.chartData2 = null;
            self.fnOnLoad2(result);
            self.graficaResumenSector = result;
          });
      }, 500);
    },
    mostrarResumenRuta(event) {
      this.graficaPorRutaId = event.target.value;
      var self = this;
      setTimeout(function () {
        self.$store
          .dispatch("Engregasmapaprincipalruta/find", {
            query: {
              ROUTE_ID: self.graficaPorRutaId,
            },
          })
          .then((result) => {
            self.resumenEntregasGrafica = [];
            self.rutasPedidosEntregados = 0;
            self.rutasPedidosDevolucion = 0;
            self.rutasPedidosPendientes = 0;
            self.rutasPedidosTotal = 0;
            self.chartData = null;

            self.fnOnLoad(result);
            self.resumenEntregasGrafica = result;
          });
      }, 500);
    },
    //
    toggleWindowVersion() {
      this.windowPcVersion = !this.windowPcVersion;
    },

    //
    fnOnLoad(entregas) {
      //variables globales, sumatoria de entregados y pendientes
      entregas.forEach((element) => {
        this.rutasPedidosEntregados =
          this.rutasPedidosEntregados + element.total_pedidos_entregados;
        this.rutasPedidosDevolucion =
          this.rutasPedidosDevolucion + element.total_pedidos_devolucion;
        this.rutasPedidosPendientes =
          this.rutasPedidosPendientes + element.total_pedidos_pendientes;
      });
      /*************************** */
      //colores
      var canvas = $("#teamDoughnutChart");
      var ctx = document
        .getElementById("pie-chart")
        .getContext("2d")
        .canvas.getContext("2d");
      //backgrounds
      var gradientColors = [
        {
          start: "#ddf8ec",
          end: "#41B883",
        },
        {
          start: "#e46953",
          end: "#fdccc4",
        },
        {
          start: "#fff9c4 ",
          end: "#7e57c2  ",
        },
      ];

      var gradients = [];

      gradientColors.forEach(function (item) {
        var gradient = ctx.createLinearGradient(10, 0, 150, 150);
        gradient.addColorStop(0, item.start);
        gradient.addColorStop(1, item.end);
        gradients.push(gradient);
      });
      //borders
      var gradientColors2 = [
        {
          start: "#fff",
          end: "#41b883",
        },
        {
          start: "#fff",
          end: "#e46953",
        },
        {
          start: "#fff",
          end: "#7e57c2 ",
        },
      ];

      var gradients2 = [];

      gradientColors2.forEach(function (item) {
        var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
        gradient2.addColorStop(0, item.start);
        gradient2.addColorStop(1, item.end);
        gradients2.push(gradient2);
      });

      //
      /********************************** */
      //se genera información, de grafica global
      this.chartData = {
        // labels: ["Entregado", "Pendientes"],
        datasets: [
          {
            borderColor: gradients2,
            borderWidth: ["7", "7", "4"],
            backgroundColor: gradients,
            data: [
              this.rutasPedidosEntregados,
              this.rutasPedidosPendientes,
              this.rutasPedidosDevolucion,
            ],
          },
        ],
      };
    },
    /************************************************************************************* */
    /************************************************************************************* */
    fnOnLoad2(entregas) {
      //variables globales, sumatoria de entregados y pendientes
      entregas.forEach((element) => {
        this.rutasPedidosEntregados2 =
          this.rutasPedidosEntregados2 + element.total_pedidos_entregados;
        this.rutasPedidosDevolucion2 =
          this.rutasPedidosDevolucion2 + element.total_pedidos_devolucion;
        this.rutasPedidosPendientes2 =
          this.rutasPedidosPendientes2 + element.total_pedidos_pendientes;
      });
      /*************************** */
      //colores
      var canvas = $("#teamDoughnutChart");
      var ctx = document
        .getElementById("pie-chart")
        .getContext("2d")
        .canvas.getContext("2d");
      //backgrounds
      var gradientColors = [
        {
          start: "#ddf8ec",
          end: "#41B883",
        },
        {
          start: "#e46953",
          end: "#fdccc4",
        },
        {
          start: "#fff9c4 ",
          end: "#7e57c2  ",
        },
      ];

      var gradients = [];

      gradientColors.forEach(function (item) {
        var gradient = ctx.createLinearGradient(10, 0, 150, 150);
        gradient.addColorStop(0, item.start);
        gradient.addColorStop(1, item.end);
        gradients.push(gradient);
      });
      //borders
      var gradientColors2 = [
        {
          start: "#fff",
          end: "#41b883",
        },
        {
          start: "#fff",
          end: "#e46953",
        },
        {
          start: "#fff",
          end: "#7e57c2 ",
        },
      ];

      var gradients2 = [];

      gradientColors2.forEach(function (item) {
        var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
        gradient2.addColorStop(0, item.start);
        gradient2.addColorStop(1, item.end);
        gradients2.push(gradient2);
      });

      //
      /********************************** */
      //se genera información, de grafica global
      this.chartData2 = {
        // labels: ["Entregado", "Pendientes"],
        datasets: [
          {
            borderColor: gradients2,
            borderWidth: ["7", "7", "4"],
            backgroundColor: gradients,
            data: [
              this.rutasPedidosEntregados2,
              this.rutasPedidosPendientes2,
              this.rutasPedidosDevolucion2,
            ],
          },
        ],
      };
    },
    /************************************************************************************* */
    /************************************************************************************* */
    fnOnPatch(pedido, accion) {
      //ruta id del pedido
      // console.log("fnOnPatch");
      // console.log(pedido);
      let route_id = pedido.MANIFEST_ID.split("--");

      //actualizar tabla de resumen de rutas (GLOBAL)
      this.fnChangeTablaResumenGlobal(
        route_id[1],
        pedido.TOTAL_PEDIDOS,
        accion
      );

      //if entregados y pendientes actualmente son 0, agregar total pedidos para los totales globales
      //actualizar totales
      if (accion == "entrega") {
        this.rutasPedidosEntregados++;
      }
      if (accion == "devolucion") {
        this.rutasPedidosDevolucion++;
      }
      this.rutasPedidosPendientes--;
      /*************************** */
      //colores
      var canvas = $("#teamDoughnutChart");
      var ctx = document
        .getElementById("pie-chart")
        .getContext("2d")
        .canvas.getContext("2d");
      //backgrounds
      var gradientColors = [
        {
          start: "#ddf8ec",
          end: "#41B883",
        },
        {
          start: "#e46953",
          end: "#fdccc4",
        },
        {
          start: "#fff9c4 ",
          end: "#7e57c2",
        },
      ];

      var gradients = [];

      gradientColors.forEach(function (item) {
        var gradient = ctx.createLinearGradient(10, 0, 150, 150);
        gradient.addColorStop(0, item.start);
        gradient.addColorStop(1, item.end);
        gradients.push(gradient);
      });
      //borders
      var gradientColors2 = [
        {
          start: "#fff",
          end: "#41b883",
        },
        {
          start: "#fff",
          end: "#e46953",
        },
        {
          start: "#fff",
          end: "#7e57c2",
        },
      ];

      var gradients2 = [];

      gradientColors2.forEach(function (item) {
        var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
        gradient2.addColorStop(0, item.start);
        gradient2.addColorStop(1, item.end);
        gradients2.push(gradient2);
      });

      //
      /********************************** */
      //se genera información, de grafica global
      this.chartData = {
        // labels: ["Entregado", "Pendientes"],
        datasets: [
          {
            borderColor: gradients2,
            borderWidth: ["7", "7", "4"],
            backgroundColor: gradients,
            data: [
              this.rutasPedidosEntregados,
              this.rutasPedidosPendientes,
              this.rutasPedidosDevolucion,
            ],
          },
        ],
      };
    },
    fnChangeTablaResumenGlobal(ROUTE_ID, TOTAL_PEDIDOS, accion) {
      // console.log(
      //   this.resumenEntregasGrafica + ", " + ROUTE_ID + ", " + TOTAL_PEDIDOS
      // );
      /*************************************** */
      //si el arreglo de rutas se encuentra vacio
      if (this.resumenEntregasGrafica == []) {
        //TODO, verficar por que se esta ejecutando esta fucnión, si se supone que la region ya existe
        //console.log("resumenEntregasGrafica vacio");
        this.fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion);
      }
      /********************************* */
      //si existe 1 o mas registros de rutas en el arreglo
      for (var i in this.resumenEntregasGrafica) {
        //si existe la ruta, se le suman entregados y se le resta pendientes
        // console.log(
        //   this.resumenEntregasGrafica[i].ROUTE_ID +
        //     "  --->" +
        //     ROUTE_ID +
        //     "&&" +
        //     TOTAL_PEDIDOS
        // );
        /************************************ */
        // si la ruta ya existe en el arreglo
        if (this.resumenEntregasGrafica[i].ROUTE_ID == ROUTE_ID) {
          if (accion == "entrega") {
            this.resumenEntregasGrafica[i].total_pedidos_entregados++;
          }
          if (accion == "devolucion") {
            this.resumenEntregasGrafica[i].total_pedidos_devolucion++;
          }

          this.resumenEntregasGrafica[i].total_pedidos_pendientes--;

          break; //Stop this loop, we found it!
        }
        /******************************** */
        //si la ruta no existe en el arreglo
        /*********************************** */
        //si el arreglo no se encuentra la ruta

        /************************************************ */
        //si el arreglo de rutas no se encuentra vacio pero no se ncuentra la nueva ruta
        if (i >= this.resumenEntregasGrafica.length - 1) {
          if (this.resumenEntregasGrafica[i].ROUTE_ID != ROUTE_ID) {
            this.fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion);
          }
        }
      }
    },
    fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion) {
      console.log("Esta ruta es nueva, reiniciar tabla");
      var self = this;
      setTimeout(function () {
        self.$store
          .dispatch("Engregasmapaprincipalruta/find", {
            query: {},
          })
          .then((result) => {
            //reset all
            self.resumenEntregasGrafica = [];
            self.rutasPedidosEntregados = 0;
            self.rutasPedidosDevolucion = 0;
            self.rutasPedidosPendientes = 0;
            self.rutasPedidosTotal = 0;
            self.chartData = null;
            console.log("***********reset all***********");
            self.fnOnLoad(result);
            self.resumenEntregasGrafica = result;
          });
      }, 500);
    },
    /************************************************************************************* */
    /************************************************************************************* */
    fnAgregarNuevaRuta2(ROUTE_ID, TOTAL_PEDIDOS, accion) {
      console.log("Esta ruta es nueva, reiniciar tabla");
      var self = this;
      setTimeout(function () {
        self.$store
          .dispatch("Engregasmapaprincipalsector/find", {
            query: {
              SECTOR_ID: this.graficaPorSectorId,
            },
          })
          .then((result) => {
            //reset all
            self.graficaResumenSector = [];
            self.rutasPedidosEntregados = 0;
            self.rutasPedidosDevolucion = 0;
            self.rutasPedidosPendientes = 0;
            self.rutasPedidosTotal = 0;
            self.chartData = null;
            console.log("***********reset all***********");
            self.fnOnLoad(result);
            self.graficaResumenSector = result;
          });
      }, 500);
    },
  },
  mounted: async function () {
    /************************************************************************************* */
    /************************************************************************************* */
    /************************************************************************************* */
    /************************************************************************************* */
    this.$store
      .dispatch("Resumenporsectoryruta/find", {
        query: {},
      })
      .then((sectores) => {
        //sectores
        sectores.forEach((sector) => {
          let rutas = [];
          //rutas
          let rawRutas = sector.ROUTE_ID.split(",");
          //
          rawRutas.forEach((rawRuta) => {
            let xx = rawRuta.split("|||");
            const resumenRuta = {
              ruta: xx[0],
              pedidos: xx[1],
            };
            rutas.push(resumenRuta);
          });
          const resumenSector = {
            sector: sector.DELIVERY_CLIENT_REGION,
            pedidos: sector.en_entrega_hoy,
            rutas: rutas,
          };

          this.resumenporsectoryruta.push(resumenSector);
          //
        });
        this.graficaPorSectorId = this.resumenporsectoryruta[0].sector;
        this.graficaPorRutaId = this.resumenporsectoryruta[0].rutas[0].ruta;
        console.log(this.resumenporsectoryruta);
      });
    //crear totales de entregas a nivel de rutas

    var self = this;
    setTimeout(function () {
      self.$store
        .dispatch("Engregasmapaprincipalruta/find", {
          query: {
            ROUTE_ID: self.graficaPorRutaId,
          },
        })
        .then((result) => {
          self.resumenEntregasGrafica = [];
          self.rutasPedidosEntregados = 0;
          self.rutasPedidosDevolucion = 0;
          self.rutasPedidosPendientes = 0;
          self.rutasPedidosTotal = 0;
          self.chartData = null;

          self.fnOnLoad(result);
          self.resumenEntregasGrafica = result;
        });
    }, 4000);
    /************************************************************************************* */
    /************************************************************************************* */
    setTimeout(function () {
      self.$store
        .dispatch("Engregasmapaprincipalsector/find", {
          query: {
            SECTOR_ID: self.graficaPorSectorId,
          },
        })
        .then((result) => {
          self.graficaResumenSector = [];
          self.rutasPedidosEntregados2 = 0;
          self.rutasPedidosDevolucion2 = 0;
          self.rutasPedidosPendientes2 = 0;
          self.rutasPedidosTotal2 = 0;
          self.chartData2 = null;
          self.fnOnLoad2(result);
          self.graficaResumenSector = result;
        });
    }, 3500);
  },
};
</script>
<style scoped>
#vistaGraficaEntregas {
  min-width: 595px;
  border-radius: 15px;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: top;
  z-index: 12;
  position: absolute;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
h2,
h3 {
  margin: 0px 15px 0px 15px;
}
.tableColorBox {
  margin: 0px 0px 0px 15px;
}
/* //modal-header encabezado */
.switchEncabezado {
  margin-left: 25px;
}
.switchEncabezado > .custom-switch {
  margin-left: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
}
/** encabezado de vista TV */
.modal-encabezadoTv {
  color: rgb(3, 29, 106);
  font-size: 24px;
}

h2 {
  font-size: 16px;
}
.resumenGraficaCantidad {
  font-weight: bold;
  font-size: 12px;
}
</style>
