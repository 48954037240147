<template>
  <div class="row">
    <!-- *******************************************RESUMEN DE SECTOR******************************************* -->
    <div class="col-sm-5">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <label v-if="tipoDeFormulario == 0" for=""
            >Seleccione un formulario (rezagados, reenvio, no recibido, anulados
            en asignación):</label
          >
          <label v-if="tipoDeFormulario == 1" for=""
            >Seleccione un formulario (<strong>rezagados</strong>, reenvio, no
            recibido, anulados en asignación):</label
          >
          <label v-if="tipoDeFormulario == 2" for=""
            >Seleccione un formulario (rezagados, <strong>reenvio</strong>, no
            recibido, anulados en asignación):</label
          >
          <label v-if="tipoDeFormulario == 3" for=""
            >Seleccione un formulario (rezagados, reenvio,
            <strong>no recibido</strong>, anulados en asignación):</label
          >
          <label v-if="tipoDeFormulario == 4" for=""
            >Seleccione un formulario (rezagados, reenvio, no recibido,
            <strong>anulados en asignación</strong>):</label
          >
        </div>

        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-11">
              <select
                name=""
                class="custom-select mr-5"
                id=""
                v-model="tipoDeFormulario"
              >
                <option value="" selected disabled>
                  seleccionar formulario
                </option>

                <option value="1">Pedidos rezagados</option>
                <option value="2">Pedidos con reenvio</option>
                <option value="3">Pedidos no recibido</option>
                <option value="4">Pedidos anulados en asignación</option>
              </select>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="card card-chart" v-if="tipoDeFormulario == 1">
        <!---->
        <div class="card-header" style="color: #344675">
          Cargar <strong>archivo</strong> de <strong>pedidos rezagados</strong>
        </div>

        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-12">
              <p>
                Por favor carga el archivo de excel con los pedidos a rezagar:
              </p>
              <input
                type="file"
                class="my_input"
                @change="importExcel"
                id="upload"
              />

              <div ref="result"></div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="card card-chart" v-if="tipoDeFormulario == 2">
        <!---->
        <div class="card-header" style="color: #344675">
          Cargar <strong>archivo</strong> de
          <strong>pedidos con reenvio</strong>
        </div>

        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-12">
              <p>
                Por favor carga el archivo de excel con los pedidos con reenvio:
              </p>
              <input
                type="file"
                class="my_input"
                @change="importExcelReenvio"
                id="uploadReenvio"
              />

              <div ref="result"></div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="card card-chart" v-if="tipoDeFormulario == 3">
        <!---->
        <div class="card-header" style="color: #344675">
          Cargar <strong>archivos</strong> con
          <strong>pedidos NO RECIBIDOS</strong>
        </div>

        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-12">
              <p>
                Por favor carga el archivo de excel con los pedidos no
                recibidos:
              </p>
              <input
                type="file"
                class="my_input"
                @change="importExcelNoReenvio"
                id="uploadNoReenvio"
              />

              <div ref="result"></div>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
      <div class="card card-chart" v-if="tipoDeFormulario == 4">
        <!---->
        <div class="card-header" style="color: #344675">
          Cargar <strong>archivo</strong> de
          <strong>pedidos ANULADOS EN ASIGNACIÓN</strong>
        </div>

        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-12">
              <p>
                Por favor carga el archivo de excel con los pedidos anulados en
                bodega:
              </p>
              <input
                type="file"
                class="my_input"
                @change="importarExcelAnuladosBodega"
                id="uploadAnuladosEnBodega"
              />

              <div ref="result"></div>
            </div>
          </div>
        </div>

        <div class="card-header">
          Se deben utilizar los siguientes titulos de columna en el excel a
          cargar <i class="fas fa-file-excel"></i>:
        </div>
        <div class="card-body">
          <div class="row mb-3 ml-2">
            <div class="col-md-12 mt-3">
              <table class="table" id="tblRezagados">
                <thead>
                  <tr>
                    <td>fecha</td>
                    <td>secuencia</td>
                    <td>documento</td>
                    <td>cliente</td>
                    <td>nombrecliente</td>
                    <td>ruta</td>
                    <td>sector</td>
                    <td>campana</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Fecha en formato Día/Mes/Año <br />
                      ej. 20/01/2020
                    </td>
                    <td>Número de scuencia</td>
                    <td>Número de pedido</td>
                    <td>Código de consejer@</td>
                    <td>Nombre de consejer@</td>
                    <td>Código de ruta</td>
                    <td>Número de sector</td>
                    <td>Campaña del pedido</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
    <!-- *******************************************LISTADO DE PEDIDOS******************************************* -->
    <div class="col-sm-7">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">Listado de pedidos {{ tituloListado }}</div>
            <div class="col-md-6" v-if="pedidosNoReenvio.length > 0">
              <button
                @click="fnPedidosNoReenvio"
                class="btn btn-sm btn-warning pull-right"
              >
                Rezagar pedidos
                <span class="d-inline-block"
                  >({{ pedidosNoReenvio.length }})</span
                >
              </button>
            </div>
            <!-- ---------------- -->
            <div class="col-md-6" v-if="pedidosReenvio.length > 0">
              <button
                @click="fnPedidosReenvio"
                class="btn btn-sm btn-warning pull-right"
              >
                Enviar pedidos
                <span class="d-inline-block"
                  >({{ pedidosReenvio.length }})</span
                >
              </button>
            </div>
            <!-- ---------------- -->
            <div class="col-md-6" v-if="pedidosRezagados.length > 0">
              <button
                @click="fnRezagarpedidos"
                class="btn btn-sm btn-warning pull-right"
              >
                Enviar pedidos
                <span class="d-inline-block"
                  >({{ pedidosRezagados.length }})</span
                >
              </button>
            </div>
            <!-- ---------------- -->
            <div class="col-md-6" v-if="pedidosAnuladosEnBodega.length > 0">
              <button
                @click="fnPedidosAnuladosEnBodega"
                class="btn btn-sm btn-warning pull-right"
              >
                Enviar pedidos
                <span class="d-inline-block"
                  >({{ pedidosAnuladosEnBodega.length }})</span
                >
              </button>
            </div>
          </div>
        </div>
        <!-- ----------------------------------BOTON ACTIVAR FORMULARIO--------------------------------------- -->
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table" v-if="pedidosNoReenvio.length > 0">
                <thead>
                  <tr>
                    <th>No. pedido ({{ pedidosNoReenvio.length }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pedido, index) in pedidosNoReenvio"
                    v-bind:key="index"
                  >
                    <td>{{ pedido.ERP_DOC }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- -------------- -->
              <table class="table" v-if="pedidosReenvio.length > 0">
                <thead>
                  <tr>
                    <th>No. pedido ({{ pedidosReenvio.length }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pedido, index) in pedidosReenvio"
                    v-bind:key="index"
                  >
                    <td>{{ pedido.ERP_DOC }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- -------------- -->
              <table class="table" v-if="pedidosRezagados.length > 0">
                <thead>
                  <tr>
                    <th>No. pedido ({{ pedidosRezagados.length }})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pedido, index) in pedidosRezagados"
                    v-bind:key="index"
                  >
                    <td>{{ pedido.ERP_DOC }}</td>
                  </tr>
                </tbody>
              </table>

              <!-- -------------- -->
              <table class="table" v-if="pedidosAnuladosEnBodega.length > 0">
                <thead>
                  <tr>
                    <th>
                      Número de pedidos ({{ pedidosAnuladosEnBodega.length }})
                    </th>
                  </tr>
                  <tr>
                    <td>fecha</td>
                    <td>secuencia</td>
                    <td>documento</td>
                    <td>cliente</td>
                    <td>nombrecliente</td>
                    <td>ruta</td>
                    <td>sector</td>
                    <td>campaña</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(pedido, index) in pedidosAnuladosEnBodega"
                    v-bind:key="index"
                  >
                    <td>
                      {{ pedido.fecha | moment("add", "", "DD/MM/YYYY") }}
                    </td>
                    <td>{{ pedido.secuencia }}</td>
                    <td>{{ pedido.documento }}</td>
                    <td>{{ pedido.cliente }}</td>
                    <td>{{ pedido.nombrecliente }}</td>
                    <td>{{ pedido.ruta }}</td>
                    <td>{{ pedido.sector }}</td>
                    <td>{{ pedido.campana }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
  </div>
</template>
<script>
const fileReader = new FileReader();
import xlsxFile from "read-excel-file";
import { mapState, mapGetters, mapActions } from "vuex";
import xlsx from "xlsx";
export default {
  data() {
    return {
      //titulo principal de los formularios
      tituloDelFormulario: 0,
      tipoDeFormulario: 0,
      tituloListado: "",
      anexosForm: [],
      pedidosRezagados: [],
      pedidosNoReenvio: [],
      pedidosReenvio: [],
      pedidosAnuladosEnBodega: []
    };
  },

  methods: {
    fnPedidosAnuladosEnBodega() {
      const confirmar = confirm(
        `¿Está seguro de querer crear ${this.pedidosAnuladosEnBodega.length} pedidos anulados en asignación?, este proceso no se puede revertir.`
      );

      if (!confirmar) {
        return;
      } else {
        this.$store
          .dispatch("Anularpedidosbodega/patch", [
            null,
            { pedidos: this.pedidosAnuladosEnBodega },
            {}
          ])
          .then(result => {
            //
            console.log(result);
            //
            this.$notify({
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "right",
              message: "Se han creado los pedidos anulados en asignación."
            });
            //
            this.pedidosAnuladosEnBodega = [];
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    fnPedidosNoReenvio() {
      const confirmar = confirm(
        `¿Está seguro de querer indicar que los ${this.pedidosNoReenvio.length} pedidos son no recibidos?, este proceso no se puede revertir.`
      );

      if (!confirmar) {
        return;
      } else {
        this.$store
          .dispatch("Pedidosnorecibidos/patch", [
            null,
            { pedidos: this.pedidosNoReenvio },
            {}
          ])
          .then(result => {
            //
            console.log(result);
            //
            this.$notify({
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "right",
              message: "Pedidos ahora con estado de no recibidos."
            });
            //
            this.pedidosNoReenvio = [];
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    fnPedidosReenvio() {
      const confirmar = confirm(
        `¿Está seguro de querer indicar que los ${this.pedidosReenvio.length} pedidos son de "reenvio"?, este proceso no se puede revertir.`
      );

      if (!confirmar) {
        return;
      } else {
        this.$store
          .dispatch("Pedidosconreenvio/patch", [
            null,
            { pedidos: this.pedidosReenvio },
            {}
          ])
          .then(result => {
            //
            console.log(result);
            //
            this.$notify({
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "right",
              message: "Pedidos ahora con estado de recibidos."
            });
            //
            this.pedidosReenvio = [];
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    fnRezagarpedidos() {
      const confirmar = confirm(
        `¿Está seguro de querer rezagar los ${this.pedidosRezagados.length} pedidos?, este proceso no se puede revertir.`
      );

      if (!confirmar) {
        return;
      } else {
        this.$store
          .dispatch("Rezagarpedidos/patch", [
            null,
            { pedidos: this.pedidosRezagados },
            {}
          ])
          .then(result => {
            //
            console.log(result);
            //
            this.$notify({
              type: "success",
              verticalAlign: "bottom",
              horizontalAlign: "right",
              message: "Pedidos rezagados."
            });
            //
            this.pedidosRezagados = [];
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    // ---------------------funciones con llamada a la API--------------------
    importarExcelAnuladosBodega(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.pedidosReenvio = [];
          this.pedidosRezagados = [];
          this.pedidosNoReenvio = [];
          this.pedidosAnuladosEnBodega = excellist;
          this.tituloListado = "anulados en asignación ";
          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("uploadAnuladosEnBodega");
      input.value = "";
    },
    importExcelNoReenvio(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.pedidosReenvio = [];
          this.pedidosRezagados = [];
          this.pedidosAnuladosEnBodega = [];
          this.pedidosNoReenvio = excellist;
          this.tituloListado = "no recibidos";
          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("uploadNoReenvio");
      input.value = "";
    },
    importExcelReenvio(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }

          this.pedidosRezagados = [];
          this.pedidosNoReenvio = [];
          this.pedidosAnuladosEnBodega = [];
          this.pedidosReenvio = excellist;
          this.tituloListado = "con reenvio";
          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("uploadReenvio");
      input.value = "";
    },
    importExcel(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }

          this.pedidosNoReenvio = [];
          this.pedidosReenvio = [];
          this.pedidosAnuladosEnBodega = [];
          this.pedidosRezagados = excellist;
          this.tituloListado = "rezagados";
          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    }
  },
  mounted() {}
};
</script>
<style scoped1>
.carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  background-color: red;
}
.custom-select {
  color: black;
}
#tblRezagados > thead > tr > td {
  font-weight: bold !important;
}
#tblRezagados > tbody > tr > td {
  font-weight: normal !important;
}
</style>
