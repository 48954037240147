<template>
  <dragItDude ref="pedidoId" id="pedidoId" class style="width: 780px">
    <div class="modal-content">
      <div class="modal-header">
        Pedido correlativo número: {{ pedidoId }}
        <br />
        <img
          width="185px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
      </div>
      <div class="modal-body" v-if="pedidoDetalle">
        <div class="row">
          <div class="col-md-12">
            <h6>Pedido asignado actualmente a:</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Piloto:</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == 1">{{
              pedidoDetalle.DELIVERY_ASSIGNED_TO_DRIVER_NAME
            }}</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == -1"
              >Pedido asignado / no cargado</span
            >
          </div>
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Placa:</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == 1">{{
              pedidoDetalle.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES
            }}</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == -1"
              >Pedido asignado / no cargado</span
            >
          </div>
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Empresa:</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == 1">{{
              pedidoDetalle.DELIVERY_COMPANY_NAME
            }}</span>
            <span class="dato-asignacion" v-if="pedidoDetalle.STATUS == -1"
              >Pedido asignado / no cargado</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Fecha de carga:</span>
            <span class="dato-asignacion">{{
              pedidoDetalle.UPLOADED_DATE
                | moment("add", "", "DD-MM-YYYY HH:mm:ss")
            }}</span>
          </div>
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Manifiesto:</span>
            <span class="dato-asignacion">{{
              pedidoDetalle.MANIFEST_DB_ID
            }}</span>
          </div>
          <div class="col-md-4">
            <span class="etiqueta-asignacion">Ruta:</span>
            <span class="dato-asignacion">{{ pedidoDetalle.ROUTE_ID }}</span>
          </div>
        </div>
        <div class="row" ref="historialAsignaciones" v-if="rows.length > 0">
          <div class="col-md-12">
            <hr />
            <h6>Historial de asignaciones</h6>
          </div>
          <v-client-table
            :data="rows"
            :columns="columns"
            :options="options"
          ></v-client-table>
        </div>
      </div>
      <hr />
      <div class="modal-footer" style="padding-top: 10px">
        <button
          class="btn btn-sm btn-success btn-float-right"
          style="right: 180px"
          @click="fnRemoverAsignacion()"
        >
          Remover asignación actual
          <i class="fas fa-save"></i>
        </button>
        <button
          class="btn btn-sm btn-succes btn-float-right"
          @click="fnResetWindow()"
        >
          Cerrar ventana
        </button>
      </div>
    </div>
  </dragItDude>
</template>
<script>
import DragItDude from "vue-drag-it-dude";
export default {
  components: {
    DragItDude,
  },
  props: ["pedidoId", "pedidoDetalle"],
  data() {
    return {
      devolucionMotivo: "",
      columns: [
        "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
        "DELIVERY_ASSIGNED_TO_VEHICULE_PLATES",
        "DELIVERY_COMPANY_NAME",
        "MANIFEST_DB_ID",
        "UPLOADED_DATE",
        "USER_NAME",
        "createdAt",
      ],
      rows: [],
      options: {
        orderBy: {
          column: "createdAt",
          ascending: false,
        },
        filterByColumn: false,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No hay rutas por asignar...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["CREATED", "createdAt"],
        dateFormat: "DD-MM-YYYY HH:mm",
        pagination: { chunk: 10, dropdown: false },
        headings: {
          DELIVERY_ASSIGNED_TO_DRIVER_NAME: "Piloto",
          DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: "Placas",
          DELIVERY_COMPANY_NAME: "Empresa",
          MANIFEST_DB_ID: "Id manifiesto",
          UPLOADED_DATE: "Fecha de carga",
          USER_NAME: "Asignación removido por",
          createdAt: "Fecha",
        },
      },
    };
  },
  methods: {
    fnResetWindow() {
      this.$emit("clicked");
    },
    fnRemoverAsignacion() {
      const confirmar = confirm(
        "¿Está seguro de querer remover esta asignación?, este proceso no se puede revertir."
      );

      if (!confirmar) {
        return;
      }

      const historial = {
        DOC_ID: this.pedidoDetalle.DOC_ID,
        DELIVERY_ASSIGNED_TO_DRIVER_ID: this.pedidoDetalle
          .DELIVERY_ASSIGNED_TO_DRIVER_ID,
        DELIVERY_ASSIGNED_TO_DRIVER_NAME: this.pedidoDetalle
          .DELIVERY_ASSIGNED_TO_DRIVER_NAME,
        DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: this.pedidoDetalle
          .DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
        DELIVERY_COMPANY_ID: this.pedidoDetalle.DELIVERY_COMPANY_ID,
        DELIVERY_COMPANY_NAME: this.pedidoDetalle.DELIVERY_COMPANY_NAME,
        MANIFEST_ID: this.pedidoDetalle.MANIFEST_ID,
        MANIFEST_DB_ID: this.pedidoDetalle.MANIFEST_DB_ID,
        UPLOADED_DATE: this.pedidoDetalle.UPLOADED_DATE,
      };

      const data = {
        STATUS: 0,
        STATUSTEXT: "En Picking",
        DELIVERY_ASSIGNED_TO_DRIVER_ID: null,
        DELIVERY_ASSIGNED_TO_DRIVER_NAME: null,
        DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: null,
        DELIVERY_COMPANY_ID: null,
        DELIVERY_COMPANY_NAME: null,
        MANIFEST_ID: null,
        MANIFEST_DB_ID: null,
        UPLOADED_DATE: null,
      };
      this.$store
        .dispatch("Pedidos/patch", [this.pedidoDetalle.id, data, {}])
        .then(() => {
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: `Se ha removido exitosamente la asignación del pedido ${this.pedidoId}`,
          });
          this.fnGuardarHistorial(historial);
          this.$emit("asignacionRemovida");
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: `Ocurrió un error al remover la asignación del pedido ${this.pedidoId}. Error: ${error.message}`,
          });
        });
    },
    fnGuardarHistorial(datos) {
      const usuario = this.$store.state.auth.payload.usuarios;
      datos.USER_CODE = usuario.usuario_codigo;
      datos.USER_NAME = usuario.usuario_nombre + " " + usuario.usuario_apellido;
      this.$store
        .dispatch("PedidosHistorialAsignaciones/create", datos)
        .then(() => {
          console.log("Historial asignación creada");
        })
        .catch((error) => {
          console.error("Error al crear historial: " + error.message);
        });
    },
  },
  watch: {
    pedidoDetalle: function (newVal, oldVal) {
      const find = {
        query: {
          DOC_ID: newVal.ERP_DOC,
        },
      };
      this.$store
        .dispatch("PedidosHistorialAsignaciones/find", find)
        .then((res) => {
          res.data.forEach((PEDIDO) => {
            PEDIDO.UPLOADED_DATE = moment(PEDIDO.UPLOADED_DATE);
            PEDIDO.createdAt = moment(PEDIDO.createdAt);
          });
          this.rows = res.data;
        })
        .catch((error) => {
          console.log("No se pudo retraer el hitorial");
        });
    },
  },
};
</script>
<style scoped>
.btn-float-right {
  display: block;
  position: absolute;
  right: 25px;
  bottom: 10px;
}
blockquote {
  font-size: 12px;
}
#detalleDevolucion {
  font-style: italic;
  font-size: 11px;
  padding-left: 5px;
  border-left: 1px solid pink;
}
.etiqueta-asignacion {
  display: block;
}
.dato-asignacion {
  font-weight: bold;
}
</style>
