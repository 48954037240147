<template>
  <div
    class="modal fade"
    id="modalUsuarioODarDeBaja"
    ref="modalUsuarioODarDeBaja"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 1015px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 1015px">
        <div class="modal-header">
          <h5 class="modal-title">
            ¿Está seguro de dar de baja al usuario
            {{ datosEditadosUsuario.usuario_codigo }}?
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <form class="" ref="fromEditarUsuario">
                <div class="form-group mr-1">
                  <label for="">*Motivo:</label>
                  <input
                    required
                    v-model="DE_BAJA_MOTIVO"
                    type="text"
                    name="motivo"
                    class="form-control"
                    placeholder="Motivo del que se le da de baja al usuario"
                  />
                </div>

                <div class="form-group mr-1">
                  <label
                    title="Escribe tu contraseña, para validar tu usuario."
                    for=""
                    >*Ingresa tu contraseña</label
                  >
                  <input
                    required
                    type="password"
                    v-model="localPW"
                    class="form-control"
                    :placeholder="`valida tu usuario para poder dar de baja al usuario ${datosEditadosUsuario.usuario_codigo}`"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
            @click="fhCheckUsuarioPassword()"
          >
            Dar de baja a {{ datosEditadosUsuario.usuario_codigo }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "GestionUsuarioEditar",
  props: ["prop_idUsuario"],
  data() {
    return {
      localPW: "",
      DE_BAJA_MOTIVO: "",
      datosEditadosUsuario: {
        id: 0,
        usuario_nombre: "",
        usuario_codigo: "",
        id_rol: "",
        DELIVERY_COMPANY_ID: "",
        DELIVERY_COMPANY_NAME: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions("Usuarios", {
      patchUsuario: "patch",
      fnGetUsuario: "get",
    }),
    fnDarDeBaja() {
      let data_bajaUsuario = {
        password: "debajaUsuario",
        status: "0",
        DE_BAJA_MOTIVO: this.DE_BAJA_MOTIVO,
        DE_BAJA_BY_USUARIO_CODIGO:
          this.$store.state.auth.payload.usuarios.usuario_codigo,
      };
      this.patchUsuario([this.datosEditadosUsuario.id, data_bajaUsuario]).then(
        (result) => {
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: `Se ha dado de baja al usuario ${this.datosEditadosUsuario}`,
          });
        }
      );
    },
    fhCheckUsuarioPassword() {
      //autenticarse
      this.$store
        .dispatch("auth/authenticate", {
          strategy: "local",
          usuario_codigo:
            this.$store.state.auth.payload.usuarios.usuario_codigo,

          password: this.localPW,
        })
        .then((result) => {
          this.fnDarDeBaja();
        })
        .catch((e) => {
          console.error(e);
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
            message:
              "Tu contraseña no es correcta, por favor intentalo de nuevo",
          });
        });
    },
  },
  mounted() {
    this.datosEditadosUsuario = this.prop_idUsuario;
  },
  watch: {
    prop_idUsuario: function (newVal, oldVal) {
      this.localPW = "";
      this.DE_BAJA_MOTIVO = "";

      this.fnGetUsuario(this.prop_idUsuario).then((result) => {
        this.datosEditadosUsuario = result;
        this.datosEditadosUsuario.password = "";
        console.log(this.datosEditadosUsuario);
        // this.$notify({
        //   type: "warning",
        //   verticalAlign: "bottom",
        //   horizontalAlign: "left",
        //   message: "Estás editando al usuario: " + result.usuario_codigo
        // });
      });
      //******************************* */
      /***********/
    },
  },
};
</script>
<style>
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
#modalUsuarioODarDeBaja
  > div
  > div
  > div.modal-body
  > div
  > div
  > form
  > div
  > select {
  color: black;
}
option {
  color: black !important;
}
</style>
