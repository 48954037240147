<template>
  <dragItDude
    id="vistaGraficaEntregas"
    :style="[
      windowPcVersion ? { width: '830px' } : { width: '1330px' },
      { top: '7px' },
      { left: '15px' }
    ]"
  >
    <div class="modal-content" style="max-height: 90vh;">
      <!-- animated bounce -->
      <!-- <div class="modal-header">
        <h1 style="margin-left: 25px;">
          Resumen de rutas {{ rutasPedidosEntregados }} /
          {{ rutasPedidosDevolucion }} /
          {{ rutasPedidosPendientes }}
        </h1>
        <img
          width="280px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />
      </div>-->
      <div
        :style="[windowPcVersion ? { height: '25px' } : { 'height:': '45px' }]"
      >
        <!-- switch y titulo encab ezado -->
        <div class="switchEncabezado">
          <!-- switch -->
          <div class="custom-control custom-switch">
            <input
              @click="toggleWindowVersion()"
              type="checkbox"
              class="custom-control-input"
              id="switchGrafica"
            />
            <label class="custom-control-label" for="switchGrafica"></label>
          </div>
          <!-- fin switch -->
          <span :class="[windowPcVersion ? '' : 'modal-encabezadoTv']">
            Resumen de rutas
            <span style="font-weight:bold; color:#41B883">{{
              rutasPedidosEntregados
            }}</span>
            /
            <span style="font-weight:bold; color:#7e57c2;">{{
              rutasPedidosDevolucion
            }}</span>
            /
            <span style="font-weight:bold; color:#e46953;">{{
              rutasPedidosPendientes
            }}</span>
          </span>
        </div>
        <!-- fin -->
        <img
          :width="[windowPcVersion ? '85px' : '185px']"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />
        <br />
      </div>
      <div
        class="modal-body"
        style="
    overflow-y: scroll;
"
      >
        <div class="row">
          <div :class="[windowPcVersion ? 'col-md-3' : 'col-md-4']">
            <reactive-bar-chart
              :chartData="chartData"
              :options="options"
            ></reactive-bar-chart>
          </div>
          <div class="col-md-6">
            <!-- identificacion de colores -->

            <hr />
            <table>
              <tr>
                <td>
                  <h2>Empresa</h2>
                </td>
                <td>
                  <h2>Ruta</h2>
                </td>
                <td>
                  <div
                    class="tableColorBox"
                    style="
                      width: 20px;
                      height: 20px;
                      background-color: #41b883;
                      border: 1px solid white;
                      display: inline-block;
                    "
                  ></div>
                  <h2 style="display: inline-block;">Entregados</h2>
                </td>

                <td>
                  <div
                    class="tableColorBox"
                    style="
                      width: 20px;
                      height: 20px;
                      background-color: #7e57c2 ;
                      border: 1px solid white;
                      display: inline-block;
                    "
                  ></div>
                  <h2 style="display: inline-block;">Devueltos</h2>
                </td>
                <td>
                  <div
                    class="tableColorBox"
                    style="
                      width: 20px;
                      height: 20px;
                      background-color: #e46651;
                      border: 1px solid white;
                      display: inline-block;
                    "
                  ></div>
                  <h2 style="display: inline-block;">Pendientes</h2>
                </td>
              </tr>
              <tr
                v-for="(ruta, index) in resumenEntregasGrafica"
                v-bind:key="index"
              >
                <td>
                  <h2 class="companyName" :title="ruta.DELIVERY_COMPANY_NAME">
                    {{ ruta.DELIVERY_COMPANY_NAME | truncate(15, "..") }}
                  </h2>
                </td>
                <td>
                  <h2>{{ ruta.ROUTE_ID }}</h2>
                </td>
                <td>
                  <h2>{{ ruta.total_pedidos_entregados }}</h2>
                </td>
                <td>
                  <h2>{{ ruta.total_pedidos_devolucion }}</h2>
                </td>
                <td>
                  <h2>{{ ruta.total_pedidos_pendientes }}</h2>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </dragItDude>
</template>

<script>
//fin
import ReactiveBarChart from "@/components/Charts/PieChart.js";
import DragItDude from "vue-drag-it-dude";
export default {
  props: ["realizarEntrega", "realizarDevolucion"],
  watch: {
    realizarEntrega: function(newVal, oldVal) {
      if (this.resumenEntregasGrafica.length === 0) {
        console.log("mapa principal graficas, watch on update, rutas vacias");
        var self = this;
        setTimeout(function() {
          self.$store
            .dispatch("Engregasmapaprincipal/find", {
              query: {}
            })
            .then(result => {
              self.fnOnLoad(result);
              self.resumenEntregasGrafica = result;
            });
        }, 500);
      } else {
        console.log("mapa principal graficas, watch on update, ruta existente");
        //this.segmentar(newVal);
        this.fnOnPatch(newVal, "entrega");
      }
    },
    realizarDevolucion: function(newVal, oldVal) {
      if (this.resumenEntregasGrafica.length === 0) {
        console.log("mapa principal graficas, watch on update, rutas vacias");
        var self = this;
        setTimeout(function() {
          self.$store
            .dispatch("Engregasmapaprincipal/find", {
              query: {}
            })
            .then(result => {
              self.fnOnLoad(result);
              self.resumenEntregasGrafica = result;
            });
        }, 500);
      } else {
        //this.segmentar(newVal);
        this.fnOnPatch(newVal, "devolucion");
      }
    }
  },
  name: "ventanaGraficaEntregas",
  components: {
    ReactiveBarChart,
    DragItDude
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  data() {
    return {
      windowPcVersion: true,
      resumenEntregasGrafica: [],
      rutasPedidosEntregados: 0,
      rutasPedidosDevolucion: 0,
      rutasPedidosPendientes: 0,
      rutasPedidosTotal: 0,
      chartData: null,
      options: {
        legend: {
          display: false
        }
      }
    };
  },
  methods: {
    //
    toggleWindowVersion() {
      console.log(this.windowPcVersion);
      this.windowPcVersion = !this.windowPcVersion;
    },
    //
    fnOnLoad(entregas) {
      //variables globales, sumatoria de entregados y pendientes
      entregas.forEach(element => {
        this.rutasPedidosEntregados =
          this.rutasPedidosEntregados + element.total_pedidos_entregados;
        this.rutasPedidosDevolucion =
          this.rutasPedidosDevolucion + element.total_pedidos_devolucion;
        this.rutasPedidosPendientes =
          this.rutasPedidosPendientes + element.total_pedidos_pendientes;
      });
      /*************************** */
      //colores
      var canvas = $("#teamDoughnutChart");
      var ctx = document
        .getElementById("pie-chart")
        .getContext("2d")
        .canvas.getContext("2d");
      //backgrounds
      var gradientColors = [
        {
          start: "#ddf8ec",
          end: "#41B883"
        },
        {
          start: "#e46953",
          end: "#fdccc4"
        },
        {
          start: "#fff9c4 ",
          end: "#7e57c2  "
        }
      ];

      var gradients = [];

      gradientColors.forEach(function(item) {
        var gradient = ctx.createLinearGradient(10, 0, 150, 150);
        gradient.addColorStop(0, item.start);
        gradient.addColorStop(1, item.end);
        gradients.push(gradient);
      });
      //borders
      var gradientColors2 = [
        {
          start: "#fff",
          end: "#41b883"
        },
        {
          start: "#fff",
          end: "#e46953"
        },
        {
          start: "#fff",
          end: "#7e57c2 "
        }
      ];

      var gradients2 = [];

      gradientColors2.forEach(function(item) {
        var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
        gradient2.addColorStop(0, item.start);
        gradient2.addColorStop(1, item.end);
        gradients2.push(gradient2);
      });

      //
      /********************************** */
      //se genera información, de grafica global
      this.chartData = {
        labels: ["Entregados", "Pendientes", "Devolución"],
        datasets: [
          {
            borderColor: gradients2,
            borderWidth: ["7", "7", "4"],
            backgroundColor: gradients,
            data: [
              this.rutasPedidosEntregados,
              this.rutasPedidosPendientes,
              this.rutasPedidosDevolucion
            ]
          }
        ]
      };
    },
    fnOnPatch(pedido, accion) {
      //ruta id del pedido
      // console.log("fnOnPatch");
      // console.log(pedido);
      let route_id = pedido.MANIFEST_ID.split("--");

      //actualizar tabla de resumen de rutas (GLOBAL)
      this.fnChangeTablaResumenGlobal(
        route_id[1],
        pedido.TOTAL_PEDIDOS,
        accion
      );

      //if entregados y pendientes actualmente son 0, agregar total pedidos para los totales globales
      //actualizar totales
      if (accion == "entrega") {
        this.rutasPedidosEntregados++;
      }
      if (accion == "devolucion") {
        this.rutasPedidosDevolucion++;
      }
      this.rutasPedidosPendientes--;
      /*************************** */
      //colores
      var canvas = $("#teamDoughnutChart");
      var ctx = document
        .getElementById("pie-chart")
        .getContext("2d")
        .canvas.getContext("2d");
      //backgrounds
      var gradientColors = [
        {
          start: "#ddf8ec",
          end: "#41B883"
        },
        {
          start: "#e46953",
          end: "#fdccc4"
        },
        {
          start: "#fff9c4 ",
          end: "#7e57c2"
        }
      ];

      var gradients = [];

      gradientColors.forEach(function(item) {
        var gradient = ctx.createLinearGradient(10, 0, 150, 150);
        gradient.addColorStop(0, item.start);
        gradient.addColorStop(1, item.end);
        gradients.push(gradient);
      });
      //borders
      var gradientColors2 = [
        {
          start: "#fff",
          end: "#41b883"
        },
        {
          start: "#fff",
          end: "#e46953"
        },
        {
          start: "#fff",
          end: "#7e57c2"
        }
      ];

      var gradients2 = [];

      gradientColors2.forEach(function(item) {
        var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
        gradient2.addColorStop(0, item.start);
        gradient2.addColorStop(1, item.end);
        gradients2.push(gradient2);
      });

      //
      /********************************** */
      //se genera información, de grafica global
      this.chartData = {
        labels: ["Entregados", "Pendientes", "Devolución"],
        datasets: [
          {
            borderColor: gradients2,
            borderWidth: ["7", "7", "4"],
            backgroundColor: gradients,
            data: [
              this.rutasPedidosEntregados,
              this.rutasPedidosPendientes,
              this.rutasPedidosDevolucion
            ]
          }
        ]
      };
    },
    fnChangeTablaResumenGlobal(ROUTE_ID, TOTAL_PEDIDOS, accion) {
      // console.log(
      //   this.resumenEntregasGrafica + ", " + ROUTE_ID + ", " + TOTAL_PEDIDOS
      // );
      /*************************************** */
      //si el arreglo de rutas se encuentra vacio
      if (this.resumenEntregasGrafica == []) {
        //TODO, verficar por que se esta ejecutando esta fucnión, si se supone que la region ya existe
        //console.log("resumenEntregasGrafica vacio");
        this.fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion);
      }
      /********************************* */
      //si existe 1 o mas registros de rutas en el arreglo
      for (var i in this.resumenEntregasGrafica) {
        //si existe la ruta, se le suman entregados y se le resta pendientes
        // console.log(
        //   this.resumenEntregasGrafica[i].ROUTE_ID +
        //     "  --->" +
        //     ROUTE_ID +
        //     "&&" +
        //     TOTAL_PEDIDOS
        // );
        /************************************ */
        // si la ruta ya existe en el arreglo
        if (this.resumenEntregasGrafica[i].ROUTE_ID == ROUTE_ID) {
          if (accion == "entrega") {
            this.resumenEntregasGrafica[i].total_pedidos_entregados++;
          }
          if (accion == "devolucion") {
            this.resumenEntregasGrafica[i].total_pedidos_devolucion++;
          }

          this.resumenEntregasGrafica[i].total_pedidos_pendientes--;

          break; //Stop this loop, we found it!
        }
        /******************************** */
        //si la ruta no existe en el arreglo
        /*********************************** */
        //si el arreglo no se encuentra la ruta

        /************************************************ */
        //si el arreglo de rutas no se encuentra vacio pero no se ncuentra la nueva ruta
        if (i >= this.resumenEntregasGrafica.length - 1) {
          if (this.resumenEntregasGrafica[i].ROUTE_ID != ROUTE_ID) {
            this.fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion);
          }
        }
      }
    },
    fnAgregarNuevaRuta(ROUTE_ID, TOTAL_PEDIDOS, accion) {
      console.log("Esta ruta es nueva, reiniciar tabla");
      var self = this;
      setTimeout(function() {
        self.$store
          .dispatch("Engregasmapaprincipal/find", {
            query: {}
          })
          .then(result => {
            //reset all
            self.resumenEntregasGrafica = [];
            self.rutasPedidosEntregados = 0;
            self.rutasPedidosDevolucion = 0;
            self.rutasPedidosPendientes = 0;
            self.rutasPedidosTotal = 0;
            self.chartData = null;
            console.log("***********reset all***********");
            self.fnOnLoad(result);
            self.resumenEntregasGrafica = result;
          });
      }, 500);
      //todo, verificar por que se esta ejecutando esta función, si la ruta ya se existe
      // console.log("01) nueva ruta: " + ROUTE_ID);
      // console.log(
      //   "02) rutas pedidos entregados: " + this.rutasPedidosEntregados
      // );
      // console.log(
      //   "03) rutas pedidos pendientes: " + this.rutasPedidosPendientes
      // );
      // console.log("04) TOTAL DE PEDIDOS NUEVOS: " + TOTAL_PEDIDOS);

      //se agrega a totales generales
      // this.rutasPedidosEntregados = this.rutasPedidosEntregados + 1;
      // this.rutasPedidosPendientes =
      //   this.rutasPedidosPendientes + (TOTAL_PEDIDOS - 1);
      // //se agrega nueva ruta

      // const nuevaRuta = {
      //   ROUTE_ID: ROUTE_ID,
      //   total_pedidos_pendientes: TOTAL_PEDIDOS - 1,
      //   total_pedidos_entregado: 1
      // };
      // //console.log("05) nueva ruta en mapa");
      // this.resumenEntregasGrafica.push({ nuevaRuta });
    }
  },
  mounted: async function() {
    //crear totales de entregas a nivel de rutas

    var self = this;
    setTimeout(function() {
      self.$store
        .dispatch("Engregasmapaprincipal/find", {
          query: {}
        })
        .then(result => {
          console.log(result);
          self.fnOnLoad(result);
          self.resumenEntregasGrafica = result;
        });

      console.log(self.resumenEntregasGrafica.length);
    }, 5000);
  }
};
</script>
<style scoped>
#vistaGraficaEntregas {
  min-width: 80%;
  border-radius: 15px;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: top;
  z-index: 12;
  position: absolute;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
h2,
h3 {
  margin: 0px 15px 0px 15px;
}
.tableColorBox {
  margin: 0px 0px 0px 15px;
}
/* //modal-header encabezado */
.switchEncabezado {
  margin-left: 25px;
}
.switchEncabezado > .custom-switch {
  margin-left: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
}
/** encabezado de vista TV */
.modal-encabezadoTv {
  color: rgb(3, 29, 106);
  font-size: 24px;
}

h2 {
  font-size: 16px;
}
</style>
