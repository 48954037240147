import DashboardLayoutCarga from "@/layout/dashboard/DashboardLayoutLeCleireCarga.vue";
import DashboardLayoutNavLess from "@/layout/dashboard/DashboardLayoutNavLess.vue";
import DashboardLayoutEntregas from "@/layout/dashboard/DashboardLayoutLeCleireEntregas.vue";
import DashboardStatus from "@/layout/dashboard/DashboardStatus.vue";
import DashboardReporteCoordenadas from "@/layout/dashboard/DashboardReporteCoordenadas.vue";
import DashboardGuatex from "@/layout/dashboard/DashboardGuatex.vue";
import DashboardRezagados from "@/layout/dashboard/DashboardPedidosRezagados.vue";
import DashboardCustodios from "@/layout/dashboard/DashboardCustodios.vue";
import DashboardBultos from "@/layout/dashboard/DashboardBultos.vue";
import ReporteOrdenesDeCompra from "@/layout/dashboard/ReporteOrdenesDeCompra.vue";
import DashboardLayoutAuditoria from "@/layout/dashboard/DashboardLayoutLeCleireAuditoria.vue";
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import DashboardGestionUsuarios from "@/layout/dashboard/DashboardGestionUsuarios.vue";
import DashboardPedidosSinApp from "@/layout/dashboard/DashboardGestionReportePedidosSinApp.vue";
import DashboardLayoutDispositivos from "@/layout/dashboard/DashboardLayoutDispositivos.vue";
import DashboardLiquidacion from "@/layout/dashboard/DashboardLayoutLiquidaciones.vue";
import DashboardLayoutConsejeras from "@/layout/dashboard/DashboardLayoutConsejeras.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";
//store
import store from "./../store";
// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "DASHBOARD" */ "@/pages/Dashboard.vue");
//const Notifications = () => import( /* webpackChunkName: "notificacions" */ "@/pages/Notifications.vue");
async function isLoggedIn(to, from, next) {
  // store.dispatch("auth/logout").then(result => console.log("logout 2"));
  store;
  // just in case need to log out
  // .dispatch("auth/logout")
  // .then(res => {
  //   console.log(res);
  //   next("/login");
  // })
  // .catch(() => {
  //   console.log("ready to go Otu!");
  //   next("/login");
  // });

  try {
    const logged = await store.dispatch("auth/authenticate");
    if (logged) {
      if (to.path.substring(0, 21) == "/dispositivos_detalle") {
        return next();
      }
      if (to.path == "/pagos") {
        return next();
      }
      if (to.path == "/liquidacion") {
        return next();
      }
      if (to.path == "/bancos") {
        return next();
      } else {
        const autorizacionModulo = await store.dispatch(
          "UsuarioAutorizacionModulo/find",
          {
            query: {
              modulo: to.path,
            },
          }
        );
        if (autorizacionModulo) {
          return next();
        }
      }
    }
  } catch (e) {
    if (e.code && e.code === 403) {
      return next("/dashboard");
    } else {
      return next("/login");
    }
  } finally {
    store.commit("auth/unsetAuthenticatePending");
  }
}
const STATUS = () =>
  import(
    /* webpackChunkName: "STATUS" */ "@/pages/DashboardEntregas/DashboardStatus/index.vue"
  );
const GstionUsuarios = () =>
  import(
    /* webpackChunkName: "GESTIONUSUARIOS" */ "@/pages//Dashboard/GestionUsuarios.vue"
  );
const GestionReportePedidosSinApp = () =>
  import(
    /* webpackChunkName: "GESTIONREPORTEPEDIDOSSINAPP" */ "@/pages//Dashboard/ReportePedidosSinApp.vue"
  );
const ReporteDeCoordenadas = () =>
  import(
    /* webpackChunkName: "REPORTECOORDENADAS" */ "@/pages/DashboardReporteCoordenadas/reportecoordenadas.vue"
  );
const GUATEX = () =>
  import(
    /* webpackChunkName: "GUATEX" */ "@/pages/DashboardEntregas/DashboardGuatex/guatex.vue"
  );
const PedidosRezagados = () =>
  import(
    /* webpackChunkName: "PEDIDOSREZAGADOS" */ "@/pages/DashboardEntregas/DashboardPedidosRezagados/pedidosrezagados.vue"
  );
const OrdenesDeCompra = () =>
  import(
    /* webpackChunkName: "REPORDENESDECOMPRA" */ "@/pages/DashboardEntregas/DashboardReporteOrdenesDeCompra/ordenesdecompra.vue"
  );
const ControlCustodios = () =>
  import(
    /* webpackChunkName: "CUSTODIOS" */ "@/pages/DashboardCustodios/custodios.vue"
  );
/******************************************************* */
//Graficas y modulo de rampas
/******************************************************* */
//
const GraficasGeneral = () =>
  import(
    /* webpackChunkName: "GRAFICASGENERAL" */ "@/pages/ModuloRampas/graficas.vue"
  );

const RampasManifiestos = () =>
  import(
    /* webpackChunkName: "RAMPASMANIFIESTOS" */ "@/pages/ModuloRampas/RampasManifiestos.vue"
  );
const RampasManifiestosAsignacionPorRuta = () =>
  import(
    /* webpackChunkName: "RAMPAMANIFIEISTOASIGNACIONPORRUTA" */ "@/pages/ModuloRampas/RampasManifiestosAsignacionPorRuta.vue"
  );
//rutas rampas detalle - RampasDetalle
const RampasDetalle = () =>
  import(
    /* webpackChunkName: "RAMPADETALLE" */ "@/pages/ModuloRampas/RampasDetalle.vue"
  );

//rutas rampas graficas - RampasGraficas
const RampasGraficas = () =>
  import(
    /* webpackChunkName: "RAMPASGRAFICAS" */ "@/pages/ModuloRampas/RampasGraficas.vue"
  );

//rutas rampas Lista - RampasLista
const RampasLista = () =>
  import(
    /* webpackChunkName: "RAMPASLISTAS" */ "@/pages/ModuloRampas/RampasLista.vue"
  );

// Asignación de rampas y rutas
const RampasAsignacion = () =>
  import(
    /* webpackChunkName: "RAMPASLISTA" */ "@/pages/ModuloRampas/RampasAsignacion.vue"
  );

/******************************************************* */
//panel de entregas
/******************************************************* */
const DashboardEntregas = () =>
  import(
    /* webpackChunkName: "DASHBOARDENTREGAS" */ "@/pages/DashboardEntregas/DashboardEntregas.vue"
  );

//mapa de entregas
const DashboardMapaPrincipal = () =>
  import(
    /* webpackChunkName: "DASHBOARDMAPAPRINCIPAL" */ "@/pages/DashboardEntregas/DashboardMapaPrincipal/DashboardMapaPrincipal.vue"
  );

//mapa de entregas
const DashboardHistorico = () =>
  import(
    /* webpackChunkName: "DASHBOARDHISTORICO" */ "@/pages/DashboardEntregas/DashboardMapaPrincipal/DashboardHistorico.vue"
  );

const EntregasVistaDetalle = () =>
  import(
    /* webpackChunkName: "ENTREGASVISTADETALLE" */ "@/pages/DashboardEntregas/EntregasVistaDetalle.vue"
  );

const EntregasVistaSector = () =>
  import(
    /* webpackChunkName: "ENTREGASVISTASECTOR" */ "@/pages/DashboardEntregas/EntregasVistaSector.vue"
  );

const ResumenBultos = () =>
  import(
    /* webpackChunkName: "RESUMENVALIDACIONDEBULTOSPORSECTOR" */ "@/pages/DashboardEntregas/bultos/validaciongeneral.vue"
  );
/******************************************************* */
//Utilería
/******************************************************* */

//login route
const loginPage = () =>
  import(/* webpackChunkName: "LOGINPAGE" */ "@/pages/loginPage.vue");

const AuditoriaDashboard = () =>
  import(
    /* webpackChunkName: "AUDITORIADASHBOARD" */ "@/pages/DashboardEntregas/DashboardAuditoria/DashboardAuditoria.vue"
  );
/**************************************************
 *          STATUS DE DCONSEJERAS
 * ***********
 */

/**************************************************
 *          STATUS DE DISPOSITIVOS
 * ***********
 */

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: isLoggedIn,
      },
    ],
  },
  {
    path: "/manifiestos",
    component: DashboardLayoutCarga,
    redirect: "/manifiestos",
    children: [
      {
        path: "/manifiestos",
        name: "manifiestos",
        component: RampasManifiestos,
        beforeEnter: isLoggedIn,
      },
      {
        path: "/asignacion",
        name: "asignacion",
        component: RampasManifiestosAsignacionPorRuta,
        beforeEnter: isLoggedIn,
      },
      {
        path: "/login",
        name: "Inicio de sesión",
        component: loginPage,
      },
    ],
  },
  {
    path: "/rampas",
    component: DashboardLayoutNavLess,
    redirect: "/rampas/detalle",
    children: [
      {
        path: "/rampas/detalle",
        name: "Detalle carga en rampa",
        component: RampasDetalle,
        beforeEnter: isLoggedIn,
      },
    ],
  },
  {
    path: "/rampas/graficas",
    component: DashboardLayoutNavLess,
    redirect: "/rampas/graficas",
    children: [
      {
        path: "/rampas/graficas",
        name: "Grafica de carga por sector",
        component: RampasGraficas,
        beforeEnter: isLoggedIn,
      },
    ],
  },
  {
    path: "/rampas/listas",
    component: DashboardLayoutNavLess,
    redirect: "/rampas/listas",
    children: [
      {
        path: "/rampas/listas",
        name: "Rutas cargando",
        component: RampasLista,
        beforeEnter: isLoggedIn,
      },
    ],
  },
  {
    path: "/rampas/asignacion",
    component: DashboardLayoutCarga,
    redirect: "/rampas/asignacion",
    children: [
      {
        path: "/rampas/asignacion",
        name: "Asignación de rampas",
        component: RampasAsignacion,
        beforeEnter: isLoggedIn,
      },
    ],
  },
  {
    /*DETALLE DE ENTREGAS, detalle, sector, piloto*/
    path: "/entregas",
    component: DashboardLayoutEntregas,
    redirect: "/detalle",
    children: [
      {
        path: "/detalle",
        name: "Infomrnación de entregas | detalle",
        component: DashboardEntregas,
      },
      {
        path: "/detalletest",
        name: "Infomrnación de entregas a detalla",
        component: EntregasVistaDetalle,
      },
      {
        path: "/dsectortest",
        name: "Infomrnación de entregas a sector",
        component: EntregasVistaSector,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    /*DETALLE DISPOSITIVOS TELEFONOS APPS, MARCA, MODELO, GPS, SEÑAL, ALMACENAMIENT, STATUS PHONE PILOTOS*/
    path: "/consejeras",
    component: DashboardLayoutConsejeras,
    redirect: "/consejeras",
    children: [
      {
        path: "/consejeras",
        name: "Consejeras status",
        component: () =>
          import(
            /* webpackChunkName: "DCONSEJERAS" */ "@/pages/DashboardConsejeras/consejeras.vue"
          ),
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    /*DETALLE DISPOSITIVOS TELEFONOS APPS, MARCA, MODELO, GPS, SEÑAL, ALMACENAMIENT, STATUS PHONE PILOTOS*/
    path: "/dispositivos",
    component: DashboardLayoutDispositivos,
    redirect: "/dispositivos",
    children: [
      {
        path: "/dispositivos",
        name: "Dispositivos status",
        component: () =>
          import(
            /* webpackChunkName: "DISPOSITIVOS" */ "@/pages/DashboardDispositivos/dispositivos.vue"
          ),
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    /*DETALLE DE LIQUIDACIÓN PAGOS REALIZADOS A LA HORA DE LA ENTREGA DEL PEDIDO*/
    path: "/pagos",
    component: DashboardLiquidacion,
    redirect: "/pagos",
    children: [
      {
        path: "/pagos",
        name: "liquidacion status",
        component: () =>
          import(
            /* webpackChunkName: "PAGOS" */ "@/pages/DashboardLiquidaciones/liquidaciones.vue"
          ),
      },
    ],
    beforeEnter: isLoggedIn,
  },

  {
    /*DETALLE DE LIQUIDACIÓN PAGOS REALIZADOS A LA HORA DE LA ENTREGA DEL PEDIDO*/
    path: "/bancos",
    component: DashboardLiquidacion,
    redirect: "/bancos",
    children: [
      {
        path: "/bancos",
        name: "bancos",
        component: () => import("@/pages/DashboardLiquidaciones/bancos.vue"),
      },
    ],
    beforeEnter: isLoggedIn,
  },

  {
    /*DETALLE DISPOSITIVOS TELEFONOS APPS, MARCA, MODELO, GPS, SEÑAL, ALMACENAMIENT, STATUS PHONE PILOTOS*/
    path: "/dispositivos_detalle/:id_usuario",
    component: DashboardLayoutDispositivos,
    redirect: "/dispositivos_detalle/:id_usuario",
    children: [
      {
        path: "/dispositivos_detalle/:id_usuario",
        name: "detalleDispositivos",
        component: () =>
          import(
            /* webpackChunkName: "DISPOSITIVOSDETALLE" */ "@/pages/DashboardDispositivos/dispositivos_detalle.vue"
          ),
      },
    ],
    beforeEnter: isLoggedIn,
  },

  {
    /*DETALLE DE ENTREGAS, detalle, sector, piloto*/
    path: "/alcontadoycredito",
    component: DashboardLayoutEntregas,
    redirect: "/alcontadoycredito",
    children: [
      {
        path: "/alcontadoycredito",
        name: "Infomrnación de entregas | pestañas",
        component: DashboardEntregas,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/auditoria",
    component: DashboardLayoutAuditoria,
    redirect: "/auditoria",
    children: [
      {
        path: "/auditoria",
        name: "Auditoria",
        component: AuditoriaDashboard,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/mapa",
    component: DashboardMapaPrincipal,
    beforeEnter: isLoggedIn,
  },
  {
    path: "/historico",
    component: DashboardHistorico,
    beforeEnter: isLoggedIn,
  },

  {
    path: "*",
    component: NotFound,
  },
  {
    path: "/graficas",
    component: DashboardLayoutNavLess,
    redirect: "/graficas",
    children: [
      {
        path: "/graficas",
        component: GraficasGeneral,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/status",
    component: DashboardStatus,
    redirect: "/status",
    children: [
      {
        path: "/status",
        component: STATUS,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/guatex",
    component: DashboardGuatex,
    redirect: "/guatex",
    children: [
      {
        path: "/guatex",
        component: GUATEX,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/reporteordenesdecompra",
    component: ReporteOrdenesDeCompra,
    redirect: "/reporteordenesdecompra",
    children: [
      {
        path: "/reporteordenesdecompra",
        component: OrdenesDeCompra,
      },
    ],

    beforeEnter: isLoggedIn,
  },
  {
    path: "/pedidosrezagados",
    component: DashboardRezagados,
    redirect: "/pedidosrezagados",
    children: [
      {
        path: "/pedidosrezagados",
        component: PedidosRezagados,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/reportecoordenadas",
    component: DashboardReporteCoordenadas,
    redirect: "/reportecoordenadas",
    children: [
      {
        path: "/reportecoordenadas",
        component: ReporteDeCoordenadas,
      },
    ],
    beforeEnter: isLoggedIn,
  },

  {
    path: "/custodios",
    component: DashboardCustodios,
    redirect: "/custodios",
    children: [
      {
        path: "/custodios",
        component: ControlCustodios,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/gestion_usuarios",
    component: DashboardGestionUsuarios,
    redirect: "/gestion_usuarios",
    children: [
      {
        path: "/gestion_usuarios",
        component: GstionUsuarios,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    path: "/reporte_pedidos_sin_app",
    component: DashboardPedidosSinApp,
    redirect: "/reporte_pedidos_sin_app",
    children: [
      {
        path: "/reporte_pedidos_sin_app",
        component: GestionReportePedidosSinApp,
      },
    ],
    beforeEnter: isLoggedIn,
  },
  {
    name: "bultos",
    path: "/bultos",
    component: DashboardBultos,
    redirect: "/bultos",
    children: [
      {
        path: "/bultos",
        component: ResumenBultos,
      },
    ],
    beforeEnter: isLoggedIn,
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
