<template>
  <div class="row">
    <RampasPieGrafica
      :varPedidoRampasGlobal="varPedidoRampasGlobal"
      :class="'col-md-' + varColumnas"
      v-for="(cargaporsector, index) in pedidosGroupBy"
      :key="index"
      :cargaporsector="cargaporsector"
      :varColumnas="varColumnas"
    ></RampasPieGrafica>
  </div>
</template>
<script>
import RampasPieGrafica from "@/pages/ModuloRampas/RampasPieGrafica.vue";

export default {
  components: { RampasPieGrafica },
  data() {
    return {
      newPage: null,
      //** */
      pedidosGroupBy: [],
      varColumnas: 12
    };
  },
  props: ["varPedidoRampasGlobal"],
  methods: {
    agruparPorSector(pedido) {
      const found = this.pedidosGroupBy.some(
        el => el.pedido_sector === pedido.pedido_sector
      );
      if (!found) {
        this.pedidosGroupBy.push({
          pedido_sector: pedido.pedido_sector,
          total_pedidos_cargados: pedido.total_pedidos_cargados,
          total_pedidos_pendientes: pedido.total_pedidos_pendientes
        });
      }
      if (found) {
        let index = this.pedidosGroupBy.findIndex(
          el => el.pedido_sector === pedido.pedido_sector
        );

        this.pedidosGroupBy[index].total_pedidos_cargados =
          this.pedidosGroupBy[index].total_pedidos_cargados +
          pedido.total_pedidos_cargados;

        this.pedidosGroupBy[index].total_pedidos_pendientes =
          this.pedidosGroupBy[index].total_pedidos_pendientes +
          pedido.total_pedidos_pendientes;
      }
    },
    cargarDetalleDeCargas() {
      this.$store
        .dispatch("Serviciorampasgraficas/find", {
          query: {
            $sort: {
              createdAt: -1
            }
          }
        })
        .then(result => {
          this.pedidosGroupBy = [];
          result.forEach(pedido => {
            if (pedido.total_pedidos_cargados >= 1) {
              this.agruparPorSector(pedido);
            }
          });

          if (result.length == 1) {
            this.varColumnas = 6;
          }
          if (result.length >= 2) {
            this.varColumnas = 6;
          }
          if (result.length > 4) {
            this.varColumnas = 4;
          }
        });
    }
  },
  mounted() {
    // llamar registros ya en la base de datos
    this.cargarDetalleDeCargas();
    //siguiente vista detalle
    // this.newPage = setTimeout(() => {
    //   this.$router.push({ path: "/rampas/detalle" });
    // }, 11000);
  },
  beforeDestroy() {
    clearTimeout(this.newPage);
  }
};
</script>
<style scoped></style>
