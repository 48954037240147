<template>
  <li class="nav-item">
    <a class="icon-dashboard" href="/dashboard" title="Regresar al dashboard">
      <span class="material-icons">apps</span>
    </a>
  </li>
</template>
<script>
export default {
  name: "home-dashboard-button"
};
</script>
<style>
.icon-dashboard {
  color: white !important;
}
.icon-dashboard .material-icons {
  font-size: 35px;
  margin-top: 5px;
}
</style>
