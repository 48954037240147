<template>
  <div
    class="modal fade"
    id="modalAdminRutas"
    ref="modalAdminRutas"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 815px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 815px">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Administrar rutas</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <h4>{{ tituloForm }} ruta</h4>
            </div>
            <div class="col-md-3">
              <div class="form-group position-relative">
                <label for="sector">Ruta</label>
                <input
                  type="text"
                  class="form-control"
                  ref="formRuta"
                  id="formRuta"
                  name="formRuta"
                />
              </div>
            </div>
            <div class="col-md-2" v-if="!actualizarRuta">
              <button
                class="btn btn-info align-boton"
                id="btn-crear-ruta"
                @click="fnCrearRuta"
              >
                Crear ruta
              </button>
            </div>
            <div class="col-md-3" v-if="actualizarRuta">
              <input
                type="hidden"
                ref="formRutaId"
                id="formRutaId"
                name="formRutaId"
              />
              <button
                class="btn btn-info align-boton"
                id="btn-actualizar-ruta"
                @click="fnActualizarRuta"
              >
                Actualizar
              </button>
            </div>
            <div class="col-md-3" v-if="actualizarRuta">
              <button
                class="btn btn-info align-boton"
                id="btn-cancelar"
                @click="fnLimpiarFormActualizacion"
              >
                Cancelar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-alert :type="tipoResultado" v-if="finRequest" dismissible>
                <span style="white-space: pre-wrap">{{
                  mensajeResultado
                }}</span>
              </base-alert>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <v-server-table
                :columns="columns"
                :options="optionsRTable"
                ref="tablaRutas"
              >
                <button
                  slot-scope="props"
                  class="btn btn-sm btn-icon"
                  slot="editar"
                  @click="fnEditarRuta(props.row.id)"
                >
                  <i class="tim-icons icon-pencil"></i>
                </button>
              </v-server-table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cerrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "AdministrarRutas",
  components: { BaseAlert },
  data() {
    return {
      tituloForm: "Crear nueva",
      actualizarRuta: false,
      tipoResultado: "",
      finRequest: false,
      mensajeResultado: "",
      columns: ["route_id", "editar"],
      optionsRTable: {
        orderBy: {
          column: "route_id",
          ascending: true
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        pagination: { chunk: 10, dropdown: false },
        headings: {
          route_id: "Ruta",
          editar: "Editar ruta"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          if (data.query) {
            if (data.query["route_id"]) {
              search += `, "route_id[$like]": "%${data.query["route_id"]}%"`;
            }
          }
          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = process.env.VUE_APP_API_URL + "/" + `rampas-asignacion`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          return {
            data: resp.data.data,
            count: resp.data.total
          };
        },
        columnsClasses: {
          editar: "text-center"
        }
      }
    };
  },
  methods: {
    ...mapActions("RampasAsignacion", {
      createRampasAsignacion: "create",
      findRampasAsignacion: "find",
      patchRampasAsignacion: "patch"
    }),
    fnCrearRuta() {
      let ruta = this.$refs.formRuta;

      if (ruta.value.trim().length == 0) {
        return false;
      }

      let rutaNueva = {
        route_id: ruta.value.trim(),
        numero_rampa: 0
      };

      this.finRequest = false;
      this.createRampasAsignacion(rutaNueva)
        .then(response => {
          this.mensajeResultado = "Se creo exitosamente la ruta";
          this.tipoResultado = "success";
          ruta.value = "";
          this.$refs.tablaRutas.refresh();
        })
        .catch(this.fnHandleErrorResponse)
        .finally(() => {
          this.finRequest = true;
        });
    },
    fnEditarRuta(id) {
      this.actualizarRuta = true;
      this.tituloForm = "Actualizar";
      let rutaSeleccionada = this.$refs.tablaRutas.data.filter(
        ruta => ruta.id == id
      )[0];
      this.$refs.formRuta.value = rutaSeleccionada.route_id;
      this.$nextTick(() => {
        this.$refs.formRutaId.value = rutaSeleccionada.id;
      });
    },
    fnActualizarRuta() {
      let formRuta = this.$refs.formRuta;
      let formRutaId = this.$refs.formRutaId;

      if (formRuta.value.trim().length == 0) {
        return false;
      }

      let rutaUpdate = {
        route_id: formRuta.value.trim()
      };

      this.finRequest = false;
      this.patchRampasAsignacion([formRutaId.value, rutaUpdate])
        .then(response => {
          this.mensajeResultado = "Se actualizó exitosamente la ruta";
          this.tipoResultado = "success";

          this.$refs.tablaRutas.data.forEach(ruta => {
            if (ruta.id == formRutaId.value) {
              ruta.route_id = response.route_id;
            }
          });
          this.fnLimpiarFormActualizacion();
        })
        .catch(this.fnHandleErrorResponse)
        .finally(() => {
          this.finRequest = true;
        });
    },
    fnLimpiarFormActualizacion() {
      this.actualizarRuta = false;
      this.tituloForm = "Crear nueva";
      this.$refs.formRuta.value = "";
      this.$refs.formRutaId.value = "";
    },
    fnHandleErrorResponse(error) {
      this.tipoResultado = "danger";
      if (error.errors && Array.isArray(error.errors)) {
        error.errors.forEach(e => {
          if (e.validatorKey && e.validatorKey === "not_unique") {
            this.mensajeResultado = `La ruta ${e.value} ya existe`;
          } else {
            this.mensajeResultado = e.message;
          }
        });
      } else {
        this.mensajeResultado = error.message;
      }
    }
  }
};
</script>
<style>
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
</style>
