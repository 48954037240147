<template>
  <div class="wrapper">

    <div class="main-panel">


      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

     
    </div>
  </div>
</template>
<style scoped>
body > div > div.wrapper > div > div {
    padding: 20px 30px 30px 30px !important;
    min-height: calc(100vh - 70px);
    }
</style>
<script>
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
