<template>
  <div class="table-responsive-lg">
    <table class="table table-striped" id="tablaRutas">
      <thead>
        <tr>
          <th scope="col">Puerta</th>
          <th scope="col">Destino - Sector - Ruta</th>
          <th scope="col">Empresa de transporte</th>
          <th scope="col">Cantidad de bultos</th>
          <th scope="col">Cantidad de premios</th>
          <th scope="col">Hora de salida</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(pedido, index) in pedidosGroupByRampasLista" :key="index">
          <td scope="row">{{ pedido.LOADING_GATE }}</td>
          <td scope="row">
            {{ pedido.ROUTE_ID.substring(0, 3) }}
            <small>-</small>
            {{ pedido.ROUTE_ID.substr(3).slice(0, -3) }}
            <small>-</small>
            {{ pedido.ROUTE_ID.substr(pedido.ROUTE_ID.length - 3) }}
          </td>
          <!-- <td scope="row">{{ (pedido.ROUTE_ID).substring(5, 8) }}</td> -->
          <td scope="row">{{ pedido.DELIVERY_COMPANY_NAME }}</td>
          <td scope="row">
            {{ pedido.total_bultos_cargados }}/{{ pedido.total_bultos }}
          </td>
          <td scope="row">{{ pedido.total_premios }}</td>
          <td scope="row">
            <!-- TODO EJ EMPLO AGREGAR TIEMPo  -->
            {{
              pedido.inicio_carga
                | moment(
                  "utc",
                  "add",
                  ((pedido.total_bultos - pedido.total_bultos_cargados) * 60) /
                    400 +
                    " minutes",
                  "DD-MM-YY HH:mm:ss"
                )
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newPage: null,
      pedidosGroupByRampasLista: []
    };
  },
  props: ["varPedidoRampasGlobal"],
  watch: {
    varPedidoRampasGlobal: function(newVal, oldVal) {
      // watch it
      this.segmentarVistaLista(newVal);
      // this.$notify({
      //   type: "danger",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      // });
    }
  },
  methods: {
    cargarDetalleDeCargas() {
      this.$store
        .dispatch("Serviciorampaslistas/find", {
          query: {
            $sort: {
              createdAt: -1
            }
          }
        })
        .then(result => {
          this.pedidosGroupByRampasLista = result;
          //console.log(this.pedidosGroupByRampasLista)

          if (result.length == 1) {
            this.varColumnas = 12;
          }
          if (result.length == 2) {
            this.varColumnas = 6;
          }
          if (result.length == 3) {
            this.varColumnas = 6;
          }
          if (result.length == 4) {
            this.varColumnas = 6;
          }
        });
    },
    segmentarVistaLista: function(pedido) {
      const found = this.pedidosGroupByRampasLista.some(
        el => el.ROUTE_ID === pedido.ROUTE_ID
      );
      if (!found) {
        console.log("not found - rampas detalle");
        this.cargarDetalleDeCargas();
      }
      if (found) {
        console.log("yes found - rampas detalle");
        let index = this.pedidosGroupByRampasLista.findIndex(
          el => el.ROUTE_ID === pedido.ROUTE_ID
        );
        this.pedidosGroupByRampasLista[index].total_bultos_cargados =
          this.pedidosGroupByRampasLista[index].total_bultos_cargados +
          pedido.TOTAL_PACKAGES;
        this.pedidosGroupByRampasLista[index].total_premios =
          this.pedidosGroupByRampasLista[index].total_premios + pedido.PRIZES;
        this.pedidosGroupByRampasLista[index].total_hogar =
          this.pedidosGroupByRampasLista[index].total_hogar + pedido.HOGAR;
        this.pedidosGroupByRampasLista[index].total_pedidos_cargados =
          this.pedidosGroupByRampasLista[index].total_pedidos_cargados + 1;
      }
    }
  },
  beforeCreate() {
    this.$forceUpdate();
  },
  created() {},
  mounted() {
    if (!this.varPedidoRampasGlobal) {
      const { Cargarpedido } = this.$FeathersVuex.api;
      Cargarpedido.on("patched", pedido => this.segmentarVistaLista(pedido));
    }

    // llamar registros ya en la base de datos
    this.cargarDetalleDeCargas();
    console.log(this.$router.history.current.path);

    // // siguiente vista graficas
    // this.newPage = setTimeout(() => {
    //   this.$router.push({ path: "/rampas/graficas" });
    // }, 11000);

    //inicio
    setTimeout(function() {
      document.querySelector("body").style.overflow = "auto";
    }, 1500);

    //fin
  },
  beforeDestroy() {
    clearTimeout(this.newPage);
  }
};
</script>
<style scoped>
body {
  overflow: auto !important;
}
table#tablaRutas tbody tr td {
  font-size: 35px;
  text-align: right;
}
table#tablaRutas tbody tr th {
  font-size: 47px;
  text-align: right;
}
table#tablaRutas thead tr th {
  font-size: 21px;
  text-align: right;
}

.main-panel {
  border-top: none !important;
}
body > div > div.wrapper > div > div[data-v-35cffe15] {
  padding: 0px 30px 30px 30px !important;
}
</style>
