<template>
  <div
    class="modal fade"
    id="modalEditarUsuario"
    ref="modalEditarUsuario"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 1015px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 1015px">
        <div class="modal-header">
          <h5 class="modal-title">
            Editar usuario {{ datosEditadosUsuario.usuario_codigo }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <form class="" ref="fromEditarUsuario">
                <div class="form-group mr-1">
                  <label for="">Nombre del usuario</label>
                  <input
                    v-model="datosEditadosUsuario.usuario_nombre"
                    @input="
                      datosEditadosUsuario.usuario_nombre = $event.target.value.toUpperCase()
                    "
                    type="text"
                    name=""
                    class="form-control"
                    placeholder="Nombre del usuario"
                  />
                </div>
                <div class="form-group mr-1">
                  <label for="">Código para inicio de sesión</label>
                  <input
                    v-model="datosEditadosUsuario.usuario_codigo"
                    @input="
                      datosEditadosUsuario.usuario_codigo = $event.target.value.toUpperCase()
                    "
                    type="text"
                    name=""
                    class="form-control"
                    placeholder="Codigo de usuario"
                    disabled
                  />
                </div>
                <div class="form-group mr-1">
                  <label for="">Contraseña</label>
                  <input
                    type="text"
                    v-model="datosEditadosUsuario.password"
                    class="form-control"
                    placeholder="Contraseña"
                  />
                </div>
                <div class="form-group mr-1">
                  <label for="">Rol</label>
                  <select
                    class="custom-select"
                    @change="fnFormEncontrarRol($event)"
                  >
                    <option selected disabled>Listado de roles</option>
                    <option
                      :value="rol.id"
                      v-for="rol in ListadoDeRoles"
                      v-bind:key="rol.id"
                      :selected="datosEditadosUsuario.id_rol == rol.id"
                    >
                      {{ rol.rol_nombre }}
                    </option>
                  </select>
                </div>
                <div class="form-group mr-1">
                  <label for="">Empresa</label>
                  <select
                    class="custom-select"
                    @change="fnEncontrarEmpresaTransportista($event)"
                  >
                    <option selected disabled
                      >Listado de empresas de transporte</option
                    >
                    <option
                      :value="empresa.id"
                      v-for="empresa in ListadoDeEmpresasTransportistas"
                      v-bind:key="empresa.id"
                      :selected="
                        datosEditadosUsuario.DELIVERY_COMPANY_ID ==
                          empresa.codigo
                      "
                    >
                      {{ empresa.nombre }}
                    </option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
            @click="fnEditarUsuario()"
          >
            Guardar cambios
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "GestionUsuarioEditar",
  props: [
    "padreCodigoUsuario",
    "ListadoDeRoles",
    "ListadoDeEmpresasTransportistas"
  ],
  data() {
    return {
      datosEditadosUsuario: {
        usuario_nombre: "",
        usuario_codigo: "",
        id_rol: "",
        DELIVERY_COMPANY_ID: "",
        DELIVERY_COMPANY_NAME: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions("Usuarios", {
      patchUsuario: "patch",
      getInfoCredencialesUsuario: "get"
    }),
    fnEditarUsuario() {
      let infoUsuario = this.datosEditadosUsuario;
      if (this.datosEditadosUsuario.password == "") {
        delete infoUsuario.password;
      }
      this.patchUsuario([this.datosEditadosUsuario.id, infoUsuario]).then(
        result => {
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message:
              "Se ha editado la informacion del usuario: " +
              result.usuario_codigo
          });
          console.log(result);
        }
      );
    },
    fnEncontrarEmpresaTransportista(event) {
      console.log(event.target.value);
      let empresa = this.ListadoDeEmpresasTransportistas.filter(
        empresa => empresa.id == event.target.value
      );

      this.datosEditadosUsuario.DELIVERY_COMPANY_ID = empresa[0].codigo;
      this.datosEditadosUsuario.DELIVERY_COMPANY_NAME = empresa[0].nombre;
    },
    fnFormEncontrarRol(event) {
      // return "papas";

      let rol = this.ListadoDeRoles.filter(rol => rol.id == event.target.value);
      console.log(rol[0]);
      this.datosEditadosUsuario.id_rol = rol[0].id;
    }
  },
  mounted() {
    this.datosEditadosUsuario = this.padreCodigoUsuario;
  },
  watch: {
    padreCodigoUsuario: function(newVal, oldVal) {
      this.getInfoCredencialesUsuario(this.padreCodigoUsuario).then(result => {
        this.datosEditadosUsuario = result;
        this.datosEditadosUsuario.password = "";

        // this.$notify({
        //   type: "warning",
        //   verticalAlign: "bottom",
        //   horizontalAlign: "left",
        //   message: "Estás editando al usuario: " + result.usuario_codigo
        // });
      });
      //******************************* */
      /***********/
    }
  }
};
</script>
<style>
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
#modalEditarUsuario
  > div
  > div
  > div.modal-body
  > div
  > div
  > form
  > div
  > select {
  color: black;
}
option {
  color: black !important;
}
</style>
