<template>
  <div class="row">
    <div class="col-md-4">
      <label for="">Consultando pedidos de la campaña:</label>
      <select
        class="form-control"
        @change="fnSelecionarCampania"
        name="campania"
      >
        <option
          v-for="(item, index) in campanias"
          :selected="index == 0"
          :key="index"
          :value="item.campania"
        >
          Campaña {{ item.campania }}
        </option>
      </select>
    </div>
    <div class="col-md-5"></div>
    <div class="col-md-3 borderandpadding">
      <button
        class="btn btn-danger"
        data-toggle="modal"
        data-target="#modalEditarUsuario"
      >
        Enviar pedidos a entregados sin APP
      </button>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->
    <div class="col-md-12">
      <download-excel
        class="pull-right btn-exportar-verde"
        style="cursor: pointer"
        :fields="columnasEXCL"
        :fetch="exportTableData"
        :type="dataExportType"
        :name="
          'Reporte resumen de pedidos entregados sin app.' + dataExportType
        "
        >Exportar xls</download-excel
      >
    </div>
    <div class="col-md-12">
      <v-client-table
        ref="tablapedidosEntregadosSinApp"
        :data="rows"
        :columns="columns"
        :options="options"
      >
      </v-client-table>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->
    <modalCheckpasswordAndNoentregadosConApp></modalCheckpasswordAndNoentregadosConApp>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";
import { mapActions } from "vuex";
import modalCheckpasswordAndNoentregadosConApp from "@/pages/Dashboard/ReportePedidosSinAppModal.vue";
export default {
  components: {
    modalCheckpasswordAndNoentregadosConApp,
    downloadExcel: JsonExcel
  },
  data() {
    return {
      //
      columnasEXCL: {
        Compañia: "DELIVERY_COMPANY_NAME",
        Piloto: "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
        Placas: "DELIVERY_ASSIGNED_TO_VEHICULE_PLATES",
        Sector: "DELIVERY_CLIENT_REGION",
        Ruta: "ROUTE_ID",
        "Total pedidos": "pedidos"
      },
      //
      dataExportType: "xls",
      //
      campaniaSelected: "",
      campanias: [],
      rows: [],
      columns: [
        "DELIVERY_COMPANY_NAME",
        "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
        "DELIVERY_ASSIGNED_TO_VEHICULE_PLATES",
        "DELIVERY_CLIENT_REGION",
        "ROUTE_ID",
        "pedidos"
      ],
      options: {
        headings: {
          title: function(h) {
            return (
              <h6>
                <input type="checkbox" />
                Title
              </h6>
            );
          }
        },
        multiSorting: {
          name: [
            {
              column: "btnDetalle",
              matchDir: false
            }
          ]
        },
        orderBy: {
          column: "ERP_DOC",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Ingrese filtros de bùsqueda",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["CREATED", "UPLOADED_DATE", "DELIVERY_DATE"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },

        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "DELIVERY_COMPANY_NAME",
          "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
          "DELIVERY_ASSIGNED_TO_VEHICULE_PLATES",
          "DELIVERY_CLIENT_REGION",
          "ROUTE_ID",
          "pedidos"
        ],
        headings: {
          DELIVERY_COMPANY_NAME: "Compañia",
          DELIVERY_ASSIGNED_TO_DRIVER_NAME: "Piloto",
          DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: "Placas",
          DELIVERY_CLIENT_REGION: "Sector",
          ROUTE_ID: "Ruta",
          pedido: "Total pedidos"
        }
      }
    };
  },
  methods: {
    ...mapActions("Consultapedidossinapp", {
      findPedidosSinApp: "find"
    }),
    ...mapActions("Campanias", {
      findCampanias: "find"
    }),
    exportTableData() {
      return this.$refs.tablapedidosEntregadosSinApp.allFilteredData; // Change "tablapedidosEntregadosSinApp" to your ref
    },
    fnSelecionarCampania() {
      this.campaniaSelected = event.target.value;
      this.fnCargarPedidos();
    },
    fnCargarPedidos() {
      this.rows = [];
      this.findPedidosSinApp({
        query: { CAMPAING_ID: this.campaniaSelected }
      }).then(result => {
        this.rows = result;
      });
    }
  },
  mounted() {
    /** */
    let encabezadoTabla = document.querySelector(
      "body > div > div.wrapper > div > div > div > div.col-md-12 > div > div:nth-child(1) > div"
    );
    encabezadoTabla.append("Tabla resumen de pedidos entregados sin APP");
    /**/
    this.findCampanias({ query: {} }).then(result => {
      this.campanias = result;
      this.campaniaSelected = this.campanias[0].campania;
      this.fnCargarPedidos();
    });
    /**/

    const { Reportepedidosentregadossinapp } = this.$FeathersVuex.api;
    Reportepedidosentregadossinapp.on("created", () => {
      this.fnCargarPedidos();
    });
    /**/
  },
  created() {}
};
</script>
<style scoped>
#usuario_codigo {
  text-transform: uppercase;
}
#usuario_codigo::placeholder {
  text-transform: capitalize;
}
form > div > select {
  color: black;
}
option {
  color: black !important;
}
.borderandpadding {
  border: 1px solid black;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
</style>
