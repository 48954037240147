// TITULO: TRAZABILIDAD DE PEDIDOS
<template>
  <div id="app" style="position: relative; height: 100vh">
    <div class="row">
      <div class="col-md-2 align-bottom">
        <h4 class="align-bottom" style="margin-top: 28px">
          Filtros de búsqueda
        </h4>
      </div>

      <div class="col-md-1" v-if="!loading && id_rol != 4 && id_rol != 8">
        <button
          title="Anular pedido"
          @click="fnAnularPedido"
          style="margin-top: 4px"
          class="pull-right btn btn-sm"
        >
          <i class="tim-icons icon-simple-remove"></i>
        </button>
      </div>

      <div class="col-md-1" v-if="!loading && canResetPedido">
        <button
          title="Reset pedido a picking"
          @click="fnResetearPedido"
          style="margin-top: 4px"
          class="pull-left btn btn-info btn-sm"
        >
          <i class="tim-icons icon-refresh-01"></i>
        </button>
      </div>

      <div class="col-md-2" v-if="!loading && id_rol != 4">
        <select
          class="custom-select"
          id="inlineFormCustomSelect"
          style="color: #000"
          @change="seleccionarPago"
        >
          <option value="1" selected>Todos</option>
          <option value="4">Al contado</option>
          <option value="3">Al crédito</option>
        </select>
      </div>
      <div class="col-md-2" v-if="!loading && id_rol != 4">
        <select
          class="form-control"
          @change="fnSelecionarCampania"
          name="campania"
        >
          <!-- <option selected disabled>Seleccionar una campaña</option> -->
          <option
            v-for="(item, index) in campanias"
            :selected="index == 0"
            :key="index"
            :value="item.campania"
          >
            Campaña {{ item.campania }}
          </option>
        </select>
      </div>
      <div class="col-md-2" v-if="!loading && id_rol != 4">
        <date-range-picker
          v-model="dateRange"
          :append-to-body="true"
          :ranges="false"
          :locale-data="locale"
          :StartDate="new Date()"
          :En="new Date()"
          @update="cambioDeFecha()"
        ></date-range-picker>
      </div>
      <div class="col-md-1" v-if="!loading && id_rol != 4">
        <download-excel
          class="btn-exportar-verde"
          style="max-width: 71px"
          :fetch="exportarDatospago"
          :fields="columnasEXCLPAGO"
          :before-generate="startDownload"
          :before-finish="finishDownload"
          :type="dataExportType"
          :name="tipoPedidoPago"
          >Exportar</download-excel
        >
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-2" v-if="loading">
        <slot name="loading">
          <i class="fas fa-spinner fa-spin"></i>
        </slot>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="formFiltroPedido">Número de pedido</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroPedido"
            id="formFiltroPedido"
            name="formFiltroPedido"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroPedido ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroPedido')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="formFiltroNombreConsejera">Nombre de consejera</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroNombreConsejera"
            id="formFiltroNombreConsejera"
            name="formFiltroNombreConsejera"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroNombreConsejera ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroNombreConsejera')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="formFiltroManifiesto">Manifiesto</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroManifiesto"
            id="formFiltroManifiesto"
            name="formFiltroManifiesto"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroManifiesto ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroManifiesto')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="formFiltroSector">Sector</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroSector"
            id="formFiltroSector"
            name="formFiltroSector"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroSector ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroSector')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="formFiltroRuta">Ruta</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroRuta"
            id="formFiltroRuta"
            name="formFiltroRuta"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroRuta ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroRuta')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <button class="btn-exportar-azul" @click="buscar" id="btn-buscar">
          Buscar
        </button>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <h4>Resultados</h4>
      </div>
    </div>
    <!-- EXPORTAR A excel -->

    <div class="row" v-if="id_rol != 4">
      <div class="col-md-12">
        <download-excel
          class="pull-left btn-exportar-verde"
          style=""
          :fields="columnasEXCL"
          :fetch="exportTableData"
          :type="dataExportType"
          :name="'Reporte de rutas activas.' + dataExportType"
        >
          <!-- <i style="color:green;font-size:20px;" class="fas fa-file-excel"></i> -->
          Exportar
        </download-excel>
      </div>
    </div>

    <!-- /*************tabla con opciones ************ */ -->
    <v-client-table
      v-if="id_rol != 4"
      ref="tablaPedidosDetalle"
      :data="rows"
      :columns="columns"
      :options="options"
    >
      <template slot="selected">
        <div>
          <h1>--</h1>
        </div>
      </template>
      <template slot="afterBody" v-if="realizandoBusqueda">
        <div class="overlay-loader"></div>
        <clip-loader
          size="50px"
          class="clip-loader"
          :color="colorSpinner"
        ></clip-loader>
      </template>
      <button
        :disabled="props.row.STATUS == 2 || props.row.STATUS == 0"
        :id="'btn' + props.row.ERP_DOC"
        v-bind:class="[
          props.row.DELIVERY_RETURN_CODE ? 'btn-warning' : 'btn-info',
        ]"
        slot-scope="props"
        class="btn btn-sm"
        slot="btnDevolucion"
        @click="fnAsignarVariableErpDocDevolucion(props.row.ERP_DOC)"
      >
        Devolución
      </button>
      <button
        :disabled="
          props.row.STATUS >= 2 ||
          props.row.STATUS == 0 ||
          props.row.DELIVERY_RETURN_CODE
        "
        :id="'btn' + props.row.ERP_DOC"
        slot-scope="props"
        class="btn btn-sm"
        slot="btnQuitarAsignacion"
        @click="fnMostrarModalAsignacion(props.row.ERP_DOC)"
      >
        Asignación
      </button>
      <button
        slot-scope="props"
        class="btn btn-primary btn-sm"
        slot="btnDetalle"
        @click="fnAsignarVariableErpDocDetalle(props.row.id)"
      >
        Detalle
      </button>
    </v-client-table>
    <!-- fin -->
    <!-- /*************tabla sin opciones para el usuario de rampas ************ */ -->
    <v-client-table
      v-if="id_rol == 4"
      ref="tablaPedidosDetalle"
      :data="rows"
      :columns="columns2"
      :options="options"
    >
      <template slot="selected">
        <div>
          <h1>coca</h1>
        </div>
      </template>
      <template slot="afterBody" v-if="realizandoBusqueda">
        <div class="overlay-loader"></div>
        <clip-loader
          size="50px"
          class="clip-loader"
          :color="colorSpinner"
        ></clip-loader>
      </template>
      <button
        :disabled="props.row.STATUS == 2 || props.row.STATUS == 0"
        :id="'btn' + props.row.ERP_DOC"
        v-bind:class="[
          props.row.DELIVERY_RETURN_CODE ? 'btn-warning' : 'btn-info',
        ]"
        slot-scope="props"
        class="btn btn-sm"
        slot="btnDevolucion"
        @click="fnAsignarVariableErpDocDevolucion(props.row.ERP_DOC)"
      >
        Devolución
      </button>
      <button
        :disabled="
          props.row.STATUS >= 2 ||
          props.row.STATUS == 0 ||
          props.row.DELIVERY_RETURN_CODE
        "
        :id="'btn' + props.row.ERP_DOC"
        slot-scope="props"
        class="btn btn-sm"
        slot="btnQuitarAsignacion"
        @click="fnMostrarModalAsignacion(props.row.ERP_DOC)"
      >
        Asignación
      </button>
      <button
        slot-scope="props"
        class="btn btn-primary btn-sm"
        slot="btnDetalle"
        @click="fnAsignarVariableErpDocDetalle(props.row.id)"
      >
        Detalle
      </button>
    </v-client-table>

    <modalEntregasDevoluciones
      @devolucionAsignada="devolucionAsignada($event)"
      @clicked="fnDesasignarErpDocDevolucion($event)"
      v-if="entregaERPDOCDevolucion !== null"
      v-bind:entregaERPDOCDevolucion="entregaERPDOCDevolucion"
      v-bind:ventanaInformacionDeEntregaDevolucion="
        ventanaInformacionDeEntregaDevolucion
      "
      v-bind:codigoDevolucion="codigoDevolucion"
    ></modalEntregasDevoluciones>
    <modalEntregasVerDetalle
      @clicked="fnDesasignarErpDocDetalle($event)"
      v-if="entregaERPDOCDetalle !== null"
      v-bind:entregaERPDOCDetalle="entregaERPDOCDetalle"
      v-bind:ventanaInformacionDeDevolucionesHistorico="
        ventanaInformacionDeDevolucionesHistorico
      "
      v-bind:ventanaInformacionDeEntrega="ventanaInformacionDeEntrega"
    ></modalEntregasVerDetalle>

    <modalEntregasAsignacion
      v-if="mostrarModalAsignacion"
      v-bind:pedidoId="entregaERPDOCAsignacion"
      v-bind:pedidoDetalle="entregaAsignacionDetalle"
      @clicked="fnMostrarModalAsignacion(null)"
      @asignacionRemovida="fnAsignacionRemovida($event)"
    ></modalEntregasAsignacion>

    <modalAnularPedido
      v-if="showAnularPedido"
      @clicked="fnAnularPedido()"
    ></modalAnularPedido>

    <modalResetearPedido
      v-if="showResetearPedido"
      @clicked="fnResetearPedido()"
    ></modalResetearPedido>

    <!-- /************************************* */ -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import JsonExcel from "vue-json-excel";
import modalEntregasDevoluciones from "@/pages/DashboardEntregas/modals/modalEntregasDevoluciones.vue";
import modalEntregasVerDetalle from "@/pages/DashboardEntregas/modals/modalEntregasVerDetalle.vue";
import modalEntregasAsignacion from "@/pages/DashboardEntregas/modals/modalEntregasAsignacion.vue";
import modalAnularPedido from "@/pages/DashboardEntregas/modals/modalAnularPedido.vue";
import modalResetearPedido from "@/pages/DashboardEntregas/modals/modalResetearPedido.vue";
import ClipLoader from "vue-spinner/src/ClipLoader";
export default {
  computed: {
    canResetPedido() {
      if (this.$store.state.auth.payload.usuarios.id == 31) {
        return true;
      }
      if (this.$store.state.auth.payload.usuarios.id == 383) {
        return true;
      }
      if (this.$store.state.auth.payload.usuarios.id == 486) {
        return true;
      }
      if (this.$store.state.auth.payload.usuarios.id == 338) {
        return true;
      }
      if (this.$store.state.auth.payload.usuarios.id == 503) {
        return true;
      } else return false;
    },
    id_rol() {
      return this.$store.state.auth.payload.usuarios.id_rol;
    },
  },
  components: {
    modalAnularPedido,
    modalResetearPedido,
    DateRangePicker,
    modalEntregasDevoluciones,
    modalEntregasVerDetalle,
    downloadExcel: JsonExcel,
    "clip-loader": ClipLoader,
    modalEntregasAsignacion,
  },
  props: ["cargarPedido", "realizarEntrega", "realizarDevolucion"],
  watch: {
    tipoPago: function (newVal, oldVal) {
      this.cambioDeFecha();
      console.log(this.tipoPedidoPago);
    },
    "dateRange.startDate": function (newVal, oldVal) {
      this.cambioDeFecha();
      console.log(this.tipoPedidoPago);
    },
    cargarPedido: function (newVal, oldVal) {
      //******************************* */
      /***********/
      this.$notify({
        type: "success",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Pedido cargado: " + newVal.ERP_DOC,
      });
    },
    realizarEntrega: function (newVal, oldVal) {
      //this.segmentar(newVal);
      for (var i in this.rows) {
        if (this.rows[i].ERP_DOC == newVal.ERP_DOC) {
          console.log(this.rows[i]);
          this.rows[i].STATUS = 2;
          this.rows[i].STATUSTEXT = newVal.STATUSTEXT;
          this.rows[i].DELIVERY_DATE = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
      //******************************* */
      /***********/
      this.$notify({
        type: "success",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Pedido entregado: " + newVal.ERP_DOC,
      });
    },
    realizarDevolucion: function (newVal, oldVal) {
      for (var i in this.rows) {
        if (this.rows[i].ERP_DOC == newVal.ERP_DOC) {
          this.rows[i].STATUS = 3;
          this.rows[i].STATUSTEXT = newVal.STATUSTEXT;
          this.rows[i].DELIVERY_DATE = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
      //
      let ref = "btn" + newVal.ERP_DOC;
      document.getElementById(ref).classList.remove("btn-info");
      document.getElementById(ref).classList.add("btn-warning");
      //
      this.$notify({
        type: "Info ",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Pedido devuelto: " + newVal.ERP_DOC,
      });
    },
  },
  data() {
    return {
      tipopeditipoPago: 1,
      campanias: [],
      campaniaSelected: "",
      showAnularPedido: false,
      showResetearPedido: false,
      tipoPedidoPago: "",
      loading: null,
      excelName: "",
      tipoPago: 1,
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      ////////////////
      //
      columnasEXCLPAGO: {
        "No. pedido": "ERP_DOC",
        Paquetes: "TOTAL_PACKAGES",
        Ruta: "ROUTE_ID",
        Consejera: "DELIVERY_CLIENT_NAME",
        Picking: "Picking",

        Manifiesto: "Manifiesto",
        Carga: "Carga",

        Piloto: "Piloto",
        Usuario: "Usuario",
        Entrega: "Entrega",
        Estado: "STATUSTEXT",
        Sector: "sector",
      },
      columnasEXCL: {
        "No. pedido": "ERP_DOC",
        Paquetes: "TOTAL_PACKAGES",
        Ruta: "ROUTE_ID",
        Consejera: "DELIVERY_CLIENT_NAME",
        Picking: "CREATED_REPORTE",
        Manifiesto: "MANIFEST_DB_ID",
        Carga: "UPLOADED_DATE_REPORTE",
        Piloto: "DELIVERY_DRIVER_NAME",
        Entrega: "DELIVERY_DATE_REPORTE",
        Estado: "STATUSTEXT",
      },
      //
      dataExportType: "xls",
      //
      codigoDevolucion: "",
      ventanaInformacionDeEntregaDevolucion: null,
      ventanaInformacionDeDevolucionesHistorico: null,
      ventanaInformacionDeEntrega: null,
      // seccion de manifiestos anexos
      entregaERPDOCDevolucion: null,
      entregaERPDOCDetalle: null,
      //
      anexosDeManifiesto: [],
      //fin
      fillInput: {
        formFiltroPedido: false,
        formFiltroNombreConsejera: false,
        formFiltroManifiesto: false,
        formFiltroSector: false,
        formFiltroRuta: false,
      },
      colorSpinner: "#031d6a",
      realizandoBusqueda: false,
      mostrarModalAsignacion: false,
      entregaERPDOCAsignacion: null,
      entregaAsignacionDetalle: null,
      columns: [
        "ERP_DOC",
        "ROUTE_ID",
        "DELIVERY_CLIENT_NAME",
        "CREATED",
        "MANIFEST_DB_ID",
        "UPLOADED_DATE",
        "DELIVERY_DRIVER_NAME",
        "DELIVERY_DATE",
        "STATUSTEXT",
        "btnDevolucion",
        "btnQuitarAsignacion",
        "btnDetalle",
      ],
      columns2: [
        "ERP_DOC",
        "ROUTE_ID",
        "DELIVERY_CLIENT_NAME",
        "CREATED",
        "MANIFEST_DB_ID",
        "UPLOADED_DATE",
        "DELIVERY_DRIVER_NAME",
        "DELIVERY_DATE",
        "STATUSTEXT",
        "btnQuitarAsignacion",
      ],
      rows: [],
      options: {
        headings: {
          title: function (h) {
            return (
              <h6>
                <input type="checkbox" />
                Title
              </h6>
            );
          },
        },
        multiSorting: {
          name: [
            {
              column: "btnDetalle",
              matchDir: false,
            },
          ],
        },
        orderBy: {
          column: "ERP_DOC",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Ingrese filtros de bùsqueda",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["CREATED", "UPLOADED_DATE", "DELIVERY_DATE"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "ERP_DOC",
          "ROUTE_ID",
          "DELIVERY_CLIENT_NAME",
          "CREATED",
          "MANIFEST_DB_ID",
          "DELIVERY_DRIVER_NAME",
          "UPLOADED_DATE",
          "DELIVERY_DATE",
          "STATUSTEXT",
        ],
        headings: {
          ERP_DOC: "Pedido",
          ROUTE_ID: "Ruta",
          DELIVERY_CLIENT_NAME: "Consejer@",
          CREATED: "fecha en picking",
          MANIFEST_DB_ID: "id manifiesto",
          UPLOADED_DATE: "Fecha de carga",
          DELIVERY_DRIVER_NAME: "Piloto",
          DELIVERY_DATE: "fecha de entrega",
          STATUSTEXT: "Estado",
          btnDevolucion: "Devolucion",
          btnQuitarAsignacion: "Asignación",
          btnDetalle: "ver detalle",
        },
      },
    };
  },
  methods: {
    ...mapActions("Pedidos", {
      getpedido: "get",
      findpedidos: "find",
      patchpedidos: "patch",
      crearpedido: "create",
    }),
    fnSelecionarCampania() {
      this.campaniaSelected = event.target.value;
    },
    fnAnularPedido() {
      this.showAnularPedido = !this.showAnularPedido;
    },
    fnResetearPedido() {
      this.showResetearPedido = !this.showResetearPedido;
    },
    cambioDeFecha() {
      if (this.tipopeditipoPago == 1) {
        console.log("modificando, todos los pedidos");
        this.tipoPedidoPago =
          "Pedidos con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (this.tipopeditipoPago == 4) {
        console.log("modificando, pedidos al contado");
        this.tipoPedidoPago =
          "Pedidos al contado con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (this.tipopeditipoPago == 3) {
        console.log("modificando, pedidos al crédito");
        this.tipoPedidoPago =
          "Pedidos al crédito con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
    },
    seleccionarPago(event) {
      console.log(event.target.value);
      this.tipoPago = event.target.value;

      if (event.target.value == 1) {
        this.tipoPedidoPago =
          "Pedidos con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (event.target.value == 4) {
        this.tipoPedidoPago =
          "Pedidos al contado con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (event.target.value == 3) {
        this.tipoPedidoPago =
          "Pedidos al crédito con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }

      console.log(this.tipoPedidoPago);
    },
    exportTableData() {
      return this.$refs.tablaPedidosDetalle.allFilteredData; // Change "tablaPedidosDetalle" to your ref
    },
    async exportarDatospago() {
      const pepinos = await this.$store
        .dispatch("Excelreporteporpago/find", {
          query: {
            tipoPago: this.tipoPago,
            dateRange: this.dateRange,
            campaniaSelected: this.campaniaSelected,
          },
        })
        .then((result) => {
          console.log(this.tipoPago);

          if (result.length == 0) {
            if (this.tipoPago == 1) {
              this.tipoPedidoPago = "No existen pedidos con movimientos del ";
            }
            if (this.tipoPago == 4) {
              this.tipoPedidoPago =
                "No existen pedidos al contado con movimientos del ";
            }
            if (this.tipoPago == 3) {
              this.tipoPedidoPago =
                "No existen pedidos al crédito con movimientos del ";
            }
            alert(
              this.tipoPedidoPago +
                moment(this.dateRange.startDate).format("DD-MM-YYYY") +
                " al " +
                moment(this.dateRange.endDate).format("DD-MM-YYYY")
            );
            this.loading = false;
          }
          return result;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$notify({
            type: "danger",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message:
              "Hubo un error al momento de generar el archivo de excel, por favor intenta de nuevo más tarde",
          });
        });

      return pepinos;
    },
    startDownload() {
      console.log(this.tipoPedidoPago);
      if (this.tipoPago == 1) {
        this.tipoPedidoPago =
          "Pedidos con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (this.tipoPago == 4) {
        this.tipoPedidoPago =
          "Pedidos al contado con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }
      if (this.tipoPago == 3) {
        console.log(
          "muffings " + moment(this.dateRange.startDate).format("DD-MM-YYYY")
        );
        this.tipoPedidoPago =
          "Pedidos al crédito con movimientos del " +
          moment(this.dateRange.startDate).format("DD-MM-YYYY") +
          " al " +
          moment(this.dateRange.endDate).format("DD-MM-YYYY");
      }

      this.loading = true;
    },
    finishDownload() {
      this.loading = false;
      this.tipoPedidoPago =
        "Pedidos con movimientos del " +
        moment(this.dateRange.startDate).format("DD-MM-YYYY") +
        " al " +
        moment(this.dateRange.endDate).format("DD-MM-YYYY");
      this.tipoPago = 1;
    },
    //
    devolucionAsignada(erpdoc) {
      let ref = "btn" + erpdoc;
      console.log("ref: " + ref);
      document.getElementById(ref).classList.remove("btn-info");
      document.getElementById(ref).classList.add("btn-warning");
    },
    //genera codigo aleatorio de caracteres
    makeCode() {
      let date = new Date();
      let campaing_id =
        ("0" + date.getYear()).slice(-2) +
        ("0" + date.getMonth() + 1).slice(-2);
      let result = "";
      let characters = "0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      //console.log(campaing_id + result);
      this.codigoDevolucion = campaing_id + result;
    },
    //se consulta a la base de datos la información del pedido
    fnAsignarVariableErpDocDetalle(erp_doc) {
      console.log("buscar información del pedido (id:" + erp_doc + ")");
      //asignar infmracion del pedido

      let date = new Date();
      let campaing_id =
        ("0" + date.getYear()).slice(-2) +
        ("0" + date.getMonth() + 1).slice(-2);
      // ASIGNAR INFORMACIÓN DEL PEDIDO
      this.getpedido(erp_doc).then((result) => {
        console.log(result);
        if (result.PRIZES === null) {
          result.PRIZES = 0;
        }
        if (result.HOME === null) {
          result.HOME = 0;
        }

        if (result.STATUS == 1) {
          this.deliveryTextColor = "red";
        } else if (result.STATUS == 2) {
          this.deliveryTextColor = "green";
        } else if (result.STATUS == 3) {
          this.deliveryTextColor = "purple";
        } else {
          this.deliveryTextColor = "orange";
        }
        this.ventanaInformacionDeEntrega = result;

        //ENVIAR NÚMERO DE ERP_DOC AL HISTORICO DE DEVOLUCIONES
        if (
          this.ventanaInformacionDeEntrega.DELIVERY_RESEND != null &&
          this.ventanaInformacionDeEntrega.DELIVERY_RESEND != 0
        ) {
          console.log(
            "ventanaInformacionDeDevolucionesHistorico" +
              this.ventanaInformacionDeEntrega.DELIVERY_RESEND
          );
          this.ventanaInformacionDeDevolucionesHistorico =
            this.ventanaInformacionDeEntrega.ERP_DOC;
        } else {
          this.ventanaInformacionDeDevolucionesHistorico = null;
        }
      });
      //variable erp_doc para detalle
      this.entregaERPDOCDetalle = erp_doc;
    },
    // DES-asignar informacion erp_doc detalle
    fnDesasignarErpDocDetalle(e) {
      this.entregaERPDOCDetalle = null;
      this.ventanaInformacionDeEntrega = null;
      this.ventanaInformacionDeDevolucionesHistorico = null;
    },
    //asignar devolucion - CREAR Y ASIGNAR CODIGO DE DEVLUCIOÓN
    fnAsignarVariableErpDocDevolucion(erp_doc) {
      // console.log();
      this.makeCode();
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            ERP_DOC: erp_doc,
          },
        })
        .then((result) => {
          if (result.data[0].PRIZES === null) {
            result.data[0].PRIZES = 0;
          }
          if (result.data[0].HOME === null) {
            result.data[0].HOME = 0;
          }

          if (result.data[0].STATUS == 1) {
            this.deliveryTextColor = "red";
          } else if (result.data[0].STATUS == 2) {
            this.deliveryTextColor = "green";
          } else if (result.data[0].STATUS == 3) {
            this.deliveryTextColor = "blue";
          } else {
            this.deliveryTextColor = "orange";
          }
          this.ventanaInformacionDeEntregaDevolucion = result.data[0];
        });
      this.entregaERPDOCDevolucion = erp_doc;
    },
    // DES-asignar MODAL de Devolución
    fnDesasignarErpDocDevolucion(e) {
      this.ventanaInformacionDeEntregaDevolucion = null;
      this.entregaERPDOCDevolucion = null;
    },
    //
    fnActualizarInformacionDelProductio(erpdoc) {
      var pedido = this.rows.find(function (p) {
        return p.ERP_DOC === erpdoc;
      });

      if (pedido && pedido["ERP_DOC"]) {
        pedido["ERP_DOC"] = val;
      }
    },
    buscar() {
      const pedido = this.$refs.formFiltroPedido;
      const nombreConsejera = this.$refs.formFiltroNombreConsejera;
      const manifiesto = this.$refs.formFiltroManifiesto;
      const sector = this.$refs.formFiltroSector;
      const ruta = this.$refs.formFiltroRuta;

      if (this.realizandoBusqueda) {
        return;
      }

      if (
        pedido.value.length == 0 &&
        nombreConsejera.value.length == 0 &&
        manifiesto.value.length == 0 &&
        sector.value.length == 0 &&
        ruta.value.length == 0
      ) {
        this.rows = [];
        return;
      }

      this.realizandoBusqueda = true;
      this.mostrarIconoLimpiarFiltro(pedido);
      this.mostrarIconoLimpiarFiltro(nombreConsejera);
      this.mostrarIconoLimpiarFiltro(manifiesto);
      this.mostrarIconoLimpiarFiltro(sector);
      this.mostrarIconoLimpiarFiltro(ruta);

      this.$store
        .dispatch("Entregasdetalle/find", {
          query: {
            ERP_DOC: pedido.value.trim(),
            DELIVERY_CLIENT_NAME: nombreConsejera.value.trim(),
            MANIFEST_ID: manifiesto.value.trim(),
            SECTOR: sector.value.trim(),
            ROUTE_ID: ruta.value.trim(),
          },
        })
        .then((response) => {
          this.rows = [];
          this.realizandoBusqueda = false;
          response.forEach((PEDIDO) => {
            if (PEDIDO.DELIVERY_RESEND != null && PEDIDO.DELIVERY_RESEND != 0) {
              console.log(PEDIDO.CREATED);
            }
            //CREATED
            if (PEDIDO.CREATED !== null) {
              PEDIDO.CREATED_REPORTE = PEDIDO.CREATED;
              //
              PEDIDO.CREATED = moment(PEDIDO.CREATED, "DD-MM-YYYY HH:mm");
            } else {
              PEDIDO.CREATED_REPORTE = "N/A";
              PEDIDO.CREATED = "N/A";
            }
            //UPLOADED_DATE
            if (PEDIDO.UPLOADED_DATE !== null) {
              PEDIDO.UPLOADED_DATE_REPORTE = PEDIDO.UPLOADED_DATE;
              //
              PEDIDO.UPLOADED_DATE = moment(
                PEDIDO.UPLOADED_DATE,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              PEDIDO.UPLOADED_DATE_REPORTE = "N/A";
              PEDIDO.UPLOADED_DATE = "N/A";
            }

            //DELIVERY_DATE
            if (PEDIDO.DELIVERY_DATE !== null) {
              PEDIDO.DELIVERY_DATE_REPORTE = PEDIDO.DELIVERY_DATE;
              //
              PEDIDO.DELIVERY_DATE = moment(
                PEDIDO.DELIVERY_DATE,
                "DD-MM-YYYY HH:mm"
              );
            } else {
              PEDIDO.DELIVERY_DATE_REPORTE = "N/A";
              PEDIDO.DELIVERY_DATE = "N/A";
            }

            //DELIVERY_DRIVER_NAME
            if (PEDIDO.DELIVERY_DRIVER_NAME) {
              PEDIDO.DELIVERY_DRIVER_NAME =
                PEDIDO.DELIVERY_DRIVER_NAME +
                " (" +
                PEDIDO.DELIVERY_VEHICULE_PLATES +
                ")";
            } else {
              PEDIDO.DELIVERY_DRIVER_NAME = "N/A";
            }
            //MANIFEST_DB_ID
            if (!PEDIDO.MANIFEST_DB_ID) {
              PEDIDO.MANIFEST_DB_ID = "N/A";
            }
            this.rows.push(PEDIDO);
          });
        });
    },
    mostrarIconoLimpiarFiltro(inputFiltro) {
      this.fillInput[inputFiltro.id] = inputFiltro.value.length > 0;
    },
    limpiarFiltro(id) {
      this.fillInput[id] = false;
      this.$refs[id].value = "";
      this.$refs[id].focus();
      this.buscar();
    },
    fnMostrarModalAsignacion(pedidoId) {
      this.mostrarModalAsignacion = pedidoId != null;
      this.entregaERPDOCAsignacion = pedidoId;
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            ERP_DOC: pedidoId,
          },
        })
        .then((result) => {
          this.entregaAsignacionDetalle = result.data[0];
        });
    },
    fnAsignacionRemovida(event) {
      this.mostrarModalAsignacion = false;
      this.entregaAsignacionDetalle = null;
      this.buscar();
    },
  },
  mounted() {
    this.$store
      .dispatch("Campanias/find", {
        query: {},
      })
      .then((result) => {
        this.campanias = result;
        this.campaniaSelected = this.campanias[0].campania;
      });
    /******************* */
    this.tipoPedidoPago =
      "Pedidos con movimientos del " +
      moment(this.dateRange.startDate).format("DD-MM-YYYY") +
      " al " +
      moment(this.dateRange.endDate).format("DD-MM-YYYY");
    document.querySelector(
      "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
    ).style.display = "none";
  },
};
</script>
<style>
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.table-responsive {
  overflow: hidden !important;
}
.white-content .table > thead > tr > th {
  font-size: 8px !important;
}
#btn-buscar {
  margin-top: 25px;
}
.form-group.position-relative input {
  padding-right: 32px;
}

.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
.overlay-loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  filter: blur(5px);
}

.clip-loader {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 60%;
  top: 40%;
}
.custom-select {
  background-color: #ffffff;
  color: #000000;
}
.btn,
.navbar .navbar-nav > a.btn {
  margin: 0px;
}
.btn-exportar-azul {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #031c68 1px solid;
  border-radius: 15px;
}
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
.btn-exportar-azul:hover,
.btn-exportar-verde:hover {
  box-shadow: 2px 4px #888888;
}
.abc {
  background-color: #ddf8ec;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
}
</style>
