<template>
  <div
    class="modal fade"
    id="modalResumenRutas"
    ref="modalResumenRutas"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 815px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 815px">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Resumen rutas
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <h4>Filtros de búsqueda</h4>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="sector">Sector</label>
                <input
                  type="text"
                  class="form-control"
                  ref="formResRutasFiltroSector"
                  id="formResRutasFiltroSector"
                  name="formResRutasFiltroSector"
                  v-on:keyup.enter="buscar"
                />
                <span
                  class="form-clear"
                  v-bind:class="
                    fillInput.formResRutasFiltroSector ? '' : 'd-none'
                  "
                  @click="limpiarFiltro('formResRutasFiltroSector')"
                >
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group position-relative">
                <label for="ruta">Ruta</label>
                <input
                  type="text"
                  class="form-control"
                  ref="formResRutasFiltroRuta"
                  id="formResRutasFiltroRuta"
                  name="formResRutasFiltroRuta"
                  v-on:keyup.enter="buscar"
                />
                <span
                  class="form-clear"
                  v-bind:class="
                    fillInput.formResRutasFiltroRuta ? '' : 'd-none'
                  "
                  @click="limpiarFiltro('formResRutasFiltroRuta')"
                >
                  <i class="fa fa-times"></i>
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <button class="btn btn-info" @click="buscar" id="btn-buscar">
                Buscar
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <v-client-table
                :data="rows"
                :columns="columns"
                :options="options"
              >
                <div slot-scope="props" slot="descargar">
                  <button
                    class="btn btn-sm"
                    @click="fnGenerarExcel(props.row.ROUTE_ID)"
                  >
                    Excel
                  </button>
                </div>
                <div
                  slot-scope="props"
                  slot="generar_manifiesto"
                  v-if="props.row.pendientes_de_cargar > 0"
                >
                  <button
                    class="btn btn-sm"
                    @click="
                      fnGenerarManifiesto(props.row.ROUTE_ID, props.row.index)
                    "
                  >
                    Generar manifiesto
                  </button>
                </div>
              </v-client-table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cerrar ventana
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ResumenRutas",
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL + "/",
      rows: [],
      columns: [
        "SECTOR",
        "ROUTE_ID",
        "total_pedidos",
        "pendientes_de_cargar",
        "total_cargados",
        "total_entregados",
        "descargar",
        "generar_manifiesto"
      ],
      fillInput: {
        formResRutasFiltroSector: false,
        formResRutasFiltroRuta: false
      },
      options: {
        orderBy: {
          column: "ROUTE_ID",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Ingrese filtros de búsqueda...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [],
        headings: {
          SECTOR: "Sector",
          ROUTE_ID: "Ruta",
          total_pedidos: "Total pedidos",
          pendientes_de_cargar: "Pedidos en picking",
          total_cargados: "Pedidos cargados",
          total_entregados: "Pedidos entregados",
          descargar: "Listado de pedidos sin cargar",
          generar_manifiesto: "Generar manifiesto"
        }
      }
    };
  },
  methods: {
    buscar() {
      this.rows = [];

      const sector = this.$refs.formResRutasFiltroSector;
      const ruta = this.$refs.formResRutasFiltroRuta;

      if (sector.value.length == 0 && ruta.value.length == 0) {
        this.rows = [];
        return;
      }

      this.mostrarIconoLimpiarFiltro(sector);
      this.mostrarIconoLimpiarFiltro(ruta);

      let data = {
        SECTOR: sector.value.trim(),
        ROUTE_ID: ruta.value.trim()
      };

      axios
        .get(`${this.apiUrl}servicio-rampas-asignacion-manifiestos/void`, {
          method: "GET",
          params: data,
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt")
          }
        })
        .then(response => {
          if (response && response.data && response.data.resumenRutas) {
            this.rows = response.data.resumenRutas;
          }
        });
    },
    fnGenerarExcel(routeId) {
      let fileName = "Ruta_" + routeId + ".xlsx";
      let url = `${this.apiUrl}excel-manifiestos/${routeId}`;
      axios
        .get(url, {
          params: {
            type: 1
          },
          responseType: "blob",
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt")
          }
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    fnGenerarManifiesto(routeId, index) {
      let confirmacion = window.confirm(
        `¿Desea crear los manifiestos para la ruta ${routeId}?`
      );
      if (!confirmacion) {
        return;
      }

      this.$store
        .dispatch("Serviciorampasasignacionmanifiestos/create", {
          ROUTE_ID: routeId
        })
        .then(response => {
          let res = response.rutasSector;
          let message = `Se generó el manifiesto ${res.MANIFEST_DB_ID} para la ruta ${res.ROUTE_ID}`;
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message
          });
        })
        .catch(error => {
          this.$notify({
            type: "error",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: error
          });
        })
        .finally(() => {});
      this.rows.forEach(row => {
        row.ROUTE_ID == routeId ? (row.pendientes_de_cargar = 0) : "";
      });
    },
    mostrarIconoLimpiarFiltro(inputFiltro) {
      this.fillInput[inputFiltro.id] = inputFiltro.value.length > 0;
    },
    limpiarFiltro(id) {
      this.fillInput[id] = false;
      this.$refs[id].value = "";
      this.$refs[id].focus();
      this.buscar();
    }
  },
  watch: {}
};
</script>
<style></style>
