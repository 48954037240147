<template>
  <div id="app">
    <div class="row">
      <div class="col-md-12">
        <download-excel
          class="pull-right btn-exportar-verde"
          style="cursor: pointer; "
          :fields="columnasEXCL"
          :fetch="exportTableData"
          :type="dataExportType"
          :name="'Reporte resumen por piloto.' + dataExportType"
          >Exportar xls</download-excel
        >
      </div>
    </div>
    <v-client-table
      ref="TablaPedidosPiloto"
      :data="rows"
      :columns="columns"
      :options="options"
    >
      <!-- ---- -->
      <span
        style="cursor:pointer;"
        data-toggle="modal"
        data-target="#modaltotalPedidosPorPiloto"
        slot="total_pedidos"
        slot-scope="props"
        @click="fnCargarPlaca(props.row.placaID)"
      >
        {{ props.row.total_pedidos }}
      </span>
      <!-- ---- -->
      <div class="progress" slot-scope="props" slot="placaProgreso">
        <div
          class="progress-bar"
          role="progressbar"
          :style="'width:' + props.row.porcentaje + '%'"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <small
          class="justify-content-center d-flex position-absolute progress-value"
          >{{ props.row.porcentaje }}%</small
        >
      </div>
    </v-client-table>
    <totalPedidosPorPiloto :placa="placa"></totalPedidosPorPiloto>
  </div>
</template>

<script>
import totalPedidosPorPiloto from "@/pages/DashboardEntregas/modals/modaltotalPedidosPorPiloto.vue";
import JsonExcel from "vue-json-excel";
export default {
  components: {
    downloadExcel: JsonExcel,
    totalPedidosPorPiloto
  },
  props: ["cargarPedido", "realizarEntrega", "realizarDevolucion"],
  watch: {
    cargarPedido: function(newVal, oldVal) {
      //this.segmentar(newVal);
    },
    realizarEntrega: function(newVal, oldVal) {
      //this.segmentar(newVal);
      for (var i in this.rows) {
        if (this.rows[i].placaID == newVal.placa) {
          this.rows[i].pedidos_entregados++;
          this.rows[i].pedidos_pendientes--;
          this.rows[i].porcentaje = (
            (this.rows[i].pedidos_entregados / this.rows[i].total_pedidos) *
            100
          ).toFixed(2);
          this.rows[i].ultima_entrega = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
    },
    realizarDevolucion: function(newVal, oldVal) {
      //this.segmentar(newVal);
      for (var i in this.rows) {
        if (this.rows[i].placaID == newVal.placa) {
          this.rows[i].pedidos_entregados++;
          this.rows[i].pedidos_pendientes--;
          this.rows[i].porcentaje = (
            (this.rows[i].pedidos_entregados / this.rows[i].total_pedidos) *
            100
          ).toFixed(2);
          this.rows[i].ultima_entrega = moment(newVal.DELIVERY_DATE);
          break; //Stop this loop, we found it!
        }
      }
    }
  },
  data() {
    return {
      placa: "C247BTB",
      //
      columnasEXCL: {
        Placas: "placas",
        Pedidos: "total_pedidos",
        Entregados: "pedidos_entregados",
        Pendientes: "pedidos_pendientes",
        "Ultima carga": "ultima_carga_REPORTE",
        "Ultima Entrega": "ultima_entrega_REPORTE",
        "Progreso (%)": "porcentaje"
      },
      //
      dataExportType: "xls",
      //
      codigoDevolucion: "",
      ventanaInformacionDeEntregaDevolucion: null,
      ventanaInformacionDeEntrega: null,
      // seccion de manifiestos anexos
      entregaERPDOCDevolucion: null,
      entregaERPDOCDetalle: null,
      //
      anexosDeManifiesto: [],
      //fin
      columns: [
        "placas",
        "total_pedidos",
        "pedidos_entregados",
        "pedidos_pendientes",
        "ultima_carga",
        "ultima_entrega",
        "placaProgreso"
      ],
      rows: [],
      options: {
        orderBy: {
          column: "pedidos_entregados",
          ascending: false
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No hay pedidos en ruta...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["ultima_entrega", "ultima_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["ultima_entrega", "ultima_carga", "placas"],
        headings: {
          placas: "PILOTO",
          total_pedidos: "total de pedidos",
          pedidos_entregados: "pedidos entregados",
          pedidos_pendientes: "pedidos pendientes",
          ultima_entrega: "ultima entrega",
          ultima_carga: "ultima carga",
          placaProgreso: "PORCENTAJE"
        }
      }
    };
  },
  methods: {
    fnCargarPlaca(placa) {
      this.placa = placa;
    },
    exportTableData() {
      return this.$refs.TablaPedidosPiloto.allFilteredData; // Change "TablaPedidosPiloto" to your ref
    }
    //
  },
  mounted() {
    this.$store
      .dispatch("Entregaspiloto/find", {
        query: {}
      })
      .then(response => {
        response.forEach(PILOTO => {
          // *************************
          // fecha de ultima entrega
          // *************************
          //ultima_entrega
          if (PILOTO.ultima_entrega !== null) {
            PILOTO.ultima_entrega_REPORTE = PILOTO.ultima_entrega;
            //
            PILOTO.ultima_entrega = moment(
              PILOTO.ultima_entrega,
              "DD-MM-YYYY HH:mm"
            );
          } else {
            PILOTO.ultima_entrega_REPORTE = "N/A";
            PILOTO.ultima_entrega = "N/A";
          }

          // *************************
          // fecha de ultima carga
          // *************************

          //ultima_carga
          if (PILOTO.ultima_carga !== null) {
            PILOTO.ultima_carga_REPORTE = PILOTO.ultima_carga;
            //
            PILOTO.ultima_carga = moment(
              PILOTO.ultima_carga,
              "DD-MM-YYYY HH:mm"
            );
          } else {
            PILOTO.ultima_carga_REPORTE = "N/A";
            PILOTO.ultima_carga = "N/A";
          }

          PILOTO.porcentaje = (
            (PILOTO.pedidos_entregados / PILOTO.total_pedidos) *
            100
          ).toFixed(2);

          //identificar la id del piloto (id unica)
          PILOTO.placaID = PILOTO.placas;

          if (PILOTO.placas !== null) {
            PILOTO.placas = PILOTO.piloto + " (" + PILOTO.placas + ")";
            this.rows.push(PILOTO);
          }
        });
        //
        document.querySelector(
          "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
        ).style.display = "none";
      });
  }
};
</script>
<style scoped>
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.table-responsive {
  overflow: hidden !important;
}
.progress {
  height: 13px !important;
}
.progress-value {
  width: 110px;
  color: white;
  font-weight: bold;
}
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
</style>
