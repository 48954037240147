<template>
  <div>
    <div
      v-if="loading"
      class="d-flex justify-content-center"
      style="padding-top:25px;"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div
        class="spinner-grow"
        style="width: 3rem; height: 3rem;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      Cargando...
    </div>
    <div v-if="!loading" class="container h-100">
      <div class="row h-100">
        <div class="col-md-6 offset-md-3 h-100">
          <div class="d-flex justify-content-center h-100">
            <div class="card card-login">
              <div class="card-header text-center">
                <h3>Inicio de sesión</h3>
                <div class="d-flex justify-content-end social_icon"></div>
              </div>
              <div class="card-body">
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    ref="usuario_codigo"
                    type="text"
                    v-model="usuario_codigo"
                    class="form-control"
                    placeholder="Código de empleado"
                    v-on:keyup.enter="login()"
                  />
                </div>
                <div class="input-group form-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    ref="password"
                    type="password"
                    v-model="password"
                    class="form-control"
                    placeholder="contraseña"
                    v-on:keyup.enter="login()"
                  />
                </div>

                <div class="form-group text-center">
                  <input
                    type="submit"
                    @click="login()"
                    value="Iniciar sesión"
                    class="btn login_btn"
                  />
                </div>
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "loginPage",
  data() {
    return {
      usuario_codigo: "",
      password: ""
    };
  },
  computed: {
    ...mapState("auth", { loading: "isAuthenticatePending" })
  },
  methods: {
    ...mapMutations("auth", {
      unsetAuthenticatePending: "unsetAuthenticatePending"
    }),
    notificacion(type, message) {
      this.$notify({
        type: type,
        verticalAlign: "top",
        horizontalAlign: "right",
        message: message
      });
    },
    //
    login() {
      //
      if (this.$refs.usuario_codigo.value == "") {
        this.$refs.usuario_codigo.style.border = "thin solid #FF4C25";
      } else if (this.$refs.password.value == "") {
        this.$refs.password.style.border = "thin solid #FF4C25";
      } else {
        //autenticarse
        this.$store
          .dispatch("auth/authenticate", {
            strategy: "local",
            usuario_codigo: this.usuario_codigo,
            password: this.password
            // usuario_codigo: "070501123",
          })
          .then(result => {
            this.notificacion("success", "Bienvenido");
            this.unsetAuthenticatePending();
            this.$router.push("/dashboard");
          })
          .catch(e => {
            console.error(e);
            this.notificacion(
              "danger",
              "Código de empleado y contraseña, no coinciden. Por favor intente de nuevo."
            );
          });
      }
    }
  },
  mounted() {
    //console.log("loginPage");
  }
};
</script>
<style scoped>
.card.card-login {
  transform: translateY(35%);
}
</style>
