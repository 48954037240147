<template>
  <li class="dropdown">
    <a
      href="#"
      class="nav-link dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <div class="photo">
        <img src="/img/default-avatar.png" />
      </div>
      <b class="caret d-none d-lg-block d-xl-block"></b>
      <p class="d-lg-none">Cerrar sesión</p>
    </a>
    <ul class="dropdown-menu dropdown-menu-right">
      <li class="nav-link">
        <a href="#" class="nav-item dropdown-item" @click="cerrarSesion()"
          >Cerrar sesión</a
        >
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  name: "user-options",
  props: {
    menuOnRight: {
      type: Boolean,
      description: "Whether menu should appear on the right",
    },
  },
  methods: {
    async cerrarSesion() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.caret.d-none.d-lg-block.d-xl-block {
  color: #ffffff;
}
.d-lg-none {
  color: #ffffff;
}
</style>
