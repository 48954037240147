import { render, staticRenderFns } from "./dispositivos_detalle.vue?vue&type=template&id=130bff30&scoped=true&"
import script from "./dispositivos_detalle.vue?vue&type=script&lang=js&"
export * from "./dispositivos_detalle.vue?vue&type=script&lang=js&"
import style0 from "./dispositivos_detalle.vue?vue&type=style&index=0&id=130bff30&prod&lang=css&"
import style1 from "./dispositivos_detalle.vue?vue&type=style&index=1&id=130bff30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "130bff30",
  null
  
)

export default component.exports