<template>
  <div class="row">
    <!-- *******************************************RESUMEN DE SECTOR******************************************* -->
    <div class="col-sm-12">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <h5 class="">Resumen por sector</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 ml-1">
              <div class>
                <label for="campania">Seleccionar campaña</label>
                <select
                  class="form-control"
                  @change="fnSelecionarCampania"
                  name="campania"
                >
                  <option
                    v-for="(item, index) in campanias"
                    :key="index"
                    :value="item.campania"
                  >
                    Campaña {{ item.campania }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <download-excel
                class="pull-right btn-exportar-verde mr-3"
                style="cursor: pointer"
                :fields="columnasEXCLsector"
                :fetch="exportTableData"
                :type="dataExportType"
                :name="'Reporte resumen por sector.' + dataExportType"
                >Exportar xls</download-excel
              >
            </div>
            <div class="col-md-12">
              <v-client-table
                ref="tablaSector"
                :data="rows"
                :columns="columns"
                :options="options"
                name="manifiestosTable"
              >
                <button
                  slot-scope="props"
                  slot="verSector"
                  class="btn btn-sm btn-info"
                  @click="verSector(props.row.sector)"
                >
                  consultar
                </button>
              </v-client-table>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
    <!-- *******************************************GRAFICA DE SECTOR******************************************* -->
    <div class="col-sm-12">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <h4 class="" ref="tituloRuta">
            Detalle de rutas del sector:
            <strong>{{ currentSector }}</strong>
          </h4>
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-12">
              <!-- boton de la tabla detalle -->
              <download-excel
                class="pull-right btn-exportar-verde mr-3"
                style="cursor: pointer"
                :fields="columnasEXCLsectorDetalle"
                :fetch="exportTableDataDetalle"
                :type="dataExportType"
                :name="
                  'Reporte detallado de rutas por sector.' + dataExportType
                "
                >Exportar xls</download-excel
              >
              <!-- boton de la tabla detalle -->
            </div>
            <div class="col-md-12">
              <v-client-table
                ref="tablaSectorDetalle"
                :data="rows2"
                :columns="columns2"
                :options="options2"
                name="manifiestosTable"
              ></v-client-table>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";
import ReactiveBarChart from "@/components/Charts/PieChart.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dataExportType: "xls",
      //
      columnasEXCLsector: {
        "Transportista(s)": "DELIVERY_COMPANY_NAME",
        Sector: "sector",
        Pago: "pago",
        "No. pedidos": "pedidos",
        "Total bultos": "total_bultos",
        Cajas: "cajas",
        Premios: "total_premios",
        Hogar: "total_home",
        "": "verSector",
      },
      columnasEXCLsectorDetalle: {
        Ruta: "ROUTE_ID",
        Transportista: "empresa",
        "Total de pedidos": "pedidos_global",
        "Pedidos entregados": "pedidos_entregados",
        "Pedidos con reenvio": "pedidos_reenvio",
        "Total de bultos": "total_bultos_global",
        "Bultos entregados": "total_bultos_entregados",
        "Total de cajas": "cajas_global",
        "Cajas entregadas": "cajas_entregadas",
        "Total de premios": "premios_global",
        "Premios entregados": "premios_entregados",
        "Total de hogar": "home_global",
        "Hogar entregados": "home_entregados",
      },
      //
      campanias: [],
      campaniaSelected: "all",
      //
      currentSector: "",
      colores: [],
      detalles: [],
      sectoresCampania: [],
      chartData: null,
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      //
      rows: [],
      rows2: [],
      ////////////////
      columns: [
        "DELIVERY_COMPANY_NAME",
        "sector",
        "pago",
        "pedidos",
        "total_bultos",
        "cajas",
        "total_premios",
        "total_home",
        "verSector",
      ],
      options: {
        bLengthChange: false,
        templates: {},
        orderBy: {
          column: "sector",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 3,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Cargando",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "DELIVERY_COMPANY_NAME",
          "sector",
          "pago",
          "pedidos",
          "total_bultos",
          "cajas",
          "total_premios",
          "total_home",
        ],
        headings: {
          DELIVERY_COMPANY_NAME: "Empresa",
          sector: "Sector",
          pago: "Pago",
          pedidos: "Pedidos",
          total_bultos: "Total bultos",
          cajas: "Cajas",
          total_premios: "Premios",
          total_home: "Hogar",
          verSector: "",
        },
      },
      ////////////////
      columns2: [
        "ROUTE_ID",
        "empresa",
        "pedidos_global",
        "pedidos_entregados",
        "total_bultos_global",
        "total_bultos_entregados",
        "cajas_global",
        "cajas_entregadas",
        "premios_global",
        "premios_entregados",
        "home_global",
        "home_entregados",
      ],
      options2: {
        bLengthChange: false,
        templates: {},
        orderBy: {
          column: "empreas",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Selecciona un sector para cargar el detalle de sus rutas",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "ROUTE_ID",
          "empresa",
          "pedidos_global",
          "pedidos_entregados",
          "pedidos_reenvio",
          "total_bultos_global",
          "total_bultos_entregados",
          "cajas_global",
          "cajas_entregadas",
          "premios_global",
          "home_global",
          "premios_entregados",
          "home_entregados",
        ],
        headings: {
          ROUTE_ID: "Ruta",
          empresa: "Transporte",
          pedidos_global: "Pedidos global",
          pedidos_entregados: "Pedidos entregados",
          pedidos_reenvio: "Pedidos reenviados",
          total_bultos_global: "Bultos global",
          total_bultos_entregados: "Bultos entregados",
          cajas_global: "Cajas global",
          cajas_entregadas: "Cajas entregadas",
          premios_global: "Premios global",
          home_global: "Hogar global",
          premios_entregados: "Premios entregados",
          home_entregados: "Hogar entregados",
        },
      },
    };
  },
  components: { downloadExcel: JsonExcel, DateRangePicker, ReactiveBarChart },

  methods: {
    exportTableData() {
      return this.$refs.tablaSector.allFilteredData; // Change "tablaSector" to your ref
    },
    exportTableDataDetalle() {
      return this.$refs.tablaSectorDetalle.allFilteredData; // Change "tablaSector" to your ref
    },
    fnSelecionarCampania() {
      this.options.texts.noResults = "Cargando...";
      this.rows = [];
      this.rows2 = [];
      this.campaniaSelected = event.target.value;

      //
      this.$store
        .dispatch("Reporteordenesdecompra/find", {
          query: {
            id: "all",
            campania: this.campaniaSelected,
          },
        })
        .then((response) => {
          if (response.length == 0)
            this.options.texts.noResults = "Sin resultados";

          response.forEach((element) => {
            if (element.DELIVERY_COMPANY_NAME != null) this.rows.push(element);
          });
        });
    },
    verSector(idSector) {
      this.rows2 = [];
      this.currentSector = idSector;
      const usuario = this.$store.state.auth.payload.usuarios;

      this.$store
        .dispatch("Reporteordenesdecompra/find", {
          query: { id: idSector, campania: this.campaniaSelected },
        })
        .then((response) => {
          document.querySelector(
            "body > div > div.wrapper > div > div > div > div:nth-child(2) > div > div.card-body > div > div:nth-child(2) > div > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
          ).style.display = "none";

          this.rows2 = response;
        });
    },
  },
  mounted() {
    this.$store
      .dispatch("Campanias/find", {
        query: { limit: 3 },
      })
      .then((result) => {
        this.campanias = result;
      });

    this.$store
      .dispatch("Reporteordenesdecompra/find", {
        query: {
          id: "all",
          campania: "all",
        },
      })
      .then((response) => {
        response.forEach((element) => {
          if (element.DELIVERY_COMPANY_NAME != null) this.rows.push(element);
        });

        document.querySelector(
          "body > div > div.wrapper > div > div > div > div:nth-child(1) > div > div.card-body > div > div:nth-child(3) > div > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
        ).style.display = "none";
      });
  },
};
</script>

<style scoped>
.carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  background-color: red;
}
.custom-select {
  color: black;
}
.alert {
  padding: 0.3rem 1.25rem;
}
.white-content .table > thead > tr > th {
  font-size: 8px !important;
}
.VueTables__heading {
  font-size: 8px !important;
}
.main-panel {
  background-color: red;
}

.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
</style>
