/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
// import router from "./router/starterRouter";
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
//
import { ServerTable, ClientTable, Event } from "vue-tables-2";
import "./registerServiceWorker";
//
import VueCompositionApi from "@vue/composition-api";
import store from "./store";
//

import VueLazyInput from "vue-lazy-input";
Vue.use(VueLazyInput);

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
//
Vue.use(VueCompositionApi);
Vue.use(ServerTable);
Vue.use(ClientTable);
Vue.use(Event);

const moment = require("moment");
require("moment/locale/es");

Vue.use(require("vue-moment"), {
  moment
});
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch, {
  prefetch: false
});
Vue.config.productionTip = false;
//

window.$ = require("jquery");
window.moment = require("moment");
window.daterangepicker = require("daterangepicker");
//

import axios from "axios";
const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});

Vue.prototype.$http = base;
// google maps
import * as VueGoogleMaps from "vue2-google-maps";
import DragItDude from "vue-drag-it-dude";
import vueDragItDudeUmd from "vue-drag-it-dude";

Vue.component("vue-drag-it-dude", DragItDude);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places" // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true
});
// fin

/* eslint-disable no-new */
new Vue({
  app,
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");

console.log("Ok!s");
