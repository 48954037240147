var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"select-campania pull-right"},[_c('label',{attrs:{"for":"campania"}},[_vm._v("Seleccionar campaña")]),_c('select',{staticClass:"form-control",attrs:{"name":"campania"},on:{"change":_vm.manifiestosPorCampania}},_vm._l((_vm.campanias),function(item,index){return _c('option',{key:index,domProps:{"value":item.campania}},[_vm._v(" Campaña "+_vm._s(item.campania)+" ")])}),0)])])]),_c('v-client-table',{attrs:{"data":_vm.rows,"columns":_vm.columns,"options":_vm.options,"name":"manifiestosTable"},scopedSlots:_vm._u([{key:"anexos",fn:function(props){return _c('button',{staticClass:"btn btn-sm",class:props.row.cantidad_anexos == 0 ? 'btn-danger' : 'btn-success',attrs:{"data-toggle":"modal","data-target":"#modalAnexosManifiestos"},on:{"click":function($event){return _vm.fnAsignaranexoManifiestoRouteId(
          props.row.MANIFEST_ID,
          props.row.MANIFEST_DB_ID
        )}}},[_vm._v(" Anexos ")])}},{key:"pdf",fn:function(props){return _c('a',{staticClass:"fa fa-file-pdf",attrs:{"target":"_blank","href":_vm.VUE_APP_API_URL +
          'generar-manifiesto?compuesta=' +
          props.row.MANIFEST_ID +
          '&correlativa=' +
          props.row.MANIFEST_DB_ID,"download":"download"}})}}])},[_c('div',{attrs:{"slot":"filter__anexos"},slot:"filter__anexos"},[_c('select',{staticClass:"form-control",on:{"change":_vm.filtroAnexos}},[_c('option',{attrs:{"value":""}},[_vm._v("Todos")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Con anexos")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Sin anexos")])])])]),_c('RampasManifiestosModalAnexos',{attrs:{"anexoManifiestoRouteId":_vm.anexoManifiestoId,"MANIFEST_DB_ID":_vm.MANIFEST_DB_ID},on:{"anexoAdjuntado":function($event){return _vm.anexoAdjuntado($event)},"anexoEliminado":function($event){return _vm.anexoEliminado($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }