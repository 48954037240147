<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <!-- <h4 class="card-title">Número de teléfonos:</h4> -->
            <p class="card-text">
              <span class="mdi mdi-format-list-text"></span>
              Aplicaciones instaladas: {{ aplicaciones_total }}
            </p>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for=""></label>
              <input
                v-model="aplicaciones_search"
                type="text"
                class="form-control"
                aria-describedby="helpId"
              />
              <small id="helpId" class="form-text text-muted"
                >Búscar una aplicación</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card-text">
        <div class="row">
          <div class="col">
            <div class="list-group">
              <div
                v-for="(app, index) in aplicaciones"
                :key="index"
                class="list-group-item list-group-item-action"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5
                    :title="`id: ${app.marca},${app.modelo},${app.paquete}`"
                    class="mb-1"
                  >
                    App: {{ app.nombre }}
                  </h5>
                  <small
                    :class="[
                      app.flag_instalada ? 'text-success' : 'text-danger',
                      'font-italic'
                    ]"
                    :title="
                      fnComputedAppDateTitle(
                        app.flag_instalada,
                        getDate(app.flag_ultima_fecha_instalada)
                      )
                    "
                  >
                    {{ getDate(app.flag_ultima_fecha_instalada) }}</small
                  >
                </div>
                <p class="mb-1">{{ app.paquete }}</p>
                <small
                  class="text-muted"
                  :title="
                    `Placa: ${app.piloto_placa}, Emprea: ${app.piloto_empresa}`
                  "
                  >Piloto: {{ app.piloto_nombre }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li :class="[page <= 1 ? 'disabled' : '', 'page-item']">
                  <a :class="'page-link'" @click="page--"
                    ><span class="mdi mdi-chevron-left"></span
                  ></a>
                </li>
                <li
                  class="page-item"
                  v-for="(item, index) in current_page_range"
                  :key="index"
                >
                  <a @click="page = fnGetPage(item)" class="page-link">{{
                    fnGetPage(item)
                  }}</a>
                </li>

                <li
                  :class="[page >= totalPages ? 'disabled' : '', 'page-item']"
                >
                  <a class="page-link" @click="page++"
                    ><span class="mdi mdi-chevron-right"></span
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      //
      current_group: 1,
      current_page: 1,
      //
      aplicaciones_or_param: "",
      aplicaciones_search: "",
      aplicaciones: [],
      aplicaciones_total: 0,
      //
      page: 1,
      totalPages: 0,
      pageSize: 4,
      pageSizes: [5, 15, 50]
    };
  },
  methods: {
    fnGetPage(item) {
      let pagefixer = (this.current_group - 1) * 10;
      return item + pagefixer;
    },
    fnComputedAppDateTitle(flag, date) {
      if (flag == true) {
        return `Instalada, ultima vez instalada en el celular el día: ${date}`;
      } else {
        return `Desinstalada, ultima vez instalada en el celular el día: ${date}`;
      }
    },
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY") : "";
    },
    getParams() {
      const params = {};

      params["$limit"] = this.pageSize;
      params["$skip"] = (this.page - 1) * this.pageSize;
      // let limit = `"$limit":${this.perPage}`;
      // let skip = `"$skip":${(data.page - 1) * this.perPage}`;

      /** */

      params["piloto_usuario_id"] = this.$route.params.id_usuario;

      /** */
      return params;
    },
    async fnGetAplicaciones() {
      let url =
        `${process.env.VUE_APP_API_URL}/pilotos-aplicaciones-status` +
        this.aplicaciones_or_param;
      await axios
        .get(url, {
          params: this.getParams(),
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt")
          }
        })
        .then(result => {
          this.aplicaciones = result.data.data;
          this.aplicaciones_total = result.data.total;
          this.totalPages = Math.ceil(
            parseInt(result.data.total) / parseInt(this.pageSize)
          );
        });
    }
  },
  watch: {
    page(newValue) {
      console.log("we are at page " + newValue);
      if (newValue > 0 && newValue <= this.aplicaciones_total) {
        if (newValue > this.current_group * 10) {
          this.current_group++;
          console.log(
            "cambiar al siguiente grupo de paginas " + this.current_group
          );
        }
        if (newValue <= (this.current_group - 1) * 10) {
          this.current_group--;
          console.log(
            "cambiair a un grupo de paginas ANTERIoR " + this.current_group
          );
        }
        this.fnGetAplicaciones();
      }
    },
    aplicaciones_search(newValue) {
      if (newValue != "") {
        this.aplicaciones_or_param = `?$or[0][nombre][$like]=%${newValue}%&$or[1][paquete]=%${newValue}%`;
        this.page = 1;
      } else {
        this.aplicaciones_or_param = "";
      }
      this.fnGetAplicaciones();
    }
  },
  mounted() {
    this.fnGetAplicaciones();
  },
  computed: {
    current_page_range() {
      if (this.totalPages > this.current_group * 10) {
        return 10;
      } else {
        let last_pages = 10 - (this.current_group * 10 - this.totalPages);
        console.log("last pages: " + last_pages);
        return last_pages;
      }
    }
  }
};
</script>
