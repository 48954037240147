<template>
  <div
    class="modal fade"
    id="modalEditarUsuario"
    ref="modalEditarUsuario"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 1015px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 1015px">
        <div class="modal-header">
          <h5 class="modal-title">
            Enviar pedidos en ruta a pedidos entregados sin APP
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div
                class="m-2 p-2"
                style="border:1px solid black; border-radius:15px"
              >
                <p>
                  Al aceptar, todos los pedidos en ruta serán tomados en cuenta
                  como pedidos entregados sin la Aplicación móvil.
                </p>
                <ul>
                  <li>
                    <strong
                      >Los pedidos cargados aún se podran entregar sin
                      problema</strong
                    >
                  </li>
                  <li>
                    <strong
                      >Los pedidos se podrán a volver a cargar con la app
                      móvil</strong
                    >
                  </li>
                  <li>
                    Esta acción permite que los pedidos no entregados con la
                    aplicación puedan limpiarse en los dispositivos moviles
                  </li>
                </ul>
              </div>

              <div
                class="m-2 p-2"
                style="border:1px solid black; border-radius:15px"
              >
                <div class="form-group">
                  <label for="">Ingrese código de campaña:</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="CAMPAING_ID"
                    id="CAMPAING_ID"
                    v-model="formCAMPAING_ID"
                    aria-describedby="helpIdCAMPAING_ID"
                    placeholder="Ingresa código de campaña"
                    autocomplete="off"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                  />
                  <small id="helpIdCAMPAING_ID" class="form-text text-muted"
                    >El código consta de 4 dígitos, en representación del año y
                    el mes de la campaña. <br />
                    Ejemplo: "2101"</small
                  >
                </div>
                <div class="form-group">
                  <label for=""
                    >Ingrese su contraseña para validar esta acción:</label
                  >
                  <input
                    required
                    type="password"
                    class="form-control"
                    aria-describedby="helpIdPassword"
                    placeholder="Ingresa tu contraseña"
                    v-model="password"
                    autocomplete="off"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                  />
                  <small
                    ref="helpIdPassword"
                    class="form-text text-muted"
                  ></small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
            @click="fhCheckUsuarioPassword()"
          >
            Guardar cambios
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "modalCheckpasswordAndNoentregadosConApp",
  props: [],
  data() {
    return {
      password: "",
      formCAMPAING_ID: ""
    };
  },
  methods: {
    ...mapActions("Usuarios", {
      patchUsuario: "patch",
      getUsuario: "get"
    }),
    ...mapActions("Reportepedidosentregadossinapp", {
      fnCreateReportepedidosentregadossinapp: "create"
    }),
    fhCheckUsuarioPassword() {
      //autenticarse
      this.$store
        .dispatch("auth/authenticate", {
          strategy: "local",
          usuario_codigo: this.$store.state.auth.payload.usuarios
            .usuario_codigo,

          password: this.password
        })
        .then(result => {
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message:
              "Se ha generado la información de pedidos entregados sin APP"
          });
          this.fnCrearReportePedidosSinApp();
        })
        .catch(e => {
          console.error(e);
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
            message:
              "Tu contraseña no concuerda con la BD, por favor intentalo de nuevo"
          });
        });
    },
    fnCrearReportePedidosSinApp() {
      this.fnCreateReportepedidosentregadossinapp({
        CAMPAING_ID: this.formCAMPAING_ID
      }).then(result => {
        this.formCAMPAING_ID = "";
        this.password = "";

        console.log("livving in an arrow");
      });
    }
  },
  mounted() {}
};
</script>
<style>
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
#modalEditarUsuario
  > div
  > div
  > div.modal-body
  > div
  > div
  > form
  > div
  > select {
  color: black;
}
option {
  color: black !important;
}
</style>
