<template>
  <keep-alive>
    <RampasDetalle :varPedidoRampasGlobal="varPedidoRampasGlobal" v-if="view===1"></RampasDetalle>
    <RampasLista :varPedidoRampasGlobal="varPedidoRampasGlobal" v-if="view===2"></RampasLista>
    <RampasGraficas :varPedidoRampasGlobal="varPedidoRampasGlobal" v-if="view===3"></RampasGraficas>
  </keep-alive>
</template>

<script>
import RampasDetalle from "./RampasDetalle.vue";
import RampasLista from "./RampasLista.vue";
import RampasGraficas from "./RampasGraficas.vue";
export default {
  components: { RampasDetalle, RampasLista, RampasGraficas },
  data() {
    return {
      view: 1,
      varPedidoRampasGlobal: "dsgasdg"
    };
  },
  methods: {
    //cambiar de vista, vista graficas general
    changeView() {
      if (this.view === 3) {
        this.view = 1;
      } else {
        this.view++;
      }
    }
  },
  mounted: async function() {
    const { Cargarpedido } = this.$FeathersVuex.api;
    Cargarpedido.on("patched", pedido => (this.varPedidoRampasGlobal = pedido));
    var self = this;
    setInterval(function() {
      self.changeView();
    }, 10000);
  }
};
</script>
