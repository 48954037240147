<template>
  <div
    class="modal fade"
    id="modalInfoPagoConsejeraNoCoincide"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalInfoPagoConsejeraNoCoincideLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      :style="[{ 'max-width': '880px !important' }]"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="modalInfoPagoConsejeraNoCoincideLabel"
            :title="`Id del pago: ${pago.id} fecha de creación ${getDate(
              pago.createdAt
            )}, piloto: ${pago.DELIVERY_DRIVER_NAME}`"
          >
            Documentos de pago
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- /** MUESTRA RESUMEN DE PAGOS EN DOCUMENTOS */ -->
          <div
            class="card"
            v-if="pago && pago.PAGO_METODO_DETALLE != 'Pago en efectivo'"
          >
            <div class="card-body shadow">
              <div class="card-text">
                <!-- INICIO DE carousel-->
                <carousel
                  :per-page="1"
                  ref="carousel2"
                  :mouse-drag="false"
                  paginationActiveColor="#000000"
                  paginationColor="#000000"
                >
                  <slide v-for="(item, index) in pago.documentos" :key="index">
                    <!-- INFORMACION DE LOS DISTINTOS DOCUMENTOS DE PAGO INGRESADOS  -->
                    <div class="row">
                      <!-- datos generales del pago -->
                      <!-- prettier-ignore -->
                      <div class="col">
                          <p>Datos del pedido y la consejera</p>
                          
                          <P><small>Consejera: </small><strong>{{ pago.DELIVERY_CLIENT_NAME }}</strong></P>
                          <P><small>Cod. Consejera: </small><strong>{{ pago.DELIVERY_CLIENT_CODE }}</strong></P>
                          <p><small>Pedido: </small><strong>{{ pago.ERP_DOC }}</strong></p>
                          <hr>
                          <P><small>Monto del pedido: </small><strong>Q.{{ pago.PAGO_DUE_TO_PAY.toFixed(2) }}</strong></P>
                          <P><small>Saldo Consejera: </small><strong>Q.{{ pago.PAGO_DELIVERY_CLIENT_SALDO.toFixed(2) }}</strong></P>
                          <p><small>Monto total a pagar: </small><strong>Q.{{pago.PAGO_MONTO_A_PAGAR.toFixed(2)}}</strong></p>
                          <hr>
                          <p><small>Monto total pagado: </small> <strong>Q.{{pago.PAGO_MONTO_TOTAL.toFixed(2)}}</strong></p>
                        </div>
                      <!-- fin --- datos generales del pago -->
                      <!-- INFORMACION DE PAGO -->
                      <div class="col">
                        <p>Información de la boleta</p>
                        <!-- INFORMACION TEXTO DE LA BOLETA -->
                        <div class="row">
                          <!-- DETALLE DEL PAGO -->
                          <div class="col">
                            <p title="Método de pago">
                              <!-- <small>Método de pago:</small> <br /> -->
                              <strong style="color: black; font-weight: 700">{{
                                pago.PAGO_METODO_DETALLE
                              }}</strong>
                            </p>
                            <p title="Banco" v-if="item.PAGO_BOLETA_BANCO">
                              <small>Banco:</small> <br />
                              <strong>{{ item.PAGO_BOLETA_BANCO }}</strong>
                            </p>
                            <p
                              title="Número de cuenta"
                              v-if="item.PAGO_BOLETA_CUENTA"
                            >
                              <small>Número de cuenta:</small> <br />
                              <strong>{{ item.PAGO_BOLETA_CUENTA }}</strong>
                            </p>
                          </div>
                          <div class="col">
                            <p>
                              <small>Número de autorización:</small> <br />
                              <strong>{{
                                item.PAGO_BOLETA_NUMERO_AUTORIZACION
                              }}</strong>
                            </p>
                            <p>
                              <small>Monto:</small><br />
                              <strong>Q{{ item.PAGO_BOLETA_MONTO }}</strong>
                            </p>
                          </div>
                          <!-- DETALLE DEL PAGO -->
                        </div>
                        <!-- INFORMACION TEXTO DE LA BOLETA -->
                        <hr />
                        <!-- informacion de pago con saldo pendiente a pagar -->
                        <div
                          class="row"
                          v-if="pago.PAGO_MONTO_DEUDOR_FLAG == 1"
                        >
                          <!-- Monto deudor autorizado -->
                          <div class="col">
                            <p>
                              Monto deudor:
                              <strong
                                >Q.{{
                                  pago.PAGO_MONTO_DEUDOR.toFixed(2)
                                }}</strong
                              >
                              <span
                                style="color: green !important"
                                class="mdi mdi-arrow-up-bold"
                              ></span>
                            </p>
                            <p>
                              Autorizado por:
                              <strong>{{
                                pago.PAGO_MONTO_DEUDOR_AUTORIZADO_POR
                              }}</strong>
                            </p>
                          </div>
                          <!-- fin --- Monto deudor autorizado -->
                        </div>
                        <!-- informacion de pago con saldo pendiente a pagar -->
                        <hr />
                        <!-- CODIGO DE CONSEJERA NO COINCIDE -->

                        <div
                          class="row"
                          v-if="item.PAGO_BOLETA_FLAG_MISMA_CONSEJERA == 1"
                        >
                          <div class="col">
                            <p>El codigo de la consejera coincide en boleta.</p>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-if="item.PAGO_BOLETA_FLAG_MISMA_CONSEJERA == 0"
                        >
                          <div class="col">
                            <p>
                              El codigo de la consejera
                              <strong style="font-weight: 700">NO</strong>
                              oincide en boleta.
                            </p>
                          </div>
                        </div>
                        <!-- CODIGO DE CONSEJERA NO COINCIDE -->
                        <!-- informacion de pago con saldo a favor de consejera -->
                        <div
                          class="row"
                          v-if="pago.PAGO_MONTO_SALDO_A_FAVOR_FLAG == 1"
                        >
                          <!-- Monto saldo a favor de consejera -->
                          <div class="col">
                            <p>
                              Monto saldo a favor de consejera;
                              <strong
                                >Q.{{
                                  pago.PAGO_MONTO_SALDO_A_FAVOR.toFixed(2)
                                }}</strong
                              >
                              <span
                                style="color: red !important"
                                class="mdi mdi-arrow-down-bold"
                              ></span>
                            </p>
                          </div>
                          <!-- fin --- Monto saldo a favor de consejera -->
                        </div>
                        <!-- informacion de pago con saldo a favor de consejera -->
                        <!-- información de pago complemento -->
                        <div class="row" v-if="pago.PAGO_COMPLEMENTO_FLAG == 1">
                          <!-- Monto deudor autorizado -->
                          <div class="col">
                            <p>
                              Monto pago complemento en efectivo:
                              <strong style="font-weight: 700"
                                >Q.{{
                                  pago.PAGO_COMPLEMENTO_MONTO.toFixed(2)
                                }}</strong
                              >
                            </p>
                          </div>
                          <!-- fin --- Monto deudor autorizado -->
                        </div>
                        <!-- información de pago complemento -->
                        <!-- IMAGEN DEL PAGO -->
                        <div class="row">
                          <div
                            class="col"
                            style="
                              max-width: 96%;
                              max-height: 420px;
                              overflow: scroll;
                              overflow-x: hidden;
                            "
                          >
                            <zoom-on-hover
                              @resized="onresize"
                              v-if="item.PAGO_BOLETA_IMAGEN"
                              id="fichaUltimaEntregaDELIVERY_IMAGE"
                              :scale="3"
                              :img-normal="
                                VUE_APP_API_URL + item.PAGO_BOLETA_IMAGEN
                              "
                              :img-zoom="
                                VUE_APP_API_URL + item.PAGO_BOLETA_IMAGEN
                              "
                            ></zoom-on-hover>
                          </div>
                        </div>
                        <!-- IMAGEN DEL PAGO -->
                      </div>
                      <!-- FIN --- INFORMACION DE PAGO -->
                    </div>
                    <!-- INFORMACION DE LOS DISTINTOS DOCUMENTOS DE PAGO INGRESADOS  -->
                  </slide>
                </carousel>
                <!-- /** FIN carousel*/ -->
              </div>
            </div>
          </div>
          <!-- /** MUESTRA RESUMEN DE PAGOS EN DOCUMENTOS */ -->
          <!-- /**MOSTRAR INFORMACION POR PAGO EN EFECTIVO */ -->
          <div
            class="card"
            v-if="pago && pago.PAGO_METODO_DETALLE == 'Pago en efectivo'"
          >
            <div class="card-body shadow">
              <div class="card-text">
                <div class="row">
                  <!-- datos generales del pago -->
                  <!-- prettier-ignore -->
                  <div class="col">
                    <p>Datos del pedido y la consejera</p>
                    
                    <P><small>Consejera: </small><strong>{{ pago.DELIVERY_CLIENT_NAME }}</strong></P>
                    <P><small>Cod. Consejera: </small><strong>{{ pago.DELIVERY_CLIENT_CODE }}</strong></P>
                    <p><small>Pedido: </small><strong>{{ pago.ERP_DOC }}</strong></p>
                    <hr>
                    <p title="Método de pago">
                      <!-- <small>Método de pago:</small> <br /> -->
                      <strong
                        style="color: black; font-weight: 700"
                        >{{ pago.PAGO_METODO_DETALLE }}</strong
                      >
                    </p>
                    <P><small>Monto del pedido: </small><strong>Q.{{ pago.PAGO_DUE_TO_PAY.toFixed(2) }}</strong></P>
                    <P><small>Saldo Consejera: </small><strong>Q.{{ pago.PAGO_DELIVERY_CLIENT_SALDO.toFixed(2) }}</strong></P>
                    <p><small>Monto total a pagar: </small><strong>Q.{{pago.PAGO_MONTO_A_PAGAR.toFixed(2)}}</strong></p>
                    <hr>
                    <p><small>Monto total pagado: </small> <strong>Q.{{pago.PAGO_MONTO_TOTAL.toFixed(2)}}</strong></p>
                  </div>
                  <!-- fin --- datos generales del pago -->
                  <!-- informacion de pago con saldo pendiente a pagar -->
                  <!-- PAGO EN EFECTIVO -->
                  <div class="col" v-if="pago.PAGO_MONTO_DEUDOR_FLAG == 1">
                    <p>
                      Monto deudor:
                      <strong>Q.{{ pago.PAGO_MONTO_DEUDOR.toFixed(2) }}</strong>
                      <span
                        style="color: green !important"
                        class="mdi mdi-arrow-up-bold"
                      ></span>
                    </p>
                    <p>
                      Autorizado por:
                      <strong>{{
                        pago.PAGO_MONTO_DEUDOR_AUTORIZADO_POR
                      }}</strong>
                    </p>
                  </div>
                  <!-- fin --- PAGO EN EFECTIVO -->
                  <!-- informacion de pago con saldo pendiente a pagar -->
                  <!-- informacion de pago con saldo a favor de la consejera -->
                  <!-- PAGO EN EFECTIVO -->
                  <div
                    class="col"
                    v-if="pago.PAGO_MONTO_SALDO_A_FAVOR_FLAG == 1"
                  >
                    <p>
                      Monto a favor de consejera:
                      <strong
                        >Q.{{
                          pago.PAGO_MONTO_SALDO_A_FAVOR.toFixed(2)
                        }}</strong
                      >
                      <span
                        style="color: red !important"
                        class="mdi mdi-arrow-down-bold"
                      ></span>
                    </p>
                  </div>
                  <!-- fin --- PAGO EN EFECTIVO -->
                  <!-- informacion de pago con saldo a favor de la consejera -->
                </div>
              </div>
            </div>
          </div>
          <!-- /**MOSTRAR INFORMACION POR PAGO EN EFECTIVO */ -->
        </div>
        <div class="modal-footer">
          <div class="w-100">
            <button
              type="button"
              class="btn-primary btn-sm float-right"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              v-if="pago != null && pago.PAGO_VERIFICACION_STATUS == 0"
              @click="fnValidarpago(pago.id)"
              class="btn-outline-success btn-sm float-right mr-2"
              data-dismiss="modal"
            >
              Verificar pago
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide,
  },
  props: ["pago"],
  computed: {
    computed_pago() {
      if (this.pago == null) {
        return {};
      } else {
        return this.pago;
      }
    },
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    },
  },
  watch: {
    pago(newValue, oldValue) {
      console.log("nueva información del pago: ", newValue);
      let vm = this;
      setTimeout(() => {
        console.log(
          "actualizacion para muestra info en vue carousel",
          newValue
        );
        vm.$forceUpdate();
      }, 350);
    },
  },
  methods: {
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
    imageLoaded() {
      console.log("force load");
      this.$refs.carousel2.$forceUpdate();
    },
    ...mapActions("PagoDePedidos", {
      getPagoDePedido: "get",
      findPagoDePedidos: "find",
      patchPagoDePedidos: "patch",
      crearPagoDePedido: "create",
    }),
    fnValidarpago(id_pago) {
      this.patchPagoDePedidos([
        id_pago,
        {
          PAGO_VERIFICACION_STATUS: 1,
          // prettier-ignore
          PAGO_VERIFICACION_USUARIO: this.$store.state.auth.payload.usuarios.usuario_codigo,
        },
      ]).then((r) => {
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Pago verificado.",
        });
      });
    },
    onresize(e) {
      console.log(e);
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(181, 181, 181);
  border-radius: 10px;
}
</style>
