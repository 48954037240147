<template>
  <DragItDude
    ref="entregaERPDOCDevolucion"
    id="entregaERPDOCDevolucion"
    class="modal-dialog"
    style="width: 780px"
  >
    <div class="modal-content" style="width: 780px">
      <div class="modal-header">
        Vista para anular pedidos
        <br />
        <img
          width="185px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <v-select
              :resetOnOptionsChange="false"
              @search="fetchOptions"
              :clearable="clearable"
              :options="options"
              :value="options.selected"
              label="ERP_DOC"
              @input="SenVal"
            >
              <div slot="no-options">Ingresa un número de pedido válido</div>
            </v-select>
          </div>
          <div v-if="pedidoAnular != null" class="col-md-12">
            <table class="table">
              <tbody>
                <tr>
                  <td>Pedido</td>
                  <td>Ruta</td>
                  <td>Consej@</td>
                  <td>Pikcing</td>
                  <td>Estado</td>
                  <td></td>
                </tr>
                <tr>
                  <td>{{ pedidoAnular.ERP_DOC }}</td>
                  <td>{{ pedidoAnular.ROUTE_ID }}</td>
                  <td>{{ pedidoAnular.DELIVERY_CLIENT_NAME }}</td>
                  <td>
                    {{ pedidoAnular.CREATED | moment("add", "", "DD-MM-YYYY") }}
                  </td>
                  <td>{{ pedidoAnular.STATUSTEXT }}</td>
                  <td>
                    <button
                      @click="fnAnularPedido()"
                      :disabled="
                        pedidoAnular.STATUS >= 2 || pedidoAnular.STATUS == -4
                      "
                      class="btn btn-sm btn-success pull-right"
                    >
                      Anular
                      <i class="fas fa-save"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="padding-top: 10px; padding-right: 2px">
        <button
          class="btn btn-sm btn-succes btn-float-right"
          @click="fnResetWindow()"
        >
          Cerrar
        </button>
      </div>
    </div>
  </DragItDude>
</template>
<script>
import DragItDude from "vue-drag-it-dude";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "modalAnularPedido",
  components: { vSelect, DragItDude },
  props: [],
  data() {
    return {
      clearable: true,
      //
      activeERPDOC: null,
      options: [],
      windowPcVersion: true,
      //
      pedidoAnular: null,
    };
  },
  created() {},
  methods: {
    fnAnularPedido() {
      const confirmar = confirm(
        `¿Está seguro de querer anular el pedido ${this.pedidoAnular.ERP_DOC}?, este proceso no se puede revertir.`
      );

      if (!confirmar) {
        return;
      } else {
        let data = {
          ERP_DOC: this.pedidoAnular.ERP_DOC,
          DELIVERY_CLIENT_REGION: this.pedidoAnular.DELIVERY_CLIENT_REGION,
        };
        let params = {};
        this.$store
          .dispatch("Anularpedido/patch", [
            this.pedidoAnular.ERP_DOC,
            data,
            params,
          ])
          .then((result) => {
            //
            this.pedidoAnular.STATUS = -4;
            this.pedidoAnular.STATUSTEXT = "Anulado";
            //
            this.$notify({
              type: "danger",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "Pedido anulado.",
            });
          });

        // this.$store
        //   .dispatch("Anularpedido/find", {
        //     query: {
        //       ERP_DOC: this.pedidoAnular.ERP_DOC,
        //       DELIVERY_CLIENT_REGION: this.pedidoAnular.DELIVERY_CLIENT_REGION,
        //     },
        //   })
        //   .then((result) => {
        //     //
        //     this.pedidoAnular.STATUS = -4;
        //     this.pedidoAnular.STATUSTEXT = "Anulado";
        //     //
        //     this.$notify({
        //       type: "danger",
        //       verticalAlign: "top",
        //       horizontalAlign: "right",
        //       message: "Pedido anulado.",
        //     });
        //   })
        //   .catch((e) => {
        //     console.log(e);
        //   });
      }
    },
    SenVal(value) {
      //this.$emit("clicked", value);
      this.pedidoAnular = value;
    },
    fetchOptions(value) {
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            $limit: 5,

            ERP_DOC: {
              $like: "%" + value + "%",
            },
            $sort: {
              ERP_DOC: 1,
            },
          },
        })
        .then((result) => {
          this.options = result.data;
        });
    },
    fnResetWindow() {
      this.$emit("clicked");
    },
  },
  computed: {
    isDisabled() {
      return this.condition1 && this.condition2;
    },
  },
};
</script>
<style scoped>
.btn-float-right {
  display: block;
  position: absolute;
  right: 25px;
  bottom: 10px;
}
blockquote {
  font-size: 12px;
}
#detalleDevolucion {
  font-style: italic;
  font-size: 11px;
  padding-left: 5px;
  border-left: 1px solid pink;
}
#buscador {
  z-index: 10;
}
.input-search {
  display: inline-block;
}
#entregaERPDOCDevolucion {
  cursor: move;
}
#entregaERPDOCDevolucion button {
  cursor: pointer;
}
</style>