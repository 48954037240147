<template>
  <footer class="footer">
    <div class="">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" >
            <!-- Gerencia de operaciones - Departamento de logistica -->
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="http://blog.creative-tim.com">
            Blog
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="http://www.creative-tim.com/license">
            Licenses
          </a>
        </li> -->
      </ul>
      <div class="copyright">
        © {{year}} 
        <!-- <i class="tim-icons icon-heart-2"></i> -->
         <!-- by -->
        <a target="_blank" rel="noopener">LêCleire</a>&nbsp;
       <!--  & <a target="_blank" rel="noopener">Creative Tim</a> for a better web. -->
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
