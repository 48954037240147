var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"card-text"},[_c('span',{staticClass:"mdi mdi-format-list-text"}),_vm._v(" Aplicaciones instaladas: "+_vm._s(_vm.aplicaciones_total)+" ")])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.aplicaciones_search),expression:"aplicaciones_search"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"helpId"},domProps:{"value":(_vm.aplicaciones_search)},on:{"input":function($event){if($event.target.composing)return;_vm.aplicaciones_search=$event.target.value}}}),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"helpId"}},[_vm._v("Búscar una aplicación")])])])])]),_c('div',{staticClass:"card-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"list-group"},_vm._l((_vm.aplicaciones),function(app,index){return _c('div',{key:index,staticClass:"list-group-item list-group-item-action"},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1",attrs:{"title":`id: ${app.marca},${app.modelo},${app.paquete}`}},[_vm._v(" App: "+_vm._s(app.nombre)+" ")]),_c('small',{class:[
                    app.flag_instalada ? 'text-success' : 'text-danger',
                    'font-italic'
                  ],attrs:{"title":_vm.fnComputedAppDateTitle(
                      app.flag_instalada,
                      _vm.getDate(app.flag_ultima_fecha_instalada)
                    )}},[_vm._v(" "+_vm._s(_vm.getDate(app.flag_ultima_fecha_instalada)))])]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(app.paquete))]),_c('small',{staticClass:"text-muted",attrs:{"title":`Placa: ${app.piloto_placa}, Emprea: ${app.piloto_empresa}`}},[_vm._v("Piloto: "+_vm._s(app.piloto_nombre))])])}),0)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{class:[_vm.page <= 1 ? 'disabled' : '', 'page-item']},[_c('a',{class:'page-link',on:{"click":function($event){_vm.page--}}},[_c('span',{staticClass:"mdi mdi-chevron-left"})])]),_vm._l((_vm.current_page_range),function(item,index){return _c('li',{key:index,staticClass:"page-item"},[_c('a',{staticClass:"page-link",on:{"click":function($event){_vm.page = _vm.fnGetPage(item)}}},[_vm._v(_vm._s(_vm.fnGetPage(item)))])])}),_c('li',{class:[_vm.page >= _vm.totalPages ? 'disabled' : '', 'page-item']},[_c('a',{staticClass:"page-link",on:{"click":function($event){_vm.page++}}},[_c('span',{staticClass:"mdi mdi-chevron-right"})])])],2)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }