// src/store/services/rampas.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Rampas extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Rampas";
  // Define default properties here
  static instanceDefaults() {
    return {
      id: 0,
      numero_rampa: "",
      status: "",
      createdAt: "",
      updatedAt: ""
    };
  }
}
const servicePath = "rampas";
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: "Rampas",
  Model: Rampas,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
