<template>
  <!-- carta transporte rampa -->
  <RampasDetalleEntrega
    :entregas="pedidosGroupBy"
    :varColumnas="varColumnas"
  ></RampasDetalleEntrega>
  <!-- fin carta transporte rampa -->
</template>
<script>
import RampasDetalleEntrega from "./RampasDetalleEntrega.vue";
export default {
  components: { RampasDetalleEntrega },
  props: ["varPedidoRampasGlobal"],
  watch: {
    varPedidoRampasGlobal: function(newVal, oldVal) {
      // watch it
      this.segmentar(newVal);
      // this.$notify({
      //   type: "danger",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      //   message: "pedidos vistos rampas global."
      // });
    }
  },
  data() {
    return {
      newPage: null,
      pedidosGroupBy: [],
      // 12 - 20
      // varColumnas : 3,
      // entregas: [{ bultos: 85 }, { bultos: 63 }, { bultos: 65 }, { bultos: 65 }, { bultos: 65 }, { bultos: 51 }, { bultos: 51 }, { bultos: 45 }, { bultos: 41 }, { bultos: 31 }, { bultos: 41 }, { bultos: 31 }, { bultos: 45 }, { bultos: 41 }, { bultos: 31 }, { bultos: 21 }, { bultos: 1 }, { bultos: 1 }, { bultos: 1 }, { bultos: 1 }]
      // 10 - 12
      // varColumnas : 4,
      // entregas: [{ bultos: 85 }, { bultos: 63 }, { bultos: 65 }, { bultos: 65 }, { bultos: 65 }, { bultos: 51 }, { bultos: 51 }, { bultos: 45 }, { bultos: 41 }, { bultos: 31 }, { bultos: 41 }, { bultos: 31 }]
      // 7 - 9
      // varColumnas : 4,
      // entregas: [  { bultos: 85 }, { bultos: 35 }, { bultos: 95 }, { bultos: 29 }, { bultos: 61 }, { bultos: 21 }, { bultos: 31 }, { bultos: 41 }, { bultos: 31 }]
      // 4 - 6
      varColumnas: 0,
      //entregas: [  { bultos: 29 }, { bultos: 61 }, { bultos: 21 }, { bultos: 31 }, { bultos: 41 }, { bultos: 31 }]
      entregas: this.pedidosGroupBy
      // 4 - 6
      // varColumnas : 12,
      // entregas: [ { bultos: 99 }, { bultos: 41 }, { bultos: 31 }]
    };
  },
  methods: {
    cargarDetalleDeCargas() {
      this.$store
        .dispatch("Serviciorampasdetalle/find", {
          query: {
            $sort: {
              createdAt: -1
            }
          }
        })
        .then(result => {
          this.pedidosGroupBy = result;
          //console.log(this.pedidosGroupBy)

          if (result.length == 1) {
            this.varColumnas = 12;
          }
          if (result.length == 2) {
            this.varColumnas = 6;
          }
          if (result.length >= 3 && result.length <= 4) {
            this.varColumnas = 6;
          }
          if (result.length >= 5) {
            this.varColumnas = 4;
          }
          if (result.length >= 7) {
            this.varColumnas = 3;
          }
        });
    },
    async crearResumenRuta(pedido) {
      const myService = app.service("pedidos");
      const items = await myService.find({
        query: {
          ROUTE_ID: pedido.ROUTE_ID,
          $or: [{ STATUS: 0 }, { STATUS: 1 }]
        }
      });
      this.pedidosGroupBy.push({
        LOADING_GATE: pedido.LOADING_GATE,
        ROUTE_ID: pedido.ROUTE_ID,
        ROUTE_ID: pedido.ROUTE_ID,
        DELIVERY_ASSIGNED_TO_DRIVER_ID: pedido.DELIVERY_ASSIGNED_TO_DRIVER_ID,
        DELIVERY_COMPANY_NAME: pedido.DELIVERY_COMPANY_NAME,
        DELIVERY_ASSIGNED_TO_DRIVER_NAME:
          pedido.DELIVERY_ASSIGNED_TO_DRIVER_NAME,
        DELIVERY_ASSIGNED_TO_VEHICULE_PLATES:
          pedido.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
        total_bultos_cargados: pedido.TOTAL_PACKAGES,
        total_pedidos: items.total,
        total_premios: pedido.PRIZES,
        total_hogar: pedido.HOME,
        inicio_carga: pedido.createdAt
      });
    },
    segmentar: function(pedido) {
      const found = this.pedidosGroupBy.some(
        el => el.MANIFEST_ID === pedido.MANIFEST_ID
      );

      //re calcular
      if (!found) {
        console.log("not found - rampas detalle");
        this.cargarDetalleDeCargas();
      }

      //sumar a la carga
      if (found) {
        console.log("yes found - rampas detalle");
        let index = this.pedidosGroupBy.findIndex(
          el => el.MANIFEST_ID === pedido.MANIFEST_ID
        );
        this.pedidosGroupBy[index].total_bultos_cargados =
          this.pedidosGroupBy[index].total_bultos_cargados +
          pedido.TOTAL_PACKAGES;
        this.pedidosGroupBy[index].total_premios =
          this.pedidosGroupBy[index].total_premios + pedido.PRIZES;

        this.pedidosGroupBy[index].total_hogar =
          this.pedidosGroupBy[index].total_hogar + pedido.HOME;
        this.pedidosGroupBy[index].total_pedidos_cargados =
          this.pedidosGroupBy[index].total_pedidos_cargados + 1;
      }
    }
  },
  beforeCreate() {
    this.$forceUpdate();
  },
  created() {},
  mounted() {
    if (!this.varPedidoRampasGlobal) {
      const { Cargarpedido } = this.$FeathersVuex.api;
      Cargarpedido.on("patched", pedido => this.segmentar(pedido));
    }

    // llamar registros ya en la base de datos
    this.cargarDetalleDeCargas();
    console.log(this.$router.history.current.path);

    //siguiente vista listas
    // this.newPage = setTimeout(() => {
    //   this.$router.push({ path: "/rampas/listas" });
    // }, 11000);
  },
  beforeDestroy() {
    clearTimeout(this.newPage);
  }
};
</script>
<style>
.rampa-info-entrega {
  margin: 0px 35px 15px 5px;
  position: relative;
  display: block;
  width: 98%;
}
.rampa-progress {
  width: 100% !important;
  height: 1rem !important;
}
@media only screen and (max-width: 600px) {
  .rampa-progress {
    width: 75% !important;
    height: 1rem !important;
  }
}
</style>
