<template>
  <div id="container">
    <div class="row row-title">
      <div class="col-md-6">
        <h4 class="line-h-50">Asignación de ruta a rampa</h4>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-3">
        <button
          class="btn btn-info pull-right"
          id="btn-admin-rampas"
          data-toggle="modal"
          data-target="#modalAdminRutas"
        >
          Administrar rutas
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-server-table
          :columns="columns"
          :options="optionsVTable"
          ref="tablaAsignaciones"
        >
          <input
            type="text"
            class="form-control input-rampa"
            slot="numero_rampa"
            slot-scope="props"
            :id="props.row.route_id"
            :name="props.row.route_id"
            :value="props.row.numero_rampa"
            :data-asignacion="props.row.id"
            :data-original="props.row.numero_rampa"
            :tabindex="props.index"
            @input="fnActualizarRampaRuta"
            v-lazy-input:debounce="500"
            v-on:blur="fnValidarInput"
          />
        </v-server-table>
      </div>
    </div>
    <administrar-rutas></administrar-rutas>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from "axios";
import AdministrarRutas from "@/pages/ModuloRampas/RampasAsignacionModals/AdministrarRutas.vue";

export default {
  name: "RampasAsignacion",
  components: { AdministrarRutas },
  data() {
    return {
      columns: ["route_id", "numero_rampa", "updatedAt"],
      asignaciones: [],
      optionsVTable: {
        orderBy: {
          column: "route_id",
          ascending: true
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["updatedAt"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        headings: {
          route_id: "Ruta",
          rampa: "Rampa",
          updatedAt: "Última actualización"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          if (data.query) {
            if (data.query["route_id"]) {
              search += `, "route_id[$like]": "%${data.query["route_id"]}%"`;
            }
            if (data.query["numero_rampa"]) {
              search += `, "numero_rampa": ${data.query["numero_rampa"]}`;
            }
            if (data.query["updatedAt"]) {
              search += `, "updatedAt[$gte]": "${data.query.updatedAt.start}"`;
              search += `, "updatedAt[$lte]": "${data.query.updatedAt.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/rampas-asignacion`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          return {
            data: resp.data.data,
            count: resp.data.total
          };
        }
      }
    };
  },
  methods: {
    ...mapActions("RampasAsignacion", {
      findRampasAsignacion: "find",
      patchRampasAsignacion: "patch"
    }),
    fnActualizarRampaRuta(event) {
      if (!event.target.dataset.asignacion) {
        return false;
      }
      if (event.target.value.trim().length === 0) {
        return false;
      }
      if (event.target.value.trim() === event.target.dataset.original) {
        return false;
      }
      if (!/^\d+$/.test(event.target.value.trim())) {
        event.target.value = event.target.dataset.original;
        return false;
      }

      let asignacionId = event.target.dataset.asignacion;
      let data = {
        numero_rampa: event.target.value.trim()
      };
      this.patchRampasAsignacion([asignacionId, data])
        .then(response => {
          event.target.dataset.original = response.numero_rampa;
          let message = `Se asigno la rampa ${response.numero_rampa} a la ruta ${response.route_id} exitosamente`;
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message
          });
          this.$refs.tablaAsignaciones.data.forEach(asignacion => {
            if (asignacion.id == asignacionId) {
              asignacion.updatedAt = response.updatedAt;
              asignacion.numero_rampa = response.numero_rampa;
            }
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fnValidarInput(event) {
      if (event.target.value.trim().length === 0) {
        event.target.value = !event.target.dataset.original
          ? 0
          : event.target.dataset.original;
      }
    }
  }
};
</script>
<style>
.alert.alert-success button.close {
  top: 50%;
}
.white-content.modal-open .wrapper {
  overflow-y: hidden;
}
.form-control.input-rampa {
  height: 25px;
  width: 100px;
}
.row-title {
  margin-bottom: 20px;
}
.line-h-50 {
  line-height: 50px;
}
</style>
