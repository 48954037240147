<template>
  <div id="app" style="position: relative; height: 100vh">
    <div class="row">
      <div class="col-md-12">
        <h4>Reportes de multiples entregas en el mismo punto</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <download-excel
          class="pull-left btn-exportar-verde"
          style="float: right; margin-right: 15px"
          :fields="columnasEXCL"
          :fetch="exportTableData"
          :type="dataExportType"
          :name="
            'Reporte de pedidos entregados en la misma coordenada.' +
            dataExportType
          "
        >
          <!-- <i style="color:green;font-size:20px;" class="fas fa-file-excel"></i> -->
          Exportar
        </download-excel>
      </div>
    </div>
    <!-- /************* tablaReporteDeCoordenadas ************ */ -->
    <v-server-table
      ref="tablaReporteDeCoordenadas"
      :columns="columns"
      :options="ClientSide_Opitons"
    >
      <div slot-scope="props" slot="pedidos">
        <span style="word-break: break-all">{{ props.row.pedidos }}</span>
      </div>
    </v-server-table>

    <!-- /************************************* */ -->
  </div>
</template>

<script>
import axios from "axios";
import JsonExcel from "vue-json-excel";
export default {
  mounted() {
    this.$store
      .dispatch("Reporteentregasmismopunto/find", {
        // query: {
        //   fechahora: {
        //     $gte: moment(this.rangoDeFechasConsulta.startDate).format(
        //       "YYYY-MM-DDT00:00:00-06:00"
        //     ),
        //     $lte: moment(this.rangoDeFechasConsulta.endDate).format(
        //       "YYYY-MM-DDT23:59:59-06:00"
        //     ),
        //   },
        // },
      })
      .then((result) => {
        result.data.forEach((REPORTE) => {
          REPORTE.fecha_entrega_reporte = REPORTE.fecha_entrega_reporte;
          //
          REPORTE.fecha_entrega_reporte = moment(REPORTE.fecha_entrega_reporte);
        });
        let filtroPaginado = document.querySelector(
          "#app > div.VueTables.VueTables--client > div:nth-child(1) > div > div.form-group.form-inline.pull-right.VueTables__limit"
        );
        if (filtroPaginado) {
          filtroPaginado.style.display = "none";
        }
        this.arrayReportesDeCoorenadas = [];
        this.arrayReportesDeCoorenadas = result.data;
      });
  },
  data() {
    return {
      arrayReportesDeCoorenadas: [],
      tablaReporteDeCoordenadasData: [],
      loading: false,
      realizandoBusqueda: false,
      fillInput: {
        formFiltroPedido: false,
        formFiltroNombreConsejera: false,
        formFiltroManifiesto: false,
        formFiltroSector: false,
        formFiltroRuta: false,
      },
      schemaQuery: {},
      columnasEXCL: {
        "Código de usuario": "piloto_id",
        Piloto: "DELIVERY_DRIVER_NAME",
        Placas: "DELIVERY_VEHICULE_PLATES",
        pedidos: "pedidos",
        "No. de pedidos": "numero",
        Motivo: "motivo_detalle",
        "Fecha de entrega": "fecha_entrega_reporte",
      },
      dataExportType: "xls",
      rows: [],
      columns: [
        "piloto_id",
        "DELIVERY_DRIVER_NAME",
        "DELIVERY_VEHICULE_PLATES",
        "pedidos",
        "numero",
        "motivo_detalle",
        "fecha_entrega_reporte",
      ],
      ClientSide_Opitons: {
        orderBy: {
          column: "fecha_entrega_reporte",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Ingrese filtros de bùsqueda",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["fecha_entrega_reporte"],

        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },

        headings: {
          piloto_id: "Código de usuario",
          DELIVERY_DRIVER_NAME: "Piloto",
          DELIVERY_VEHICULE_PLATES: "Placas",
          pedidos: "pedidos",
          numero: "No. de pedidos",

          motivo_detalle: "Motivo",
          fecha_entrega_reporte: "Fecha de entrega",
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          // search += `, "CAMPAING_ID": "${this.campania}"`;

          if (data.query) {
            if (data.query["piloto_id"]) {
              search += `, "piloto_id[$like]": "%${data.query["piloto_id"]}%"`;
            }
            if (data.query["DELIVERY_DRIVER_NAME"]) {
              search += `, "DELIVERY_DRIVER_NAME[$like]": "%${data.query["DELIVERY_DRIVER_NAME"]}%"`;
            }
            if (data.query["DELIVERY_VEHICULE_PLATES"]) {
              search += `, "DELIVERY_VEHICULE_PLATES[$like]": "%${data.query["DELIVERY_VEHICULE_PLATES"]}%"`;
            }
            if (data.query["pedidos"]) {
              search += `, "pedidos[$like]": "%${data.query["pedidos"]}%"`;
            }
            if (data.query["numero"]) {
              search += `, "numero[$like]": "%${data.query["numero"]}%"`;
            }
            if (data.query["motivo_detalle"]) {
              search += `, "motivo_detalle[$like]": "%${data.query["motivo_detalle"]}%"`;
            }
            if (data.query["fecha_entrega_reporte"]) {
              search += `, "fecha_entrega_reporte[$gte]": "${data.query.fecha_entrega_reporte.start}"`;
              search += `, "fecha_entrega_reporte[$lte]": "${data.query.fecha_entrega_reporte.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          console.log(data);

          let url = `${process.env.VUE_APP_API_URL}/reporteentregasmismopunto`;
          return axios.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
      },
    };
  },
  components: {
    downloadExcel: JsonExcel,
  },
  methods: {
    exportTableData() {
      let array = [];
      this.$refs.tablaReporteDeCoordenadas.allFilteredData.forEach(
        (reporte) => {
          reporte.pedidos = '"' + reporte.pedidos + '"';
          reporte.fecha_entrega_reporte = moment(
            reporte.fecha_entrega_reporte
          ).format("DD-MM-YYYY HH:mm");
          array.push(reporte);
        }
      );
      console.log(array);
      return array;
    },
  },
};
</script>
<style scoped>
.btn-exportar-azul {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #031c68 1px solid;
  border-radius: 15px;
}
.btn-exportar-verde {
  cursor: pointer;
  padding: 2px 6px 2px 6px;

  border: #389466 1px solid;
  border-radius: 15px;
}
.btn-exportar-azul:hover,
.btn-exportar-verde:hover {
  box-shadow: 2px 4px #888888;
}
#btn-buscar {
  margin-top: 27px;
}
.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
.wordbreak {
  word-break: break-all;
}
</style>
