<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card" style="">
          <div class="card-body">
            <h5
              class="card-title"
              title="Consejeras con número de teléfono actualizado"
            >
              <span
                class="mdi mdi-12px text-primary mdi-card-account-phone"
              ></span>
              Total de Consejeras:
              <span class="text-primary">{{ view_total_de_consejeras }}</span>
            </h5>
            <div class="card-text">
              <br />
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card" style="">
          <div class="card-body">
            <h5 class="card-title">
              <span title="Total de consejeras filtradas"
                >Consejeras:
                {{ optionsVTable.view_total_de_consejeras_filtradas }}</span
              >
            </h5>
            <footer class="blockquote-footer">
              <br />

              <download-excel
                class="outline-btn-excel pull-right"
                style="cursor: pointer; margin-top:-35px;"
                :fields="columnasEXCLsector"
                :before-generate="
                  () => {
                    return startDownload();
                  }
                "
                :fetch="
                  () => {
                    return fnExportarConsejereasNuevoTelefono();
                  }
                "
                :type="dataExportType"
                :before-finish="finishDownload"
                :name="
                  'Actualización consejeras numero de telefono' + dataExportType
                "
                >Descargar Excel</download-excel
              >
            </footer>

            <div class="card-text">
              <v-server-table
                :columns="columns"
                :options="optionsVTable"
                ref="tablaConsejerasNuevoTelefono"
              >
                <span slot="updatedAt" slot-scope="props">
                  {{ props.row.updatedAt | moment("DD-MM-YYYY HH:mm:ss a") }}
                </span>

                <div slot="opciones" slot-scope="props">
                  <a
                    class="btn btn-outline-info"
                    :href="'/dispositivos_detalle/' + props.row.id"
                    target="_blank"
                    title="Ver detalle de dispositivos"
                  >
                    <span class="mdi mdi-24px mdi-cellphone-information"></span>
                  </a>
                </div>
              </v-server-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    downloadExcel: JsonExcel
  },
  data() {
    return {
      //view vars
      //
      view_total_de_consejeras: 0,
      // export excel vars
      dataExportType: "xls",
      columnasEXCLsector: {
        "Código de consejera": "delivery_client_code",
        Consejera: "delivery_client_name",
        "Tel. antiguo": "old_telefono",
        "Tel. nuevo.": "new_telefono",
        Piloto: "updated_by_piloto_nombre",
        Placa: "updated_by_piloto_placa",
        "Fecha actualización": "updatedAt"
      },
      // tabla vars
      columns: [
        "delivery_client_code",
        "delivery_client_name",
        "old_telefono",
        "new_telefono",
        "updated_by_piloto_nombre",
        "updated_by_piloto_placa",

        "updatedAt"
      ],
      optionsVTable: {
        view_total_de_consejeras_filtradas: 0,
        orderBy: {
          column: "delivery_client_code",
          ascending: true
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["updatedAt"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "delivery_client_code",
          "delivery_client_name",
          "old_telefono",
          "new_telefono",
          "updated_by_piloto_nombre",
          "updated_by_piloto_placa"
        ],
        headings: {
          delivery_client_code: "Código de consejera",
          delivery_client_name: "Consejera",
          old_telefono: "Tel. antiguo",
          new_telefono: "Tel. nuevo.",
          updated_by_piloto_nombre: "Piloto",
          updated_by_piloto_placa: "Placa",
          updatedAt: "Fecha actualización"
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          // search += `, "reportebultosvalidados_id": "${this.idDelReporte}"`;
          if (data.query) {
            //
            if (data.query["delivery_client_code"]) {
              search += `, "delivery_client_code[$like]": "%${data.query["delivery_client_code"]}%"`;
            }
            //
            if (data.query["delivery_client_name"]) {
              search += `, "delivery_client_name[$like]": "%${data.query["delivery_client_name"]}%"`;
            }
            //
            if (data.query["old_telefono"]) {
              search += `, "old_telefono[$like]": "%${data.query["old_telefono"]}%"`;
            }
            //
            if (data.query["new_telefono"]) {
              search += `, "new_telefono[$like]": "%${data.query["new_telefono"]}%"`;
            }
            //
            if (data.query["updated_by_piloto_nombre"]) {
              search += `, "updated_by_piloto_nombre[$like]": "%${data.query["updated_by_piloto_nombre"]}%"`;
            }
            //
            if (data.query["updated_by_piloto_placa"]) {
              search += `, "updated_by_piloto_placa[$like]": "%${data.query["updated_by_piloto_placa"]}%"`;
            }

            if (data.query["updatedAt"]) {
              search += `, "updatedAt[$gte]": "${data.query.updatedAt.start}"`;
              search += `, "updatedAt[$lte]": "${data.query.updatedAt.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/consejeras-telefono`;
          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt")
            }
          });
        },
        responseAdapter(resp) {
          //asignar total de usuarios consultados
          console.log(resp.data.data);
          this.options.view_total_de_consejeras_filtradas = resp.data.total;
          //asignar total de dispositivos con GPS desconectado
          this.$parent.fnMostrarPilotosConGpsApagado();

          return {
            data: resp.data.data,
            count: resp.data.total
          };
        }
      }
    };
  },
  methods: {
    // para mandar a traer datos de excel
    ...mapActions("ConsejerasTelefono", {
      findConsejerasTelefono: "find"
    }),
    // funciones excel
    startDownload() {},
    finishDownload() {},
    getParams() {
      /** */
      const params = {};
      // params["id_rol"] = 3;

      if (
        this.$refs.tablaConsejerasNuevoTelefono.query.delivery_client_code != ""
      ) {
        params["delivery_client_code"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query.delivery_client_code +
            "%"
        };
      }
      if (
        this.$refs.tablaConsejerasNuevoTelefono.query.delivery_client_name != ""
      ) {
        params["delivery_client_name"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query.delivery_client_name +
            "%"
        };
      }
      if (this.$refs.tablaConsejerasNuevoTelefono.query.old_telefono != "") {
        params["old_telefono"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query.old_telefono +
            "%"
        };
      }
      if (this.$refs.tablaConsejerasNuevoTelefono.query.new_telefono != "") {
        params["new_telefono"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query.new_telefono +
            "%"
        };
      }
      if (
        this.$refs.tablaConsejerasNuevoTelefono.query
          .updated_by_piloto_nombre != ""
      ) {
        params["updated_by_piloto_nombre"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query
              .updated_by_piloto_nombre +
            "%"
        };
      }
      if (
        this.$refs.tablaConsejerasNuevoTelefono.query.updated_by_piloto_placa !=
        ""
      ) {
        params["updated_by_piloto_placa"] = {
          $like:
            "%" +
            this.$refs.tablaConsejerasNuevoTelefono.query
              .updated_by_piloto_placa +
            "%"
        };
      }

      /** FIN FILTRO ESPACIO DEL PILOTO*/
      return params;
    },
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
    async fnExportarConsejereasNuevoTelefono() {
      const bultos = await this.findConsejerasTelefono({
        query: this.getParams()
      }).then(result => {
        result.data.forEach(registro => {
          registro.updatedAt = this.getDate(registro.updatedAt);
          registro.createdAt = this.getDate(registro.createdAt);
        });
        return result.data;
      });

      return bultos;
    },
    // funciones propias de la vista
    fnShowEjemplo() {
      //
      let bateria = document.querySelector(
        "body > div > div.wrapper > div > div > div > div:nth-child(2) > div > div > div > div > div > div.table-responsive > table > thead > tr.VueTables__filters-row > th:nth-child(5) > div > input"
      );
      bateria.value = "<=50";

      this.$refs.tablaConsejerasNuevoTelefono.query.PILOTO_BATERIA = "<=50";

      bateria.select(); // you can also use input.focus()

      this.$refs.tablaConsejerasNuevoTelefono.refresh();
    },
    async fnMostrarPilotosConGpsApagado() {
      let url = `${process.env.VUE_APP_API_URL}/consejeras-telefono`;
      await this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt")
          }
        })
        .then(result => {
          this.view_total_de_consejeras = result.data.total;
        });
    }
  },
  mounted() {}
};
</script>
<style scoped>
.btn-outline-info {
  color: #17a2b8 !important;
  background-color: transparent !important;
  background-image: none !important;
  border-color: #17a2b8 !important;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
}
.btn-outline-info:focus {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
.outline-btn {
  border: none;
  background-color: white;
  color: rgb(17, 96, 216);
  padding: 1px 2px;
  font-size: 12px;
  cursor: pointer;
}

.outline-btn-excel {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: white;
  color: #1f6e43;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
.outline-btn-excel:hover {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: #739b85;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
</style>
