<template>
  <div>
    <GmapMap
      :center="mapCenter"
      :map-type-id="mapTypeId"
      :zoom="mapZoom"
      ref="mymap"
    >
      <gmap-polyline
        v-if="deliveryPath.length > 0"
        :path="deliveryPath"
        v-bind:options="{
          strokeWeight: 10,
          strokeColor: deliveryPathColor,
          strokeOpacity: 0.7,
          icons: [
            {
              icon: {
                path: lePate,
                scale: 4,
                fillColor: deliveryPathColor,
                fillOpacity: 1,
              },
              offset: '0',
              repeat: '100px',
            },
          ],
        }"
        ref="polyline"
        v-on:click="fnDeleteDeliveryPath()"
      ></gmap-polyline>
      <!-- inicio custom marker -->
      <cluster :gridSize="30" :zoomOnClick="true" :maxZoom="21">
        <gmap-custom-marker
          alignment="top"
          v-for="(item, index) in markers"
          v-bind:key="index"
          :marker="{ lat: item.lat, lng: item.lng }"
          class="marcador"
        >
          <img
            :id="'markerID' + item.ERP_DOC"
            :src="
              'https://maps.google.com/mapfiles/ms/icons/' +
              item.color +
              '-dot.png'
            "
          />
          <div v-show="showByIndex === index" class="marker-info">
            <span class="marcadorEtiqueta">
              <span class="marcadorContador" v-show="item.contador > 0">{{
                item.contador
              }}</span>
              -
              {{ item.titulo }}
            </span>
          </div>
        </gmap-custom-marker>
      </cluster>
      <!-- fin custom marker -->
    </GmapMap>
    <buscador @clicked="fnventanaInformacionDeEntrega($event)"></buscador>
    <ventanaDetalleEntrega
      v-show="ventanaInformacionDeEntrega"
      :historico="true"
      :ventanaInformacionDeEntrega="ventanaInformacionDeEntrega"
      :deliveryTextColor="deliveryTextColor"
      @clicked="fnCloseDetalleWindow($event)"
    ></ventanaDetalleEntrega>
    <!-- FAB ICON -->
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
//inicio
//FAB ICONS
import fab from "vue-fab";
//DashboardMapaPrincipalGraficaSectorRuta
import buscador from "./buscador";
import ventanaDetalleEntrega from "./DashboardMapaPrincipalDetalleEntrega";
import ventanaUltimaEntrega from "./DashboardMapaPrincipalUltimaEntrega.vue";
import ventanaGraficaEntregas from "./DashboardMapaPrincipalGrafica.vue";
import DashboardMapaPrincipalGraficaSectorRuta from "./DashboardMapaPrincipalGraficaSectorRuta.vue";
//
import DragItDude from "vue-drag-it-dude";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import { gmapApi } from "vue2-google-maps";
export default {
  computed: {
    google: gmapApi,
  },
  components: {
    buscador,
    DashboardMapaPrincipalGraficaSectorRuta,
    fab,
    ventanaDetalleEntrega,
    ventanaGraficaEntregas,
    ventanaUltimaEntrega,
    cluster: GmapCluster,
    DragItDude,
    "gmap-custom-marker": GmapCustomMarker,
  },
  data() {
    return {
      ventanaInformacionDeEntrega: null,
      //eventos
      realizarEntrega: null,
      realizarDevolucion: null,
      // FAB VARIABLES
      DashboardMapaPrincipalGraficaSectorRuta: true,
      ventanaUltimaEntrega: true,
      ventanaGraficaEntregas: true,
      // FAB ICONS
      bgColor: "#031c684d",
      position: "top-right",
      fabActions: [
        {
          name: "FABResumenRutaSector",
          icon: "pie_chart",
          color: "#031D6A",
          tooltip: "Resumen sector y ruta",
        },
        {
          name: "FABultimaEntrega",
          icon: "history",
          color: "#031D6A",
          tooltip: "Ultima entrega",
        },
        {
          name: "FABmapa",
          icon: "map",
          color: "#031D6A",
          tooltip: "Resumen de rutas",
        },
      ],
      // FIN FAB ICONS
      deliveryTextColor: "",
      ventanaInformacionDeEntregaTOP: 55,
      ventanaInformacionDeEntregaLEFT: 9,
      lePate: null,
      chartData: [
        ["Year", "Sales", "Expenses", "Profit"],
        ["2014", 1000, 400, 200],
      ],
      chartOptions: {
        isStacked: true,
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
      },
      // end of chart
      contador: 0,
      symbolOne: {
        path: "M -2,0 0,-2 2,0 0,2 z",
        strokeColor: "#F00",
        fillColor: "#F00",
        fillOpacity: 1,
      },
      edited: null,
      deliveryPathColor: "",
      deliveryPath: [],
      showByIndex: null,
      mapCenter: { lat: 15.0032599, lng: -90.4136375 },
      mapTypeId: "roadmap",
      mapZoom: 8,
      lastEntrega: [],
      ventanaInformacionDeEntrega: null,
      markers: [],
    };
  },
  methods: {
    // cerrar ventana de detalle
    fnCloseDetalleWindow(e) {
      this.markers = [];
      this.ventanaInformacionDeEntrega = null;
    },
    // FAB ICONS METHODS
    FABResumenRutaSector() {
      this.DashboardMapaPrincipalGraficaSectorRuta =
        !this.DashboardMapaPrincipalGraficaSectorRuta;
    },
    FABultimaEntrega() {
      this.ventanaUltimaEntrega = !this.ventanaUltimaEntrega;
    },
    FABmapa() {
      this.ventanaGraficaEntregas = !this.ventanaGraficaEntregas;
    },
    fnIrMarcador(coordenadas) {
      //console.log(coordenadas);
      this.mapCenter = {
        lat: parseFloat(coordenadas.lat),
        lng: parseFloat(coordenadas.lng),
      };
      this.mapZoom = 22;
      setTimeout(() => {
        document.getElementById(coordenadas.markerID).src =
          "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
        document.getElementById(coordenadas.markerID).classList.add("animated");
        document.getElementById(coordenadas.markerID).classList.add("bounce");
      }, 300);
    },
    fnCargarPedidoEnMapa(pedido) {
      if (pedido.DELIVERY_LON != null) {
        this.markers.push(pedido);
      }

      // const pathCoor = {
      //   lng: parseFloat(pedido.lng),
      //   lat: parseFloat(pedido.lat),
      //   placa: pedido.placa
      // };
      // this.deliveryPath.push(pathCoor);
      console.log("informacion de pedido entregado: ");
      console.log(pedido);
      console.log("fin informacion de pedido entregado ^");
    },
    fnventanaInformacionDeEntrega(erpEntrega) {
      if (erpEntrega !== null) {
        /***************************inicio */
        //, markerID, contador
        //

        //
        //
        this.markers = [];

        this.$store
          .dispatch("PedidosHistorico/find", {
            query: {
              ERP_DOC: erpEntrega.ERP_DOC,
            },
          })
          .then((result) => {
            console.log(
              "resultado de la busqueda de un pedido en  historico",
              result
            );

            //
            if (result.data[0].PRIZES === null) {
              result.data[0].PRIZES = 0;
            }
            if (result.data[0].HOME === null) {
              result.data[0].HOME = 0;
            }

            if (result.data[0].STATUS == 1) {
              this.deliveryTextColor = "red";
            } else if (result.data[0].STATUS == 2) {
              this.deliveryTextColor = "green";
            } else if (result.data[0].STATUS == 3) {
              this.deliveryTextColor = "purple";
            } else {
              this.deliveryTextColor = "orange";
            }
            result.data[0].DELIVERY_ORDER_SEQUENCE;
            this.ventanaInformacionDeEntrega = result.data[0];
            /************************************************* */
            if (result.data[0].STATUS == 1) {
            }
            if (result.data[0].STATUS == 1) {
              result.data[0].color = "red";
            } else if (result.data[0].STATUS == 2) {
              result.data[0].color = "green";
            } else if (result.data[0].STATUS == 3) {
              result.data[0].color = "purple";
            } else {
              result.data[0].color = "orange";
            }
            /*************************************** */
            console.log(result.data[0].DELIVERY_LAT);
            console.log(result.data[0].DELIVERY_LON);
            if (result.data[0].DELIVERY_LAT == null) {
              // result.data[0].DELIVERY_LAT = 14.598564;
            }
            if (result.data[0].DELIVERY_LON == null) {
              // result.data[0].DELIVERY_LON = -90.645965;
            }
            /*************************************** */
            if (result.data[0].DELIVERY_LON !== null) {
              const entrega = {
                DELIVERY_ASSIGNED_TO_VEHICULE_PLATES:
                  result.data[0].DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
                ROUTE_ID: result.data[0].ROUTE_ID,
                ERP_DOC: result.data[0].ERP_DOC,
                placa: result.data[0].DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
                placa_quien_entrego: result.data[0].DELIVERY_VEHICULE_PLATES,
                titulo: result.data[0].DELIVERY_CLIENT_NAME,
                color: result.data[0].color,
                lng: result.data[0].DELIVERY_LON,
                lat: result.data[0].DELIVERY_LAT,
                contador: result.data[0].DELIVERY_ORDER_SEQUENCE,
              };

              this.markers.push(entrega);
              /*************************************** */
              // let markerID = "markerID" + result.data[0].ERP_DOC;
              // console.log(markerID);
              // /*************************************** */
              // document.getElementById(markerID).classList.remove("animated");
              // document.getElementById(markerID).classList.remove("bounce");
              // // cambiar marcador de color
              // document.getElementById(markerID).src =
              //   "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
              // document.getElementById(markerID).classList.add("animated");
              // document.getElementById(markerID).classList.add("bounce");
            }
          });
        this.$store
          .dispatch("Pedidos/find", {
            query: {
              ERP_DOC: erpEntrega.ERP_DOC,
            },
          })
          .then((result) => {
            console.log(
              "resultado de la busqueda de un pedido en  historico",
              result
            );

            //
            if (result.data[0].PRIZES === null) {
              result.data[0].PRIZES = 0;
            }
            if (result.data[0].HOME === null) {
              result.data[0].HOME = 0;
            }

            if (result.data[0].STATUS == 1) {
              this.deliveryTextColor = "red";
            } else if (result.data[0].STATUS == 2) {
              this.deliveryTextColor = "green";
            } else if (result.data[0].STATUS == 3) {
              this.deliveryTextColor = "purple";
            } else {
              this.deliveryTextColor = "orange";
            }
            result.data[0].DELIVERY_ORDER_SEQUENCE;
            this.ventanaInformacionDeEntrega = result.data[0];
            /************************************************* */
            if (result.data[0].STATUS == 1) {
            }
            if (result.data[0].STATUS == 1) {
              result.data[0].color = "red";
            } else if (result.data[0].STATUS == 2) {
              result.data[0].color = "green";
            } else if (result.data[0].STATUS == 3) {
              result.data[0].color = "purple";
            } else {
              result.data[0].color = "orange";
            }
            /*************************************** */
            console.log(result.data[0].DELIVERY_LAT);
            console.log(result.data[0].DELIVERY_LON);
            if (result.data[0].DELIVERY_LAT == null) {
              // result.data[0].DELIVERY_LAT = 14.598564;
            }
            if (result.data[0].DELIVERY_LON == null) {
              // result.data[0].DELIVERY_LON = -90.645965;
            }
            /*************************************** */
            if (result.data[0].DELIVERY_LON !== null) {
              const entrega = {
                DELIVERY_ASSIGNED_TO_VEHICULE_PLATES:
                  result.data[0].DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
                ROUTE_ID: result.data[0].ROUTE_ID,
                ERP_DOC: result.data[0].ERP_DOC,
                placa: result.data[0].DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
                placa_quien_entrego: result.data[0].DELIVERY_VEHICULE_PLATES,
                titulo: result.data[0].DELIVERY_CLIENT_NAME,
                color: result.data[0].color,
                lng: result.data[0].DELIVERY_LON,
                lat: result.data[0].DELIVERY_LAT,
                contador: result.data[0].DELIVERY_ORDER_SEQUENCE,
              };

              this.markers.push(entrega);
              /*************************************** */
              // let markerID = "markerID" + result.data[0].ERP_DOC;
              // console.log(markerID);
              // /*************************************** */
              // document.getElementById(markerID).classList.remove("animated");
              // document.getElementById(markerID).classList.remove("bounce");
              // // cambiar marcador de color
              // document.getElementById(markerID).src =
              //   "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
              // document.getElementById(markerID).classList.add("animated");
              // document.getElementById(markerID).classList.add("bounce");
            }
          });

        // cargar informacion del pedido
        /******************************fin */
      }
    },
    fnDeleteDeliveryPath() {
      this.deliveryPath = [];
    },
    fnColorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },

    FnCrearDeliveryPath(ROUTE_ID, ERP_DOC) {
      //inicio de api de google
      this.lePate = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
      //seleccionamos el color de la líea "pathDelivery"
      //let pathLineColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16);
      let pathLineColor = this.fnColorLuminance(
        "#" + ((Math.random() * 0xffffff) << 0).toString(16),
        -0.5
      );

      //validaciones para mostrar y ocultar la linea de entregas
      // sí deliveryPath no posee cordenadas dentro, es apta para recrear la linea
      // si deliveryPath ya posee coodenadas dentro, pero eran de otra ruta
      if (
        this.deliveryPath.length <= 0 ||
        this.deliveryPath[0].ERP_DOC != ERP_DOC
      ) {
        this.deliveryPath = [];
        this.deliveryPathColor = pathLineColor;

        const DAY_MS = 24 * 60 * 60 * 1000;
        this.$store
          .dispatch("Createdeliverypath/find", {
            query: {
              ERP_DOC: ERP_DOC,
              DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: ROUTE_ID,
            },
          })
          .then((result) => {
            ("delivery path created");
            console.log(result);
            let contadorEntregas = 0;
            this.deliveryPath = [];
            for (let i = 0; i < result.length; i++) {
              if (i == 0 && this.deliveryPath.length <= 0) {
                const pathCoor = {
                  lng: parseFloat(result[i].DELIVERY_LON),
                  lat: parseFloat(result[i].DELIVERY_LAT),
                  ROUTE_ID: ROUTE_ID,
                  ERP_DOC: ERP_DOC,
                };

                this.deliveryPath.push(pathCoor);
              } else if (
                result[i].DELIVERY_LON != this.deliveryPath[0].lng &&
                result[i].DELIVERY_LAT != this.deliveryPath[0].lat
              ) {
                const pathCoor = {
                  lng: parseFloat(result[i].DELIVERY_LON),
                  lat: parseFloat(result[i].DELIVERY_LAT),
                  ROUTE_ID: ROUTE_ID,
                  ERP_DOC: ERP_DOC,
                };

                this.deliveryPath.push(pathCoor);
              } else {
                break;
              }
            }
            //console.log(this.deliveryPath);
          });
        //fin
      }
    },
  },
  // mounted() {
  //   let date = new Date();
  //
  //   this.$store
  //     .dispatch("Entregasdiariasmapa/find", {
  //       query: {}
  //     })
  //     .then(result => {
  //       result.forEach(el => {
  //         if (el.STATUS == 1) {
  //         }
  //         if (el.STATUS == 1) {
  //           el.color = "red";
  //         } else if (el.STATUS == 2) {
  //           el.color = "green";
  //         } else if (el.STATUS == 3) {
  //           el.color = "purple";
  //         } else {
  //           el.color = "orange";
  //         }
  //         const entrega = {
  //           DELIVERY_ASSIGNED_TO_VEHICULE_PLATES:
  //             el.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
  //           ROUTE_ID: el.ROUTE_ID,
  //           ERP_DOC: el.ERP_DOC,
  //           placa: el.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
  //           placa_quien_entrego: el.DELIVERY_VEHICULE_PLATES,
  //           titulo: el.DELIVERY_CLIENT_NAME,
  //           color: el.color,
  //           lng: el.DELIVERY_LON,
  //           lat: el.DELIVERY_LAT,
  //           contador: el.DELIVERY_ORDER_SEQUENCE
  //         };

  //         this.markers.push(entrega);
  //       });

  //       //this.markers = result.data;
  //       //console.log(this.markers);
  //     });
  //   // FIN DE CARGA DE PEDIDOS YA ENTREGADOS EN EL MAPA

  //   const { Realizarentrega, Realizardevolucion } = this.$FeathersVuex.api;

  //   Realizarentrega.on("patched", pedido => {
  //     this.realizarEntrega = pedido;
  //     this.fnCargarPedidoEnMapa(pedido);
  //   });
  //   Realizardevolucion.on("patched", pedido => {
  //     this.realizarDevolucion = pedido;
  //     pedido.color = "purple";
  //     this.fnCargarPedidoEnMapa(pedido);
  //   });
  // }
};
</script>
<style scoped>
.modal-header {
  padding-bottom: 10px;
  background-color: rgb(3, 29, 106) !important;
  color: white;
  font-size: 24px;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.marcador {
  cursor: pointer;
}
#grafica {
  top: 220px;
  left: 420px;
  position: absolute;
  cursor: move;
  z-index: 18;
}
.vue-map-container {
  height: 100vh;
  width: 100%;
  display: inline-block;
  z-index: 9;
}
#ventanaInformacionDeEntrega {
  min-width: 860px;
  border-radius: 15px;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: top;
  z-index: 12;
  position: absolute;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
#app-ultima-entrega {
  width: 390px;
  display: inline-block;
  vertical-align: top;
  z-index: 11;
  top: 49px;
  left: 420px;
  position: absolute;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.marker-info {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px;
  border-radius: 20px;
  color: #ffff;
  font-size: 14px;
  word-break: keep-all;
  width: 290px;
}
thead tr th:first-child,
tbody tr td:first-child {
  width: 11em;
  min-width: 11em;
  max-width: 11em;
  word-break: break-all;
}
p {
  padding-top: 2px;
  padding-bottom: 2px;
  background: /* gradient can be an image */ linear-gradient(
      to left,
      rgb(255, 255, 255) 0%,
      rgb(28, 0, 105) 12%,
      rgb(0, 0, 0) 47%,
      rgb(255, 255, 255) 100%
    )
    left bottom no-repeat;
  background-size: 100% 1.2px; /* if linear-gradient, we need to resize it */
}

.modal-dialog {
  margin: 0px !important;
}
strong {
  font-weight: bold;
}
.btn-float-right {
  margin-top: 15px;
  position: absolute;
  right: 25px;
  bottom: 10px;
}
.marcadorEtiqueta {
  word-break: keep-all;
}
.marcadorContador {
  font-size: 24px;
}
</style>
