// src/store/services/pedidos.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Serviciorampasdetalle extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Serviciorampasdetalle";
  // Define default properties here
  static instanceDefaults() {
    return {
      MANIFEST_ID: "",
      total_pedidos: "",
      inicio_carga: "",
      DELIVERY_ASSIGNED_TO_DRIVER_NAME: "",
      DELIVERY_COMPANY_NAME: ""
    };
  }
}
const servicePath = "servicio-rampas-detalle";
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: "Serviciorampasdetalle",
  Model: Serviciorampasdetalle,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
