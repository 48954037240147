<template>
  <div id="container">
    <div class="row">
      <div class="col-md-6 offset-md-6" style="text-align: right">
        <button
          class="btn btn-info"
          data-toggle="modal"
          data-target="#modalResumenRutas"
          style="margin-right: 20px"
        >
          Resumen rutas
        </button>
        <button
          class="btn btn-info"
          data-toggle="modal"
          data-target="#modalGenerarManifiestos"
        >
          Manifiestos sector
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>Filtros de búsqueda</h4>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="sector">Manifiesto</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroManifiesto"
            id="formFiltroManifiesto"
            name="formFiltroManifiesto"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroManifiesto ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroManifiesto')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="sector">Sector</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroSector"
            id="formFiltroSector"
            name="formFiltroSector"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroSector ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroSector')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="ruta">Ruta</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroRuta"
            id="formFiltroRuta"
            name="formFiltroRuta"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroRuta ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroRuta')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="nombre_piloto">Nombre piloto</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroNombrePiloto"
            id="formFiltroNombrePiloto"
            name="formFiltroNombrePiloto"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroNombrePiloto ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroNombrePiloto')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group position-relative">
          <label for="placas">Placas</label>
          <input
            type="text"
            class="form-control"
            ref="formFiltroPlacas"
            id="formFiltroPlacas"
            name="formFiltroPlacas"
            v-on:keyup.enter="buscar"
          />
          <span
            class="form-clear"
            v-bind:class="fillInput.formFiltroPlacas ? '' : 'd-none'"
            @click="limpiarFiltro('formFiltroPlacas')"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="col-md-2">
        <button class="btn btn-info" @click="buscar" id="btn-buscar">
          Buscar
        </button>
      </div>
    </div>
    <h3>Manifiestos</h3>
    <v-client-table :data="rows" :columns="columns" :options="options">
      <template slot="afterBody" v-if="realizandoBusqueda">
        <div class="overlay-loader"></div>
        <clip-loader
          size="50px"
          class="clip-loader"
          :color="colorSpinner"
        ></clip-loader>
      </template>
      <div slot-scope="props" slot="ROUTE_ID">
        <span style="word-break: break-all">{{ props.row.ROUTE_ID }}</span>
      </div>
      <a
        slot="imprimir"
        slot-scope="props"
        class="fa fa-file-pdf icon-manifest"
        href="javascript:void(0)"
        @click="fnGenerarPdfManifiesto(props.row.MANIFEST_DB_ID)"
        v-if="props.row.ESTADO_MANIFIESTO == 1"
      ></a>
      <a
        slot="descargar"
        slot-scope="props"
        class="fa fa-file-excel icon-manifest"
        href="javascript:void(0)"
        @click="fnGenerarExcelManifiesto(props.row.MANIFEST_DB_ID)"
      ></a>
    </v-client-table>

    <!-- modals -->
    <ResumenRutas></ResumenRutas>
    <GenerarManifiestos></GenerarManifiestos>
    <!-- fin modals -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ResumenRutas from "@/pages/ModuloRampas/asignacionmodals/ResumenRutas.vue";
import GenerarManifiestos from "@/pages/ModuloRampas/asignacionmodals/GenerarManifiestos.vue";
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "vistaManifiestos",
  components: { ResumenRutas, GenerarManifiestos, "clip-loader": ClipLoader },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL + "/",
      // id ventanas
      AsignacionIPedidos: false,
      AsignacionPedidosRouteId: null,
      AisngacionManifiestos: false,
      AisngacionManifiestosManifiestoId: null,
      InfoRuta: null,
      realizandoBusqueda: false,
      colorSpinner: "#031d6a",
      //fin
      fields: [
        //...
        {
          name: "gender-slot",
          title: '<i class="grey heterosexual icon"></i>Gender',
          titleClass: "center aligned",
          dataClass: "center aligned",
          width: "15%",
        },
      ],
      fillInput: {
        formFiltroManifiesto: false,
        formFiltroSector: false,
        formFiltroRuta: false,
        formFiltroNombrePiloto: false,
        formFiltroPlacas: false,
      },
      columns: [
        "MANIFEST_DB_ID",
        "DELIVERY_CLIENT_REGION",
        "ROUTE_ID",
        "DELIVERY_ASSIGNED_TO_DRIVER_NAME",
        "DELIVERY_COMPANY_NAME",
        "TOTAL",
        "UPLOADED_DATE",
        "imprimir",
        "descargar",
      ],
      rows: [],
      options: {
        rowClassCallback: (row) => {
          return row.ESTADO_MANIFIESTO == 1
            ? "manifiesto-completado"
            : "manifiesto-no-completado";
        },
        cellClasses: {
          total_pendientes: [
            {
              class: "text-red",
              condition: (row) => row.total_pendientes > 0,
            },
          ],
        },
        orderBy: {
          column: "MANIFEST_DB_ID",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Ingrese filtros de búsqueda...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [],
        headings: {
          MANIFEST_DB_ID: "Manifiesto",
          DELIVERY_CLIENT_REGION: "Sector",
          ROUTE_ID: "Ruta",
          DELIVERY_ASSIGNED_TO_DRIVER_NAME: "Piloto",
          DELIVERY_COMPANY_NAME: "Empresa",
          TOTAL: "Total pedidos",
          UPLOADED_DATE: "Inicio carga",
          imprimir: "Imprimir",
          descargar: "Descargar",
        },
      },
    };
  },
  computed: {
    ...mapState("Serviciorampasasignacionmanifiestos", {
      areServiciorampasasignacionmanifiestosLoading: "isFindPending",
    }),
    ...mapGetters("Serviciorampasasignacionmanifiestos", {
      findServiciorampasasignacionmanifiestosInStore: "find",
    }),
  },
  methods: {
    ...mapActions("Serviciorampasasignacionmanifiestos", {
      findServiciorampasasignacionmanifiestos: "find",
    }),
    fnGenerarExcelManifiesto(manifiestoId) {
      let fileName = "Manifiesto_" + manifiestoId + ".xlsx";
      let url = `${this.apiUrl}excel-manifiestos/${manifiestoId}`;
      this.fnDescargarArchivo(url, "application/vnd.ms-excel", fileName);
    },
    fnGenerarPdfManifiesto(manifiestoId) {
      let fileName = "Manifiesto_" + manifiestoId + ".pdf";
      let url = `${this.apiUrl}generarmanifiestoporid/${manifiestoId}`;
      this.fnDescargarArchivo(url, "application/pdf", fileName);
    },
    fnDescargarArchivo(url, tipo, nombreArchivo) {
      axios
        .get(url, {
          responseType: "blob",
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((response) => {
          let urlDown = URL.createObjectURL(
            new Blob([response.data], { type: tipo })
          );
          let link = document.createElement("a");
          link.href = urlDown;
          link.setAttribute("download", nombreArchivo);
          document.body.appendChild(link);
          link.click();
        });
    },
    fnGenerarExcel(manifiestoId) {
      let fileName = "Ruta_" + manifiestoId + ".xlsx";
      let url = `${this.apiUrl}excel-manifiestos/${manifiestoId}`;
      axios
        .get(url, {
          params: {
            type: 1,
          },
          responseType: "blob",
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    },
    fnSetInfoRuta(id, total_pedidos, total_cargados) {
      this.InfoRuta = {
        rutaId: id,
        totalPedidos: total_pedidos,
        totalPedidosCargados: total_cargados,
      };
    },
    buscar() {
      this.rows = [];

      const manifiesto = this.$refs.formFiltroManifiesto;
      const sector = this.$refs.formFiltroSector;
      const ruta = this.$refs.formFiltroRuta;
      const nombre = this.$refs.formFiltroNombrePiloto;
      const placas = this.$refs.formFiltroPlacas;

      if (this.realizandoBusqueda) {
        return;
      }

      if (
        manifiesto.value.length == 0 &&
        sector.value.length == 0 &&
        ruta.value.length == 0 &&
        nombre.value.length == 0 &&
        placas.value.length == 0
      ) {
        this.rows = [];
        return;
      }

      this.realizandoBusqueda = true;
      this.mostrarIconoLimpiarFiltro(manifiesto);
      this.mostrarIconoLimpiarFiltro(sector);
      this.mostrarIconoLimpiarFiltro(ruta);
      this.mostrarIconoLimpiarFiltro(nombre);
      this.mostrarIconoLimpiarFiltro(placas);

      let data = {
        MANIFEST_DB_ID: manifiesto.value.trim(),
        SECTOR: sector.value.trim(),
        ROUTE_ID: ruta.value.trim(),
        DRIVER_NAME: nombre.value.trim(),
        VEHICLE_PLATES: placas.value.trim(),
      };
      axios
        .get(`${this.apiUrl}servicio-rampas-asignacion-manifiestos/`, {
          method: "GET",
          params: data,
          timeout: 1000 * 10,
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((response) => {
          if (response.data && response.data.length == 0) {
            let message = `No se encontraron manifiestos con los filtros ingresados`;
            this.$notify({
              type: "danger",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message,
            });
            return;
          }

          response.data.forEach((manifiesto) => {
            manifiesto.UPLOADED_DATE = manifiesto.UPLOADED_DATE
              ? moment.utc(new Date(manifiesto.UPLOADED_DATE).toISOString())
              : "N/A";

            const newManifiesto = {
              MANIFEST_DB_ID: manifiesto.MANIFEST_DB_ID,
              DELIVERY_CLIENT_REGION: manifiesto.DELIVERY_CLIENT_REGION,
              ROUTE_ID: manifiesto.ROUTE_ID,
              DELIVERY_ASSIGNED_TO_DRIVER_NAME:
                manifiesto.DELIVERY_ASSIGNED_TO_DRIVER_NAME,
              DELIVERY_COMPANY_NAME: manifiesto.DELIVERY_COMPANY_NAME,
              TOTAL: manifiesto.TOTAL,
              UPLOADED_DATE: manifiesto.UPLOADED_DATE,
              ESTADO_MANIFIESTO: manifiesto.status,
            };

            this.rows.push(newManifiesto);
          });
        })
        .finally(() => {
          this.realizandoBusqueda = false;
        });
    },
    mostrarIconoLimpiarFiltro(inputFiltro) {
      this.fillInput[inputFiltro.id] = inputFiltro.value.length > 0;
    },
    limpiarFiltro(id) {
      this.fillInput[id] = false;
      this.$refs[id].value = "";
      this.$refs[id].focus();
      this.buscar();
    },
  },
  mounted() {},
};
</script>
<style>
.VueTables__date-filter {
  border: 1px solid #ccc;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.white-content .table > thead > tr > th {
  font-size: 8px !important;
}
.table-responsive {
  overflow: hidden !important;
}
.text-red {
  color: red !important;
}
.text-green {
  color: green !important;
}
#btn-buscar {
  margin-top: 25px;
}
.form-group.position-relative input {
  padding-right: 32px;
}

.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}

.white-content.modal-open {
  padding-right: 0px !important;
}
.white-content.modal-open .wrapper {
  overflow-y: hidden;
}
.manifiesto-no-completado {
  background-color: #e62f2f !important;
}
.alert-danger {
  background-color: #e62f2f !important;
}
.overlay-loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  filter: blur(5px);
}

.clip-loader {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 60%;
  top: 40%;
}
</style>
