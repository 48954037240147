var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"padding-top":"25px"}},[_vm._m(0),_vm._v(" Cargando... ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"row"},[(_vm.vistasegmentada)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"custom-control custom-switch ml-3"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customSwitch1"},on:{"change":function($event){return _vm.fnMostrarPedidosSegmentados()}}}),(_vm.PedidosAlContado)?_c('label',{staticClass:"custom-control-label",attrs:{"for":"customSwitch1"}},[_vm._v(_vm._s(_vm.PedidosAlContadoTitulo))]):_vm._e(),(!_vm.PedidosAlContado)?_c('label',{staticClass:"custom-control-label",attrs:{"for":"customSwitch1"}},[_vm._v(_vm._s(_vm.PedidosAlCreditoTitulo))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-12"},[_c('download-excel',{staticClass:"pull-right btn-exportar-verde",staticStyle:{"cursor":"pointer"},attrs:{"fields":_vm.columnasEXCL,"fetch":_vm.exportTableData,"type":_vm.dataExportType,"name":'Reporte resumen por sector.' + _vm.dataExportType}},[_vm._v("Exportar xls")])],1)]),_c('v-client-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"tablaPedidosSector",attrs:{"id":"tablaPedidosSector","data":_vm.rows,"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"sectorProgreso",fn:function(props){return _c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",class:[
          'progress-bar',
          props.row.porcentaje <= 84.99 ? ' bg-red' : '',
          props.row.porcentaje >= 85 && props.row.porcentaje <= 89.99
            ? ' bg-yellow'
            : '',
          props.row.porcentaje >= 90 && props.row.porcentaje <= 94.99
            ? ' bg-orange'
            : '',
          props.row.porcentaje >= 95 ? ' bg-success' : ''
        ],style:('width:' + props.row.porcentaje + '%'),attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"100"}}),_c('small',{staticClass:"justify-content-center d-flex position-absolute progress-value"},[_vm._v(_vm._s(props.row.porcentaje)+"%")])])}}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-grow",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }