<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
    style="background-color: #031d6a !important"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <img
          class="img-fluid"
          width="215px"
          style="margin-right: 5px; top: -1px !important; position: absolute"
          src="/img/logoLeCleire.png"
          alt
        />
      </div>

      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <div
              class="search-bar input-group"
              @click="searchModalVisible = true"
            >
              <div class="input-group-addon">
                <span style="color: white !important">
                  <!-- <span v-if="routeName">- {{ routeName }}</span> -->
                </span>
              </div>
              <div class="input-group-addon">
                <a class style="color: white !important"> Boletas de pago</a>
              </div>
            </div>

            <li class="dropdown">
              <a
                title="Boletas deposito con número de consejera diferente"
                href="#"
                class="icon-dashboard"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  :class="[
                    no_boletas_sin_revisar >= 1 ? 'badge-danger' : 'badge-info',
                    'badge float-right',
                  ]"
                  style="position: absolute"
                  >{{ no_boletas_sin_revisar }}</span
                >
                <span
                  title="Boletas deposito con número de consejera diferente"
                  class="material-icons"
                  >notifications</span
                >
                <b class="caret d-none d-lg-block d-xl-block"></b>
              </a>
              <ul
                v-if="no_boletas_sin_revisar >= 1"
                class="dropdown-menu dropdown-menu-right"
              >
                <li
                  class="nav-link"
                  v-for="item in no_boletas_sin_revisa_array"
                  :key="item.id"
                  data-toggle="modal"
                  data-target="#modalInfoPagoConsejeraNoCoincide"
                  @click="(documentos = item.documentos), (pago = item)"
                >
                  <a class="nav-item dropdown-item"
                    >Código consejera no coincide en boleta ({{
                      item.documentos[0].PAGO_BOLETA_NUMERO_AUTORIZACION
                    }})</a
                  >
                </li>

                <li
                  v-if="no_boletas_sin_revisar > 4"
                  class="nav-link list-group-item d-flex justify-content-between align-items-center"
                >
                  <a class="nav-item dropdown-item"
                    >+{{ no_boletas_sin_revisar - 4 }}</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <a
                class="icon-dashboard"
                href="/pagos"
                title="Cuadre de manifiestos"
              >
                <span
                  style="font-size: 30px"
                  class="mdi mdi-cash-multiple"
                ></span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="icon-dashboard"
                href="/bancos"
                title="Gestión de cuentas bancarias"
              >
                <span style="font-size: 30px" class="mdi mdi-bank"></span>
              </a>
            </li>

            <home-dashboard-button></home-dashboard-button>
            <user-options :menu-on-right="!$rtl.isRTL"></user-options>
          </ul>
        </div>
      </collapse-transition>
    </div>
    <modalInfoPagoConsejera :pago="pago"></modalInfoPagoConsejera>
  </nav>
</template>
<script>
import modalInfoPagoConsejera from "./../../pages/DashboardLiquidaciones/boletaConsejeraNoCoincide.vue";
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import UserOptions from "@/components/UserOptions";
import HomeDashboardButton from "@/components/HomeDashboardButton";
import { mapActions } from "vuex";
export default {
  components: {
    modalInfoPagoConsejera,
    CollapseTransition,
    Modal,
    UserOptions,
    HomeDashboardButton,
  },
  computed: {
    no_boletas_sin_revisar() {
      return this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden;
    },
    no_boletas_sin_revisa_array() {
      return this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden;
    },
    id_rol() {
      return this.$store.state.auth.payload.usuarios.id_rol;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
  },
  data() {
    return {
      pago: null,
      documentos: [],
      //
      TrazabilidadSegmentada: false,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    //cargar numero de boletas sin revisar con codigo de consejera no coincide
    ...mapActions("PagoDePedidos", {
      findPagoDePedidos: "find",
    }),
    fnRenderizarVista(vistaId, vistaName) {
      this.$store.state.paginado.pageNumber = vistaId;
      this.$store.state.paginado.pageName = vistaName;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    if (this.$route.path == "/alcontadoycredito") {
      this.TrazabilidadSegmentada = !this.TrazabilidadSegmentada;
    }
    /** consultar boletas sin revisar con codigo de consejera diferente */
    let url = `${process.env.VUE_APP_API_URL}/pago-de-pedidos?pagos_codigo_consejera_no_coincide=0&$limit=4&PAGO_VERIFICACION_STATUS=0&CAMPAING_ID=${this.CAMPAING_ID}`;
    return this.$http
      .get(url, {
        params: {},
        headers: {
          Authorization:
            "Bearer  " + window.localStorage.getItem("feathers-jwt"),
        },
      })
      .then((r) => {
        console.log(r.data.data);
        this.$store.state.conteoPagoBoletasCodConsejeraNoCoinciden =
          r.data.total;
        this.$store.state.arregloPagoBoletasCodConsejeraNoCoinciden =
          r.data.data;
      });
  },
};
</script>
<style scoped>
.TrazabilidadSeleccionada {
  background: #041b61 !important;
  border: 1px solid white !important;
  border-radius: 15px;
}
.input-group-addon.btn-input-group-addon {
  border-radius: 15px;
  margin-right: 1.4px;
}
.input-group-addon.btn-input-group-addon:hover {
  background: #1d3479 !important;
  border: 1px solid rgb(179, 179, 179) !important;
}
.input-group-addon.btn-blank-input-group-addon {
  margin-right: 1.4px;
}
.input-group-addon.btn-blank-input-group-addon:hover {
  background: #192e6b !important;
  border: 1px solid rgb(24, 25, 105) !important;
}
</style>
