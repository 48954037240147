<template>
  <div
    class="modal fade"
    id="modalEditarModulosDeUsuario"
    ref="modalEditarModulosDeUsuario"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 1015px; overflow: auto; overflow-y: auto"
    >
      <div class="modal-content" style="width: 1015px">
        <div class="modal-header">
          <h5 class="modal-title">
            Editar acceo a modulos para el usuario
            {{ datosEditadosUsuario.usuario_codigo }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="row">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Modulo</th>
                  <th>Activo</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(modulo,
                  index) in localmodalEditarModulosModulosDeUsuario"
                  v-bind:key="index"
                >
                  <td scope="row">
                    {{ modulo.modulo_nombre }}
                  </td>
                  <td>
                    <div class="btn-group" data-toggle="buttons">
                      <label
                        class="btn btn-checkbox "
                        style="background-color: #26314800 !important;
                          background-image: linear-gradient(
                            to bottom left,
                            #34467500,
                            #26314800,
                            #34467500
                          ) !important;
                          box-shadow: none !important;"
                      >
                        <input
                          type="checkbox"
                          name=""
                          :ref="'checkbox' + index"
                          :id="'checkbox' + index"
                          :disabled="modulo.tipo_modulo == 'modulo_por_rol'"
                          :checked="modulo.tipo_modulo != 'sin_asignacion'"
                          :value="modulo.id + '-' + datosEditadosUsuario.id"
                          autocomplete="off"
                          @click="fnRemoveOrAdd($event)"
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
            @click="fnEditarUsuario()"
          >
            Guardar cambios
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "GestionUsuarioEditar",
  props: [
    "padreCodigoUsuario",
    "ListadoDeRoles",
    "ListadoDeEmpresasTransportistas",
    "modalEditarModulosModulosGlobal",
    "modalEditarModulosModulosDeUsuario"
  ],
  data() {
    return {
      localmodalEditarModulosModulosDeUsuario: [],
      datosEditadosUsuario: {
        usuario_codigo: "",
        id_rol: "",
        DELIVERY_COMPANY_ID: "",
        DELIVERY_COMPANY_NAME: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapActions("Usuarios", {
      patchUsuario: "patch",
      getInfoModulosUsuario: "get"
    }),
    ...mapActions("Usuario_modulos", {
      deleteUsuarioModulos: "remove",
      createUsuarioModulos: "create"
    }),
    fnRemoveOrAdd(event) {
      console.log(event.target.value);
      let moduloUsuario = event.target.value.split("-");
      /** */
      if (event.target.checked == false) {
        console.log(moduloUsuario[0]);
        console.log(moduloUsuario[1]);
        console.log("quitar el permiso");
        this.deleteUsuarioModulos(event.target.value)
          .then(result => {
            this.$notify({
              type: "success",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "Permiso revocado con éxito"
            });
          })
          .catch(e => {
            console.log(e);
          });
      }
      /** */
      if (event.target.checked == true) {
        console.log("agregar el permiso");
        console.log(moduloUsuario[0]);
        console.log(moduloUsuario[1]);
        this.createUsuarioModulos({
          id_modulo: moduloUsuario[0],
          id_usuario: moduloUsuario[1]
        })
          .then(result => {
            console.log(result);
            this.$notify({
              type: "success",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "Permiso creado con éxito"
            });
          })
          .catch(e => {
            console.log(e);
          });
      }
      // console.log("antes");
      // console.log(this.localmodalEditarModulosModulosDeUsuario);
      // this.localmodalEditarModulosModulosDeUsuario = this.localmodalEditarModulosModulosDeUsuario.filter(
      //   function(ele) {
      //     return ele.id != event.target.value;
      //   }
      // );
      // console.log("despues");
      // console.log(this.localmodalEditarModulosModulosDeUsuario);
    },
    fnCheckIfModuloAsignado(id_modulo) {
      let modulo = this.localmodalEditarModulosModulosDeUsuario.filter(
        modulo => modulo.id == id_modulo
      );
      if (modulo[0]) {
        console.log("si existe");
        return true;
      } else {
        console.log("no existe");
        return false;
      }
    },
    fnCheckIfModuloAsignadoEsExtra(id_modulo) {
      let modulo = this.localmodalEditarModulosModulosDeUsuario.filter(
        modulo => modulo.id == id_modulo
      );
      if (modulo[0]) {
        console.log("si existe");
        if (modulo[0].tipo_modulo != "modulo_agregado") {
          return true;
        }
        return false;
      } else {
        console.log("no existe");
        return false;
      }
    },
    fnEditarUsuario() {
      let infoUsuario = this.datosEditadosUsuario;
      if (this.datosEditadosUsuario.password == "") {
        delete infoUsuario.password;
      }
      this.patchUsuario([this.datosEditadosUsuario.id, infoUsuario]).then(
        result => {
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message:
              "Se ha editado la informacion del usuario: " +
              result.usuario_codigo
          });
          console.log(result);
        }
      );
    },
    fnEncontrarEmpresaTransportista(event) {
      let empresa = this.ListadoDeEmpresasTransportistas.filter(
        empresa => empresa.id == event.target.value
      );

      this.datosEditadosUsuario.DELIVERY_COMPANY_ID = empresa[0].codigo;
      this.datosEditadosUsuario.DELIVERY_COMPANY_NAME = empresa[0].nombre;
    },
    fnFormEncontrarRol(event) {
      // return "papas";

      let rol = this.ListadoDeRoles.filter(rol => rol.id == event.target.value);
      console.log(rol[0]);
      this.datosEditadosUsuario.id_rol = rol[0].id;
    }
  },
  mounted() {
    /** */
    this.datosEditadosUsuario = this.padreCodigoUsuario;
    /** */
  },
  watch: {
    modalEditarModulosModulosDeUsuario: function(newVal, oldVal) {
      this.localmodalEditarModulosModulosDeUsuario = newVal;
      //******************************* */
      /***********/
    },
    padreCodigoUsuario: function(newVal, oldVal) {
      this.getInfoModulosUsuario(this.padreCodigoUsuario).then(result => {
        this.datosEditadosUsuario = result;
        this.datosEditadosUsuario.password = "";

        // this.$notify({
        //   type: "warning",
        //   verticalAlign: "bottom",
        //   horizontalAlign: "left",
        //   message: "Estás editando al usuario: " + result.usuario_codigo
        // });
      });
      //******************************* */
      /***********/
    }
  }
};
</script>
<style>
.btn.btn-checkbox {
  background-color: #26314800 !important;
  background-image: linear-gradient(
    to bottom left,
    #34467500,
    #26314800,
    #34467500
  ) !important;
  box-shadow: none !important;
}
.btn.btn-checkbox.active {
  background-color: #26314800 !important;
  background-image: linear-gradient(
    to bottom left,
    #34467500,
    #26314800,
    #34467500
  ) !important;
  box-shadow: none !important;
}
.btn.btn-checkbox:hover {
  background-color: #6c757d0f !important;
  background-image: linear-gradient(
    to bottom left,
    #2e3b5f00,
    #26314800,
    #4b5c8a
  ) !important;
  box-shadow: none !important;
}
.alert.alert-success button.close {
  top: 50%;
}
#btn-crear-ruta,
#btn-actualizar-ruta,
#btn-cancelar {
  margin-top: 30px;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
#modalEditarModulosDeUsuario
  > div
  > div
  > div.modal-body
  > div
  > div
  > form
  > div
  > select {
  color: black;
}
option {
  color: black !important;
}
</style>
