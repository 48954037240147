<template>
  <div class="row">
    <div
      class="col-md-6"
      :style="[varColumnas == 1 ? { 'margin-left': '7%' } : { background: '' }]"
    >
      <reactive-bar-chart :chartData="chartData"></reactive-bar-chart>
    </div>
    <div class="col-md-6 my-auto">
      <p>
        <span
          :class="'detalleSector'"
          :style="[
            varColumnas == 4 ? { 'font-size': '51px' } : { 'font-size': '56px' }
          ]"
          >Sector {{ cargaporsector.pedido_sector }}</span
        >
        <br />
        <span :class="'detalleSector'">
          {{
            (
              (cargaporsector.total_pedidos_cargados /
                (cargaporsector.total_pedidos_cargados +
                  cargaporsector.total_pedidos_pendientes)) *
              100
            ).toFixed(0)
          }}%
          <span class="miniDetalle">Cargado</span>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ReactiveBarChart from "@/components/Charts/PieChart.js";
export default {
  name: "RampasPieGrafica",
  components: {
    ReactiveBarChart
  },
  data() {
    return {
      chartData: null
    };
  },
  props: ["varColumnas", "cargaporsector", "varPedidoRampasGlobal"],
  watch: {
    varPedidoRampasGlobal: function(newVal, oldVal) {
      // watch it
      this.segmentar(newVal);
      // this.$notify({
      //   type: "danger",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      // });
    }
  },
  methods: {
    generateData() {
      if (this.cargaporsector.total_pedidos_pendientes > 0) {
        this.cargaporsector.total_pedidos_cargados =
          this.cargaporsector.total_pedidos_cargados + 1;
        this.cargaporsector.total_pedidos_pendientes =
          this.cargaporsector.total_pedidos_pendientes - 1;
        //colores
        var canvas = $("#teamDoughnutChart");
        var ctx = document
          .getElementById("pie-chart")
          .getContext("2d")
          .canvas.getContext("2d");
        //backgrounds
        var gradientColors = [
          {
            start: "#ddf8ec",
            end: "#41B883"
          },
          {
            start: "#fdccc4",
            end: "#e46953"
          },
          {
            start: "#fff9c4 ",
            end: "#7e57c2  "
          }
        ];

        var gradients = [];

        gradientColors.forEach(function(item) {
          var gradient = ctx.createLinearGradient(0, 0, 150, 150);
          gradient.addColorStop(0, item.start);
          gradient.addColorStop(1, item.end);
          gradients.push(gradient);
        });
        //borders
        var gradientColors2 = [
          {
            start: "#fff",
            end: "#41b883"
          },
          {
            start: "#fff",
            end: "#e46953"
          },
          {
            start: "#fff",
            end: "#7e57c2 "
          }
        ];

        var gradients2 = [];

        gradientColors2.forEach(function(item) {
          var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
          gradient2.addColorStop(0, item.start);
          gradient2.addColorStop(1, item.end);
          gradients2.push(gradient2);
        });

        //
        this.chartData = {
          datasets: [
            {
              borderColor: gradients2,
              borderWidth: ["5", "5"],
              backgroundColor: gradients,
              data: [
                this.cargaporsector.total_pedidos_cargados,
                this.cargaporsector.total_pedidos_pendientes
              ]
            }
          ]
        };
      }
    },
    segmentar: function(pedido) {
      // console.log(pedido);
      if (this.cargaporsector.pedido_sector === pedido.DELIVERY_CLIENT_REGION) {
        this.cargaporsector.total_pedidos_cargados =
          this.cargaporsector.total_pedidos_cargados + 1;
        this.cargaporsector.total_pedidos_pendientes =
          this.cargaporsector.total_pedidos_pendientes - 1;
        //colores
        var canvas = $("#teamDoughnutChart");
        var ctx = document
          .getElementById("pie-chart")
          .getContext("2d")
          .canvas.getContext("2d");
        //backgrounds
        var gradientColors = [
          {
            start: "#ddf8ec",
            end: "#41B883"
          },
          {
            start: "#fdccc4",
            end: "#e46953"
          },
          {
            start: "#fff9c4 ",
            end: "#7e57c2  "
          }
        ];

        var gradients = [];

        gradientColors.forEach(function(item) {
          var gradient = ctx.createLinearGradient(0, 0, 150, 150);
          gradient.addColorStop(0, item.start);
          gradient.addColorStop(1, item.end);
          gradients.push(gradient);
        });
        //borders
        var gradientColors2 = [
          {
            start: "#fff",
            end: "#41b883"
          },
          {
            start: "#fff",
            end: "#e46953"
          },
          {
            start: "#fff",
            end: "#7e57c2 "
          }
        ];

        var gradients2 = [];

        gradientColors2.forEach(function(item) {
          var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
          gradient2.addColorStop(0, item.start);
          gradient2.addColorStop(1, item.end);
          gradients2.push(gradient2);
        });

        //
        this.chartData = {
          datasets: [
            {
              borderColor: gradients2,
              borderWidth: ["5", "5"],
              backgroundColor: gradients,
              data: [
                this.cargaporsector.total_pedidos_cargados,
                this.cargaporsector.total_pedidos_pendientes
              ]
            }
          ]
        };
      }
    }
  },
  mounted() {
    // setInterval(this.generateData, 6000);
    // console.log(this.cargaporsector);
    //colores
    var canvas = $("#teamDoughnutChart");
    var ctx = document
      .getElementById("pie-chart")
      .getContext("2d")
      .canvas.getContext("2d");
    //backgrounds
    var gradientColors = [
      {
        start: "#ddf8ec",
        end: "#41B883"
      },
      {
        start: "#fdccc4",
        end: "#e46953"
      },
      {
        start: "#fff9c4 ",
        end: "#7e57c2  "
      }
    ];

    var gradients = [];

    gradientColors.forEach(function(item) {
      var gradient = ctx.createLinearGradient(0, 0, 150, 150);
      gradient.addColorStop(0, item.start);
      gradient.addColorStop(1, item.end);
      gradients.push(gradient);
    });
    //borders
    var gradientColors2 = [
      {
        start: "#fff",
        end: "#41b883"
      },
      {
        start: "#fff",
        end: "#e46953"
      },
      {
        start: "#fff",
        end: "#7e57c2 "
      }
    ];

    var gradients2 = [];

    gradientColors2.forEach(function(item) {
      var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
      gradient2.addColorStop(0, item.start);
      gradient2.addColorStop(1, item.end);
      gradients2.push(gradient2);
    });

    //

    this.chartData = {
      labels: ["Cargados", "Pendientes"],
      options: {
        legend: {
          display: false
        }
      },
      datasets: [
        {
          borderColor: gradients2,
          borderWidth: ["5", "5"],
          backgroundColor: gradients,
          data: [
            this.cargaporsector.total_pedidos_cargados,
            this.cargaporsector.total_pedidos_pendientes
          ]
        }
      ]
    };

    //cuando pedido es actualizado
    //si la ruta actual es de graficas, escuchar cuando un pedido es cargado.
    if (!this.varPedidoRampasGlobal) {
      const { Cargarpedido } = this.$FeathersVuex.api;
      Cargarpedido.on("patched", pedido => {
        this.segmentar(pedido);
        console.log(pedido);
      });
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.detalleSector {
  font-size: 56px;
}
.miniDetalle {
  font-size: 38px;
}
.main-panel {
  border-top: none !important;
}
body > div > div.wrapper > div > div[data-v-35cffe15] {
  padding: 0px 30px 30px 30px !important;
}
</style>
