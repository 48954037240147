// src/store/services/rampas.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class RutasCampania extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "RutasCampania";
  // Define default properties here
  static instanceDefaults() {
    return {
      ROUTE_ID: ""
    };
  }
}
const servicePath = "rutas-campania";
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: "RutasCampania",
  Model: RutasCampania,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
