var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('span',[_vm._v("información del teléfono del piloto: "),_c('strong',[_vm._v(_vm._s(_vm.usuario.usuario_nombre)+" ")]),_c('strong',{attrs:{"title":"Código de usuario"}},[_vm._v("("+_vm._s(_vm.usuario.usuario_codigo)+")")])]),_c('p',{staticClass:"mb-3"},[_c('span',[_c('span',{staticClass:"mdi mdi-24px mdi-signal"}),_vm._v(" Señal: "),_c('strong',[_vm._v(_vm._s(_vm.usuario.PILOTO_SENAL))])]),_c('br'),_c('span',[_c('span',{staticClass:"mdi mdi-24px mdi-sd"}),_vm._v(" Espacio disponible: "),_c('strong',[_vm._v(_vm._s(_vm.usuario.PILOTO_ESPACIO)+" GB")])]),_c('br'),_c('span',[_c('span',{class:'mdi mdi-24px ' + _vm.fnGetBaatteryIcon(100)}),_vm._v(" Batería: "),_c('strong',[_vm._v(" "+_vm._s(_vm.usuario.PILOTO_BATERIA)+" %")])]),_c('br'),_c('span',[_c('span',{class:'mdi mdi-24px mdi-cellphone'}),_vm._v(" Marca: "),_c('strong',[_vm._v(" "+_vm._s(_vm.usuario.PILOTO_MARCA))])]),_c('br'),_c('span',[_c('span',{class:'mdi mdi-24px mdi-cellphone'}),_vm._v(" Modelo: "),_c('strong',[_vm._v(" "+_vm._s(_vm.usuario.PILOTO_MODELO))])]),_c('br'),_c('span',[_c('span',{class:[
                    'mdi mdi-24px',
                    _vm.usuario.PILOTO_GPS_FLAG
                      ? 'text-success mdi-map-marker-check'
                      : 'text-danger mdi-map-marker-alert',
                  ]}),_vm._v(" GPS: "),(_vm.usuario.PILOTO_GPS_FLAG == 0)?_c('strong',{staticClass:"text-danger",attrs:{"title":_vm.getDate(_vm.usuario.PILOTO_GPS_FECHA)}},[_vm._v("Apagado")]):_vm._e(),(_vm.usuario.PILOTO_GPS_FLAG == 1)?_c('strong',{staticClass:"text-success",attrs:{"title":_vm.getDate(_vm.usuario.PILOTO_GPS_FECHA)}},[_vm._v("Encendido")]):_vm._e()])]),_c('footer',{staticClass:"blockquote-footer"},[_vm._v(" Ultimo registro, capturado el día "),_c('cite',{attrs:{"title":"Fecha de captura de información del perfil del dispositivo"}},[_vm._v(_vm._s(_vm.getDate(_vm.usuario.updatedAt)))])]),_c('br')])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('p',[_c('span',[_vm._v("Histórico ")]),_c('br'),_c('span',{staticClass:"font-weight-light"},[_vm._v("Total: "+_vm._s(_vm.optionsVTable.view_total_registros_resumen_status))])]),_c('footer',{staticClass:"blockquote-footer"},[_c('cite',{attrs:{"title":"Filtrado de columnas"}},[_vm._v("Las columnas \"BATERÍA (%)\" y \"ESPACIO (GB)\" pueden filtrarse utilizando los signos de comparación '<', '>', <=' y '>='")]),_c('br'),_c('button',{staticClass:"outline-btn",on:{"click":function($event){return _vm.fnShowEjemplo2()}}},[_vm._v(" Ver ejemplo ")]),_c('download-excel',{staticClass:"outline-btn-excel pull-right",staticStyle:{"cursor":"pointer","margin-top":"-15px"},attrs:{"fields":_vm.columnasEXCLsector,"before-generate":() => {
                    return _vm.startDownload();
                  },"fetch":() => {
                    return _vm.fnExportarRegistrosCelularesDePilotos();
                  },"type":_vm.dataExportType,"before-finish":_vm.finishDownload,"name":`información del teléfono del piloto ${_vm.usuario.usuario_nombre} (${_vm.usuario.usuario_codigo})${_vm.dataExportType}`}},[_vm._v("Descargar Excel")])],1),_c('v-server-table',{ref:"tablaHistoricoStatus",attrs:{"columns":_vm.columns,"options":_vm.optionsVTable},scopedSlots:_vm._u([{key:"createdAt",fn:function(props){return _c('span',{},[_c('span',{attrs:{"title":_vm.getDate(props.row.createdAt)}},[_vm._v(_vm._s(_vm._f("moment")(props.row.createdAt,"DD-MM-YYYY")))])])}},{key:"piloto_MARCA",fn:function(props){return _c('span',{attrs:{"title":props.row.piloto_MARCA}},[_vm._v(" "+_vm._s(props.row.piloto_MARCA)+" ")])}},{key:"piloto_MODELO",fn:function(props){return _c('span',{attrs:{"title":props.row.piloto_MODELO}},[_vm._v(" "+_vm._s(props.row.piloto_MODELO)+" ")])}},{key:"piloto_GPS_FLAG",fn:function(props){return _c('div',{},[(props.row.piloto_GPS_FLAG == 0)?_c('span',{staticClass:"text-danger"},[_vm._v("Apagado")]):_vm._e(),(props.row.piloto_GPS_FLAG == 1)?_c('span',{staticClass:"text-success"},[_vm._v("Encendido")]):_vm._e()])}},{key:"opciones",fn:function(props){return _c('div',{},[_c('a',{staticClass:"btn",attrs:{"href":'/dispositivos_detalle/' + props.row.id,"target":"_blank","title":"Ver detalle de dispositivos"}},[_c('span',{staticClass:"mdi mdi-24px mdi-format-list-bulleted-square"})])])}}])})],1)])])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-text"},[_c('dispositivosDetalleAplicaciones',{attrs:{"piloto_usuario_id":_vm.$route.params.id_usuario}})],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }