<template>
  <!-- mostrar informacion de entrega  // modal info entrega-->
  <DragItDude
    ref="ventanaInformacionDeEntrega"
    id="ventanaInformacionDeEntrega"
    class="modal-dialog"
    v-if="ventanaInformacionDeEntrega !== null"
    :style="[
      windowPcVersion
        ? historico == false
          ? { 'min-width': '620px', width: '620px' }
          : { 'min-width': '850px' }
        : { 'min-width': '1050px' },
      { top: '7px' },
      { left: '15px' }
    ]"
  >
    <div class="modal-content">
      <!-- <div class="modal-header">
        Pedido número: {{ ventanaInformacionDeEntrega.ERP_DOC }}
        <img
          width="185px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute;"
        />
      </div>-->
      <div
        :style="[
          windowPcVersion ? { height: '50px' } : { 'height:': '45px' },
          { 'background-color': '#031D6A' }
        ]"
      >
        <!-- switch y titulo encab ezado -->
        <div class="switchEncabezado">
          <!-- switch -->
          <div class="custom-control custom-switch">
            <input
              @click="toggleWindowVersion()"
              type="checkbox"
              class="custom-control-input"
              id="switchDetalleEntrega"
            />
            <label
              class="custom-control-label"
              for="switchDetalleEntrega"
            ></label>
          </div>
          <!-- fin switch -->
          <span
            :class="[
              windowPcVersion ? 'modal-encabezadoPc' : 'modal-encabezadoTv'
            ]"
            >Pedido número: {{ ventanaInformacionDeEntrega.ERP_DOC }}</span
          >
        </div>
        <!-- fin -->
        <img
          :width="[windowPcVersion ? '85px' : '185px']"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
        <br />
      </div>
      <div class="modal-body">
        <div class="row">
          <div :class="historico ? 'col-md-5' : 'col-md-8'">
            <table>
              <tbody>
                <!-- <tr v-if="ventanaInformacionDeEntrega.DELIVERY_ORDER_SEQUENCE">
                  <td>Correlativo de entrega:</td>
                  <td>
                    <strong>
                      {{
                      ventanaInformacionDeEntrega.DELIVERY_ORDER_SEQUENCE
                      }}
                    </strong>
                  </td>
                </tr>-->
                <tr class="mt-5">
                  <td>CONSEJER@:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERY_CLIENT_NAME }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Teléfono:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERY_CLIENT_PHONE }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.DELIVERIED_TO_PHONE">
                  <td>Teléfono de entrega:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERIED_TO_PHONE }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Dirección:</td>
                  <td>
                    <strong
                      :style="[windowPcVersion ? { 'font-size': '10px' } : {}]"
                    >
                      {{ ventanaInformacionDeEntrega.DELIVERY_CLIENT_ADDRESS }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Contenido:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.TOTAL_PACKAGES }} bultos:
                      {{
                        ventanaInformacionDeEntrega.TOTAL_PACKAGES -
                          ventanaInformacionDeEntrega.PRIZES -
                          ventanaInformacionDeEntrega.HOME
                      }}
                      cajas,
                      {{ ventanaInformacionDeEntrega.PRIZES }} premios Y
                      {{ ventanaInformacionDeEntrega.HOME }} hogar.
                    </strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.STATUS >= 1 &&
                      ventanaInformacionDeEntrega.supervisor_de_carga &&
                      !historico
                  "
                >
                  <td>Cuadrador:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.supervisor_de_carga }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 1">
                  <td>Empresa:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERY_COMPANY_NAME }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 1">
                  <td>Ruta:</td>
                  <td>
                    <strong>{{ ventanaInformacionDeEntrega.ROUTE_ID }}</strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 1">
                  <td>Piloto que cargó:</td>
                  <td>
                    <strong title="piloto">
                      {{
                        ventanaInformacionDeEntrega.DELIVERY_ASSIGNED_TO_DRIVER_NAME
                      }}
                    </strong>
                    <strong title="placa"
                      >({{
                        ventanaInformacionDeEntrega.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES
                      }})</strong
                    >
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 2">
                  <td>Piloto que entregó:</td>
                  <td>
                    <strong title="piloto">
                      {{ ventanaInformacionDeEntrega.DELIVERY_DRIVER_NAME }}
                    </strong>
                    <strong title="placa">
                      ({{
                        ventanaInformacionDeEntrega.DELIVERY_VEHICULE_PLATES
                      }})
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 1">
                  <td>Manifiesto:</td>
                  <td>
                    <strong>{{
                      ventanaInformacionDeEntrega.MANIFEST_DB_ID
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Estado:</td>
                  <td>
                    <strong
                      :style="'color:' + deliveryTextColor + '!important;'"
                      >{{ ventanaInformacionDeEntrega.STATUSTEXT }}</strong
                    >
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 2">
                  <td>El día:</td>
                  <td>
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.DELIVERY_DATE
                          | moment("add", "", "DD-MM-YYYY")
                      }}
                    </strong>
                    a las:
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.DELIVERY_DATE
                          | moment("add", "", "HH:mm:ss")
                      }}
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 2">
                  <td>Coordenadas de entrega:</td>
                  <td>
                    latitud:
                    <strong>{{
                      ventanaInformacionDeEntrega.DELIVERY_LAT
                    }}</strong>
                    longitud:
                    <strong>{{
                      ventanaInformacionDeEntrega.DELIVERY_LON
                    }}</strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS == 2">
                  <td>Detalles de entrega</td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS == 2">
                  <td>¿Zona roja?</td>
                  <td>
                    <strong>
                      <span
                        v-if="
                          ventanaInformacionDeEntrega.DELIVERIED_TO_AREA_ROJA ==
                            1
                        "
                        >Si</span
                      >
                      <span v-else>No</span>
                    </strong>
                  </td>
                </tr>
                <tr v-if="ventanaInformacionDeEntrega.STATUS == 2">
                  <td>¿Pedido recibido por el titular del pedido?</td>
                  <td>
                    <strong
                      v-if="
                        ventanaInformacionDeEntrega.DELIVERIED_TO_OWNER == 1
                      "
                    >
                      <span>Si</span>
                    </strong>
                    <strong v-else>No</strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.DELIVERIED_TO_OWNER != 1 &&
                      ventanaInformacionDeEntrega.STATUS == 2
                  "
                >
                  <td>Entregado a la persona:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERIED_TO_NAME }}
                    </strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.DELIVERIED_TO_OWNER != 1 &&
                      ventanaInformacionDeEntrega.STATUS == 2
                  "
                >
                  <td>Relación:</td>
                  <td>
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.DELIVERIED_TO_RELATIONSHIP
                      }}
                    </strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.DELIVERIED_TO_OWNER != 1 &&
                      ventanaInformacionDeEntrega.STATUS == 2
                  "
                >
                  <td>No. DPI:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERIED_TO_DPI }}
                    </strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.DELIVERIED_TO_OWNER != 1 &&
                      ventanaInformacionDeEntrega.STATUS == 2
                  "
                >
                  <td style="word-break: keep-all">
                    Número de teléfono en la entrega:
                  </td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.DELIVERIED_TO_PHONE }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="historico" class="col-md-3">
            <table>
              <tbody class="secbar">
                <tr>
                  <td>Picking :</td>
                  <td>
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.CREATED
                          | moment("add", "", "DD-MM-YYYY")
                      }}
                    </strong>
                    a las:
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.CREATED
                          | moment("utc", "HH:mm:ss a")
                      }}
                    </strong>
                  </td>
                </tr>

                <tr v-if="ventanaInformacionDeEntrega.STATUS >= 1">
                  <td>Carga :</td>
                  <td>
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.UPLOADED_DATE
                          | moment("utc", "add", "", "DD-MM-YYYY")
                      }}
                    </strong>
                    a las:
                    <strong>
                      {{
                        ventanaInformacionDeEntrega.UPLOADED_DATE
                          | moment("utc", "HH:mm:ss a")
                      }}
                    </strong>
                  </td>
                </tr>

                <tr v-else>
                  <td>Carga:</td>
                  <td>
                    <small>N/A</small>
                  </td>
                </tr>
                <tr
                  v-if="
                    ventanaInformacionDeEntrega.STATUS >= 1 &&
                      ventanaInformacionDeEntrega.supervisor_de_carga
                  "
                >
                  <td>Cuadrador:</td>
                  <td>
                    <strong>
                      {{ ventanaInformacionDeEntrega.supervisor_de_carga }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4">
            <zoom-on-hover
              v-if="ventanaInformacionDeEntrega.DELIVERY_IMAGE !== null"
              id="infoEntregaDELIVERY_IMAGE"
              :scale="7"
              :img-normal="
                VUE_APP_API_URL + ventanaInformacionDeEntrega.DELIVERY_IMAGE
              "
              :img-zoom="
                VUE_APP_API_URL + ventanaInformacionDeEntrega.DELIVERY_IMAGE
              "
            ></zoom-on-hover>
            <!-- // -->
            <p v-if="ventanaInformacionDeEntrega.STATUS == 2">
              <label for>Firma de recibido:</label>
              <br />
              <img
                id="infoEntregaDELIVERIED_SIGNATURE"
                :src="
                  VUE_APP_API_URL +
                    ventanaInformacionDeEntrega.DELIVERIED_SIGNATURE
                "
                alt
                class="responsive-img"
              />
            </p>
            <p v-else style="background: none; text-align: center">
              <img
                width="215px"
                src="/img/logoLeCleire.png"
                alt
                class="img-fluid"
                style
              />
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-sm btn-succes btn-float-right"
          @click="fnResetWindow()"
        >
          Cerrar ventana
        </button>
      </div>
    </div>
  </DragItDude>
  <!-- fin mostrar informacion de entrega -->
</template>
<script>
import DragItDude from "vue-drag-it-dude";
export default {
  data() {
    return {
      windowPcVersion: true
    };
  },
  name: "ventanaDetalleEntrega",
  props: ["ventanaInformacionDeEntrega", "deliveryTextColor", "historico"],
  components: { DragItDude },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    }
  },
  methods: {
    //
    toggleWindowVersion() {
      console.log(this.windowPcVersion);
      this.windowPcVersion = !this.windowPcVersion;
    },
    //
    fnResetWindow() {
      console.log("party");
      this.$emit("clicked");
    }
  }
};
</script>

<style scoped>
/* //franjas en tabla */
tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* //ancho width primera columna */
thead tr th:first-child,
tbody tr td:first-child {
  width: 15em;
  min-width: 15em;
  max-width: 15em;
  font-size: 9px;
  word-break: break-all;
}
thead.secbar tr th:first-child,
tbody.secbar tr td:first-child {
  width: 7em;
  min-width: 7em;
  max-width: 7em;
  font-size: 9px;
  word-break: break-all;
}
/* //ancho width segunda columna */
thead tr th:nth-child(2),
tbody tr td:nth-child(2) {
  font-size: 11px;
}

/* //modal-header encabezado */
.switchEncabezado {
  margin-left: 25px;
}
.switchEncabezado > .custom-switch {
  margin-left: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
}
/** encabezado de vista TV */
.modal-encabezadoTv {
  margin-top: 15px;
  color: white;
  font-size: 24px;
}
.modal-encabezadoPc {
  margin-top: 5px;
  color: white;
  font-size: 13px;
}
/* //modal footer */
.modal-footer {
  padding-top: 0px !important;
}
</style>
