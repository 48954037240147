<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <div class="card" style="">
          <div class="card-body">
            <div class="card-text">
              <span
                >información del teléfono del piloto:
                <strong>{{ usuario.usuario_nombre }} </strong
                ><strong title="Código de usuario"
                  >({{ usuario.usuario_codigo }})</strong
                ></span
              >
              <p class="mb-3">
                <span
                  ><span class="mdi mdi-24px mdi-signal"></span> Señal:
                  <strong>{{ usuario.PILOTO_SENAL }}</strong></span
                >
                <br />
                <span
                  ><span class="mdi mdi-24px mdi-sd"></span> Espacio disponible:
                  <strong>{{ usuario.PILOTO_ESPACIO }} GB</strong></span
                >
                <br />
                <span
                  ><span
                    :class="'mdi mdi-24px ' + fnGetBaatteryIcon(100)"
                  ></span>
                  Batería:
                  <strong> {{ usuario.PILOTO_BATERIA }} %</strong></span
                >
                <br />
                <span
                  ><span :class="'mdi mdi-24px mdi-cellphone'"></span> Marca:
                  <strong> {{ usuario.PILOTO_MARCA }}</strong></span
                >
                <br />
                <span
                  ><span :class="'mdi mdi-24px mdi-cellphone'"></span> Modelo:
                  <strong> {{ usuario.PILOTO_MODELO }}</strong></span
                >
                <br />
                <span
                  ><span
                    :class="[
                      'mdi mdi-24px',
                      usuario.PILOTO_GPS_FLAG
                        ? 'text-success mdi-map-marker-check'
                        : 'text-danger mdi-map-marker-alert',
                    ]"
                  ></span>
                  GPS:

                  <strong
                    :title="getDate(usuario.PILOTO_GPS_FECHA)"
                    v-if="usuario.PILOTO_GPS_FLAG == 0"
                    class="text-danger"
                    >Apagado</strong
                  >
                  <strong
                    :title="getDate(usuario.PILOTO_GPS_FECHA)"
                    v-if="usuario.PILOTO_GPS_FLAG == 1"
                    class="text-success"
                    >Encendido</strong
                  ></span
                >
              </p>
              <footer class="blockquote-footer">
                Ultimo registro, capturado el día
                <cite
                  title="Fecha de captura de información del perfil del dispositivo"
                  >{{ getDate(usuario.updatedAt) }}</cite
                >
              </footer>
              <br />
            </div>
          </div>
        </div>
        <div class="card" style="">
          <div class="card-body">
            <div class="card-text">
              <p>
                <span>Histórico </span><br />
                <span class="font-weight-light"
                  >Total:
                  {{ optionsVTable.view_total_registros_resumen_status }}</span
                >
              </p>
              <footer class="blockquote-footer">
                <cite title="Filtrado de columnas"
                  >Las columnas "BATERÍA (%)" y "ESPACIO (GB)" pueden filtrarse
                  utilizando los signos de comparación '&#60;', '&#62;', &#60;='
                  y '&#62;='</cite
                ><br />
                <button @click="fnShowEjemplo2()" class="outline-btn">
                  Ver ejemplo
                </button>
                <download-excel
                  class="outline-btn-excel pull-right"
                  style="cursor: pointer; margin-top: -15px"
                  :fields="columnasEXCLsector"
                  :before-generate="
                    () => {
                      return startDownload();
                    }
                  "
                  :fetch="
                    () => {
                      return fnExportarRegistrosCelularesDePilotos();
                    }
                  "
                  :type="dataExportType"
                  :before-finish="finishDownload"
                  :name="`información del teléfono del piloto ${usuario.usuario_nombre} (${usuario.usuario_codigo})${dataExportType}`"
                  >Descargar Excel</download-excel
                >
              </footer>
              <v-server-table
                :columns="columns"
                :options="optionsVTable"
                ref="tablaHistoricoStatus"
              >
                <span slot="createdAt" slot-scope="props">
                  <span :title="getDate(props.row.createdAt)">{{
                    props.row.createdAt | moment("DD-MM-YYYY")
                  }}</span>
                </span>
                <span
                  :title="props.row.piloto_MARCA"
                  slot="piloto_MARCA"
                  slot-scope="props"
                >
                  {{ props.row.piloto_MARCA }}
                </span>
                <span
                  :title="props.row.piloto_MODELO"
                  slot="piloto_MODELO"
                  slot-scope="props"
                >
                  {{ props.row.piloto_MODELO }}
                </span>
                <div slot="piloto_GPS_FLAG" slot-scope="props">
                  <span
                    v-if="props.row.piloto_GPS_FLAG == 0"
                    class="text-danger"
                    >Apagado</span
                  >
                  <span
                    v-if="props.row.piloto_GPS_FLAG == 1"
                    class="text-success"
                    >Encendido</span
                  >
                </div>
                <div slot="opciones" slot-scope="props">
                  <a
                    class="btn"
                    :href="'/dispositivos_detalle/' + props.row.id"
                    target="_blank"
                    title="Ver detalle de dispositivos"
                  >
                    <span
                      class="mdi mdi-24px mdi-format-list-bulleted-square"
                    ></span>
                  </a>
                </div>
              </v-server-table>
            </div>
          </div>
        </div>
      </div>
      <!-- fin columna izquierda -->
      <div class="col-md-5">
        <div class="card" style="">
          <div class="card-body">
            <div class="card-text">
              <dispositivosDetalleAplicaciones
                :piloto_usuario_id="$route.params.id_usuario"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import JsonExcel from "vue-json-excel";

import dispositivosDetalleAplicaciones from "./dispositivos_detalle_aplicaciones_lista.vue";

export default {
  components: {
    dispositivosDetalleAplicaciones,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      //view vars
      //
      usuario: {},
      view_total_apagados: 0,
      // export excel vars
      dataExportType: "xls",
      columnasEXCLsector: {
        Día: "createdAt",
        Piloto: "piloto_nombre",
        Placa: "piloto_placa",
        Marca: "piloto_MARCA",
        Modelo: "piloto_MODELO",
        Señal: "piloto_SENAL",
        "Bateria (%)": "piloto_BATERIA",
        "Espacio (GB)": "piloto_ESPACIO",
        Evento: "piloto_EVENTO",
      },
      // tabla vars
      perPage: 10,
      columns: [
        "createdAt",
        "piloto_nombre",
        "piloto_placa",
        "piloto_MARCA",
        "piloto_MODELO",
        "piloto_SENAL",
        "piloto_ESPACIO",
        "piloto_BATERIA",
        "piloto_EVENTO",
      ],
      optionsVTable: {
        view_total_registros_resumen_status: 0,
        view_ultimo_registros_resumen_status: 0,
        orderBy: {
          column: "createdAt",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["createdAt", "fecha_validacion"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "piloto_nombre",
          "piloto_placa",
          "piloto_MARCA",
          "piloto_MODELO",
          "piloto_SENAL",
          "piloto_ESPACIO",
          "piloto_BATERIA",
          "piloto_EVENTO",
          "fecha_validacion",
        ],
        headings: {
          piloto_nombre: "Piloto nombre",
          piloto_placa: "Placa",
          piloto_MARCA: "Marca",
          piloto_MODELO: "Modelo",
          piloto_SENAL: "Señal",
          piloto_BATERIA: "(%) Batería",
          piloto_ESPACIO: "Espacio (GB)",
          createdAt: "Día",
          opciones: "Aplicaciones",
          piloto_EVENTO: "Evento",
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          // search += `, "reportebultosvalidados_id": "${this.idDelReporte}"`;
          if (data.query) {
            //
            if (data.query["piloto_nombre"]) {
              search += `, "piloto_nombre[$like]": "%${data.query["piloto_nombre"]}%"`;
            }
            //
            //
            if (data.query["piloto_placa"]) {
              search += `, "piloto_placa[$like]": "%${data.query["piloto_placa"]}%"`;
            }
            //
            if (data.query["piloto_MARCA"]) {
              search += `, "piloto_MARCA[$like]": "%${data.query["piloto_MARCA"]}%"`;
            } //
            if (data.query["piloto_MODELO"]) {
              search += `, "piloto_MODELO[$like]": "%${data.query["piloto_MODELO"]}%"`;
            }
            //
            if (data.query["piloto_SENAL"]) {
              search += `, "piloto_SENAL[$like]": "%${data.query["piloto_SENAL"]}%"`;
            }
            //
            if (data.query["piloto_EVENTO"]) {
              search += `, "piloto_EVENTO[$like]": "%${data.query["piloto_EVENTO"]}%"`;
            }
            //
            // buscar porcentaje de bateria por medio de caracteres especiales > < >= <=

            if (data.query["piloto_BATERIA"]) {
              // 1) eliminar todos los espacios en blanco ingresados por el usuario
              let no_white_spaces = data.query["piloto_BATERIA"].replace(
                /\s+/g,
                ""
              );

              //1.2) validar si la cadena de caracteres es lo suficientemente larga
              if (no_white_spaces.length >= 2) {
                //1.3) remover cualquier caracter especial para no afectar a la busqueda
                // Solo cuando este "caracter especial" se encuentre al final de la cadena de caracteres
                let special_symbol_flag = no_white_spaces.substr(
                  no_white_spaces.length - 1
                );
                if (special_symbol_flag == "." || special_symbol_flag == ",") {
                  no_white_spaces = no_white_spaces.slice(0, -1);
                }
                // 2) identificar simbolo de coparación
                // 2.1) sección de mayor, mayor o igual
                if (no_white_spaces.substring(0, 2) == ">=") {
                  //2.1.1) filtrar registros con porcentaje de bateria "mayor o igual que" %
                  if (no_white_spaces.length >= 3) {
                    let without_comparation_symbol_number =
                      no_white_spaces.substring(2);
                    search += `, "piloto_BATERIA[$gte]": ${without_comparation_symbol_number}`;
                  } else {
                  }
                } else if (no_white_spaces.substring(0, 1) == ">") {
                  //2.1.2) filtrar registros con porcentaje de bateria "mayor que" %
                  let without_comparation_symbol_number =
                    no_white_spaces.substring(1);
                  search += `, "piloto_BATERIA[$gt]": ${without_comparation_symbol_number}`;
                }
                // 2.2) sección de menor, menor o igual
                if (no_white_spaces.substring(0, 2) == "<=") {
                  //2.2.1) filtrar registros con porcentaje de bateria "menor o igual que" %
                  if (no_white_spaces.length >= 3) {
                    let without_comparation_symbol_number =
                      no_white_spaces.substring(2);
                    search += `, "piloto_BATERIA[$lte]": ${without_comparation_symbol_number}`;
                  } else {
                  }
                } else if (no_white_spaces.substring(0, 1) == "<") {
                  //2.2.2) filtrar registros con porcentaje de bateria "menor que" %
                  let without_comparation_symbol_number =
                    no_white_spaces.substring(1);
                  search += `, "piloto_BATERIA[$lt]": ${without_comparation_symbol_number}`;
                }
              } else {
                // console.log("cadena muy pequeña");
              }
            }

            // buscar porcentaje de almacenamiento por medio de caracteres especiales > < >= <=

            if (data.query["piloto_ESPACIO"]) {
              // 1) eliminar todos los espacios en blanco ingresados por el usuario
              let no_white_spaces = data.query["piloto_ESPACIO"].replace(
                /\s+/g,
                ""
              );

              //1.2) validar si la cadena de caracteres es lo suficientemente larga
              if (no_white_spaces.length >= 2) {
                //1.3) remover cualquier caracter especial para no afectar a la busqueda
                // Solo cuando este "caracter especial" se encuentre al final de la cadena de caracteres
                let special_symbol_flag = no_white_spaces.substr(
                  no_white_spaces.length - 1
                );
                if (special_symbol_flag == "." || special_symbol_flag == ",") {
                  no_white_spaces = no_white_spaces.slice(0, -1);
                }
                // 2) identificar simbolo de coparación
                // 2.1) sección de mayor, mayor o igual
                if (no_white_spaces.substring(0, 2) == ">=") {
                  //2.1.1) filtrar registros con porcentaje de bateria "mayor o igual que" %
                  if (no_white_spaces.length >= 3) {
                    let without_comparation_symbol_number =
                      no_white_spaces.substring(2);
                    search += `, "piloto_ESPACIO[$gte]": ${without_comparation_symbol_number}`;
                  } else {
                  }
                } else if (no_white_spaces.substring(0, 1) == ">") {
                  //2.1.2) filtrar registros con porcentaje de bateria "mayor que" %
                  let without_comparation_symbol_number =
                    no_white_spaces.substring(1);
                  search += `, "piloto_ESPACIO[$gt]": ${without_comparation_symbol_number}`;
                }
                // 2.2) sección de menor, menor o igual
                if (no_white_spaces.substring(0, 2) == "<=") {
                  //2.2.1) filtrar registros con porcentaje de bateria "menor o igual que" %
                  if (no_white_spaces.length >= 3) {
                    let without_comparation_symbol_number =
                      no_white_spaces.substring(2);
                    search += `, "piloto_ESPACIO[$lte]": ${without_comparation_symbol_number}`;
                  } else {
                  }
                } else if (no_white_spaces.substring(0, 1) == "<") {
                  //2.2.2) filtrar registros con porcentaje de bateria "menor que" %
                  let without_comparation_symbol_number =
                    no_white_spaces.substring(1);
                  search += `, "piloto_ESPACIO[$lt]": ${without_comparation_symbol_number}`;
                }
              } else {
                // console.log("cadena muy pequeña");
              }
            }

            if (data.query["createdAt"]) {
              search += `, "createdAt[$gte]": "${data.query.createdAt.start}"`;
              search += `, "createdAt[$lte]": "${data.query.createdAt.end}"`;
            }
            if (data.query["fecha_validacion"]) {
              search += `, "fecha_validacion[$gte]": "${data.query.fecha_validacion.start}"`;
              search += `, "fecha_validacion[$lte]": "${data.query.fecha_validacion.end}"`;
            }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/pilotos-dispositivos-status?id_usuario=${this.$route.params.id_usuario}`;
          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          //asignar total de usuarios consultados
          this.options.view_total_registros_resumen_status = resp.data.total;
          this.options.view_ultimo_registros_resumen_status = resp.data.data[0];
          //asignar total de dispositivos con GPS desconectado
          this.$parent.fnMostrarPilotosConGpsApagado();

          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
      },
    };
  },
  methods: {
    // para mandar a traer datos de excel
    ...mapActions("PilotosDispositivosStatus", {
      findPilotosDispositivosStatus: "find",
    }),
    // funciones excel
    startDownload() {},
    finishDownload() {},
    getParams() {
      /** */
      const params = {};
      params["id_usuario"] = this.$route.params.id_usuario;
      /** FILTRO BATERIA DEL PILOTO */
      if (this.$refs.tablaHistoricoStatus.query.piloto_BATERIA != "") {
        /** transformacionpara parametros de búsqueda */
        let no_white_spaces =
          this.$refs.tablaHistoricoStatus.query.piloto_BATERIA.replace(
            /\s+/g,
            ""
          );
        /** FILTRO BATERIA DEL PILOTO */
        if (no_white_spaces.length >= 2) {
          //1.3) remover cualquier caracter especial para no afectar a la busqueda
          // Solo cuando este "caracter especial" se encuentre al final de la cadena de caracteres
          let special_symbol_flag = no_white_spaces.substr(
            no_white_spaces.length - 1
          );
          if (special_symbol_flag == "." || special_symbol_flag == ",") {
            no_white_spaces = no_white_spaces.slice(0, -1);
          }
          // 2) identificar simbolo de coparación
          // 2.1) sección de mayor, mayor o igual
          if (no_white_spaces.substring(0, 2) == ">=") {
            //2.1.1) filtrar registros con porcentaje de bateria "mayor o igual que" %
            if (no_white_spaces.length >= 3) {
              let without_comparation_symbol_number =
                no_white_spaces.substring(2);
              params["piloto_BATERIA"] = {
                $gte: without_comparation_symbol_number,
              };
            } else {
            }
          } else if (no_white_spaces.substring(0, 1) == ">") {
            //2.1.2) filtrar registros con porcentaje de bateria "mayor que" %
            let without_comparation_symbol_number =
              no_white_spaces.substring(1);

            params["piloto_BATERIA"] = {
              $gt: without_comparation_symbol_number,
            };
          }
          // 2.2) sección de menor, menor o igual
          if (no_white_spaces.substring(0, 2) == "<=") {
            //2.2.1) filtrar registros con porcentaje de bateria "menor o igual que" %
            if (no_white_spaces.length >= 3) {
              let without_comparation_symbol_number =
                no_white_spaces.substring(2);
              params["piloto_BATERIA"] = {
                $lte: without_comparation_symbol_number,
              };
            } else {
            }
          } else if (no_white_spaces.substring(0, 1) == "<") {
            //2.2.2) filtrar registros con porcentaje de bateria "menor que" %
            let without_comparation_symbol_number =
              no_white_spaces.substring(1);
            params["piloto_BATERIA"] = {
              $lt: without_comparation_symbol_number,
            };
          }
        }
      }
      /** FIN FILTRO ESPACIO DEL PILOTO*/

      /** FILTRO ESPACIO DEL PILOTO */
      if (this.$refs.tablaHistoricoStatus.query.piloto_ESPACIO != "") {
        /** transformacionpara parametros de búsqueda */
        let no_white_spaces =
          this.$refs.tablaHistoricoStatus.query.piloto_ESPACIO.replace(
            /\s+/g,
            ""
          );
        /** FILTRO ESPACIO DEL PILOTO */
        if (no_white_spaces.length >= 2) {
          //1.3) remover cualquier caracter especial para no afectar a la busqueda
          // Solo cuando este "caracter especial" se encuentre al final de la cadena de caracteres
          let special_symbol_flag = no_white_spaces.substr(
            no_white_spaces.length - 1
          );
          if (special_symbol_flag == "." || special_symbol_flag == ",") {
            no_white_spaces = no_white_spaces.slice(0, -1);
          }
          // 2) identificar simbolo de coparación
          // 2.1) sección de mayor, mayor o igual
          if (no_white_spaces.substring(0, 2) == ">=") {
            //2.1.1) filtrar registros con porcentaje de ESPACIO "mayor o igual que" %
            if (no_white_spaces.length >= 3) {
              let without_comparation_symbol_number =
                no_white_spaces.substring(2);
              params["piloto_ESPACIO"] = {
                $gte: without_comparation_symbol_number,
              };
            } else {
            }
          } else if (no_white_spaces.substring(0, 1) == ">") {
            //2.1.2) filtrar registros con porcentaje de ESPACIO "mayor que" %
            let without_comparation_symbol_number =
              no_white_spaces.substring(1);

            params["piloto_ESPACIO"] = {
              $gt: without_comparation_symbol_number,
            };
          }
          // 2.2) sección de menor, menor o igual
          if (no_white_spaces.substring(0, 2) == "<=") {
            //2.2.1) filtrar registros con porcentaje de ESPACIO "menor o igual que" %
            if (no_white_spaces.length >= 3) {
              let without_comparation_symbol_number =
                no_white_spaces.substring(2);
              params["piloto_ESPACIO"] = {
                $lte: without_comparation_symbol_number,
              };
            } else {
            }
          } else if (no_white_spaces.substring(0, 1) == "<") {
            //2.2.2) filtrar registros con porcentaje de ESPACIO "menor que" %
            let without_comparation_symbol_number =
              no_white_spaces.substring(1);
            params["piloto_ESPACIO"] = {
              $lt: without_comparation_symbol_number,
            };
          }
        }
      }
      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_nombre != "") {
        params["piloto_nombre"] = {
          $like:"%" + this.$refs.tablaHistoricoStatus.query.piloto_nombre + "%"};
      }
      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_placa != "") {
        params["piloto_placa"] = {
          $like:"%" + this.$refs.tablaHistoricoStatus.query.piloto_placa + "%"};
      }
      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_MARCA != "") {
        params["piloto_MARCA"] = {
          $like: "%" + this.$refs.tablaHistoricoStatus.query.piloto_MARCA + "%"};
      }
      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_MODELO != "") {
        params["piloto_MODELO"] = {
          $like: "%" + this.$refs.tablaHistoricoStatus.query.piloto_MODELO + "%"};
      }
      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_SENAL != "") {
        params["piloto_SENAL"] = {
          $like:"%" + this.$refs.tablaHistoricoStatus.query.piloto_SENAL + "%"};
      }

      // prettier-ignore
      if (this.$refs.tablaHistoricoStatus.query.piloto_EVENTO != "") {
        params["piloto_EVENTO"] = {
          $like:"%" + this.$refs.tablaHistoricoStatus.query.piloto_EVENTO + "%"};
      }

      /** FIN FILTRO ESPACIO DEL PILOTO*/
      return params;
    },
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
    async fnExportarRegistrosCelularesDePilotos() {
      const bultos = await this.findPilotosDispositivosStatus({
        query: this.getParams(),
      }).then((result) => {
        result.data.forEach((registro) => {
          registro.updatedAt = this.getDate(registro.updatedAt);
          registro.createdAt = this.getDate(registro.createdAt);
          if (registro.piloto_GPS_FLAG == true) {
            registro.piloto_GPS_FLAG = "Encendido";
          } else {
            registro.piloto_GPS_FLAG = "Apagado";
          }
        });
        return result.data;
      });

      return bultos;
    },
    // funciones propias de la vista
    fnShowEjemplo2() {
      let bateria = document.querySelector(
        "body > div > div.wrapper > div > div > div > div > div.col-md-7 > div:nth-child(2) > div > div > div > div.table-responsive > table > thead > tr.VueTables__filters-row > th:nth-child(8) > div > input"
      );

      bateria.select(); // you can also use input.focus()
      bateria.value = "<=50";

      this.$refs.tablaHistoricoStatus.query.piloto_BATERIA = "<=50";

      this.$refs.tablaHistoricoStatus.refresh();
    },
    fnGetBaatteryIcon(charge) {
      if (charge <= 10) {
        return "mdi-battery-10";
      }
      if (charge <= 20) {
        return "mdi-battery-20";
      }
      if (charge <= 30) {
        return "mdi-battery-30";
      }
      if (charge <= 40) {
        return "mdi-battery-40";
      }
      if (charge <= 50) {
        return "mdi-battery-50";
      }
      if (charge <= 60) {
        return "mdi-battery-60";
      }
      if (charge <= 70) {
        return "mdi-battery-70";
      }
      if (charge <= 80) {
        return "mdi-battery-80";
      }
      if (charge <= 90) {
        return "mdi-battery-90";
      }
      if (charge <= 100) {
        return "mdi-battery";
      }
    },
    truncate(text, length, clamp) {
      clamp = clamp || "...";
      var node = document.createElement("div");
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
    async fnMostrarPilotosConGpsApagado() {
      let url = `${process.env.VUE_APP_API_URL}/pilotos-aplicaciones-status?piloto_usuario_id=${this.$route.params.id_usuario}`;
      await this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((result) => {
          this.view_total_apagados = result.data.data.length;
        });
    },
    async loadUser() {
      let url = `${process.env.VUE_APP_API_URL}/usuarios/${this.$route.params.id_usuario}`;
      await this.$http
        .get(url, {
          params: {},
          headers: {
            Authorization:
              "Bearer  " + window.localStorage.getItem("feathers-jwt"),
          },
        })
        .then((result) => {
          // console.log(result.data);
          this.usuario = result.data;
        });
    },
    getDate(date) {
      return date ? moment(date).format("DD-MM-YYYY hh:mm a") : "";
    },
  },
  mounted() {
    this.loadUser();

    const { PilotosGpsStatus } = this.$FeathersVuex.api;
    PilotosGpsStatus.on("created", () => {
      this.loadUser();
    });
  },
};
</script>
<style>
tr td:nth-child(1) {
  width: 120px;
}
tr td:nth-child(3) {
  width: 85px;
}
</style>
<style scoped>
.outline-btn {
  border: none;
  background-color: white;
  color: rgb(17, 96, 216);
  padding: 1px 2px;
  font-size: 12px;
  cursor: pointer;
}
.form-clear {
  align-items: center;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 5px;
  width: 24px;
}

.form-text + .form-clear {
  bottom: calc(1rem + 18px);
}

.form-clear .material-icons {
  font-size: 16px;
  font-weight: 500;
}
body
  > div
  > div.wrapper
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div.VueTables.VueTables--server
  > div.table-responsive {
  overflow: none !important;
}
.outline-btn-excel {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: white;
  color: #1f6e43;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
.outline-btn-excel:hover {
  border: 1px solid #1f6e43;
  border-radius: 15px;
  background-color: #739b85;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}
</style>
