<template>
  <DragItDude
    ref="fichaUltimaEntrega"
    id="fichaUltimaEntrega"
    class="modal-dialog"
    v-if="fichaUltimaEntrega !== null"
    :style="[
      windowPcVersion
        ? { 'min-width': '420px', width: '420px' }
        : { 'min-width': '850px' },
      { top: '7px' },
      { left: '15px' }
    ]"
  >
    <div class="modal-content">
      <div
        :style="[windowPcVersion ? { height: '25px' } : { 'height:': '45px' }]"
      >
        <!-- switch y titulo encab ezado -->
        <div class="switchEncabezado">
          <!-- switch -->
          <div class="custom-control custom-switch">
            <input
              @click="toggleWindowVersion()"
              type="checkbox"
              class="custom-control-input"
              id="switchUltimaEntrega"
            />
            <label
              class="custom-control-label"
              for="switchUltimaEntrega"
            ></label>
          </div>
          <!-- fin switch -->
          <span :class="[windowPcVersion ? '' : 'modal-encabezadoTv']"
            >Ultimo pedido entregado: {{ fichaUltimaEntrega.ERP_DOC }}</span
          >
        </div>
        <!-- fin -->
        <img
          :width="[windowPcVersion ? '85px' : '185px']"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
        <br />
      </div>
      <div class="modal-body vista-pc" v-if="windowPcVersion == true">
        <div class="row">
          <div class="col-md-12">
            <table>
              <tbody>
                <tr v-if="fichaUltimaEntrega.DELIVERY_ORDER_SEQUENCE">
                  <td>Correlativo de entrega:</td>
                  <td>
                    <strong>
                      {{ fichaUltimaEntrega.DELIVERY_ORDER_SEQUENCE }}
                    </strong>
                  </td>
                </tr>
                <tr v-else>
                  <td></td>
                </tr>
                <tr>
                  <td v-if="fichaUltimaEntrega.DELIVERY_IMAGE !== null">
                    <zoom-on-hover
                      id="fichaUltimaEntregaDELIVERY_IMAGE"
                      :scale="7"
                      :img-normal="
                        VUE_APP_API_URL + fichaUltimaEntrega.DELIVERY_IMAGE
                      "
                      :img-zoom="
                        VUE_APP_API_URL + fichaUltimaEntrega.DELIVERY_IMAGE
                      "
                    ></zoom-on-hover>
                  </td>
                  <td
                    v-if="fichaUltimaEntrega.DELIVERIED_SIGNATURE !== null"
                    style="text-align: center"
                  >
                    <img
                      width="180px"
                      id="infoEntregaDELIVERIED_SIGNATURE"
                      :src="
                        VUE_APP_API_URL +
                          fichaUltimaEntrega.DELIVERIED_SIGNATURE
                      "
                      alt
                      class="responsive-img"
                    />
                  </td>
                  <td v-else style="text-align: center">
                    <img
                      width="215px"
                      src="/img/logoLeCleire.png"
                      alt
                      class="img-fluid"
                      style
                    />
                  </td>
                </tr>

                <tr>
                  <td>CONSEJER@:</td>
                  <td>
                    <strong>{{
                      fichaUltimaEntrega.DELIVERY_CLIENT_NAME
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Dirección:</td>
                  <td>
                    <strong style="font-size: 9px">{{
                      fichaUltimaEntrega.DELIVERY_CLIENT_ADDRESS
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Contenido:</td>
                  <td>
                    <strong>
                      {{ fichaUltimaEntrega.TOTAL_PACKAGES }} bultos:
                      {{
                        fichaUltimaEntrega.TOTAL_PACKAGES -
                          fichaUltimaEntrega.PRIZES -
                          fichaUltimaEntrega.HOME
                      }}
                      cajas,
                      {{ fichaUltimaEntrega.PRIZES }} premios Y
                      {{ fichaUltimaEntrega.HOME }} hogar.
                    </strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    fichaUltimaEntrega.STATUS >= 1 &&
                      fichaUltimaEntrega.supervisor_de_carga
                  "
                >
                  <td>Cuadrador:</td>
                  <td>
                    <strong>{{
                      fichaUltimaEntrega.supervisor_de_carga
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>Empresa:</td>
                  <td>
                    <strong>{{
                      fichaUltimaEntrega.DELIVERY_COMPANY_NAME
                    }}</strong>
                  </td>
                </tr>
                <tr v-if="fichaUltimaEntrega.STATUS >= 1">
                  <td>Piloto que cargó:</td>
                  <td>
                    <span title="piloto">
                      <strong>{{
                        fichaUltimaEntrega.DELIVERY_ASSIGNED_TO_DRIVER_NAME
                      }}</strong>
                    </span>
                    <span title="placa"
                      ><strong>
                        ({{
                          fichaUltimaEntrega.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES
                        }})</strong
                      ></span
                    >
                  </td>
                </tr>

                <tr>
                  <td>Piloto que entregó: :</td>
                  <td>
                    <strong title="piloto">{{
                      fichaUltimaEntrega.DELIVERY_DRIVER_NAME
                    }}</strong>
                    <strong title="placa"
                      >({{
                        fichaUltimaEntrega.DELIVERY_VEHICULE_PLATES
                      }})</strong
                    >
                  </td>
                </tr>

                <tr>
                  <td>Manifiesto:</td>
                  <td>
                    <strong>{{ fichaUltimaEntrega.MANIFEST_DB_ID }}</strong>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center">
                    <strong>{{ fichaUltimaEntrega.STATUSTEXT }}</strong>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>El día:</td>
                  <td>
                    <strong>
                      {{
                        fichaUltimaEntrega.DELIVERY_DATE
                          | moment("add", "", "DD-MM-YY")
                      }}
                    </strong>
                    a las:
                    <strong>
                      {{
                        fichaUltimaEntrega.DELIVERY_DATE
                          | moment("add", "", "HH:mm:ss")
                      }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>Coordenadas de entrega:</td>
                  <td>
                    <span>
                      latitud:
                      <strong>{{ fichaUltimaEntrega.DELIVERY_LAT }}</strong>
                    </span>
                    <span>
                      longitud:
                      <strong>{{ fichaUltimaEntrega.DELIVERY_LON }}</strong>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-md-4">
            <P class="p-vista-pc">
              <button
                class="btn btn-sm btn-success"
                @click="
                  fnIrMarcador(
                    fichaUltimaEntrega.ERP_DOC,
                    fichaUltimaEntrega.DELIVERY_LAT,
                    fichaUltimaEntrega.DELIVERY_LON
                  )
                "
              >
                ver en mapa
              </button>
            </P>
          </div>
        </div>
      </div>
      <div class="modal-body" v-if="windowPcVersion == false">
        <div class="row">
          <div class="col-md-6">
            <p
              v-if="fichaUltimaEntrega.DELIVERY_ORDER_SEQUENCE"
              style="font-size: 24px"
            >
              Correlativo de entrega:
              <strong>{{ fichaUltimaEntrega.DELIVERY_ORDER_SEQUENCE }}</strong>
            </p>
            <p>
              CONSEJER@:
              <strong>{{ fichaUltimaEntrega.DELIVERY_CLIENT_NAME }}</strong>
            </p>

            <p>
              Teléfono:
              <strong>{{ fichaUltimaEntrega.DELIVERIED_TO_PHONE }}</strong>
            </p>
            <p v-if="fichaUltimaEntrega.DELIVERIED_TO_PHONE">
              Teléfono de entrega:
              <strong>{{ fichaUltimaEntrega.DELIVERIED_TO_PHONE }}</strong>
            </p>

            <p>
              Dirección:
              <strong>{{ fichaUltimaEntrega.DELIVERY_CLIENT_ADDRESS }}</strong>
            </p>

            <p>
              Contenido:
              <strong>
                {{ fichaUltimaEntrega.TOTAL_PACKAGES }} bultos:
                {{
                  fichaUltimaEntrega.TOTAL_PACKAGES -
                    fichaUltimaEntrega.PRIZES -
                    fichaUltimaEntrega.HOME
                }}
                cajas,
                {{ fichaUltimaEntrega.PRIZES }} premios y
                {{ fichaUltimaEntrega.HOME }} hogar.
              </strong>
            </p>

            <p>
              Empresa:
              <strong>{{ fichaUltimaEntrega.DELIVERY_COMPANY_NAME }}</strong>
            </p>

            <p>
              Manifiesto:
              <strong>{{ fichaUltimaEntrega.MANIFEST_ID }}</strong>
            </p>

            <p>
              Piloto:
              <strong>{{ fichaUltimaEntrega.DELIVERY_DRIVER_NAME }}</strong>
            </p>

            <p>
              Placa:
              <strong>{{ fichaUltimaEntrega.DELIVERY_VEHICULE_PLATES }}</strong>
            </p>

            <p>
              <strong :style="'color:green!important;'">
                {{ fichaUltimaEntrega.STATUSTEXT }}
              </strong>

              <br />El día:
              <strong>
                {{
                  fichaUltimaEntrega.DELIVERY_DATE
                    | moment("add", "", "DD-MM-YY")
                }}
              </strong>
              a las:
              {{
                fichaUltimaEntrega.DELIVERY_DATE | moment("add", "", "HH:mm:ss")
              }}
              <span>
                <br />Coordenadas de entrega:
                <br />
                <strong>latitud: {{ fichaUltimaEntrega.DELIVERY_LAT }}</strong>
                <br />
                <strong>longitud: {{ fichaUltimaEntrega.DELIVERY_LON }}</strong>
                <br />
              </span>
            </p>
            <p v-if="fichaUltimaEntrega.DELIVERIED_SIGNATURE !== null">
              <label for>Firma de recibido:</label>
              <br />
              <img
                id="infoEntregaDELIVERIED_SIGNATURE"
                :src="VUE_APP_API_URL + fichaUltimaEntrega.DELIVERIED_SIGNATURE"
                alt
                class="responsive-img"
              />
            </p>
          </div>
          <div class="col-md-6">
            <!-- <p v-if="fichaUltimaEntrega.DELIVERY_IMAGE !== null">
              <img
                id="infoEntregaDELIVERY_IMAGE"
                :src="fichaUltimaEntrega.DELIVERY_IMAGE"
                alt
                class="responsive-img"
              />
            </p>-->
            <zoom-on-hover
              v-if="fichaUltimaEntrega.DELIVERY_IMAGE !== null"
              id="fichaUltimaEntregaDELIVERY_IMAGE"
              :scale="5"
              :img-normal="VUE_APP_API_URL + fichaUltimaEntrega.DELIVERY_IMAGE"
              :img-zoom="VUE_APP_API_URL + fichaUltimaEntrega.DELIVERY_IMAGE"
            ></zoom-on-hover>
            <p v-else style="background: none; text-align: center">
              <img
                width="215px"
                src="/img/logoLeCleire.png"
                alt
                class="img-fluid"
                style
              />
            </p>
            <P>
              <button
                class="btn btn-sm btn-success"
                @click="
                  fnIrMarcador(
                    fichaUltimaEntrega.ERP_DOC,
                    fichaUltimaEntrega.DELIVERY_LAT,
                    fichaUltimaEntrega.DELIVERY_LON
                  )
                "
              >
                ver en mapa
              </button>
            </P>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button class="btn btn-sm btn-succes btn-float-right">Cerrar ventana</button>
      </div>-->
    </div>
  </DragItDude>
</template>
<script>
import DragItDude from "vue-drag-it-dude";
export default {
  data() {
    return {
      windowPcVersion: true,
      deliveryTextColor: "",
      fichaUltimaEntregaTOP: 55,
      fichaUltimaEntregaLEFT: 9,
      fichaUltimaEntrega: null
    };
  },
  components: { DragItDude },
  methods: {
    //
    toggleWindowVersion() {
      console.log(this.windowPcVersion);
      this.windowPcVersion = !this.windowPcVersion;
    },
    //
    fnIrMarcador(erp_doc, lat, lng) {
      let coordenadas = { lat: lat, lng: lng, markerID: "markerID" + erp_doc };
      this.$emit("clicked", coordenadas);
    },
    fnCargarPedidoEnMapa(pedido) {
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            ERP_DOC: pedido.ERP_DOC
          }
        })
        .then(result => {
          console.log("vista ultima entrega");
          console.log(result);
          let ultimaEntrega = result.data[0];
          ultimaEntrega.DELIVERY_ORDER_SEQUENCE = pedido.contador;
          this.fichaUltimaEntrega = ultimaEntrega;
        });

      //console.log("pedido entregado.");
    }
  },
  computed: {
    VUE_APP_API_URL() {
      return process.env.VUE_APP_API_URL + "/";
    }
  },
  mounted() {
    //
    const DAY_MS = 24 * 60 * 60 * 1000;
    this.$store
      .dispatch("Pedidos/find", {
        query: {
          $limit: 2,
          STATUS: 2,
          DELIVERY_DATE: {
            $gte: new Date().getTime() - DAY_MS
          },
          $sort: {
            DELIVERY_DATE: -1
          }
        }
      })
      .then(result => {
        console.log("vista ultima entrega");
        console.log(result);
        let pedido = result.data[0];
        pedido.DELIVERY_ORDER_SEQUENCE = null;
        this.fichaUltimaEntrega = pedido;
      });
    ///
    const { Realizarentrega, Realizardevolucion } = this.$FeathersVuex.api;

    Realizarentrega.on("patched", pedido => this.fnCargarPedidoEnMapa(pedido));
    Realizardevolucion.on("patched", pedido => {
      this.fnCargarPedidoEnMapa(pedido);
      console.log("Ultima entrega: actualizar pedido con devolucion;");
    });
  }
};
</script>
<style scoped>
#fichaUltimaEntrega {
  min-width: 860px;
  border-radius: 15px;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: top;
  z-index: 12;
  position: absolute;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
strong {
  font-weight: bold;
}
.btn-float-right {
  position: absolute;
  right: 25px;
  bottom: 10px;
}
.modal-header {
  padding-bottom: 10px;
  background-color: white !important;
  color: rgb(3, 29, 106);
  font-size: 24px;
  cursor: move;
  /* not highlable */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.p-vista-pc {
  font-size: 9px !important;
}

/* //franjas en tabla */
tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* //ancho width primera columna */
thead tr th:first-child,
tbody tr td:first-child {
  width: 15em;
  min-width: 15em;
  max-width: 15em;
  font-size: 9px;
  word-break: break-all;
}
/* //ancho width segunda columna */
thead tr th:nth-child(2),
tbody tr td:nth-child(2) {
  font-size: 11px;
}

/* //modal-header encabezado */
.switchEncabezado {
  margin-left: 25px;
}
.switchEncabezado > .custom-switch {
  margin-left: 7px;
  margin-bottom: 5px;
  margin-top: 5px;
}
/** encabezado de vista TV */
.modal-encabezadoTv {
  color: rgb(3, 29, 106);
  font-size: 24px;
}
</style>
