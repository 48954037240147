<template>
  <DragItDude
    ref="buscador"
    id="buscador"
    :style="[
      windowPcVersion
        ? { 'min-width': '335px', width: '335px' }
        : { 'min-width': '850px' },
      { top: '7px' },
      { left: '15px' },
    ]"
  >
    <div class="modal-content">
      <div
        :style="[
          windowPcVersion ? { height: '50px' } : { 'height:': '45px' },
          { 'background-color': '#031D6A' },
        ]"
      >
        <img
          :width="[windowPcVersion ? '85px' : '185px']"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
        <br />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <!-- <v-select
              label="ERP_DOC"
              @input="SenVal"
              :options="options"
              :value="options.selected"
            ></v-select>-->
            <v-select
              :loading="searchLoading"
              :resetOnOptionsChange="true"
              @search="fetchOptions"
              :clearable="clearable"
              :options="options"
              :value="options.selected"
              label="ERP_DOC"
              @input="SenVal"
            >
              <div slot="no-options">Ingresa un número de pedido válido</div>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </DragItDude>
</template>
<script>
import vSelect from "vue-select";
import DragItDude from "vue-drag-it-dude";
export default {
  name: "buscador",
  components: { DragItDude, vSelect },
  data() {
    return {
      searchLoading: false,
      clearable: true,
      //
      activeERPDOC: null,
      options: [],
      windowPcVersion: true,
    };
  },
  methods: {
    async fetchOptions(value) {
      this.searchLoading = true;
      this.options = [];
      await this.$store
        .dispatch("Pedidos/find", {
          query: {
            $limit: 5,
            $select: ["ERP_DOC"],
            ERP_DOC: {
              $like: "%" + value + "%",
            },
            $sort: {
              ERP_DOC: 1,
            },
          },
        })
        .then((result) => {
          console.log(result.data);
          this.options = this.options.concat(result.data);
        });
      await this.$store
        .dispatch("PedidosHistorico/find", {
          query: {
            $limit: 5,
            $select: ["ERP_DOC"],
            ERP_DOC: {
              $like: "%" + value + "%",
            },
            $sort: {
              ERP_DOC: 1,
            },
          },
        })
        .then((result) => {
          console.log(result.data);
          this.options = this.options.concat(result.data);
        });
      this.searchLoading = false;
    },

    SenVal(value) {
      this.$emit("clicked", value);
    },
  },
};
</script>
<style scoped>
#buscador {
  z-index: 10;
}
.input-search {
  display: inline-block;
}
</style>
