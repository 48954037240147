<template>
  <dragItDude
    ref="entregaERPDOCDevolucion"
    id="entregaERPDOCDevolucion"
    class
    style="width: 780px"
  >
    <div class="modal-content">
      <div class="modal-header">
        Pedido correlativo número: {{ entregaERPDOCDevolucion }}
        <br />
        <img
          width="185px"
          src="/img/logoLeCleire.png"
          alt
          class="img-fluid"
          style="right: 25px; top: 3px !important; position: absolute"
        />
      </div>
      <div class="modal-body" v-if="ventanaInformacionDeEntregaDevolucion">
        <div v-if="ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_CODE">
          Código de devolución:
          <strong>{{
            ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_CODE
          }}</strong>
          <span>
            . Asignado por
            <strong>{{
              ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_BY_USER
            }}</strong>
            (
            <strong>{{
              ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_BY_USER_CODIGO
            }}</strong
            >)
          </span>
          <br />Detalle devolución:
          <br />
          <span id="detalleDevolucion">{{
            ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_REASON
          }}</span>
          <hr />
        </div>

        <blockquote>
          <small>Pedido a nombre de:</small>
          <br />
          {{ ventanaInformacionDeEntregaDevolucion.DELIVERY_CLIENT_NAME }}
          <br />
          <small>Dirección:</small>
          <br />
          {{ ventanaInformacionDeEntregaDevolucion.DELIVERY_CLIENT_ADDRESS }}
          <br />
          <small>Teléfono:</small>
          {{ ventanaInformacionDeEntregaDevolucion.DELIVERY_CLIENT_PHONE }}
          <small>Bultos:</small>
          {{ ventanaInformacionDeEntregaDevolucion.TOTAL_PACKAGES }}
        </blockquote>
        <hr />
        <div
          v-if="!ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_CODE"
          class="row"
        >
          <div class="col-md-12">
            <form>
              <div class="form-group">
                <label for="devolucionCodigo">Código de devolución</label>
                <input
                  :value="codigoDevolucion"
                  class="form-control"
                  type="text"
                  name="devolucionCodigo"
                  id="devolucionCodigo"
                  readonly
                />
              </div>

              <div class="form-group">
                <label for="devolucionMotivo">Detalle devolución:</label>
                <textarea
                  maxlength="200"
                  v-model="devolucionMotivo"
                  class="form-control"
                  id="devolucionMotivo"
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer" style="padding-top: 10px">
        <button
          v-if="
            ventanaInformacionDeEntregaDevolucion &&
            !ventanaInformacionDeEntregaDevolucion.DELIVERY_RETURN_CODE
          "
          class="btn btn-sm btn-success btn-float-right"
          style="right: 180px"
          @click="
            asignarDevolucion(
              ventanaInformacionDeEntregaDevolucion.id,
              ventanaInformacionDeEntregaDevolucion.ERP_DOC
            )
          "
        >
          Crear devolucion
          <i class="fas fa-save"></i>
        </button>
        <button
          class="btn btn-sm btn-succes btn-float-right"
          @click="fnResetWindow()"
        >
          Cerrar ventana
        </button>
      </div>
    </div>
  </dragItDude>
</template>
<script>
import DragItDude from "vue-drag-it-dude";
export default {
  components: {
    DragItDude,
  },
  props: [
    "entregaERPDOCDevolucion",
    "ventanaInformacionDeEntregaDevolucion",
    "codigoDevolucion",
  ],
  data() {
    return {
      devolucionMotivo: "",
    };
  },
  created() {
    console.log(this.$store.state.auth.payload.usuarios);
  },
  methods: {
    asignarDevolucion(idPedido, erpdoc) {
      let data = {
        // STATUS: 4,
        // STATUSTEXT: "CON DEVOLUCIÓN",
        DELIVERY_RETURN_CODE: this.codigoDevolucion,

        DELIVERY_RETURN_REASON: this.devolucionMotivo,

        DELIVERY_RETURN_BY_USER:
          this.$store.state.auth.payload.usuarios.usuario_nombre +
          " " +
          this.$store.state.auth.payload.usuarios.usuario_apellido,

        DELIVERY_RETURN_BY_USER_CODIGO: this.$store.state.auth.payload.usuarios
          .usuario_codigo,

        DELIVERY_RETURN_DATE: new Date(),
      };
      let params = {};
      this.$store.dispatch("Pedidos/patch", [idPedido, data, params]).then(
        this.$emit("devolucionAsignada", erpdoc),
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Asignación de devolución exitosa al pedido: " + erpdoc,
        })
      );
    },
    fnResetWindow() {
      this.$emit("clicked");
    },
  },
};
</script>
<style scoped>
.btn-float-right {
  display: block;
  position: absolute;
  right: 25px;
  bottom: 10px;
}
blockquote {
  font-size: 12px;
}
#detalleDevolucion {
  font-style: italic;
  font-size: 11px;
  padding-left: 5px;
  border-left: 1px solid pink;
}
</style>