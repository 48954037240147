<template>
  <div class="content">
    <div
      v-if="loading"
      class="d-flex justify-content-center"
      style="padding-top: 25px; position: absolute; z-index: 99999999"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow" style="width: 3rem; height: 3rem" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      Cargando... {{ uploadCounting }}/{{ arrayPedidos.length }}
    </div>
    <div class="row" v-if="!loading">
      <div class="col-sm-5">
        <div class="card card-chart">
          <!---->
          <div class="card-header">Formulario de pedidos GUATEX</div>

          <div class="card-body">
            <div class="row mb-3 ml-2">
              <div class="col-md-12">
                <p>
                  Por favor carga el archivo de excel con los pedidos asignados
                  a GUATEX:
                </p>
                <input
                  type="file"
                  class="my_input"
                  id="upload"
                  @change="cargarPedidosExcel"
                />

                <div ref="result"></div>
              </div>
            </div>
          </div>
          <!---->
          <!---->
        </div>
      </div>
      <div class="col-sm-5">
        <div class="card card-chart">
          <!---->
          <div class="card-header">
            Entregar todos los pedidos cargados a GUATEX
          </div>

          <div class="card-body">
            <div class="row mb-3 ml-2">
              <div class="col-md-12">
                <span
                  >Cambiar el estado de todos los pedidos
                  <strong>"en ruta"</strong> asignados a Guatex
                  <strong>a "Entregado"</strong></span
                >
                <br />
                <button
                  @click="validationSTring = randomString()"
                  data-toggle="modal"
                  data-target="#modalEntregarGuatex"
                  class="btn btn-sm btn-info mt-2"
                >
                  Entregar todos
                </button>
              </div>
            </div>
          </div>
          <!---->
          <!---->
        </div>
      </div>
      <div class="col-md-12" v-if="arrayPedidos.length > 0">
        <div class="card card-chart">
          <!---->
          <div class="card-header">Listado de pedidos (GUATEX)</div>

          <div
            class="card-body"
            style="max-height: 250px; overflow-y: scroll; overflow-x: hidden"
          >
            <div class="row mb-3 ml-2">
              <div class="col-md-12">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <td>pedido</td>
                      <td>Consejer@</td>
                      <td>Ruta</td>
                      <td>Sector</td>
                      <td>Campaña</td>
                      <td>Estado del pedido</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pedido, index) in arrayPedidos" :key="index">
                      <td>{{ pedido.ERP_DOC }}</td>
                      <td>{{ pedido.DELIVERY_CLIENT_NAME }}</td>
                      <td>{{ pedido.ROUTE_ID }}</td>
                      <td>{{ pedido.DELIVERY_CLIENT_REGION }}</td>
                      <td>{{ pedido.CAMPAING_ID }}</td>
                      <td>
                        <span
                          :class="
                            pedido.STATUS == 0 ? 'text-success' : 'text-danger'
                          "
                        >
                          {{ pedido.STATUSTEXT }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row" v-if="arrayPedidos.length > 0">
            <div class="col-md-9"></div>
            <div class="col-md-3">
              <button
                class="pull-right btn-sm btn-info mt-3 mb-2 mr-3"
                @click="fnAsignarPedidosAguatex"
              >
                Asignar ({{ countPedidosEnPlanta }}) pedidos a GUATEX
              </button>
            </div>
          </div>

          <!---->
          <!---->
        </div>
      </div>
    </div>
    <div class="row" v-if="!loading">
      <tablaResumenPedidosPorGuatex
        :status="status"
      ></tablaResumenPedidosPorGuatex>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalEntregarGuatex"
      role="dialog"
      aria-labelledby="modalEntregarTodoGuatex"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEntregarTodoGuatex">
              Registrar entrega de guatex
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="content">
              <p>
                Cambiar el estado a <strong>entregado</strong> de todos los
                pedidos de Guatex es un proceso irreversible. <br />
                <strong>Ingresa tu contraseña</strong> en el siguiente
                formulario para entregar todos los pedidos asignados a Guatex:
              </p>
              <div
                v-if="loadingv2"
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              >
                <span class="sr-only"
                  >Entregando pedidos asignados a Guatex...</span
                >
              </div>
              <!-- FORMULARIO ENTREGA DE PEDIDO DE GUATEX -->
              <input
                ref="codigoDeValidacionUsuario"
                v-model="codigoDeValidacionUsuario"
                type="password"
                class="form-control"
                autocomplete="off"
                readonly
                onfocus="this.removeAttribute('readonly');"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              ref="botonCerrarModalEntregarGuatex"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              id="botonParaValidarCodigo"
              type="button"
              class="btn btn-primary"
              @click="checkCodigoDeValidacion()"
              :disabled="loadingv2 == true"
            >
              Entregar todos los pedidos por Guatex
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>
<script>
import xlsx from "xlsx";
import tablaGuatex from "./tablaResumenPedidosPorGuatex";
export default {
  components: {
    tablaResumenPedidosPorGuatex: tablaGuatex
  },
  data() {
    return {
      loadingv2: false,
      //** */
      codigoDeValidacion: "",
      codigoDeValidacionUsuario: "",
      // --------------------
      uploadCounting: 0,
      loading: false,
      status: 0,
      // --------------------

      erpdocs: [],
      arrayErpPedidosGuatex: [],
      arrayPedidos: [],
      countPedidosEnPlanta: 0
    };
  },
  methods: {
    cambiarElEstadoDeTodosLosDeGuatex() {
      this.$store
        .dispatch("Pedidos/patch", [
          null,
          {
            // DEFAULT
            DELIVERY_COMPANY_NAME: "GUATEX",
            DELIVERY_DRIVER_NAME: "GUATEX",
            DELIVERY_VEHICULE_PLATES: "GUATEX",
            DELIVERY_ORDER_SEQUENCE: 1,
            // FORMULARIOo
            DELIVERIED_TO_OWNER: 1,
            DELIVERY_DATE: new Date(),
            STATUS: 2,
            STATUSTEXT: "ENTREGADO"
          },
          {
            query: {
              GUATEX: 1,
              STATUS: 1
            }
          }
        ])
        .then(result => {
          console.log(result);
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: `Se han actualizado todos los pedidos de Guatex a ENTREGADO(s)`
          });
          this.loadingv2 = false;
          this.$refs.botonCerrarModalEntregarGuatex.click();
          this.status++;
        });
    },
    checkCodigoDeValidacion() {
      this.loadingv2 = true;
      if (this.codigoDeValidacionUsuario != "") {
        this.$store
          .dispatch("auth/authenticate", {
            strategy: "local",
            usuario_codigo: this.$store.state.auth.payload.usuarios
              .usuario_codigo,

            password: this.codigoDeValidacionUsuario
          })
          .then(result => {
            this.cambiarElEstadoDeTodosLosDeGuatex();
          })
          .catch(e => {
            this.$notify({
              type: "danger",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: `La clave que haz ingresado no es correcta, por favor intentalo de nuevo.`
            });
            this.$refs.codigoDeValidacionUsuario.focus();
            this.loadingv2 = false;
          });
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: `Por favor ingresa tu clave para proceder con la actualización de los pedidos.`
        });
        this.$refs.codigoDeValidacionUsuario.focus();
        this.loadingv2 = false;
      }
    },
    randomString() {
      //define a variable consisting alphabets in small and capital letter
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXTZ";

      //specify the length for the new string
      var lenString = 7;
      var randomstring = "";

      //loop to select a new character in each iteration
      for (var i = 0; i < lenString; i++) {
        var rnum = Math.floor(Math.random() * characters.length);
        randomstring += characters.substring(rnum, rnum + 1);
      }

      this.codigoDeValidacion = randomstring;
    },
    fnCerrarManifiesto(idManifiesto) {
      console.log("cerrando manifiesto: " + idManifiesto);
      this.$store.dispatch("Manifiestos/patch", [
        idManifiesto,
        { status: 1 },
        {}
      ]);
      this.loading = false;
    },
    consultarManifiesto(erpdoc) {
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            $select: ["MANIFEST_DB_ID"],
            erp_doc: erpdoc
          }
        })
        .then(result => {
          this.fnCerrarManifiesto(result.data[0].MANIFEST_DB_ID);
        })
        .catch(e => console.log(e));
    },
    cargarPedidosExcel(e) {
      const files = e.target.files;

      if (!files.length) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        return alert(
          "La extención del archivo es incorrecta. Formatos aceptados: 'xls' or 'xlsx'."
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const excelContent = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(excelContent, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            raw: false
          }); // Generate JSON table content，wb.Sheets[Sheet名]    Get the excelContent of the first sheet
          const excellist = []; // Clear received excelContent
          // Edit excelContent
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }

          this.arrayErpPedidosGuatex = excellist;
          //   ----------------------------------------CONSULTAR INFORMACION DE LOS PEDIDOS
          this.fnConsultarInfoPedidos();

          //console.log("lectura exitosa", excellist); // At this point, you get an array containing objects that need to be processed
        } catch (e) {
          console.log(e);
          return alert(
            "Fallo en la lectura, por favor intente cargar el archivo de excel de nuevo!"
          );
        }
      };
      fileReader.readAsBinaryString(files[0]);
      var input = document.getElementById("upload");
      input.value = "";
    },
    fnConsultarInfoPedidos() {
      this.loading = true;
      this.erpdocs = [];
      this.arrayErpPedidosGuatex.forEach(pedido => {
        this.erpdocs.push(pedido.ERP_DOC);
      });
      this.$store
        .dispatch("Pedidos/find", {
          query: {
            $select: [
              "ERP_DOC",
              "DELIVERY_CLIENT_NAME",
              "ROUTE_ID",
              "DELIVERY_CLIENT_REGION",
              "CAMPAING_ID",
              "STATUSTEXT",
              "STATUS"
            ],
            ERP_DOC: {
              $in: this.erpdocs
            }
          }
        })
        .then(result => {
          this.loading = false;
          this.countPedidosEnPlanta = 0;
          result.data.forEach(pedido => {
            if (pedido.STATUS == 0) {
              this.countPedidosEnPlanta++;
            }
          });
          this.arrayPedidos = result.data;
        });
    },
    fnAsignarPedidosAguatex() {
      let arregloErpDocs = [];

      this.loading = true;
      this.uploadCounting = 0;

      let vm = this;
      let fechaManifiesto = moment().format("DDMMYYYYhhmm");

      let infoEntrega = {
        STATUS: "1",
        MANIFEST_ID: "GUATEX" + fechaManifiesto,
        STATUSTEXT: "EN RUTA",
        DELIVERY_ASSIGNED_TO_DRIVER_ID: this.$store.state.auth.payload.usuarios
          .id,
        DELIVERY_COMPANY_ID: "100190",
        DELIVERY_ASSIGNED_TO_DRIVER_NAME: "GUATEX",
        DELIVERY_COMPANY_NAME: "GUATEX",
        DELIVERY_ASSIGNED_TO_VEHICULE_PLATES: "GUATEX " + fechaManifiesto,
        UPLOADED_DATE: moment(),
        updatedAt: moment(),

        GUATEX: 1,
        supervisor_de_carga: this.$store.state.auth.payload.usuarios
          .usuario_codigo
      };

      this.arrayPedidos.forEach(element => {
        arregloErpDocs.push(element.ERP_DOC);
        this.uploadCounting++;
      });

      vm.$store
        .dispatch("Pedidos/patch", [
          null,
          infoEntrega,
          {
            query: {
              ERP_DOC: {
                $in: arregloErpDocs
              }
            }
          }
        ])
        .then(result => {
          console.log(result);
          this.status++;
          this.loading = false;
          console.log("pedidos de guatex cargados");
          this.arrayPedidos = [];
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: `Se han asignado los pedidos a la empresa de transporte GUATEX`
          });
          this.uploadCounting = 0;
        });

      // this.arrayPedidos.forEach(function(pedido, index, collection) {
      //   setTimeout(() => {
      //     if (pedido.STATUS == 0 || pedido.STATUS == 3) {
      //       infoEntrega = {
      //         piloto_COMPANY_ID: "100190",
      //         piloto_COMPANY_NAME: "GUATEX",
      //         piloto_nombre: "GUATEX",
      //         piloto_apellido: "",
      //         piloto_placa: "GUATEX " + fechaManifiesto,
      //         ERP_DOC: pedido.ERP_DOC,
      //         GUATEX: 1
      //       };

      //       let params = {};
      //       vm.$store.dispatch("Cargarpedido/patch", [null, infoEntrega, {}]);

      //       if (index === vm.arrayPedidos.length - 1) {
      //         vm.consultarManifiesto(vm.arrayPedidos[index].ERP_DOC);
      //         setTimeout(() => {
      //           vm.status++;
      //           console.log("pedidos de guatex cargados");
      //           vm.arrayPedidos = [];
      //           vm.$notify({
      //             type: "success",
      //             verticalAlign: "top",
      //             horizontalAlign: "right",
      //             message: `Se han asignado los pedidos a la empresa de transporte GUATEX`
      //           });
      //         }, 500);
      //       }
      //     } else {
      //       if (index === vm.arrayPedidos.length - 1) {
      //         vm.consultarManifiesto(vm.arrayPedidos[index].ERP_DOC);
      //         setTimeout(() => {
      //           vm.loading = false;
      //           vm.status++;
      //           console.log("pedidos de guatex cargados");
      //           vm.arrayPedidos = [];
      //           vm.$notify({
      //             type: "success",
      //             verticalAlign: "top",
      //             horizontalAlign: "right",
      //             message: `Se han asignado los pedidos a la empresa de transporte GUATEX`
      //           });
      //         }, 500);
      //       }
      //     }
      //     vm.uploadCounting++;
      //   }, index * 1100);
      // });
    }
  }
};
</script>
