// src/store/services/Pedidosporcampana.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Pedidosporcampana extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Pedidosporcampana";
  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}
const servicePath = "pedidosporcampana";
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: "Pedidosporcampana",
  Model: Pedidosporcampana,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
