<template>
  <div class="col-md-12">
    <v-client-table
      ref="tablaPedidosDetalle"
      :data="rows"
      :columns="columns"
      :options="options"
    >
      <button
        @click="
          fnAsignarInformacionDelPedido(
            props.row.ERP_DOC,
            props.row.MANIFEST_DB_ID,
            props.row.MANIFEST_ID,
            props.row.TOTAL_PACKAGES,
            props.row.PRIZES,
            props.row.HOME,
            props.row.DELIVERY_CLIENT_NAME,
            props.row.TOTAL_PACKAGES,
            props.row.DELIVERY_CLIENT_ADDRESS,
            props.row.DELIVERY_CLIENT_PHONE
          )
        "
        data-toggle="modal"
        data-target="#exampleModalLong"
        :disabled="props.row.STATUS != 1"
        :id="'btnEntregarGuatex' + props.row.ERP_DOC"
        slot-scope="props"
        class="btn btn-sm"
        slot="btnEntregarPedidoGuatex"
      >
        Entregar
      </button>
    </v-client-table>
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModalLong"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Registrar entrega de guatex
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="content">
              <!-- FORMULARIO ENTREGA DE PEDIDO DE GUATEX -->
              <label for=""
                >¿La persona que está recibiendo el pedido, es el titular de la
                cuenta?</label
              >
              <input
                type="radio"
                id="1"
                value="1"
                v-model="data.DELIVERIED_TO_OWNER"
              />
              <label for="1">Si</label>
              <br />
              <input
                type="radio"
                id="2"
                value="0"
                v-model="data.DELIVERIED_TO_OWNER"
              />
              <label for="2">No</label>
              <br />
              <label for=""
                >¿La zona donde reside la persona es considerada como zona
                roja?</label
              >
              <input
                type="radio"
                id="1roja"
                value="1"
                v-model="data.DELIVERIED_TO_AREA_ROJA"
              />
              <label for="1roja">Si</label>
              <br />
              <input
                type="radio"
                id="2roja"
                value="0"
                v-model="data.DELIVERIED_TO_AREA_ROJA"
              />
              <label for="2roja">No</label>
              <br />

              <input
                type="text"
                class="form-control"
                placeholder="Nombre de persona quien recibe"
                v-model="data.DELIVERY_CLIENT_NAME"
              />
              <label for="">Fecha de entrega:</label>
              <date-range-picker
                v-model="fechahora"
                ref="fechahora"
                :append-to-body="true"
                :single-date-picker="true"
                :time-picker24-hour="true"
                :time-picker-seconds="true"
                :time-picker="true"
                :ranges="false"
                :locale-data="locale"
              ></date-range-picker>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              @click="fnEnviarPedidoGuatex"
              type="button"
              class="btn btn-primary"
            >
              Entregar pedido por Guatex
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  props: ["status"],
  components: { DateRangePicker },
  data() {
    return {
      data: {
        // DEFAULT
        DELIVERY_COMPANY_NAME: "GUATEX",
        DELIVERY_DRIVER_NAME: "GUATEX",
        DELIVERY_IMAGE: null,
        DELIVERY_LAT: null,
        DELIVERY_LON: null,
        DELIVERIED_SIGNATURE: null,
        DELIVERY_RESEND: null,
        DELIVERY_VEHICULE_PLATES: "GUATEX",
        DELIVERY_ORDER_SEQUENCE: 1,

        // FORMULARIOo
        DELIVERIED_TO_OWNER: 1,
        DELIVERY_CLIENT_ADDRESS: null,
        DELIVERY_CLIENT_NAME: null,
        DELIVERIED_TO_PHONE: "null",
        DELIVERY_DATE: null,
        DELIVERIED_TO_AREA_ROJA: 1,

        // BY API
        DELIVERIED_TO_NAME: null,
        ERP_DOC: null,
        MANIFEST_DB_ID: null,
        MANIFEST_ID: null,
        TOTAL_PEDIDOS: null,
        PRIZES: null,
        HOME: null,
        TOTAL_PACKAGES: null
      },
      telefono: "",
      nombre: "",

      fechahora: {
        startDate: new Date()
      },
      rows: [],
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        firstDay: 1
      },
      //
      columns: [
        "ERP_DOC",
        "DELIVERY_CLIENT_REGION",
        "ROUTE_ID",
        "DELIVERY_CLIENT_NAME",
        "CREATED",
        "MANIFEST_ID",
        "DELIVERY_DRIVER_NAME",
        "STATUSTEXT",
        "btnEntregarPedidoGuatex"
      ],
      options: {
        footerHeadings: true,

        hiddenColumns: ["porcentaje"],
        orderBy: {
          column: "porcentaje",
          ascending: false
        },

        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down"
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar ",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Sin resultados",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas"
        },
        dateColumns: ["ultima_entrega"],
        dateFormat: "DD-MM-YYYY HH:mm",
        // templates: {
        //   CREATED: function(row) {
        //     return moment(row.CREATED).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   UPLOADED_DATE: function(row) {
        //     return moment(row.UPLOADED_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   },
        //   DELIVERY_DATE: function(row) {
        //     return moment(row.DELIVERY_DATE).format("DD-MM-YYYY HH:mm:ss");
        //   }
        // },
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD-MM-YYYY HH:mm:ss",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre"
            ],
            firstDay: 1
          },
          showDropdowns: true,
          autoUpdateInput: true
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "ERP_DOC",
          "ultima_entrega",
          "DELIVERY_CLIENT_REGION",
          "ROUTE_ID"
        ],
        headings: {
          ERP_DOC: "Pedido",
          ROUTE_ID: "Ruta",
          DELIVERY_CLIENT_NAME: "Consejer@",
          CREATED: "fecha en picking",
          MANIFEST_DB_ID: "id manifiesto",
          UPLOADED_DATE: "Fecha de carga",
          DELIVERY_DRIVER_NAME: "Piloto",
          DELIVERY_DATE: "fecha de entrega",
          STATUSTEXT: "Estado",
          DELIVERY_CLIENT_REGION: "Sector",
          btnEntregarPedidoGuatex: ""
        },

        cellClasses: {
          PEDIDO_REENVIO: [
            {
              class: "pedido-reenvio",
              condition: row => row.PEDIDO_REENVIO >= 0
            }
          ],
          PEDIDO_NO_REENVIO: [
            {
              class: "pedido-no-recibido",
              condition: row => row.PEDIDO_NO_REENVIO >= 0
            }
          ],
          ANULADO_EN_BODEGA: [
            {
              class: "pedido-anulado-bodega",
              condition: row => row.ANULADO_EN_BODEGA >= 0
            }
          ]
        }
      }
    };
  },
  methods: {
    fnAsignarInformacionDelPedido(
      ERP_DOC,
      MANIFEST_DB_ID,
      MANIFEST_ID,
      TOTAL_PEDIDOS,
      PRIZES,
      HOME,
      DELIVERY_CLIENT_NAME,
      TOTAL_PACKAGES,
      DELIVERY_CLIENT_ADDRESS,
      DELIVERY_CLIENT_PHONE
    ) {
      this.data.ERP_DOC = ERP_DOC;
      this.data.MANIFEST_DB_ID = MANIFEST_DB_ID;
      this.data.MANIFEST_ID = MANIFEST_ID;

      this.data.PRIZES = PRIZES;
      this.data.HOME = HOME;
      this.data.DELIVERY_CLIENT_NAME = DELIVERY_CLIENT_NAME;
      this.data.TOTAL_PACKAGES = TOTAL_PACKAGES;
      this.data.DELIVERY_CLIENT_ADDRESS = DELIVERY_CLIENT_ADDRESS;
      this.data.DELIVERY_CLIENT_PHONE = DELIVERY_CLIENT_PHONE;
      this.data.DELIVERIED_TO_PHONE = DELIVERY_CLIENT_PHONE;

      this.data.TOTAL_PEDIDOS =
        this.data.TOTAL_PACKAGES - this.data.PRIZES - this.data.HOME;
      console.log(this.data);
    },
    fnEnviarPedidoGuatex() {
      if (this.data.DELIVERIED_TO_OWNER == 0) {
        this.data.DELIVERIED_TO_NAME = this.data.DELIVERY_CLIENT_NAME;
      }
      this.data.DELIVERY_DATE = this.fechahora.startDate;
      let params = {};
      this.$store.dispatch("Realizarentrega/patch", [null, this.data, {}]);
      this.fnCargarPedidosDeGuatex();
    },
    fnCargarPedidosDeGuatex() {
      this.$store
        .dispatch("Getallfromguatex/find", {
          query: {}
        })
        .then(response => {
          console.log(response);
          this.rows = [];
          this.rows = response;
        });
    }
  },

  mounted() {
    this.fnCargarPedidosDeGuatex();
  },
  computed: {
    id_rol() {
      return this.$store.state.auth.payload.usuarios.id_rol;
    }
  },
  watch: {
    status: function(newVal, oldVal) {
      this.fnCargarPedidosDeGuatex();
    }
  }
};
</script>
<style>
@media (min-width: 1367px) {
  div.daterangepicker.dropdown-menu.ltr.show-calendar.single.openscenter.linked {
    top: 453px !important;
  }
}
@media (max-width: 1366px) {
  div.daterangepicker.dropdown-menu.ltr.show-calendar.single.openscenter.linked {
    top: 523px !important;
  }
}
</style>
