<template>
  <div class="modal fade" id="modalGenerarManifiestos" ref="modalGenerarManifiestos" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document" style="max-width: 815px;overflow: auto;overflow-y: auto;">
      <div class="modal-content" style="width: 815px;">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Generar manifiestos por sector</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4 offset-md-4">
              <div class="form-group position-relative">
                <label for="sector">Sector</label>
                <input type="text" class="form-control" id="sector" name="sector" ref="formInputSector" :disabled="generandoManifiestos"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 offset-md-4 text-center">
              <button class="btn btn-info" id="btn-generar" @click="fnCrearManifiesto" :disabled="generandoManifiestos">Generar</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <base-alert :type="tipoResultado" v-if="finGenerarManifiesto" dismissible>
              <span style="white-space: pre-wrap;">{{mensajeResultado}}</span>
            </base-alert>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm btn-float-right"
            data-dismiss="modal"
          >Cerrar ventana</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from '@/components';
export default {
  name: 'GenerarManifiestos',
  components: {
    BaseAlert
  },
  data() {
    return {
      generandoManifiestos: false,
      finGenerarManifiesto: false,
      mensajeResultado: '',
      tipoResultado: ''
    }
  },
  methods: {
    fnCrearManifiesto() {
      const sector = this.$refs.formInputSector;
      if (sector.value.trim().length === 0) {
        return;
      }

      let confirmacion = window.confirm(`¿Desea crear los manifiestos para el sector ${sector.value}?`);

      if (confirmacion) {
        this.generandoManifiestos = true;
        this.finGenerarManifiesto = false;

        this.$store.dispatch('Serviciorampasasignacionmanifiestos/create', {
          SECTOR: sector.value.trim()
        })
        .then(response => {
          let listado = '';
          console.log(response);
          response.rutasSector.forEach(ruta => {listado += `Ruta ${ruta.ROUTE_ID} Manifiesto ${ruta.MANIFEST_DB_ID}\n`});
          this.mensajeResultado = `Se generaron los siguientes manifiestos:\n${listado}`;
          this.tipoResultado = 'success';
        })
        .catch(error => {
          this.mensajeResultado = error.message;
          this.tipoResultado = 'danger';
        })
        .finally(() => {
          this.generandoManifiestos = false;
          this.finGenerarManifiesto = true;
        })
      }
    }
  }
}
</script>
<style>
.alert.alert-success button.close {
  top: 10%;
}
.alert.alert-success {
  text-align: center;
}
.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
</style>
