<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
    style="background-color:#031D6A!important;"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <img
          class="img-fluid"
          width="215px"
          style="margin-right:5px; top:-1px!important; position:absolute;"
          src="/img/logoLeCleire.png"
          alt
        />
      </div>

      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <div
              class="search-bar input-group"
              @click="searchModalVisible = true"
            >
              <div class="input-group-addon">
                <span style="color:white!important">
                  <!-- <span v-if="routeName">- {{ routeName }}</span> -->
                </span>
              </div>
              <div class="input-group-addon">
                <a class style="color: white !important">
                  Dispositivos de pilotos</a
                >
              </div>
            </div>
            <home-dashboard-button></home-dashboard-button>
            <user-options :menu-on-right="!$rtl.isRTL"></user-options>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import UserOptions from "@/components/UserOptions";
import HomeDashboardButton from "@/components/HomeDashboardButton";

export default {
  components: {
    CollapseTransition,
    Modal,
    UserOptions,
    HomeDashboardButton
  },
  computed: {
    id_rol() {
      return this.$store.state.auth.payload.usuarios.id_rol;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      TrazabilidadSegmentada: false,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ""
    };
  },
  methods: {
    fnRenderizarVista(vistaId, vistaName) {
      this.$store.state.paginado.pageNumber = vistaId;
      this.$store.state.paginado.pageName = vistaName;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  },
  mounted() {
    if (this.$route.path == "/alcontadoycredito") {
      this.TrazabilidadSegmentada = !this.TrazabilidadSegmentada;
    }
  }
};
</script>
<style scoped>
.TrazabilidadSeleccionada {
  background: #041b61 !important;
  border: 1px solid white !important;
  border-radius: 15px;
}
.input-group-addon.btn-input-group-addon {
  border-radius: 15px;
  margin-right: 1.4px;
}
.input-group-addon.btn-input-group-addon:hover {
  background: #1d3479 !important;
  border: 1px solid rgb(179, 179, 179) !important;
}
.input-group-addon.btn-blank-input-group-addon {
  margin-right: 1.4px;
}
.input-group-addon.btn-blank-input-group-addon:hover {
  background: #192e6b !important;
  border: 1px solid rgb(24, 25, 105) !important;
}
</style>
