<template>
  <!-- Esta vista contempla toda la información a desplegar de las entregas a nivel de detalle -->
  <div class="row">
    <div v-for="(entrega, index) in entregas" :key="index" :class="'col-md-'+varColumnas">
      <div class="card mb-10">
        <!-- inicio -->
        <div v-if="entregas.length <= 12" class="card-header mb-4">
          <h5 class="card-category">Carga de vehiculo</h5>
          <h2 class="card-title">
          
            <span v-bind:class="[(entregas.length <= 6 ? 'datos_generales_grande' : 'datos_generales_pequeno')]">
              {{ (entrega.ROUTE_ID).substring(0, 3) }}
              <small>-</small>
              {{ (entrega.ROUTE_ID).substr(3).slice(0, -3) }}
              <small>-</small>
              {{ (entrega.ROUTE_ID).substr(entrega.ROUTE_ID.length - 3)}}
            </span>
            <strong v-if="entregas.length <= 6">Rampa <span style="font-size:35px">{{ entrega.LOADING_GATE }}</span></strong><strong v-if="entregas.length >= 7">  (<span style="font-size:35px; font-weight:bold">{{ entrega.LOADING_GATE }}</span>)  </strong>
          </h2>
        </div>
        <!-- fin -->
        <div class="card-body">
          <h3 v-if="entregas.length >= 13" class="card-title">
            <strong>Rampa {{ entrega.LOADING_GATE }} </strong> -
            <strong>Ruta {{ entrega.ROUTE_ID }} </strong>
            - sector
            <strong>{{ entrega.ROUTE_ID }}</strong>.
          </h3>
          <div class="rampa-info-entrega">
            <div class="row">
              <div class="col-lg-6">
                <strong v-bind:class="[(entregas.length <= 6) ? 'tantoDeTantoGrande' : 'tantoDeTantoPequeno']">{{ entrega.total_pedidos_cargados }} / {{ entrega.total_pedidos }}</strong> (
                <strong v-bind:class="[(entregas.length <= 6) ? 'porcentajeGrande' : 'porcentajePequeno']">{{ ((entrega.total_pedidos_cargados/entrega.total_pedidos)*100).toFixed(0) }}%</strong>)
              </div>
              <div class="col-lg-6">
                <br />
                <span v-if="varColumnas >= 6"  v-bind:class="[(entregas.length <= 6) ? 'datoTransporteGrande' : 'datoTransportePequeno']">
                  Piloto: 
                </span>
                <span v-bind:class="[(entregas.length <= 6) ? 'datoTransporteGrande' : 'datoTransportePequeno']">
                  <span class>{{ entrega.DELIVERY_ASSIGNED_TO_DRIVER_NAME }}</span>
                </span>
                <br />
                <span v-if="varColumnas >= 6"  v-bind:class="[(entregas.length <= 6) ? 'datoTransporteGrande' : 'datoTransportePequeno']">
                  Placa: 
                </span>
                <span  v-bind:class="[(entregas.length <= 6) ? 'datoTransporteGrande' : 'datoTransportePequeno']">
                  <span class>{{ entrega.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES }}</span>
                </span>
                <br />
                <!-- <span  v-bind:class="[(entregas.length <= 6) ? 'datoTransporteGrande' : 'datoTransportePequeno']">
                  Empresa:
                  <span class>TRANSPORTES MYA</span>
                </span> -->
              </div>
            </div>
          </div>
          <!-- info transporte rampa -->
          <div v-if="entregas.length <= 9" class="rampa-info-entrega">
            <div class="progress">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                :style="'width: '+((entrega.total_pedidos_cargados/entrega.total_pedidos)*100).toFixed(0)+'%'"
                aria-valuenow="entrega.total_pedidos_cargados"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <!-- <span>Transporte que debe estar cargando.</span> -->
            <div class="row mt-2">
              
              <div class="col-md-7">
                <h4>
                  Empresa:
                  <strong>{{ entrega.DELIVERY_COMPANY_NAME }}</strong>
                </h4>
              </div>
              <div class="col-md-5">
                <!-- <h4>
                  Región: 
                  <strong>---</strong>
                </h4> -->
              </div>
            </div>
          </div>
          <!-- fin info transporte rampa -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RampaDetalleEntrega",
  props: ["entregas", "varColumnas"]
};
</script>
<style  scoped>
.datoTransporteGrande {
  font-size: 28px;
  font-weight: bold;
}
.datoTransportePequeno {
  font-size: 20px;
  font-weight: bold;
}
.datoimportante {
  font-size: 24px;
}
.porcentajeGrande {
  font-size:65px
}
.porcentajePequeno {
  font-size:52px
}.datos_generales_grande{
  font-size:55px; font-weight: bold;
}
.datos_generales_pequeno
{
  font-size:40px; font-weight: bold;
}
.tantoDeTantoGrande {
  font-size: 40px;
}
.tantoDeTantoPequeno {
  font-size: 15px;
}
</style>
