var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-absolute",class:{ 'bg-white': _vm.showMenu, 'navbar-transparent': !_vm.showMenu },staticStyle:{"background-color":"#031D6A!important"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"navbar-wrapper"},[_c('div',{staticClass:"navbar-toggle d-inline",class:{ toggled: _vm.$sidebar.showSidebar }},[_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","aria-label":"Navbar toggle button"},on:{"click":_vm.toggleSidebar}},[_c('span',{staticClass:"navbar-toggler-bar bar1"}),_c('span',{staticClass:"navbar-toggler-bar bar2"}),_c('span',{staticClass:"navbar-toggler-bar bar3"})])]),_c('img',{staticClass:"img-fluid",staticStyle:{"margin-right":"5px","top":"-1px!important","position":"absolute"},attrs:{"width":"215px","src":"/img/logoLeCleire.png","alt":""}})]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navigation","aria-controls":"navigation-index","aria-label":"Toggle navigation"},on:{"click":_vm.toggleMenu}},[_c('span',{staticClass:"navbar-toggler-bar navbar-kebab"}),_c('span',{staticClass:"navbar-toggler-bar navbar-kebab"}),_c('span',{staticClass:"navbar-toggler-bar navbar-kebab"})]),_c('collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMenu),expression:"showMenu"}],staticClass:"collapse navbar-collapse show"},[_c('ul',{staticClass:"navbar-nav",class:_vm.$rtl.isRTL ? 'mr-auto' : 'ml-auto'},[_c('div',{staticClass:"search-bar input-group",on:{"click":function($event){_vm.searchModalVisible = true}}},[_c('div',{staticClass:"input-group-addon"},[_c('span',{staticStyle:{"color":"white!important"}})]),(!_vm.TrazabilidadSegmentada)?_c('div',{class:[
                this.$store.state.paginado.pageNumber == 1
                  ? 'TrazabilidadSeleccionada'
                  : '',
                'input-group-addon',
                'btn-input-group-addon'
              ]},[_c('a',{staticStyle:{"color":"white!important"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.fnRenderizarVista(1, 'pedidos detalle')}}},[_c('i',{staticClass:"tim-icons icon-bullet-list-67",staticStyle:{"color":"white!important"}}),_vm._v(" Trazabilidad detallada ")])]):_vm._e(),(_vm.id_rol != 4)?_c('div',{class:[
                this.$store.state.paginado.pageNumber == 2
                  ? 'TrazabilidadSeleccionada'
                  : '',
                'input-group-addon',
                'btn-input-group-addon'
              ]},[_c('a',{staticStyle:{"color":"white!important"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.fnRenderizarVista(2, 'pedidos sector')}}},[_c('i',{staticClass:"tim-icons icon-square-pin",staticStyle:{"color":"white!important"}}),_vm._v(" Trazabilidad por sector ")])]):_vm._e(),_c('div',{class:[
                this.$store.state.paginado.pageNumber == 3
                  ? 'TrazabilidadSeleccionada'
                  : '',
                'input-group-addon',
                'btn-input-group-addon'
              ],attrs:{"c-if":"id_rol != 4 && !TrazabilidadSegmentada"}},[_c('a',{staticStyle:{"color":"white!important"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.fnRenderizarVista(3, 'pedidos piloto')}}},[_c('i',{staticClass:"tim-icons icon-delivery-fast",staticStyle:{"color":"white!important"}}),_vm._v(" Trazabilidad por piloto ")])]),(_vm.id_rol != 4)?_c('div',{staticClass:"input-group-addon btn-blank-input-group-addon"},[_c('a',{staticStyle:{"color":"white!important"},attrs:{"target":"_blank","href":"/mapa"}},[_c('i',{staticClass:"tim-icons icon-map-big",staticStyle:{"color":"white!important"}}),_vm._v(" Mapa ")])]):_vm._e(),(_vm.id_rol != 4)?_c('div',{staticClass:"input-group-addon btn-blank-input-group-addon"},[_c('a',{staticStyle:{"color":"white!important"},attrs:{"target":"_blank","href":"/historico"}},[_c('i',{staticClass:"tim-icons icon-app",staticStyle:{"color":"white!important"}}),_vm._v(" Bitácora ")])]):_vm._e()]),_c('home-dashboard-button'),_c('user-options',{attrs:{"menu-on-right":!_vm.$rtl.isRTL}})],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }