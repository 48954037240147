<template>
  <div class="row">
    <div class="col-md-12 formularioCreacionUsuario">
      <h3>Crear usuario:</h3>
      <form class="form-inline" ref="formCrearUsuario">
        <div class="form-group mr-1">
          <input
            v-model="varNuevoUsuario.usuario_nombre"
            @input="
              varNuevoUsuario.usuario_nombre = $event.target.value.toUpperCase()
            "
            type="text"
            name=""
            id="usuario_nombre"
            class="form-control"
            title="Alias del usuario, este no se utiliza para iniciar sesión"
            placeholder="Nombre del usuario"
            required
          />
        </div>
        <div class="form-group mr-1">
          <input
            v-model="varNuevoUsuario.usuario_codigo"
            @input="
              varNuevoUsuario.usuario_codigo = $event.target.value.toUpperCase()
            "
            type="text"
            name=""
            id="usuario_codigo"
            class="form-control"
            placeholder="Codigo de usuario"
            title="Código que se utiliza para iniciar sesion en el panel o en la APP"
            required
          />
        </div>
        <div class="form-group mr-1">
          <label for=""></label>
          <input
            type="text"
            v-model="varNuevoUsuario.password"
            class="form-control"
            placeholder="*Contraseña"
            required
          />
        </div>
        <div class="form-group mr-1">
          <select
            class="custom-select"
            @change="fnFormEncontrarRol($event)"
            required
          >
            <option selected disabled>Listado de roles</option>
            <option
              :value="rol.id"
              v-for="rol in ListadoDeRoles"
              v-bind:key="rol.id"
            >
              {{ rol.rol_nombre }}
            </option>
          </select>
        </div>
        <div class="form-group mr-1">
          <select
            class="custom-select"
            @change="fnEncontrarEmpresaTransportista($event)"
            required
          >
            <option selected disabled>Listado de empresas de transporte</option>
            <option
              :value="empresa.id"
              v-for="empresa in ListadoDeEmpresasTransportistas"
              v-bind:key="empresa.id"
            >
              {{ empresa.nombre }}
            </option>
          </select>
        </div>
        <div class="form-group mr-1">
          <button
            @click.prevent="fnCrearUsuario()"
            type="button"
            class="btn btn-primary"
          >
            Crear usuario
          </button>
        </div>
      </form>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->
    <div class="col-md-12">
      <v-server-table
        :columns="columns"
        :options="optionsVTable"
        ref="tablaListadoDeUsuarios"
      >
        <span slot="usuario_nombre" slot-scope="props">
          <span
            >{{ props.row.usuario_nombre }}

            <span
              :title="props.row.DE_BAJA_MOTIVO"
              v-if="props.row.status == 0"
              class="badge badge-danger"
              >De baja</span
            >
          </span>
        </span>
        <span slot="rol" slot-scope="props">
          {{ fnTableEncontrarRol(props.row.id_rol) }}
        </span>
        <div slot="opciones" slot-scope="props">
          <button
            @click="fnTableEncontrarRol(props.row.id_rol)"
            class="mr-2 btn-opcionIcono"
            data-toggle="modal"
            data-target="#modalEditarUsuario"
            :disabled="props.row.status == 0"
          >
            <span
              :title="
                'Edita las credenciales del usuario ' + props.row.usuario_codigo
              "
              class="material-icons icon-image-preview text-info"
              @click="fnAsignarVarUsuarioEditar(props.row.id)"
              >edit</span
            >
          </button>

          <button
            @click="fnCargarModulosDeUsuarioParaEditar(props.row.id)"
            class="mr-2 btn-opcionIcono"
            data-toggle="modal"
            data-target="#modalEditarModulosDeUsuario"
            :disabled="props.row.status == 0"
          >
            <span
              :title="
                'Edita el acceso a modulos para el usuario ' +
                props.row.usuario_codigo
              "
              class="material-icons icon-image-preview opcionIcono text-info"
              @click="fnAsignarVarUsuarioEditar(props.row.id)"
              >playlist_add</span
            >
          </button>

          <button
            :title="'Dar de baja al usuario ' + props.row.usuario_codigo"
            class="mr-2 btn-opcionIcono"
            data-toggle="modal"
            data-target="#modalUsuarioODarDeBaja"
            :disabled="props.row.status == 0"
            @click="fnAsignarVarUsuarioEditar(props.row.id)"
          >
            <span
              class="material-icons icon-image-preview opcionIcono text-danger"
              >delete</span
            >
          </button>

          <!-- <button
            :title="'Dar de baja al usuario ' + props.row.usuario_codigo"
            @click="fnDarDeBajaUsuario(props.row.id, props.row.usuario_codigo)"
            class="mr-2 btn-opcionIcono"
            :disabled="props.row.status == 0"
          >
            <span
              class="material-icons icon-image-preview opcionIcono text-danger"
              >delete</span
            >
          </button> -->
        </div>
      </v-server-table>
    </div>
    <!-- ******************** TABLA DE USUARIOS -->
    <GestionUsuarioDarDeBaja
      :prop_idUsuario="padreCodigoUsuario"
    ></GestionUsuarioDarDeBaja>
    <GestionUsuarioEditar
      :padreCodigoUsuario="padreCodigoUsuario"
      :ListadoDeRoles="ListadoDeRoles"
      :ListadoDeEmpresasTransportistas="ListadoDeEmpresasTransportistas"
    ></GestionUsuarioEditar>
    <GestionModulosDeUsuarioEditar
      :modalEditarModulosModulosGlobal="modalEditarModulosModulosGlobal"
      :modalEditarModulosModulosDeUsuario="modalEditarModulosModulosDeUsuario"
      :padreCodigoUsuario="padreCodigoUsuario"
      :ListadoDeRoles="ListadoDeRoles"
      :ListadoDeEmpresasTransportistas="ListadoDeEmpresasTransportistas"
    ></GestionModulosDeUsuarioEditar>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import GestionUsuarioEditar from "@/pages/Dashboard/GestionUsurioEditar.vue";
import GestionModulosDeUsuarioEditar from "@/pages/Dashboard/GestionModulosDeUsurioEditar.vue";
import GestionUsuarioDarDeBaja from "@/pages/Dashboard/GestionusuarioDarDeBaja.vue";
export default {
  components: {
    GestionUsuarioDarDeBaja,
    GestionUsuarioEditar,
    GestionModulosDeUsuarioEditar,
  },
  data() {
    return {
      modalEditarModulosModulosGlobal: [],
      modalEditarModulosModulosDeUsuario: [],
      padreCodigoUsuario: "",
      ListadoDeRoles: [],
      ListadoDeEmpresasTransportistas: [],
      varNuevoUsuario: {
        usuario_nombre: "",
        usuario_codigo: "",
        id_rol: "",
        DELIVERY_COMPANY_ID: "",
        DELIVERY_COMPANY_NAME: "",
        password: "",
      },
      columns: [
        "usuario_nombre",
        "usuario_codigo",
        "rol",
        "DELIVERY_COMPANY_NAME",
        "opciones",
      ],
      optionsVTable: {
        roles: [],
        orderBy: {
          column: "usuario_codigo",
          ascending: true,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 10,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "Ultimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "No se encontraron resultados...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateFormat: "DD-MM-YYYY HH:mm",
        dateColumns: ["updatedAt"],
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: [
          "usuario_nombre",
          "usuario_codigo",
          "DELIVERY_COMPANY_NAME",
          "rol",
        ],
        headings: {
          usuario_nombre: "Nombre",
          usuario_codigo: "Usuario",
          id_rol: "id_rol",
          DELIVERY_COMPANY_NAME: "Empresa",
          edit: "Editar",
          abaja: "Dar de baja",
        },
        requestAdapter(data) {
          let limit = `"$limit":${this.perPage}`;
          let skip = `"$skip":${(data.page - 1) * this.perPage}`;
          let sort = `"$sort[${data.orderBy}]":${data.ascending}`;
          let search = ``;
          if (data.query) {
            if (data.query["usuario_nombre"]) {
              search += `, "usuario_nombre[$like]": "%${data.query["usuario_nombre"]}%"`;
            }
            if (data.query["usuario_codigo"]) {
              search += `, "usuario_codigo[$like]": "%${data.query["usuario_codigo"]}%"`;
            }
            if (data.query["DELIVERY_COMPANY_NAME"]) {
              search += `, "DELIVERY_COMPANY_NAME[$like]": "%${data.query["DELIVERY_COMPANY_NAME"]}%"`;
            }
            if (data.query["rol"]) {
              let idRol = this.roles.filter(
                (rol) =>
                  rol.rol_nombre
                    .toUpperCase()
                    .indexOf(data.query["rol"].toUpperCase()) > -1
              );
              console.log(this.roles);
              console.log(idRol[0].id);
              // console.log(rol[0]);
              search += `, "id_rol": "${idRol[0].id}"`;
            }
            // if (data.query["updatedAt"]) {
            //   search += `, "updatedAt[$gte]": "${data.query.updatedAt.start}"`;
            //   search += `, "updatedAt[$lte]": "${data.query.updatedAt.end}"`;
            // }
          }

          return JSON.parse(`{${limit}, ${skip}, ${sort} ${search}}`);
        },
        requestFunction(data) {
          let url = `${process.env.VUE_APP_API_URL}/usuarios`;
          return this.$http.get(url, {
            params: data,
            headers: {
              Authorization:
                "Bearer  " + window.localStorage.getItem("feathers-jwt"),
            },
          });
        },
        responseAdapter(resp) {
          console.log(resp);
          return {
            data: resp.data.data,
            count: resp.data.total,
          };
        },
      },
    };
  },
  methods: {
    ...mapActions("Usuarios", {
      getUsuario: "get",
      findUsuarios: "find",
      patchUsuarios: "patch",
      crearusuario: "create",
    }),
    ...mapActions("Roles", {
      FindRoles: "find",
    }),
    ...mapActions("ListadoEmpresasTransportistas", {
      FindListadoEmpresasTransportistas: "find",
    }),
    ...mapActions("Modulos", {
      findModulos: "find",
    }),

    fnBuscarRolPorNombreYdevolverId() {
      console.log("buscar por rol");
    },
    fnDarDeBajaUsuario(id_usuario, codigo_usuario) {
      const confirmar = confirm(
        `¿Está seguro de querer dar de baja al usuario ${codigo_usuario}?, este proceso no se puede revertir.`
      );
      if (!confirmar) {
        return;
      }
      let data = {
        password: "debajaUsuario",
        status: "0",
        DE_BAJA_BY_USUARIO_CODIGO:
          this.$store.state.auth.payload.usuarios.usuario_codigo,
      };
      this.patchUsuarios([id_usuario, data])
        .then((response) => {
          let message = `Se se ha dado de baja al usuario ${codigo_usuario}`;
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fnAsignarVarUsuarioEditar(idUsuario) {
      this.padreCodigoUsuario = idUsuario;
    },
    fnCrearUsuario() {
      if (this.varNuevoUsuario.usuario_codigo != "") {
        if (this.varNuevoUsuario.password != "") {
          this.crearusuario(this.varNuevoUsuario).then((result) => {
            this.$refs.formCrearUsuario.reset();
            this.$notify({
              type: "success",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "Usuario creado con éxito",
            });
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Todos los campos son obligatorios.",
        });
      }
    },
    fnFormEncontrarRol(event) {
      // return "Buscar, filter, search en array de objetos por un atributo en particular";
      //donde ListadoDeRoles es el global
      //donde event.target.value es el "parametro"/ "atributo" por el cual quieres buscar
      //donde event.target.value es el "parametro"/ "atributo" por el cual quieres buscar
      //si tienes que buscar dentro de un arreglo de objetos porque quieres ver en donde hacen unión, según atributo
      // filtrar por array
      let rol = this.ListadoDeRoles.filter(
        (rol) => rol.id == event.target.value
      );
      // console.log(rol[0]);
      if (rol) {
        this.varNuevoUsuario.id_rol = rol[0].id;
      }
    },
    fnTableEncontrarRol(id_rol) {
      if (id_rol) {
        // return "papas";
        // console.log("buscando rol con id: " + id_rol);
        let rol = this.ListadoDeRoles.filter((rol) => rol.id == id_rol);
        // console.log(rol[0]);
        if (rol[0]) {
          return rol[0].rol_nombre;
        } else {
          return "";
        }
      }
    },
    fnCargarModulosDeUsuarioParaEditar(id_usuario) {
      // console.log("buscando in fo del  usuario" + id_usuario);

      this.getUsuario(id_usuario).then((result) => {
        // console.log("info del usuario");
        this.modalEditarModulosModulosDeUsuario = result.modulos;
        // console.log(this.modalEditarModulosModulosDeUsuario);

        this.$notify({
          type: "warning",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Estás editando al usuario: " + result.usuario_codigo,
        });
      });
    },
    fnSearchRol(id_rol) {
      // return "papas";

      let rol = this.ListadoDeRoles.filter((rol) => rol.id == id_rol);
      // console.log(rol[0]);
      return rol[0].rol_nombre;
    },
    fnEncontrarEmpresaTransportista(event) {
      let empresa = this.ListadoDeEmpresasTransportistas.filter(
        (empresa) => empresa.id == event.target.value
      );

      this.varNuevoUsuario.DELIVERY_COMPANY_ID = empresa[0].id;
      this.varNuevoUsuario.DELIVERY_COMPANY_NAME = empresa[0].nombre;

      // console.log(this.varNuevoUsuario);
    },

    fnFindInArray(objeto, id_rol) {
      return objeto.nombre == id_rol;
      // https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Array/find
    },
    fnActualizarRampaRuta(event) {
      let asignacionId = event.target.dataset.usuario_codigo;
      let data = {
        numero_rampa: event.target.value.trim(),
      };
      this.patchUsuarios([asignacionId, data])
        .then((response) => {
          let message = `Se asigno el rol ${response.rol_nombre} al usuario ${response.usuario_codigo} exitosamente`;
          this.$notify({
            type: "success",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    /**/

    const { Usuarios } = this.$FeathersVuex.api;
    Usuarios.on("patched", (result) => {
      this.$refs.tablaListadoDeUsuarios.refresh();
    });
    /**/
  },
  created() {
    /** */
    this.FindRoles({ query: {} }).then((result) => {
      // console.log("roles");
      // console.log(result.data);
      this.ListadoDeRoles = result.data;
      this.optionsVTable.roles = result.data;
    });
    /** */
    this.FindListadoEmpresasTransportistas({ query: {} }).then((result) => {
      this.ListadoDeEmpresasTransportistas = result.data;
      // console.log(result.data);
    });
    /** */
    this.findModulos({ query: {} }).then((result) => {
      this.modalEditarModulosModulosGlobal = result.data;
      // console.log("/** */ los modulos");
      // console.log(this.modalEditarModulosModulosGlobal);
    });
    /** */
  },
};
</script>
<style>
th#VueTables_th--usuario_nombre {
  width: 210px !important;
}
</style>
<style scoped>
#usuario_codigo {
  text-transform: uppercase;
}
#usuario_codigo::placeholder {
  text-transform: capitalize;
}
form > div > select {
  color: black;
}
option {
  color: black !important;
}
.formularioCreacionUsuario {
  border: 1px solid black;
  border-radius: 15px;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.btn-opcionIcono {
  border-radius: 8px;
}
</style>
