<template>
  <div class="row">
    <!-- *******************************************RESUMEN DE SECTOR******************************************* -->
    <div class="col-sm-12 col-md-7">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <h5 class="card-category">Resumen de datos por sector</h5>
        </div>
        <div class="card-body m-2">
          <div class="row">
            <div class="col-md-6">
              <select class="custom-select" @change="seleccionarRegion">
                <option selected disabled>Seleccionar un sector</option>
                <option
                  v-for="(item, index) in sectoresCampania"
                  :key="index"
                  :value="item.DELIVERY_CLIENT_REGION"
                >
                  Sector {{ item.DELIVERY_CLIENT_REGION }}
                  <small>({{item.pago}})</small>
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <date-range-picker
                v-model="dateRange"
                :append-to-body="true"
                :ranges="false"
                :locale-data="locale"
                :StartDate="new Date()"
              ></date-range-picker>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-12">
              <button
                class="btn btn-sm pull-right float-right"
                @click="mostrarDatosEnGrafica"
              >Consultar datos</button>
            </div>
          </div>
          <div
            v-for="(item, index) in detalles"
            :key="index"
            class="alert alert-info mt-1"
            role="alert"
          >{{ item.etiqueta }} : {{ item.tot }}</div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
    <!-- *******************************************GRAFICA DE SECTOR******************************************* -->
    <div class="col-sm-12 col-md-5">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <h5 class="card-category">Gráfica de sector</h5>
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-12">
              <reactive-bar-chart :height="203" :chartData="chartData"></reactive-bar-chart>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>

    <div class="col-sm-12">
      <div class="card card-chart">
        <!---->
        <div class="card-header">
          <h5 class="card-category">Rutas sin pendientes de carga ni entrega</h5>
        </div>
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-md-12">
              <v-client-table
                :data="rows"
                :columns="columns"
                :options="options"
                name="manifiestosTable"
              ></v-client-table>
            </div>
          </div>
        </div>
        <!---->
        <!---->
      </div>
    </div>
  </div>
</template>
<script>
import ReactiveBarChart from "@/components/Charts/PieChart.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      colores: [],
      detalles: [],
      sectoresCampania: [],
      chartData: null,
      locale: {
        direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
        format: "dd-mm-yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        fromLabel: "Del",
        toLabel: "Al",
        customRangeLabel: "Perosnalizado",
        daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        monthNames: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        firstDay: 1,
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      ////////////////
      columns: ["DELIVERY_CLIENT_REGION", "ROUTE_ID", "tot"],
      rows: [],
      options: {
        templates: {
          PEDIDOS: function (h, row, index) {
            if (row.PEDIDOS == null) {
              return "Pedidos asignados";
            } else if (row.PEDIDOS.length <= 20) {
              return row.PEDIDOS;
            }

            let text = row.PEDIDOS.substring(0, 12) + " ";
            let last = text.lastIndexOf(" ");
            text = text.substring(0, last);
            return text + "...";
          },
          consejeras: function (h, row, index) {
            if (row.consejeras == null) {
              return "N/A";
            }
            let consejera = row.consejeras.toLowerCase();
            if (consejera.length <= 40) {
              return consejera;
            }

            let text = consejera.substring(0, 40) + " ";
            let last = text.lastIndexOf(" ");
            text = text.substring(0, last);
            return text + "...";
          },
        },
        orderBy: {
          column: "MANIFEST_DB_ID",
          ascending: false,
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-arrow-up",
          down: "fa-arrow-down",
        },
        filterByColumn: true,
        perPage: 8,
        texts: {
          filter: "Filtrar:",
          filterBy: "Filtrar por {column}",
          count: " ",
          first: "Primero",
          last: "UUltimo",
          filter: "Filtrar:",
          limit: "",
          page: "Pág:",
          noResults: "Cargando...",
          loading: "Cargando...",
          defaultOption: "Seleccionar {column}",
          columns: "Columnas",
        },
        dateColumns: ["inicio_carga"],
        dateFormat: "DD-MM-YYYY HH:mm",
        datepickerOptions: {
          locale: {
            direction: $("#rtl").is(":checked") ? "rtl" : "ltr",
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            fromLabel: "Del",
            toLabel: "Al",
            customRangeLabel: "Perosnalizado",
            daysOfWeek: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            monthNames: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            firstDay: 1,
          },
          showDropdowns: true,
          autoUpdateInput: true,
        },
        pagination: { chunk: 10, dropdown: false },
        filterable: ["DELIVERY_CLIENT_REGION", "ROUTE_ID", "tot"],
        headings: {
          DELIVERY_CLIENT_REGION: "Sector",
          ROUTE_ID: "Ruta",
          tot: "Total pedidos",
        },
      },
    };
  },
  components: { DateRangePicker, ReactiveBarChart },
  computed: {
    ...mapGetters("Sectorescampanaactual", {
      findSectorescampanaactualInStore: "find",
    }),
    ...mapGetters("Rutasterminadas", {
      findRutasterminadasInStore: "find",
    }),
  },

  methods: {
    ...mapActions("Sectorescampanaactual", {
      findSectorescampanaactual: "find",
    }),
    ...mapActions("Rutasterminadas", {
      findRutasterminadas: "find",
    }),
    position() {
      return "center";
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date();
      }
      return classes;
    },
    seleccionarRegion(event) {
      this.region = event.target.value;
      console.log(event.target.value);
    },
    mostrarDatosEnGrafica(event) {
      console.log(this.region);
      this.$store
        .dispatch("Excelreporteporpago/find", {
          query: {
            DELIVERY_CLIENT_REGION: this.region,
            dateRange: this.dateRange,
          },
        })
        .then((result) => {
          console.log(result);
        });
      /////////////////////////////////////////////////////////////////////////////////////////
      this.$store
        .dispatch("Resumendesectorporcampania/find", {
          query: {
            DELIVERY_CLIENT_REGION: this.region,
            dateRange: this.dateRange,
          },
        })
        .then((result) => {
          let colores = [];
          let etiquetas = [];
          let bordes = [];
          let datos = [];
          this.chartData = null;
          this.detalles = [];
          result.forEach((sector) => {
            this.detalles.push({
              etiqueta: sector.STATUSTEXT,
              tot: sector.tot,
            });

            console.log(this.detalles);
            etiquetas.push(sector.STATUSTEXT);
            bordes.push("2");
            datos.push(sector.tot);

            switch (sector.STATUS) {
              case "0":
                colores.push({ start: "#fff9c4", end: "#f5ed00" });

                break;
              case "1":
                colores.push({ start: "#fff", end: "#e46953" });
                break;
              case "2":
                colores.push({ start: "#fff", end: "#41b883" });
                break;
              case "3":
                colores.push({ start: "#fff", end: "#7e57c2 " });
                break;

              default:
              // code block
            }
          });
          //
          /////////////////////////////////////////////////////////////////////////////////////////
          //colores
          var canvas = $("#teamDoughnutChart");
          var ctx = document
            .getElementById("pie-chart")
            .getContext("2d")
            .canvas.getContext("2d");
          //backgrounds
          var gradientColors = colores;
          var gradients = [];
          gradientColors.forEach(function (item) {
            var gradient = ctx.createLinearGradient(0, 0, 150, 150);
            gradient.addColorStop(0, item.start);
            gradient.addColorStop(1, item.end);
            gradients.push(gradient);
          });
          //borders
          var gradientColors2 = colores;
          var gradients2 = [];
          gradientColors2.forEach(function (item) {
            var gradient2 = ctx.createLinearGradient(0, 0, 0, 250);
            gradient2.addColorStop(0, item.start);
            gradient2.addColorStop(1, item.end);
            gradients2.push(gradient2);
          });
          //
          //
          this.chartData = {
            labels: etiquetas,
            options: {
              legend: {
                display: false,
              },
            },
            datasets: [
              {
                borderColor: gradients2,
                borderWidth: bordes,
                backgroundColor: gradients,
                data: datos,
              },
            ],
          };
        });
      /////////////////////////////////////////////////////////////////////////////////////////
    },
  },
  created() {
    this.findSectorescampanaactual({ query: {} }).then((response) => {
      this.sectoresCampania = response;
    });
    this.findRutasterminadas({ query: {} }).then((response) => {
      this.rows = response;
    });
  },
};
</script>

<style scoped>
.carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.05);
}
.custom-select {
  color: black;
}
.alert {
  padding: 0.3rem 1.25rem;
}
</style
>>
