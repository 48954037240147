// src/store/services/pedidos.js
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../feathers-client";

class Engregasmapaprincipalsector extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Engregasmapaprincipalsector";
  // Define default properties here
  static instanceDefaults() {
    return {
      ERP_DOC: "",
      ROUTE_ID: ""
    };
  }
}
const servicePath = "engregas-mapa-principal-sector";
const servicePlugin = makeServicePlugin({
  namespaced: true,
  namespace: "Engregasmapaprincipalsector",
  Model: Engregasmapaprincipalsector,
  service: feathersClient.service(servicePath),
  servicePath
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
});

export default servicePlugin;
