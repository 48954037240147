<template>
  <keep-alive>
    <VistaDetalle
      :cargarPedido="cargarPedido"
      :realizarEntrega="realizarEntrega"
      :realizarDevolucion="realizarDevolucion"
      v-if="paginado.pageNumber === 1"
    ></VistaDetalle>
    <VistaSector
      :anuladosbodega="anuladosbodega"
      :pedsconreenvio="pedsconreenvio"
      :pedsnorecibidos="pedsnorecibidos"
      :pedidosRezagados="pedidosRezagados"
      :anularPedido="anularPedido"
      :cargarPedido="cargarPedido"
      :realizarEntrega="realizarEntrega"
      :realizarDevolucion="realizarDevolucion"
      v-if="paginado.pageNumber === 2"
    ></VistaSector>
    <VistaPilotos
      :cargarPedido="cargarPedido"
      :realizarEntrega="realizarEntrega"
      :realizarDevolucion="realizarDevolucion"
      v-if="paginado.pageNumber === 3"
    ></VistaPilotos>
  </keep-alive>
</template>

<script>
import VistaDetalle from "./EntregasVistaDetalle.vue";
import VistaSector from "./EntregasVistaSector.vue";
import VistaPilotos from "./EntregasVistaPilotos.vue";
export default {
  components: { VistaDetalle, VistaSector, VistaPilotos },
  data() {
    return {
      anuladosbodega: null,
      pedsconreenvio: null,
      pedsnorecibidos: null,
      pedidosRezagados: null,
      anularPedido: null,
      cargarPedido: null,
      realizarEntrega: null,
      realizarDevolucion: null,
      testVar: 0
    };
  },
  mounted: async function() {
    if (this.$route.path == "/alcontadoycredito") {
      this.$store.state.paginado.pageNumber = 2;
      this.$store.state.paginado.pageName = "pedidos sector";
    }
    document.querySelector(
      "body > div:nth-child(1) > div.wrapper"
    ).style.overflow = "scroll";
    const {
      Anularpedidosbodega,
      Pedidosconreenvio,
      Pedidosnorecibidos,
      Rezagarpedidos,
      Anularpedido,
      Cargarpedido,
      Realizarentrega,
      Realizardevolucion
    } = this.$FeathersVuex.api;

    Pedidosconreenvio.on("patched", pedidos => {
      console.log("Pedidosconreenvio");
      console.log(pedidos);
      this.pedsconreenvio = pedidos;
    });

    Anularpedidosbodega.on("patched", pedidos => {
      console.log("Anularpedidosbodega");
      console.log(pedidos);
      this.anuladosbodega = pedidos;
    });

    Pedidosnorecibidos.on("patched", pedidos => {
      console.log("Pedidosnorecibidos");
      console.log(pedidos);
      this.pedsnorecibidos = pedidos;
    });

    Rezagarpedidos.on("patched", pedidos => {
      console.log("Rezagarpedidos");
      console.log(pedidos);
      this.pedidosRezagados = pedidos;
    });

    Anularpedido.on("patched", pedido => {
      this.anularPedido = pedido;
    });

    Cargarpedido.on("patched", pedido => {
      this.cargarPedido = pedido;
      console.log("cargar pedido");
      console.log(pedido);

      // this.$notify({
      //   type: "success",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      //   message: pedido
      // });
    });

    Realizarentrega.on("patched", pedido => {
      this.realizarEntrega = pedido;
      console.log("realizar entrega");
      console.log(pedido);

      // this.$notify({
      //   type: "danger",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      //   message: pedido
      // });
    });

    Realizardevolucion.on("patched", pedido => {
      this.realizarDevolucion = pedido;
      console.log("realizar devolucion");
      console.log(pedido);

      // this.$notify({
      //   type: "warning",
      //   verticalAlign: "top",
      //   horizontalAlign: "right",
      //   message: pedido
      // });
    });

    // contador
    // var self = this;
    // setInterval(function() {
    //   self.changeView();
    // }, 10000);
  },
  computed: {
    paginado() {
      return this.$store.state.paginado;
    }
  }
};
</script>
<style></style>
