var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{ref:"mymap",attrs:{"center":_vm.mapCenter,"map-type-id":_vm.mapTypeId,"zoom":_vm.mapZoom}},[(_vm.deliveryPath.length > 0)?_c('gmap-polyline',{ref:"polyline",attrs:{"path":_vm.deliveryPath,"options":{
        strokeWeight: 10,
        strokeColor: _vm.deliveryPathColor,
        strokeOpacity: 0.7,
        icons: [
          {
            icon: {
              path: _vm.lePate,
              scale: 4,
              fillColor: _vm.deliveryPathColor,
              fillOpacity: 1
            },
            offset: '0',
            repeat: '100px'
          }
        ]
      }},on:{"click":function($event){return _vm.fnDeleteDeliveryPath()}}}):_vm._e(),_c('cluster',{attrs:{"gridSize":30,"zoomOnClick":true,"maxZoom":21}},_vm._l((_vm.markers),function(item,index){return _c('gmap-custom-marker',{key:index,staticClass:"marcador",attrs:{"alignment":"top","marker":{ lat: item.lat, lng: item.lng }},nativeOn:{"mouseout":function($event){_vm.showByIndex = null},"mouseover":function($event){(_vm.showByIndex = index),
            _vm.FnCrearDeliveryPath(
              item.DELIVERY_ASSIGNED_TO_VEHICULE_PLATES,
              item.ERP_DOC
            )},"click":function($event){return _vm.fnventanaInformacionDeEntrega(
            item.ERP_DOC,
            'markerID' + item.ERP_DOC,
            item.contador
          )}}},[_c('img',{attrs:{"id":'markerID' + item.ERP_DOC,"src":'https://maps.google.com/mapfiles/ms/icons/' +
              item.color +
              '-dot.png'}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === index),expression:"showByIndex === index"}],staticClass:"marker-info"},[_c('span',{staticClass:"marcadorEtiqueta"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.contador > 0),expression:"item.contador > 0"}],staticClass:"marcadorContador"},[_vm._v(" "+_vm._s(item.contador)+" ")]),_vm._v(" - "+_vm._s(item.titulo)+" ")])])])}),1)],1),_c('ventanaDetalleEntrega',{directives:[{name:"show",rawName:"v-show",value:(_vm.ventanaInformacionDeEntrega),expression:"ventanaInformacionDeEntrega"}],attrs:{"historico":false,"ventanaInformacionDeEntrega":_vm.ventanaInformacionDeEntrega,"deliveryTextColor":_vm.deliveryTextColor},on:{"clicked":function($event){return _vm.fnCloseDetalleWindow($event)}}}),_c('ventanaUltimaEntrega',{directives:[{name:"show",rawName:"v-show",value:(_vm.ventanaUltimaEntrega),expression:"ventanaUltimaEntrega"}],attrs:{"realizarEntrega":_vm.realizarEntrega,"realizarDevolucion":_vm.realizarDevolucion},on:{"clicked":function($event){return _vm.fnIrMarcador($event)}}}),_c('ventanaGraficaEntregas',{directives:[{name:"show",rawName:"v-show",value:(_vm.ventanaGraficaEntregas),expression:"ventanaGraficaEntregas"}],attrs:{"realizarEntrega":_vm.realizarEntrega,"realizarDevolucion":_vm.realizarDevolucion}}),_c('DashboardMapaPrincipalGraficaSectorRuta',{directives:[{name:"show",rawName:"v-show",value:(_vm.DashboardMapaPrincipalGraficaSectorRuta),expression:"DashboardMapaPrincipalGraficaSectorRuta"}],attrs:{"realizarEntrega":_vm.realizarEntrega,"realizarDevolucion":_vm.realizarDevolucion}}),_c('fab',{attrs:{"actions":_vm.fabActions,"bg-color":_vm.bgColor},on:{"FABResumenRutaSector":_vm.FABResumenRutaSector,"FABultimaEntrega":_vm.FABultimaEntrega,"FABmapa":_vm.FABmapa}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }